import React, { useLayoutEffect, useState } from 'react';

function useWindowSize() {
  // const [size, setSize] = useState([0, 0]);
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    function updateSize() {
      // setSize([window.innerWidth, window.innerHeight]);
      if (window.innerWidth < 600) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return isMobile;
}

export default useWindowSize;
