import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";

import React, { FC, ReactNode } from "react";
import { IconButton } from "@mui/material";
import { cn } from "../../../lib/utils";

interface AlertProps {
  /**
   * The variant of alert.
   */
  variant: "info" | "success" | "warning" | "error" | "info-dark";
  /**
   * The title of the alert.
   */
  title?: string;
  /**
   * The content of the alert
   */
  content?: ReactNode;
  /**
   * The size of the alert.
   */
  size: "small" | "large";
  /**
   * Whether the alert should persist or not (user can close it)
   */
  persist: boolean;
  /**
   * Whether the alert is open or not.
   */
  open?: boolean | undefined;
  /**
   * Parents control of the open state of the alert.
   */
  setOpen?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  /**
   * The margin bottom of the alert.
   */
  mb?: string;
  mt?: string;
}

const Disclaimer: FC<AlertProps> = ({
  variant,
  title,
  content,
  size,
  persist,
  open = undefined,
  setOpen = undefined,
  mb,
  mt,
  ...props
}: AlertProps) => {
  const icon =
    variant === "info" ? (
      <div className={`text-info-20`}>
        <InfoOutlinedIcon />
      </div>
    ) : variant === "success" ? (
      <div className={`text-success-20`}>
        <CheckCircleOutlinedIcon />
      </div>
    ) : variant === "warning" ? (
      <div className={`text-warning-20`}>
        <WarningAmberIcon />
      </div>
    ) : variant === "info-dark" ? (
      <div className={`text-white`}>
        <CheckCircleOutlinedIcon />
      </div>
    ) : (
      <div className={`text-error-20`}>
        <ErrorOutlineIcon />
      </div>
    );

  const bgColor =
    variant === "info"
      ? "bg-info-95"
      : variant === "success"
      ? "bg-success-95"
      : variant === "warning"
      ? "bg-warning-95"
      : variant === "info-dark"
      ? "bg-secondary-10"
      : "bg-error-95";

  // If open is undefined, then the alert is not controlled by the parent
  return (
    <>
      {open !== undefined ? (
        <>
          {open && setOpen && (
            <main
              className={cn(
                `flex flex-row break-words whitespace-normal items-center ${bgColor} p-1 rounded-xl justify-between`,
                mb && mb,
                mt && mt,
                size === "small" ? "w-fit" : "w-full"
              )}
            >
              <div className="flex flex-row p-1">
                {/* The Icon of the alert */}
                {icon}
                {/* The title and content of the alert */}
                <div className="flex flex-col gap-1 px-4">
                  <p className="text-lg font-semibold">{title}</p>
                  {content && (
                    <p
                      className={`text-sm font-normal ${
                        variant === "info-dark" ? "text-white" : "text-light"
                      }`}
                    >
                      {content}
                    </p>
                  )}
                </div>
              </div>
              {/* The button to close the alert (if persist is false) */}
              {!persist && (
                <div
                  className={
                    variant === "info-dark" ? "text-white" : "text-light"
                  }
                >
                  <IconButton
                    color="inherit"
                    onClick={() => setOpen(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              )}
            </main>
          )}
        </>
      ) : (
        <main
          className={`flex flex-row items-center ${bgColor} p-1 w-full rounded-xl justify-between ${
            mb && mb
          } ${mt && mt}`}
        >
          <div className="flex flex-row p-1">
            {/* The Icon of the alert */}
            {icon}
            {/* The title and content of the alert */}
            <div className="flex flex-col gap-1 px-4">
              <p className="text-lg font-semibold">{title}</p>
              {content && (
                <p
                  className={`text-sm font-normal ${
                    variant === "info-dark" ? "text-white" : "text-light"
                  }`}
                >
                  {content}
                </p>
              )}
            </div>
          </div>
          {/* The button to close the alert (if persist is false) */}
          {!persist && (
            <div
              className={variant === "info-dark" ? "text-white" : "text-light"}
            >
              <IconButton color="inherit">
                <CloseIcon />
              </IconButton>
            </div>
          )}
        </main>
      )}
    </>
  );
};

export default Disclaimer;
