import React, { ReactNode } from 'react';
import cn from 'classnames';

interface Headline1Props {
  // The text inside the Headline
  children: ReactNode;
  // The size of headline
  size: 'xl' | 'l-m' | 's-xs';
  // Extra css customization
  className?: string;
}

const Headline1 = ({ children, size, className, ...props }: Headline1Props) => {
  if (size === 'xl') {
    return (
      <p className={cn('font-[500] text-[40px] leading-[1.5]', className)}>
        {children}
      </p>
    );
  } else if (size === 'l-m') {
    return (
      <p
        className={cn(
          'font-[500] text-[33px] tracking-[0.0025em] leading-[1.5] ',
          className
        )}
      >
        {children}
      </p>
    );
  } else {
    return (
      <p
        className={cn(
          'font-[600] text-[25px] tracking-[0.0025em] leading-[1.5]',
          className
        )}
      >
        {children}
      </p>
    );
  }
};

export default Headline1;
