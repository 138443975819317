import { gql } from "@apollo/client";
const GET_PAYROLL_BATCHES = gql`
  query PayrollBatches($page: Int, $pageSize: Int) {
    payrollBatches(page: $page, pageSize: $pageSize) {
      count
      payrollBatches {
        id
        name
        year
        month
        shiftCutoffDate
        payDate
        createdAt
        updatedAt
        payrollBatchStatus {
          id
          name
        }
        numOfShifts
      }
    }
  }
`;

const GET_UNPAID_PAYROLL_BATCHES = gql`
  query PayrollBatchesUnpaid {
    payrollBatchesUnpaid {
      id
      name
      shiftCutoffDate
    }
  }
`;

const GET_PAYROLL_BATCH_BY_ID = gql`
  query PayrollBatch($payrollBatchId: ID!) {
    payrollBatch(id: $payrollBatchId) {
      id
      name
      year
      month
      shiftCutoffDate
      payDate
      createdAt
      numOfShifts
      payrollBatchStatus {
        id
        name
      }
      shifts {
        id
        startLocal
        endLocal
        hours
        rate
        amount
        isVisible
        weekday
        staffType {
          id
          name
        }
        shiftStatus {
          id
          value
        }
        venue {
          id
          name
        }
        region {
          id
          name
        }
        contractorId
        user {
          id
          firstName
          lastName
        }
        payrollBatch {
          name
          payrollBatchStatus {
            id
            name
          }
        }
        shiftReport {
          shiftReportStatusId
        }
      }
    }
  }
`;

const CREATE_PAYROLL_BATCH = gql`
  mutation PayrollBatchCreate($payrollBatchArgs: PayrollBatchInput!) {
    payrollBatchCreate(payrollBatchArgs: $payrollBatchArgs) {
      code
      success
      message
      payrollBatch {
        id
        name
        year
        month
        shiftCutoffDate
        payDate
        createdAt
        updatedAt
        payrollBatchStatus {
          id
          name
        }
      }
    }
  }
`;

const UPDATE_PAYROLL_BATCH = gql`
  mutation PayrollBatchUpdate(
    $payrollBatchUpdateId: String!
    $payrollBatchArgs: PayrollBatchInput!
  ) {
    payrollBatchUpdate(
      id: $payrollBatchUpdateId
      payrollBatchArgs: $payrollBatchArgs
    ) {
      code
      message
      success
    }
  }
`;

const GET_PAYROLL_BATCH_DETAILS_BY_ID = gql`
  query PayrollBatchDetails($payrollBatchId: ID!) {
    payrollBatch(id: $payrollBatchId) {
      id
      name
      year
      month
      shiftCutoffDate
    }
  }
`;

export {
  GET_PAYROLL_BATCHES,
  GET_PAYROLL_BATCH_BY_ID,
  GET_UNPAID_PAYROLL_BATCHES,
  CREATE_PAYROLL_BATCH,
  UPDATE_PAYROLL_BATCH,
  GET_PAYROLL_BATCH_DETAILS_BY_ID,
};
