export const phoneNumberByCountry: { [key: string]: number } = {
  US: 6788694690,
  CA: 4167814263,
};

export const formatPhoneNumber = (phoneNumber: number) => {
  // Remove all non-numeric characters
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");

  // Format the number as xxx-xxx-xxxx
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }

  return null; // Return null if the input doesn't match the required format
};
