'use client';

import React, { useState, FC, HTMLProps, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { cn } from '../../../../lib/utils';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import classes from './FormFieldDate.module.css';

interface FormFieldDateProps extends HTMLProps<HTMLInputElement> {
  /**
   * The label for the date picker
   */
  label: string;
  /**
   * The assistive text for the date picker
   */
  assistiveText?: string;
  /**
   * Set to true if the input should be disabled.
   */
  disabled?: boolean;
  /**
   * Set to true if the input should be showing an error state
   */
  error?: boolean;
  /**
   * Function that is called when the date modal is closed
   */
  dateChange: (date: Date) => void;
  initialValue?: Date;
}

const FormFieldDate: FC<FormFieldDateProps> = ({
  label,
  assistiveText,
  error = false,
  disabled = false,
  dateChange,
  initialValue,
  ...props
}: FormFieldDateProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    initialValue ? initialValue : undefined
  );

  useEffect(() => {
    setSelectedDate(initialValue ? initialValue : undefined);
  }, [initialValue]);

  const mode = disabled
    ? 'border-2 border-neutral-60 bg-neutral-90 focus:outline-none'
    : error
    ? 'border border-error-30 hover:border-error-30 focus:outline-2 focus:outline-error-30'
    : 'border border-secondary-80 hover:border-secondary-50 focus:outline-2 focus:outline-primary-80';

  return (
    <div className={cn(`flex flex-col w-full text-black`, props.className)}>
      <label
        className={`px-3 text-xs font-medium  ${
          error && !disabled && 'text-error-10'
        }`}
      >
        {label}
      </label>
      <DatePicker
        popperClassName={classes['my-custom-input']}
        selected={selectedDate}
        onChange={(date) => {
          if (date) {
            setSelectedDate(date);
          }
        }}
        onCalendarClose={() => {
          if (selectedDate) {
            dateChange(selectedDate);
          }
        }}
        dateFormat='yyyy/MM/dd'
        placeholderText='yyyy/MM/dd'
        disabled={disabled}
        className={cn(
          'w-full font-normal rounded-xl px-3 py-2',
          disabled
            ? 'border-2 border-neutral-60 bg-neutral-90 focus:outline-none'
            : error
            ? 'border border-error-30 hover:border-error-30 focus:outline-2 focus:outline-error-30'
            : 'border border-secondary-80 hover:border-secondary-50 focus:outline-2 focus:outline-primary-80'
        )}
      />
      <div
        className={`px-3 text-xxs font-normal ${
          error && !disabled ? 'text-error-10' : 'text-light'
        }`}
      >
        {assistiveText}
      </div>
    </div>
  );
};

export default FormFieldDate;
