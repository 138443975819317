import { gql } from "@apollo/client";

const GET_REFUND_APPROVALS = gql`
  query GetRefundApprovals($refundApprovalFilter: RefundApprovalFilter!) {
    getRefundApprovals(refundApprovalFilter: $refundApprovalFilter) {
      id
      status {
        name
        id
      }
      type {
        name
        id
      }
      reason {
        id
        name
      }
      itemId
      requestedBy {
        firstName
        lastName
      }
      requestedDatetime
      txnId
      approvedDatetime
      approvedBy {
        firstName
        lastName
      }
      amount
      notes
      shoppingCartItem {
        product {
          league {
            name
          }
          registrationBatch {
            name
          }
        }
        shoppingCart {
          user {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const REJECT_REFUND = gql`
  mutation RejectRefund($id: Int!) {
    rejectRefund(id: $id) {
      success
      message
    }
  }
`;

const APPROVE_REFUND = gql`
  mutation ApproveRefund($ids: [Int]!) {
    approveRefund(ids: $ids) {
      success
      message
      invalidRefunds {
        id
        reason
      }
      failedRefunds {
        id
        reason
      }
      successfulRefunds {
        id
        type
        amount
      }
    }
  }
`;

const GET_REFUND_REASONS = gql`
  query GetRefundReasons {
    getRefundReasons {
      id
      name
    }
  }
`;
const GET_REFUND_STATUSES = gql`
  query GetRefundStatuses {
    getRefundStatuses {
      id
      name
    }
  }
`;
const GET_REFUND_TYPES = gql`
  query GetRefundTypes {
    getRefundTypes {
      id
      name
    }
  }
`;

export {
  GET_REFUND_APPROVALS,
  APPROVE_REFUND,
  GET_REFUND_REASONS,
  GET_REFUND_STATUSES,
  GET_REFUND_TYPES,
  REJECT_REFUND,
};
