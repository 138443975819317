import { gql } from "@apollo/client";

const SEND_EMAILS = gql`
  mutation SendEmail(
    $name: String!
    $from: String!
    $to: [String]!
    $subject: String!
    $body: String!
  ) {
    sendEmail(
      name: $name
      from: $from
      to: $to
      subject: $subject
      body: $body
    ) {
      success
      message
    }
  }
`;

const SEND_DIVISION_EMAIL = gql`
  mutation SendDivisionEmail(
    $divisionId: Int!
    $teamIds: [Int!]!
    $subject: String!
    $body: String!
    $captainsOnly: Boolean
  ) {
    sendDivisionEmail(
      divisionId: $divisionId
      teamIds: $teamIds
      subject: $subject
      body: $body
      captainsOnly: $captainsOnly
    ) {
      success
      message
    }
  }
`;

const GET_NUMBER_OF_RECIPIENTS = gql`
  query GetNumberOfRecipients(
    $getNumberOfRecipientsInput: GetNumberOfRecipientsInput!
  ) {
    getNumberOfRecipients(
      getNumberOfRecipientsInput: $getNumberOfRecipientsInput
    ) {
      success
      message
      count
    }
  }
`;

gql`
  query GetNumberOfEmailMarketingRecipientsByEmailMarketingFilterId($id: Int!) {
    getNumberOfEmailMarketingRecipientsByEmailMarketingFilterId(id: $id) {
      success
      message
      count
    }
  }
`;

const SEND_TEST_EMAIL = gql`
  mutation SendTestEmail {
    sendTestEmail
  }
`;

gql`
  mutation SendTestEmailInstance($instanceId: Int!) {
    sendTestEmailInstance(instanceId: $instanceId) {
      success
      message
    }
  }
`;

const GET_EMAIL_TEMPLATES = gql`
  query GetEmailTemplates($typeId: Int) {
    getEmailTemplates(typeId: $typeId) {
      id
      name
      subject
      body
      updatedBy
      updatedAt
      typeId
    }
  }
`;
const GET_EMAIL_TEMPLATE_TYPES = gql`
  query GetEmailTemplateTypes {
    getEmailTemplateTypes {
      id
      name
    }
  }
`;

const CREATE_EMAIL_TEMPLATE = gql`
  mutation CreateEmailTemplate($emailTemplateInput: EmailTemplateInput) {
    createEmailTemplate(emailTemplateInput: $emailTemplateInput) {
      emailTemplate {
        id
        name
        subject
        body
        updatedBy
        updatedAt
        typeId
      }
      message {
        message
        success
      }
    }
  }
`;

const UPDATE_EMAIL_TEMPLATE = gql`
  mutation updateEmailTemplate($emailTemplateInput: EmailTemplateInput) {
    updateEmailTemplate(emailTemplateInput: $emailTemplateInput) {
      message
      success
    }
  }
`;

const GET_EMAIL_LOGS_BY_USER = gql`
  query GetEmailLogsByUser($email: String!, $page: Int, $pageSize: Int) {
    getEmailLogsByUser(email: $email, page: $page, pageSize: $pageSize) {
      count
      email {
        body
        createdAt
        from
        id
        subject
      }
    }
  }
`;

const GET_EMAIL_LOGS_MAILGUN_BY_USER = gql`
  query GetEmailLogsMailgunByUser($email: String!) {
    getEmailLogsMailgunByUser(email: $email) {
      mailgun {
        body
        createdAt
        from
        id
        status
        subject
        clicked
        opened
      }
    }
  }
`;

export {
  SEND_EMAILS,
  SEND_TEST_EMAIL,
  SEND_DIVISION_EMAIL,
  GET_EMAIL_TEMPLATES,
  CREATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATE_TYPES,
  GET_NUMBER_OF_RECIPIENTS,
  GET_EMAIL_LOGS_BY_USER,
  GET_EMAIL_LOGS_MAILGUN_BY_USER,
};
