import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import {
  getCGToDivision,
  getCapacityGroupOverview,
  getCapacityGroups,
  cleanupCapacityOverview,
} from "../../app/venueMasterSlice";
import { FormRowSelect } from "../UI";
import classes from "./CapacityGroupOverview.module.css";
import { Card, CardContent, Typography } from "@mui/material";
import Gameslots from "./Gameslots";
import CollapseVenue from "../UI/CollapseVenue";
import {
  CapacityGroup,
  CgOverviewFilters,
  VenuesListType,
} from "../../types/types";
import dayjs from "dayjs";

const CapacityGroupOverview = () => {
  const initialSelectedDivision = {
    id: 0,
    name: "",
  };

  const dispatch = useDispatch<AppDispatch>();
  const [capacityGroupId, setCapacityGroupId] = React.useState<number>(0);
  const [selectedDivision, setSelectedDivision] = React.useState<
    typeof initialSelectedDivision
  >(initialSelectedDivision);

  const [dateRange, setDateRange] = React.useState({
    startDate: dayjs().format("YYYY-MM-DD H:mm:ss"),
    endDate: dayjs().add(14, "days").format("YYYY-MM-DD H:mm:ss"),
    day: 0,
  });

  const [cgOverviewFilters, setCGOverviewFilters] =
    React.useState<CgOverviewFilters>({
      startDate: dayjs().format("YYYY-MM-DD H:mm:ss"),
      endDate: dayjs().add(14, "days").format("YYYY-MM-DD H:mm:ss"),
      day: 1,
      regionId: 1,
      venues: [],
      capacityGroupId: capacityGroupId,
      durationInterval: 60,
    });

  const {
    isLoading,
    capacityGroups,
    divisionsForCapacityGroup,
    capacityGroupOverview,
  }: any = useSelector((state: RootState) => state.venueMaster);

  useEffect(() => {
    dispatch(getCapacityGroups(""));
    return () => {
      dispatch(cleanupCapacityOverview(""));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCGToDivision(capacityGroupId));
    dispatch(getCapacityGroupOverview(cgOverviewFilters));
  }, [dispatch, cgOverviewFilters, capacityGroupId]);

  const handleDivisionCardSelection = (divisionId: number, name: string) => {
    setSelectedDivision({ id: +divisionId, name });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let venuesIds: string[] = [];
    let dayOfWeek: number = 0;
    const capacityGroup = capacityGroups.find(
      (capacity: CapacityGroup) => capacity.id === e.target.value
    );
    let min = dayjs().format("YYYY-MM-DD H:mm:ss");
    let max = dayjs().add(14, "days").format("YYYY-MM-DD H:mm:ss");
    if (capacityGroup && capacityGroup.sessions) {
      const startDateArray = capacityGroup.sessions.map(
        (session: any) => new Date(session.startDate)
      );
      min = dayjs(Math.min(...startDateArray)).format("YYYY-MM-DD H:mm:ss");
      const endDateArray = capacityGroup.sessions.map(
        (session: any) => new Date(session.endDate)
      );
      max = dayjs(Math.max(...endDateArray)).format("YYYY-MM-DD H:mm:ss");
      capacityGroup.sessions.map((session: any) => {
        dayOfWeek = session.dayOfWeek;
        if (session.venues) {
          const ids = session.venues.map((venue: any) => venue.id);
          venuesIds = ids;
        }
      });
    }

    Promise.all([
      setCapacityGroupId(+e.target.value),
      setCGOverviewFilters({
        ...cgOverviewFilters,
        day: +dayOfWeek,
        startDate: min,
        endDate: max,
        venues: venuesIds,
        capacityGroupId: +e.target.value,
      }),
      setDateRange({
        ...dateRange,
        day: dayOfWeek,
        startDate: min,
        endDate: max,
      }),
    ]);
  };

  const list_to_tree = (list: VenuesListType[]) => {
    const map: any = {};
    let node;
    const roots = [];
    let i = 0;

    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parentId !== null) {
        // if you have dangling branches check that map[node.parentId] exists
        if (list[map[node.parentId]]) {
          list[map[node.parentId]].children.push(node);
        } else {
          roots.push(node);
        }
      } else {
        roots.push(node);
      }
    }
    return roots;
  };

  let temp: VenuesListType[] = capacityGroupOverview.map(
    (venue: VenuesListType) => ({
      ...venue,
    })
  );

  const venueOverviewData = list_to_tree(temp);

  const venueOverviewTable = (
    <Fragment>
      <FormRowSelect
        value={cgOverviewFilters.durationInterval}
        labelText="Duration Interval"
        list={[
          { id: 15, name: "15 min" },
          { id: 30, name: "30 min" },
          { id: 45, name: "45 min" },
          { id: 60, name: "60 min" },
        ]}
        handleChange={(event) =>
          setCGOverviewFilters({
            ...cgOverviewFilters,
            durationInterval: +event.target.value,
          })
        }
      />
      {venueOverviewData &&
        venueOverviewData.map((row: VenuesListType) => (
          <CollapseVenue
            row={row}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            day={dateRange.day}
            selectedDivision={selectedDivision}
            cgOverviewFilters={cgOverviewFilters}
          />
        ))}
    </Fragment>
  );

  const divisionRadioButtons = (
    <div>
      {divisionsForCapacityGroup.sessions?.map((session: any) => {
        return session.divisions.map((division: any) => {
          return (
            <div className={classes.division__radio__container}>
              <input
                type="radio"
                id={division.id}
                name="division"
                value={division.id}
                className={classes.division__radio__input}
              />
              <label htmlFor={division.id}>
                <Card
                  key={division.id}
                  sx={{
                    minWidth: "100px",
                    maxWidth: "250px",
                    marginBottom: "10px",
                    border: `2px solid ${
                      selectedDivision.id === +division.id
                        ? `${division.vmColor}`
                        : "var(--grey-100)"
                    }`,
                  }}
                  className={classes.divisionCard}
                  onClick={() => {
                    handleDivisionCardSelection(division.id, division.name);
                  }}
                >
                  <CardContent className={classes.cardContent}>
                    <Typography variant="body2">
                      {" "}
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor: division.vmColor,
                          height: "10px",
                          width: "10px",
                          marginRight: "5px",
                        }}
                      ></div>
                      {division.name}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      {`No. of weekly slots: `}
                      {session.setCapacity / 2}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      {`Duration: `}
                      {session.league.gameTimeslot}
                    </Typography>
                  </CardContent>
                </Card>
              </label>
            </div>
          );
        });
      })}
    </div>
  );

  return (
    <main>
      <h1>Capacity Group Overview</h1>
      {/* Select a capacity group to view its divisions */}
      <div>
        <FormRowSelect
          name="capacityGroupId"
          labelText="Capacity Group"
          value={capacityGroupId}
          list={[
            ...capacityGroups,
            ...[{ id: "0", name: "Select Capacity Group" }],
          ]}
          width="100%"
          handleChange={handleChange}
        />
      </div>
      {/* Divisions */}
      <div>{divisionRadioButtons}</div>

      {/* <div>{divisionCards}</div> */}
      {/* CG overview */}
      <h4>Venue Overview</h4>
      {capacityGroupOverview.length > 0 && (
        <div className={classes.table__contracts}>{venueOverviewTable}</div>
      )}
      {!(capacityGroupOverview.length > 0) && (
        <div className={classes.table__contracts}>No Data Found</div>
      )}
      {/* GameSlots */}
      {/* <div>
        {gameslots.map((gs: any, index: number) => {
          return <div>{`${gs.id} ${gs.division?.vmName}`}</div>;
        })}
      </div> */}
      <div>
        <Gameslots
          selectedCapacityGroupId={capacityGroupId}
          cgOverviewFilters={cgOverviewFilters}
        />
      </div>
    </main>
  );
};

export default CapacityGroupOverview;
