import React, { FC, HTMLProps, ReactNode, useEffect, useState } from 'react';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import * as Select from '@radix-ui/react-select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface FormFieldSelectProps extends HTMLProps<HTMLSelectElement> {
  /**
   * The placeholder for the select
   */
  placeholder: string;
  /**
   * The label for the select (text above the field)
   */
  label?: string;
  /**
   * The assitive text for the field (text under the field)
   */
  assistiveText?: string;
  /**
   * Set to true if the input should be disabled.
   */
  disabled?: boolean;
  /**
   * Set to true if the input should be showing an error state
   */
  error?: boolean;
  /**
   * The content inside the select element
   */
  // children: { id: number; name: string; icon?: any }[];
  children: any[];
  /**
   * The function that is called whenever the input changes
   */
  inputChange: (data: any) => void;

  initialValue?: string;
}

/**
 *
 * @param children: Takes an array of strings as it's children and displays them as select options.
 */
const FormFieldDropdown: FC<FormFieldSelectProps> = ({
  placeholder,
  label,
  assistiveText,
  disabled = false,
  error = false,
  children,
  inputChange,
  initialValue,
  ...props
}: FormFieldSelectProps) => {
  const [inputVal, setInputVal] = useState(initialValue ? initialValue : '0');

  useEffect(() => {
    setInputVal(initialValue ? initialValue : '0');
  }, [initialValue]);

  const mode = disabled
    ? 'border-2 border-neutral-60 bg-neutral-90 focus:outline-none bg-white'
    : error
    ? 'border border-error-30 hover:border-error-30 focus:outline-2 focus:outline-error-30 bg-white'
    : 'border border-secondary-80 hover:border-secondary-50 focus:outline-2 focus:outline-primary-80 bg-white';

  return (
    <div className={`flex flex-col w-full text-black`}>
      <div
        className={`px-3 text-xs font-medium  ${
          error && !disabled && 'text-error-10'
        }`}
      >
        {label}
      </div>
      <div className="relative flex flex-row items-center gap-2">
        <Select.Root
          value={inputVal}
          onValueChange={(value) => {
            inputChange(value);
            setInputVal(value);
          }}
        >
          <Select.Trigger
            className={`${mode} w-full flex justify-between items-center rounded-xl px-3 py-2 my-0.5 font-normal text-sm`}
            aria-label="Gender"
            disabled={disabled}
            defaultValue={'Select'}
          >
            <Select.Value placeholder={placeholder} className="text-light" />
            <Select.Icon>
              <ExpandMoreIcon />
            </Select.Icon>
          </Select.Trigger>
          <Select.Portal>
            <Select.Content className="z-50 justify-start bg-white shadow-lg rounded-xl">
              <Select.ScrollUpButton className="flex items-center justify-center h-[25px] bg-white cursor-default">
                <ExpandMoreIcon />
              </Select.ScrollUpButton>
              <Select.Viewport className="p-1">
                <Select.Group className="z-50">
                  {children.map((item, i) => {
                    return (
                      <SelectItem key={i} value={item.id} className="z-50">
                        <div className="flex items-center gap-2">
                          {item.icon}
                          {item.name}
                        </div>
                      </SelectItem>
                    );
                  })}
                </Select.Group>
              </Select.Viewport>
              <Select.ScrollDownButton className="flex items-center justify-center h-[25px] bg-white cursor-default">
                <ExpandLessIcon />
              </Select.ScrollDownButton>
            </Select.Content>
          </Select.Portal>
        </Select.Root>
        {/* The error icon will appear to the right of the input if it's in an error state */}
        {error && !disabled && (
          <ErrorOutlineOutlinedIcon className="absolute right-2 text-error-30" />
        )}
      </div>
      <div
        className={`px-3 text-xxs font-normal ${
          error && !disabled ? 'text-error-10' : 'text-light'
        }`}
      >
        {assistiveText}
      </div>
    </div>
  );
};

interface SelectItemProps extends HTMLProps<HTMLSelectElement> {
  /**
   * The text for the select item
   */
  value: number;
  /**
   * The children of the select item (the text)
   */
  children: ReactNode;
}

const SelectItem: FC<SelectItemProps> = ({
  value,
  children,
  ...props
}: SelectItemProps) => {
  return (
    <Select.Item
      className={
        'text-sm font-normal justify-start leading-3 rounded-md flex items-center h-[25px] pr-[35px] pl-[25px] relative select-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-primary-95'
      }
      value={value.toString()}
    >
      <Select.ItemText>{children}</Select.ItemText>
    </Select.Item>
  );
};

export default FormFieldDropdown;
