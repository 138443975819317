import React, { useEffect, useState } from "react";
import Button from "../UI/Button/Button";
import Card from "../UI/Card/Card";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import FormField from "../UI/FormField/FormField";
import BreadCrumbs from "../UI/Breadcrumbs/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { getVendor, getVendorTypes } from "../../app/venueMasterSlice";
import { AppDispatch, RootState } from "../../app/store";
import FormFieldDropdown from "../UI/FormField/FormFieldDropdown/FormFieldDropdown";
import { Vendor } from "../../types/types";
import { useMutation } from "@apollo/client";
import { CREATE_VENDOR, UPDATE_VENDOR } from "../../graphql/queries/vendor";
import { useNavigate, useParams } from "react-router-dom";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";

const initialVendor: Vendor = {
  id: null,
  name: "",
  vendorType: 0,
  paymentMethod: "",
  primaryContact: "",
  primaryEmail: "",
  primaryPhone: "",
  afterHoursPhone: "",
};

const CreateVendor = () => {
  const publicUrl = process.env.PUBLIC_URL;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const params = useParams();
  const {
    isLoading,
    vendorTypes,
    vendor: vendorById,
  }: any = useSelector((state: RootState) => state.venueMaster);
  const [vendor, setVendor] = useState<Vendor>(initialVendor);
  const [CreateVendor, { data, loading, error }] = useMutation(CREATE_VENDOR);
  const [UpdateVendor] = useMutation(UPDATE_VENDOR);
  const [isEditVendor, setIsEditVendor] = useState<string | null>(
    params.id ? params.id : null
  );

  const handleCreateVendor = () => {
    if (isEditVendor) {
      UpdateVendor({
        variables: {
          vendorUpdateId: isEditVendor,
          vendorArgs: {
            name: vendor.name,
            vendorTypeId: vendor.vendorType,
            paymentMethod: vendor.paymentMethod,
            primaryContact: vendor.primaryContact,
            primaryEmail: vendor.primaryEmail,
            primaryPhone: vendor.primaryPhone,
            afterHoursPhone: vendor.afterHoursPhone,
          },
        },
      })
        .then((res) => {
          if (res.data.vendorUpdate.success) {
            setVendor(initialVendor);
            dispatch(displayAlertSuccess(res.data.vendorUpdate.message));
            setIsEditVendor(null);
            navigate(`${publicUrl}/ops/vendors`);
          } else {
            dispatch(displayAlertError(res.data.vendorUpdate.message));
          }
        })
        .catch((err) => {
          dispatch(displayAlertError(err.message));
        });
    } else {
      CreateVendor({
        variables: {
          vendorArgs: {
            name: vendor.name,
            vendorTypeId: vendor.vendorType,
            paymentMethod: vendor.paymentMethod,
            primaryContact: vendor.primaryContact,
            primaryEmail: vendor.primaryEmail,
            primaryPhone: vendor.primaryPhone,
            afterHoursPhone: vendor.afterHoursPhone,
          },
        },
      })
        .then((res) => {
          if (res.data.vendorCreate.success) {
            setVendor(initialVendor);
            dispatch(displayAlertSuccess(res.data.vendorCreate.message));
            setIsEditVendor(null);
            navigate(`${publicUrl}/ops/vendors`);
          } else {
            dispatch(displayAlertError(res.data.vendorCreate.message));
          }
        })
        .catch((err) => {
          dispatch(displayAlertError(err.message));
        });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVendor((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (!vendorTypes.length) {
      dispatch(getVendorTypes());
    }
    if (isEditVendor) {
      dispatch(getVendor(isEditVendor)).then((res: any) => {
        if (!res.payload) {
          dispatch(displayAlertError("Vendor not found"));
          setIsEditVendor(null);
          navigate(`${publicUrl}/ops/vendor`);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (vendorById && isEditVendor) {
      setVendor({
        ...vendorById,
        vendorType: vendorById.vendorType?.id
          ? parseInt(vendorById.vendorType.id)
          : 0,
      });
    }
  }, [vendorById]);

  const pathsBreadcrumbs = [
    { name: "Vendors", url: "/ops/vendors" },
    { name: "Vendor Details", url: "/ops/vendors" },
  ];

  return (
    <main>
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/ops/vendors"
      ></BreadCrumbs>
      <div className="flex flex-row justify-between w-full mt-6">
        <Headline1Variable>
          {isEditVendor ? "Edit Vendor" : "Create New Vendor"}
        </Headline1Variable>
        <div className="h-10">
          <Button
            variant="primary"
            onClick={handleCreateVendor}
          >
            {isEditVendor ? (
              <span> Edit Vendor</span>
            ) : (
              <span> Create Vendor</span>
            )}
          </Button>
        </div>
      </div>
      <Card className="mt-6">
        <Subtitle1>Vendor Details</Subtitle1>
        <div className="flex flex-row w-full gap-4 mt-6">
          <FormField
            initialValue={vendor.name ? vendor.name : ""}
            inputChange={handleChange}
            name="name"
            label="Vendor Name"
          ></FormField>
          <FormFieldDropdown
            initialValue={
              vendor.vendorType ? vendor.vendorType.toString() : "0"
            }
            inputChange={(value) => {
              setVendor((prevState) => ({
                ...prevState,
                vendorType: parseInt(value),
              }));
            }}
            name="vendorType"
            label="Vendor Type"
            placeholder="Vendor Type"
          >
            {[{ id: 0, name: "Select Vendor Type" }, ...vendorTypes]}
          </FormFieldDropdown>
          <FormField
            initialValue={vendor.paymentMethod ? vendor.paymentMethod : ""}
            inputChange={handleChange}
            name="paymentMethod"
            label="Payment Method"
          ></FormField>
        </div>
      </Card>
      <Card className="mt-6">
        <Subtitle1>Contact Details</Subtitle1>
        <div className="flex flex-row w-full gap-4 mt-6">
          <FormField
            initialValue={vendor.primaryContact ? vendor.primaryContact : ""}
            inputChange={handleChange}
            name="primaryContact"
            label="Primary Contact"
          ></FormField>
          <FormField
            initialValue={vendor.primaryEmail ? vendor.primaryEmail : ""}
            inputChange={handleChange}
            name="primaryEmail"
            label="Primary Email"
          ></FormField>
          <FormField
            initialValue={vendor.primaryPhone ? vendor.primaryPhone : ""}
            inputChange={handleChange}
            name="primaryPhone"
            label="Primary Phone"
          ></FormField>
          <FormField
            initialValue={vendor.afterHoursPhone ? vendor.afterHoursPhone : ""}
            inputChange={handleChange}
            name="afterHoursPhone"
            label="After Hours Phone"
          ></FormField>
        </div>
      </Card>
      <div className="flex justify-end mt-6">
        <Button
          variant="primary"
          onClick={handleCreateVendor}
        >
          {isEditVendor ? (
            <span> Edit Vendor</span>
          ) : (
            <span> Create Vendor</span>
          )}
        </Button>
      </div>
    </main>
  );
};

export default CreateVendor;
