import { gql } from "@apollo/client";
const CREATE_SHIFT_NOTE = gql`
  mutation ShiftNoteCreate($shiftNoteArgs: ShiftNoteCreateInput) {
    shiftNoteCreate(shiftNoteArgs: $shiftNoteArgs) {
      message
      code
      shiftNote {
        id
      }
      success
    }
  }
`;

const GET_SHIFT_NOTES_BY_SHIFTID = gql`
  query ShiftNotesById($shiftId: ID) {
    shiftNotesById(shiftId: $shiftId) {
      id
      note
      public
      shiftId
      user {
        id
        email
      }
      createdAt
    }
  }
`;

export { CREATE_SHIFT_NOTE, GET_SHIFT_NOTES_BY_SHIFTID };
