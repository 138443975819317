import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  Box,
  IconButton,
  Button,
  Tooltip,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, { Fragment, useEffect } from 'react';
import classes from './CollapseVenue.module.css';
import moment from 'moment';
import { startOfWeek, addWeeks, differenceInDays } from 'date-fns';
import { VenuesListType } from '../../types/types';
import { useMutation } from '@apollo/client';
import {
  GET_GAMESLOTS,
  GET_GAMESLOT_BY_ID,
  UPDATE_GAMESLOT,
} from '../../graphql/queries/gameslot';
import { useDispatch } from 'react-redux';
import {
  getCapacityGroupOverview,
  getGameslots,
} from '../../app/venueMasterSlice';
import { AppDispatch } from '../../app/store';

interface collapseVenueProps {
  row: VenuesListType;
  startDate: string;
  endDate: string;
  day: number;
  selectedDivision?: { id: number; name: string } | undefined;
  cgOverviewFilters?: any;
}

const CollapseVenue: React.FC<collapseVenueProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { row, startDate, endDate, day, selectedDivision, cgOverviewFilters } =
    props;

  const [dateRange, setDateRange] = React.useState({
    startDate,
    endDate,
    day,
  });

  const [UpdateGameslot, { dataUpdate, loadingUpdate, errorUpdate }]: any =
    useMutation(UPDATE_GAMESLOT);

  useEffect(() => {
    setDateRange({ startDate, endDate, day });
  }, [startDate, endDate, day]);

  const [open, setOpen] = React.useState(true);

  const diffWeeks = Math.ceil(
    differenceInDays(
      new Date(dateRange.endDate),
      new Date(dateRange.startDate)
    ) / 6
  );

  const COLUMNS = ['time', 'duration'];

  for (var i = 0; i < diffWeeks; i++) {
    const dayFilter = dateRange.day;
    const date = addWeeks(
      startOfWeek(new Date(dateRange.startDate), {
        weekStartsOn: dayFilter as any,
      }),
      i + 1
    );
    const header = moment(date).local().format('YYYY-MM-DD');
    COLUMNS.push(header);
  }

  const handleAssociateDivision = (
    e: React.MouseEvent<HTMLButtonElement>,
    {
      gameslotId,
      divisionId,
    }: {
      gameslotId: number;
      divisionId: number | undefined;
    }
  ) => {
    UpdateGameslot({
      variables: {
        gameslotArgs: {
          id: gameslotId,
          gameslotInput: { divisionId: selectedDivision?.id },
        },
      },
      // optimisticResponse: {
      //   gameslotUpdate: {
      //     __typename: 'GameslotResponse',
      //     code: 200,
      //     success: true,
      //     message: `Gameslot has been updated `,
      //     gameslot: {
      //       id: gameslotId.toString(),
      //       __typename: 'Gameslot',
      //       division: {
      //         id: selectedDivision?.id.toString(),
      //         name: selectedDivision?.name,
      //         __typename: 'Division',
      //       },
      //     },
      //   },
      // },
      // update: (cache: any, { data: { gameslotUpdate } }: any) => {
      //   const { gameslot } = gameslotUpdate;

      //   const { gameslots } = cache.readQuery({
      //     query: GET_GAMESLOTS,
      //     variables: {
      //       gameslotFilters: {
      //         capacityGroupId: cgOverviewFilters.capacityGroupId,
      //       },
      //     },
      //   });

      //   cache.writeQuery({
      //     query: GET_GAMESLOTS,
      //     variables: {
      //       gameslotFilters: {
      //         capacityGroupId: cgOverviewFilters.capacityGroupId,
      //       },
      //     },
      //     data: {
      //       gameslots: gameslots.map((gs: any) =>
      //         gs.id === gameslotId ? gameslot : gs
      //       ),
      //     },
      //   });

      //   const dataAfterWrite = cache.readQuery({
      //     query: GET_GAMESLOTS,
      //     variables: {
      //       gameslotFilters: {
      //         capacityGroupId: cgOverviewFilters.capacityGroupId,
      //       },
      //     },
      //   });
      // },
    }).then(() => {
      dispatch(getCapacityGroupOverview({ ...cgOverviewFilters }));
      dispatch(
        getGameslots({ capacityGroupId: cgOverviewFilters.capacityGroupId })
      );
    });
  };

  return (
    <Fragment>
      <div className={classes.collapse__title}>
        <IconButton size="small" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <h5>{row.name}</h5>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ margin: 2 }}>
          {row.overview && (
            <Table size="small">
              <TableHead>
                {COLUMNS.map((column: string) => (
                  <TableCell
                    sx={{
                      bgcolor: 'var(--primary-blue-400)',
                      color: 'white',
                      fontSize: '18px',
                      textAlign: 'center',
                    }}
                    align="right"
                  >
                    {column}
                  </TableCell>
                ))}
              </TableHead>
              <TableBody>
                {row.overview &&
                  row.overview.length > 0 &&
                  row.overview.map((rows: any, index: number) => (
                    <TableRow key={rows.time + '-' + index}>
                      {COLUMNS.map((key: string) => (
                        <TableCell
                          className={classes[rows[key]?.status || '']}
                          sx={{ textAlign: 'center', fontSize: '18px' }}
                          key={row.name + '-' + key}
                        >
                          {!(rows[key] && rows[key].status) && rows[key]}
                          {rows[key] &&
                            rows[key].status &&
                            rows[key].gameslot && (
                              <div className={classes.button_container}>
                                {rows[key].gameslot.map((gameslot: any) => (
                                  <Tooltip title={gameslot.text} arrow>
                                    <span>
                                      <Button
                                        disabled={gameslot.extra}
                                        sx={{
                                          background:
                                            gameslot.extra &&
                                            gameslot.division.id
                                              ? `var(--grey-200)`
                                              : gameslot.division.vmColor,
                                        }}
                                        onClick={(e) =>
                                          handleAssociateDivision(e, {
                                            gameslotId: gameslot.id,
                                            divisionId: selectedDivision?.id,
                                          })
                                        }
                                      >
                                        {gameslot.division.name ||
                                          gameslot.text}
                                      </Button>
                                    </span>
                                  </Tooltip>
                                ))}
                              </div>
                            )}
                          {rows[key] &&
                            rows[key].status &&
                            !rows[key].gameslot &&
                            rows[key].status}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
          {row.children &&
            row.children.length > 0 &&
            row.children.map((a: VenuesListType) => {
              return (
                <CollapseVenue
                  row={a}
                  startDate={startDate}
                  endDate={endDate}
                  day={day}
                  selectedDivision={selectedDivision}
                  cgOverviewFilters={cgOverviewFilters}
                />
              );
            })}
        </Box>
      </Collapse>
    </Fragment>
  );
};

export default CollapseVenue;
