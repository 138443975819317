import React, { FC, useEffect, useState } from "react";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import Subtitle2 from "../../Text/Subtitle/Subtitle2";
import { cn } from "../../../../lib/utils";

interface TabGroupProps {
  /**
   * An array of strings for the values of the button group
   */
  content: string[];
  /**
   * The function that is called whenever the input changes
   */
  inputChange: (data: any) => void;
  /**
   * Extra styling to add to the button group
   */
  className?: string;
}

const TabGroup: FC<TabGroupProps> = ({
  content,
  inputChange,
  className,
  ...props
}: TabGroupProps) => {
  const [value, setValue] = useState<string>(content[0]);

  return (
    <ToggleGroup.Root
      className={cn(
        "flex flex-row w-full border-b border-b-secondary-90 max-w-lg",
        className
      )}
      type="single"
      defaultValue="center"
      aria-label="Text alignment"
      onValueChange={(value) => {
        if (value) {
          setValue(value);
          inputChange(value);
        }
      }}
      value={value}
    >
      {content.map((item, key) => {
        return (
          <ToggleGroup.Item
            className="data-[state=on]:border-b-[6px] data-[state=on]:border-tertiary-50 data-[state=off]:text-light data-[state=off]:border-b-transparent flex items-center justify-center w-full py-4 px-2 border-b-[6px] border-b-secondary-90"
            value={item}
            aria-label={`Button ${key}`}
            key={key}
          >
            <Subtitle2 className="whitespace-nowrap">{item}</Subtitle2>
          </ToggleGroup.Item>
        );
      })}
    </ToggleGroup.Root>
  );
};

export default TabGroup;
