import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const VenueHome = () => {
  return (
    <main>
      <h3>This is the OPS Venue home page</h3>
      <div style={{ display: "flex", gap: "1rem" }}>
        <Link to={"/ops/venues"}>
          <Button
            sx={{
              color: "var(--black)",
              bgcolor: "var(--primary-green-400)",
              "&:hover": { bgcolor: "var(--primary-green-500)" },
              borderRadius: "0.5rem",
            }}
          >
            Venues
          </Button>
        </Link>
        <Link to={"/ops/contracts"}>
          <Button
            sx={{
              color: "var(--black)",
              bgcolor: "var(--primary-green-400)",
              "&:hover": { bgcolor: "var(--primary-green-500)" },
              borderRadius: "0.5rem",
            }}
          >
            Contracts
          </Button>
        </Link>
        <Link to={"/ops/venue-overview"}>
          <Button
            sx={{
              color: "var(--black)",
              bgcolor: "var(--primary-green-400)",
              "&:hover": { bgcolor: "var(--primary-green-500)" },
              borderRadius: "0.5rem",
            }}
          >
            Venue Overview
          </Button>
        </Link>
        <Link to={"/ops/vendors"}>
          <Button
            sx={{
              color: "var(--black)",
              bgcolor: "var(--primary-green-400)",
              "&:hover": { bgcolor: "var(--primary-green-500)" },
              borderRadius: "0.5rem",
            }}
          >
            Vendors
          </Button>
        </Link>
      </div>
    </main>
  );
};

export default VenueHome;
