'use client';

import React, { FC, HTMLProps } from 'react';
import { cn } from '../../../../lib/utils';

interface FormFieldFileProps extends HTMLProps<HTMLInputElement> {
  /**
   * The function that is called whenever the input changes
   */
  onChange: (data: any) => void;
  /**
   * Input text
   */
  text?: string;
  /**
   * file accept type
   */
  accept?: string;
  /**
   * multiple file option
   */
  multiple?: boolean;
  /**
   * multiple file option
   */
  icon?: any;
}

const FormFieldFile: FC<FormFieldFileProps> = ({
  onChange,
  text,
  accept,
  icon,
  multiple = false,
  ...props
}: FormFieldFileProps) => {
  const mode =
    'border border-secondary-80 hover:border-secondary-50 focus:outline-2 focus:outline-primary-80';

  return (
    <div className={`flex flex-col w-full text-black`}>
      <div className="relative flex flex-row items-center gap-2">
        <label
          htmlFor="files"
          className={cn(
            'w-full font-normal rounded-xl px-3 py-2 my-0.5 flex justify-center',
            mode
          )}
        >
          {icon && <div className="pr-2">{icon}</div>}
          {text}
        </label>
        <input
          id="files"
          type="file"
          onChange={onChange}
          accept={accept || 'image/png, image/gif, image/jpeg'}
          multiple={multiple}
          hidden
        />
      </div>
    </div>
  );
};

export default FormFieldFile;
