import React, { useEffect, useState } from "react";
import moment from "moment";
import { FormRow, FormRowSelect, LoadingMaterialUI } from "../../UI";
import DeleteShift from "./DeleteShift";
import classes from "./AllShifts.module.css";
import {
  getThirdPartyContractorShifts,
  getThirdPartyContractors,
  handleThirdPartyContractorFilters,
  deleteShift,
  getAllLocations,
  editShift,
  addShiftNoteId,
} from "../../../app/shiftSlice";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../app/store";

const initialStateEdit = {
  id: 0,
  locationId: 1,
  staffTypeId: 1,
  startDate: "",
  endDate: "",
  startTime: "",
  endTime: "",
  isShiftOvernight: false,
  contractorId: 0,
  emailChecked: [],
  rate: 0,
  regionId: 0,
  assignedStaffId: 0,
};

const AllShiftsContractor = () => {
  const {
    thirdPartyContractorShifts,
    isLoading,
    thirdPartyContractorFilters,
    thirdPartyContractorOptions,
    locationsOptions,
  } = useSelector((state: RootState) => state.shift);

  const { selectedRegions }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [isModalVisible, setModalVisible] = useState(false);
  const [deleteShiftId, setdeleteShiftId] = useState("");

  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(initialStateEdit);

  const publicUrl = process.env.PUBLIC_URL;

  const handleEditActionModal = (shift: any) => {
    const startDate = moment(shift.shift.startLocal).format("YYYY-MM-DD");
    const endDate = moment(shift.shift.endLocal).format("YYYY-MM-DD");
    const startTime = moment(shift.shift.startLocal).format("HH:mm");
    const endTime = moment(shift.shift.endLocal).format("HH:mm");
    const contractorId = shift.shift.contractorId;
    const assignedStaffId = shift.shift.userId || 0;

    setModalContent({
      ...initialStateEdit,
      id: shift.shift.id,
      locationId: shift.shift.locationId,
      staffTypeId: shift.shift.staffTypeId,
      startDate: startDate,
      endDate: endDate,
      startTime: startTime,
      endTime: endTime,
      contractorId: contractorId,
      rate: shift.shift.rate,
      regionId: shift.shift.regionId,
      assignedStaffId: assignedStaffId,
    });
    setOpen(true);
  };

  const handleChangeOnEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModalContent({ ...modalContent, [e.target.name]: e.target.value });
  };

  const handleEditAction = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const {
      id,
      locationId,
      // staffType,
      startDate,
      endDate,
      startTime,
      endTime,
      contractorId,
      rate,
      regionId,
      assignedStaffId,
    } = modalContent;

    dispatch(
      editShift({
        id,
        locationId,
        startDate,
        endDate,
        startTime,
        endTime,
        contractorId,
        rate,
        isContractedShift: true,
        regionId,
        assignedStaffId,
      })
    ).then(() => {
      dispatch(getThirdPartyContractorShifts(thirdPartyContractorFilters));
    });
    setOpen(false);
  };

  useEffect(() => {
    if (!thirdPartyContractorOptions.length) {
      dispatch(getThirdPartyContractors());
    }
    if (!locationsOptions.length) {
      dispatch(getAllLocations());
    }
  }, [dispatch, locationsOptions, thirdPartyContractorOptions]);

  useEffect(() => {
    dispatch(getThirdPartyContractorShifts(thirdPartyContractorFilters));
  }, [dispatch, thirdPartyContractorFilters, selectedRegions]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      handleThirdPartyContractorFilters({
        ...thirdPartyContractorFilters,
        [e.target.name]: e.target.value,
      })
    );
  };

  const openCartHandler = (shiftId: string) => {
    setdeleteShiftId(shiftId);
    setModalVisible(true);
  };

  const closeCartHandler = () => {
    setModalVisible(false);
  };

  const handleAddNote = (shiftId: string) => {
    dispatch(addShiftNoteId(shiftId));
    // dispatch(getShiftNotes(shiftId));

    navigate(`${publicUrl}/shift-notes/${shiftId}`, { state: { shiftId } });
  };

  // if shifts array is null then no shift found
  const displayedShifts = (
    <div className={classes.shift__container}>
      {Object.keys(thirdPartyContractorShifts)?.map((date, index) => {
        return (
          <div key={index}>
            <h5 className={classes.shift__date}>
              {moment(date).local().format("dddd MMM,DD YYYY")}
            </h5>
            <table className={classes.shift__table}>
              <thead>
                <tr>
                  <th className={classes.left}>Shift#</th>
                  <th>Shift Time</th>
                  <th className={classes.longCol}>Venue</th>
                  <th>Assigned To</th>
                  <th className={classes.longCol}>Email</th>
                  <th>Rate</th>
                  <th>Attendance</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {thirdPartyContractorShifts[date].map((shift: any) => (
                  <tr key={shift.shift.id}>
                    <td className={classes.left}>{shift.shift.id}</td>
                    <td>{`${moment(shift.shift.startLocal)
                      .local()
                      .format("hh:mma")} - ${moment(shift.shift.endLocal)
                      .local()
                      .format("hh:mma")}`}</td>
                    <td className={classes.longCol}>{shift.location?.name}</td>
                    <td>{shift.shift.thirdPartyContractor.name}</td>
                    <td className={classes.longCol}>
                      {shift.shift.thirdPartyContractor.email}
                    </td>
                    <td>{`$${shift.shift.rate}`}</td>
                    <td>{shift.shift.shiftStatus.value}</td>
                    <td>
                      <Button
                        variant="text"
                        type="button"
                        className={classes.notes__btn}
                        onClick={() => handleAddNote(shift.shift.id)}
                      >
                        Shift Notes
                      </Button>
                      <Button
                        variant="text"
                        type="button"
                        className={classes.delete__btn}
                        onClick={() => {
                          handleEditActionModal(shift);
                        }}
                      >
                        Edit Shift
                      </Button>
                      <Button
                        variant="text"
                        type="button"
                        className={classes.delete__btn}
                        onClick={() => {
                          openCartHandler(shift.shift.id);
                        }}
                        disabled={
                          // disabled if it is past shift and shift report has been reported
                          shift.shift.shift_report?.id &&
                          new Date().getTime() >
                            new Date(shift.shift.startLocal).getTime()
                        }
                      >
                        Delete Shift
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );

  if (isLoading) {
    return (
      <div>
        <LoadingMaterialUI />
      </div>
    );
  }

  return (
    <main>
      <h3>View Contractor Shifts from</h3>
      <div className={classes.shifts__filters}>
        <div>
          <FormRow
            type="date"
            name="fromDate"
            value={thirdPartyContractorFilters.fromDate}
            labelText="Start Date"
            handleChange={handleChange}
          />
          <FormRow
            type="date"
            name="toDate"
            value={thirdPartyContractorFilters.toDate}
            labelText="End Date"
            handleChange={handleChange}
          />
        </div>
        <div>
          <FormRowSelect
            name="contractorId"
            value={thirdPartyContractorFilters.contractorId}
            labelText="Third Party Contractor"
            list={[
              { id: 0, name: "All Staff" },
              ...thirdPartyContractorOptions,
            ]}
            handleChange={handleChange}
          />
          <FormRowSelect
            name="weekday"
            value={thirdPartyContractorFilters.weekday}
            labelText="Day"
            list={[
              { id: 0, name: "Sunday" },
              { id: 1, name: "Monday" },
              { id: 2, name: "Tuesday" },
              { id: 3, name: "Wednesday" },
              { id: 4, name: "Thursday" },
              { id: 5, name: "Friday" },
              { id: 6, name: "Saturday" },
              { id: 7, name: "All" },
            ]}
            handleChange={handleChange}
          />
        </div>
      </div>
      {Object.keys(thirdPartyContractorShifts)?.length !== 0
        ? displayedShifts
        : `No such records`}
      <Modal
        open={isModalVisible}
        onClose={() => setModalVisible(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <DeleteShift
            onClose={closeCartHandler}
            onOkay={() => {
              dispatch(deleteShift(deleteShiftId)).then(() => {
                dispatch(
                  getThirdPartyContractorShifts(thirdPartyContractorFilters)
                );
              });
              closeCartHandler();
            }}
          />
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          {
            <div>
              {
                <form className={classes.form}>
                  <div className={classes.form__center}>
                    <div>
                      <div>
                        <div>
                          <FormRowSelect
                            name="regionId"
                            value={modalContent.regionId}
                            handleChange={handleChangeOnEdit}
                            list={[
                              ...selectedRegions,
                              ...[{ id: 0, name: "Select Region" }],
                            ]}
                            labelText="Region"
                          />
                        </div>
                      </div>
                      <div>
                        <FormRowSelect
                          name="locationId"
                          value={modalContent.locationId}
                          labelText="Site"
                          list={locationsOptions}
                          handleChange={handleChangeOnEdit}
                        />
                      </div>
                      <div className={classes.timepickers}>
                        <div>
                          <FormRow
                            type="date"
                            labelText="Start Date & Time"
                            name="startDate"
                            // helperText="Start Date of the Shift"
                            value={modalContent.startDate}
                            handleChange={handleChangeOnEdit}
                          />
                          <FormRow
                            type="time"
                            labelText="Start Time"
                            name="startTime"
                            value={modalContent.startTime}
                            handleChange={handleChangeOnEdit}
                          />
                        </div>
                        <div>
                          <FormRow
                            type="date"
                            labelText="End Date & Time"
                            name="endDate"
                            value={modalContent.endDate}
                            handleChange={handleChangeOnEdit}
                          />
                          <FormRow
                            type="time"
                            labelText="End Time"
                            name="endTime"
                            value={modalContent.endTime}
                            handleChange={handleChangeOnEdit}
                          />
                        </div>
                      </div>
                      <div>
                        <FormRowSelect
                          name="contractorId"
                          value={modalContent.contractorId}
                          labelText="Third Party Contractor"
                          list={[...thirdPartyContractorOptions]}
                          handleChange={handleChangeOnEdit}
                        />
                      </div>
                      <FormRow
                        type="number"
                        name="rate"
                        value={modalContent.rate}
                        labelText="Rate ($)"
                        handleChange={handleChangeOnEdit}
                        adornment="$"
                      />
                    </div>
                    <div className={classes.btn__container}>
                      <Button
                        variant="contained"
                        type="submit"
                        className={classes.btn__submit}
                        disabled={isLoading}
                        sx={{
                          color: "var(--primary-blue-500)",
                          bgcolor: "var(--primary-red-500)",
                          "&:hover": { bgcolor: "var(--primary-red-600)" },
                          marginRight: "10px",
                        }}
                        onClick={handleEditAction}
                      >
                        Edit Shift
                      </Button>
                      <Button
                        variant="contained"
                        type="button"
                        className={classes.btn__cancel}
                        sx={{
                          color: "var(--primary-blue-500)",
                          bgcolor: "var(--primary-red-500)",
                          "&:hover": { bgcolor: "var(--primary-red-600)" },
                          marginRight: "10px",
                        }}
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              }
            </div>
          }
        </Box>
      </Modal>
    </main>
  );
};

export default AllShiftsContractor;
