import { Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const PlayerReportHome = () => {
  return (
    <main>
      <h3>This is the PLAYERS PlayerReport home page</h3>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <Link to={'/players/shopping-carts'}>
          <Button
            sx={{
              color: 'var(--black)',
              bgcolor: 'var(--primary-green-400)',
              '&:hover': { bgcolor: 'var(--primary-green-500)' },
              borderRadius: '0.5rem',
            }}>
            Shopping Carts
          </Button>
        </Link>
        <Link to={'/players/create-shopping-cart'}>
          <Button
            sx={{
              color: 'var(--black)',
              bgcolor: 'var(--primary-green-400)',
              '&:hover': { bgcolor: 'var(--primary-green-500)' },
              borderRadius: '0.5rem',
            }}>
            Create Shopping Cart
          </Button>
        </Link>
      </div>
    </main>
  );
};

export default PlayerReportHome;
