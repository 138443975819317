import { gql } from "@apollo/client";

const GET_CONTRACTS = gql`
  query Contracts($page: Int, $pageSize: Int) {
    contracts(page: $page, pageSize: $pageSize) {
      contracts {
        id
        name
        regionId
        region {
          id
          name
        }
        permitStatusId
        permitStatus {
          id
          name
        }
        typeId
        type {
          id
          name
        }
        vendor {
          id
          name
        }
        vendorId
        approvalStatusId
        approvalStatus {
          id
          name
        }
        updatedAt
        createdAt
        createdBy
        createdByUser {
          id
          firstName
          lastName
        }
      }
      count
    }
  }
`;

const CREATE_CONTRACT = gql`
  mutation ContractCreate($createContractInput: CreateContractInput!) {
    contractCreate(createContractInput: $createContractInput) {
      code
      message
      success
      contract {
        id
      }
    }
  }
`;

const UPDATE_CONTRACT = gql`
  mutation contractUpdate($updateContractInput: UpdateContractInput!) {
    contractUpdate(updateContractInput: $updateContractInput) {
      code
      message
      success
      contract {
        id
      }
    }
  }
`;

const CREATE_CONTRACT_NOTE = gql`
  mutation CreateContractNote(
    $createContractNoteInput: CreateContractNoteInput!
  ) {
    createContractNote(createContractNoteInput: $createContractNoteInput) {
      code
      message
      success
    }
  }
`;

const GET_CONTRACT_APPROVAL_USERS = gql`
  query GetContractApprovalUsers($regionIds: [Int]!) {
    getContractApprovalUsers(regionIds: $regionIds) {
      id
      firstName
      lastName
    }
  }
`;
const GET_CONTRACT_STATUSES = gql`
  query GetContractStatuses {
    getContractStatuses {
      permitStatus {
        id
        name
      }
      contractType {
        id
        name
      }
      appovalStatus {
        id
        name
      }
    }
  }
`;

const GET_CONTRACT_BY_ID = gql`
  query ContractById($id: Int!) {
    contractById(id: $id) {
      id
      name
      grossCost
      taxes
      contractKey
      regionId
      vendorId
      approverId
      permitStatusId
      typeId
      approvalStatusId
      contractItems {
        id
        contractId
        venueId
        startDateTimeLocal
        endDateTimeLocal
        day
        isExcluded
        isDeleted
      }
      contractNotes {
        id
        note
        createdAt
        createdByUser {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

const GET_CONTRACTS_COUNT = gql`
  query ContractsCount {
    contractsCount
  }
`;
export {
  GET_CONTRACTS,
  CREATE_CONTRACT,
  UPDATE_CONTRACT,
  GET_CONTRACT_BY_ID,
  GET_CONTRACTS_COUNT,
  GET_CONTRACT_APPROVAL_USERS,
  GET_CONTRACT_STATUSES,
  CREATE_CONTRACT_NOTE,
};
