import { gql } from "@apollo/client";

const GET_GAMESLOTS = gql`
  query Gameslots($gameslotFilters: GameslotFilters) {
    gameslots(gameslotFilters: $gameslotFilters) {
      id
      date
      startTime
      duration
      venue {
        name
      }
      capacityGroup {
        name
      }
      division {
        id
        vmColor
        vmName
      }
    }
  }
`;

const GET_GAMESLOT_BY_ID = gql`
  query Gameslot($gameslotId: ID!) {
    gameslot(id: $gameslotId) {
      id
      date
      startTime
      duration
      venue {
        id
        name
      }
      capacityGroup {
        id
        name
      }
      division {
        id
        vmColor
        vmName
      }
    }
  }
`;

const CREATE_GAMESLOT = gql`
  mutation GameslotCreate($gameslotArgs: CreateGameslotInput!) {
    gameslotCreate(gameslotArgs: $gameslotArgs) {
      code
      success
      message
    }
  }
`;

const UPDATE_GAMESLOT = gql`
  mutation GameslotUpdate($gameslotArgs: UpdateGameslotInput!) {
    gameslotUpdate(gameslotArgs: $gameslotArgs) {
      code
      success
      message
      gameslot {
        id
        division {
          id
          name
        }
      }
    }
  }
`;

export { GET_GAMESLOTS, GET_GAMESLOT_BY_ID, CREATE_GAMESLOT, UPDATE_GAMESLOT };
