import React, { useEffect, useState } from "react";
import Button from "../UI/Button/Button";
import Card from "../UI/Card/Card";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import FormField from "../UI/FormField/FormField";
import BreadCrumbs from "../UI/Breadcrumbs/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountries,
  getStates,
  getVendors,
  getVenue,
  getVenueSizes,
  getVenueTypes,
  handleResetImageUpload,
  uploadImages,
} from "../../app/venueMasterSlice";
import { AppDispatch, RootState } from "../../app/store";
import FormFieldDropdown from "../UI/FormField/FormFieldDropdown/FormFieldDropdown";
import { Venue } from "../../types/types";
import { useMutation } from "@apollo/client";
import { CREATE_VENDOR, UPDATE_VENDOR } from "../../graphql/queries/vendor";
import { useNavigate, useParams } from "react-router-dom";
import { CREATE_VENUE, UPDATE_VENUE } from "../../graphql/queries/venues";
import FormFieldFile from "../UI/FormField/FormFieldFile/FormFieldFile";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import Subtitle2 from "../UI/Text/Subtitle/Subtitle2";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";

const initialVenue: Venue = {
  id: 0,
  name: "",
  startBuffer: 0,
  endBuffer: 0,
  intersection: "",
  address: "",
  city: "",
  zipCode: "",
  locationLink: "",
  latitude: 0,
  longitude: 0,
  parentId: null,
  stateId: 0,
  countryId: 0,
  venueSetupId: 0,
  vendorId: 0,
  regionId: 0,
  type: 0,
  size: 0,
  islights: false,
  // for interface
  permit: false,
  facilityDescription: "",
  facilityImage: "",
};

const CreateVenue = () => {
  const publicUrl = process.env.PUBLIC_URL;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const params = useParams();
  const {
    isLoading,
    venueTypes,
    venueSizes,
    regions,
    vendorList,
    states,
    countries,
    imageUpload,
    venue: venueById,
  }: any = useSelector((state: RootState) => state.venueMaster);
  const [venue, setVenue] = useState<Venue>(initialVenue);

  const [CreateVenue, { data, loading, error }] = useMutation(CREATE_VENUE);
  const [UpdateVenue] = useMutation(UPDATE_VENUE);

  const [isEditVenue, setIsEditVenue] = useState<string | null>(
    params.id ? params.id : null
  );

  const [fileUrl, setFileUrl] = useState<string>("");
  const [file, setFile] = useState<any | null>(null);

  const handleChangeimage = (e: any) => {
    if (e.target.files[0]) {
      setFileUrl(URL.createObjectURL(e.target.files[0]));
      setFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (imageUpload) {
      handleMutations();
      dispatch(handleResetImageUpload({}));
    }
  }, [imageUpload]);

  const handleMutations = () => {
    if (isEditVenue) {
      UpdateVenue({
        variables: {
          venueArgs: {
            id: isEditVenue,
            venueInput: {
              name: venue.name,
              startBuffer: venue.startBuffer,
              endBuffer: venue.endBuffer,
              parentId: venue.parentId,
              venueSetupId: venue.venueSetupId,
              typeId: venue.type,
              sizeId: venue.size,
              islights: venue.islights,
              venueDetailId: venue.venueDetailId,
            },
            venueDetailInput: {
              address: venue.address,
              city: venue.city,
              zipCode: venue.zipCode,
              locationLink: venue.locationLink,
              latitude: venue.latitude,
              longitude: venue.longitude,
              stateId: venue.stateId,
              countryId: venue.countryId,
              vendorId: venue.vendorId,
              regionId: venue.regionId,
              intersection: venue.intersection,
              facilityDescription: venue.facilityDescription,
              facilityImage: imageUpload || venue.facilityImage,
            },
          },
        },
      })
        .then((res) => {
          if (res.data.venueUpdate.success) {
            setVenue(initialVenue);
            dispatch(displayAlertSuccess(res.data.venueUpdate.message));
            setIsEditVenue(null);
            navigate(`${publicUrl}/ops/venues`);
          } else {
            dispatch(displayAlertError(res.data.venueUpdate.message));
          }
        })
        .catch((err) => {
          dispatch(displayAlertError(err.message));
        });
    } else {
      //Add image URL to facility image venue input
      CreateVenue({
        variables: {
          venueArgs: {
            venueInput: {
              name: venue.name,
              startBuffer: venue.startBuffer,
              endBuffer: venue.endBuffer,
              parentId: venue.parentId,
              venueSetupId: venue.venueSetupId,
              typeId: venue.type,
              sizeId: venue.size,
              islights: venue.islights,
              venueDetailId: null,
            },
            venueDetailInput: {
              address: venue.address,
              city: venue.city,
              zipCode: venue.zipCode,
              locationLink: venue.locationLink,
              latitude: venue.latitude,
              longitude: venue.longitude,
              stateId: venue.stateId,
              countryId: venue.countryId,
              vendorId: venue.vendorId,
              regionId: venue.regionId,
              intersection: venue.intersection,
              facilityDescription: venue.facilityDescription,
              facilityImage: imageUpload || venue.facilityImage,
            },
          },
        },
      })
        .then((res) => {
          if (res.data.venueCreate.success) {
            setVenue(initialVenue);
            dispatch(displayAlertSuccess(res.data.venueCreate.message));
            setIsEditVenue(null);
            navigate(`${publicUrl}/ops/venues`);
          } else {
            dispatch(displayAlertError(res.data.venueCreate.message));
          }
        })
        .catch((err) => {
          dispatch(displayAlertError(err.message));
        });
    }
  };

  const handleCreateVendor = () => {
    // VALIDATIONS

    // venue parent should not exist
    if (venue.parentId) {
      dispatch(displayAlertError("Parent Venue should not exist"));
      return;
    }

    // mandatory fields
    if (
      !venue.name ||
      !venue.type ||
      !venue.size ||
      !venue.regionId ||
      !venue.vendorId ||
      !venue.stateId ||
      !venue.countryId ||
      !venue.zipCode ||
      !venue.city ||
      !venue.address ||
      !venue.latitude ||
      !venue.longitude
    ) {
      dispatch(displayAlertError("Please fill mandatory fields"));
      return;
    }

    if (file) {
      try {
        const form = new FormData();
        form.append("venueFiles", file);
        dispatch(uploadImages(form));
      } catch (err: any) {
        dispatch(displayAlertError(err.message));
      }
    } else handleMutations();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVenue((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    dispatch(getVenueTypes(""));
    dispatch(getVenueSizes(""));
    dispatch(getVendors(""));
    dispatch(getCountries(""));
    dispatch(getStates(venue.countryId ? venue.countryId : null));

    if (isEditVenue) {
      dispatch(getVenue(isEditVenue)).then((res: any) => {
        if (!res.payload) {
          dispatch(displayAlertError("Venue not found"));
          setIsEditVenue(null);
          navigate(`${publicUrl}/ops/venue-management`);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (venueById && isEditVenue) {
      setVenue({
        ...venueById,
        parentId: venueById.parentId ? venueById.parentId : null,
        type: venueById.typeId ? venueById.typeId : 0,
        size: venueById.sizeId ? venueById.sizeId : 0,
        // Venue Detail
        facilityDescription: venueById.venueDetail?.facilityDescription || "",
        ...venueById.venueDetail,
      });
      setFileUrl(venueById.venueDetail?.facilityImage);
    }
  }, [venueById, isEditVenue]);

  const pathsBreadcrumbs = [
    { name: "Venue", url: "/ops/venues" },
    { name: "Venue Details", url: "/ops/venues" },
  ];

  return (
    <main>
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/ops/venues"
      ></BreadCrumbs>
      <div className="flex flex-row justify-between w-full mt-6">
        <Headline1Variable>
          {isEditVenue ? "Edit Venue" : "Create New Venue"}
        </Headline1Variable>
        <div className="h-10">
          <Button
            variant="primary"
            onClick={handleCreateVendor}
          >
            {isEditVenue ? (
              <span> Edit Venue</span>
            ) : (
              <span> Create Venue</span>
            )}
          </Button>
        </div>
      </div>
      <Card className="mt-6">
        <Subtitle1>Venue Details</Subtitle1>
        <div className="flex flex-col">
          <div className="flex flex-row w-full gap-4 mt-6">
            <FormField
              initialValue={venue.name ? venue.name : ""}
              inputChange={handleChange}
              name="name"
              label="Venue Name"
            ></FormField>
            {/* For top level Venue, parentID = null,
          For sub-Venue, params will decide the parentID
          In both cases, its not an input field, disabled
          */}
            <FormFieldDropdown
              initialValue={venue.parentId ? venue.parentId.toString() : "0"}
              inputChange={(value) => {}}
              name="parentId"
              label="Parent Venue"
              placeholder="Parent Venue"
              disabled={true}
            >
              {[{ id: 0, name: "None" }]}
            </FormFieldDropdown>
            <FormFieldDropdown
              initialValue={venue.type ? venue.type.toString() : "0"}
              inputChange={(value) => {
                setVenue((prevState) => {
                  return {
                    ...prevState,
                    type: parseInt(value),
                  };
                });
              }}
              name="venueType"
              label="Venue Type"
              placeholder="Venue Type"
            >
              {[{ id: 0, name: "Select Venue Type" }, ...venueTypes]}
            </FormFieldDropdown>
            <FormFieldDropdown
              initialValue={venue.size ? venue.size.toString() : "0"}
              inputChange={(value) => {
                setVenue((prevState) => {
                  return {
                    ...prevState,
                    size: parseInt(value),
                  };
                });
              }}
              name="venueSize"
              label="Venue Size"
              placeholder="Venue Size"
            >
              {[{ id: 0, name: "Select Venue Size" }, ...venueSizes]}
            </FormFieldDropdown>
          </div>
          <div className="flex flex-row w-full gap-4 mt-6">
            <FormFieldDropdown
              initialValue={venue.islights ? "1" : "0"}
              inputChange={(value) => {
                setVenue((prevState) => ({
                  ...prevState,
                  islights: value === "1" ? true : false,
                }));
              }}
              name="islights"
              label="Lights"
              placeholder="Lights"
            >
              {[
                { id: 0, name: "No" },
                { id: 1, name: "Yes" },
              ]}
            </FormFieldDropdown>
            {/* For top level Venue, parentID = null,
          For sub-Venue, params will decide the parentID
          In both cases, its not an input field, disabled
          */}
            <FormFieldDropdown
              initialValue={venue.parentId ? venue.parentId.toString() : "0"}
              inputChange={(value) => {}}
              name="venueSetup"
              label="Venue Setup"
              placeholder="Venue Setup"
              disabled={true}
            >
              {[{ id: 0, name: "None" }]}
            </FormFieldDropdown>
            <FormField
              initialValue={
                venue.startBuffer ? venue.startBuffer.toString() : "0"
              }
              inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setVenue((prevState) => ({
                  ...prevState,
                  [e.target.name]: parseInt(e.target.value),
                }));
              }}
              name="startBuffer"
              label="Start Buffer"
            ></FormField>
            <FormField
              initialValue={venue.endBuffer ? venue.endBuffer.toString() : "0"}
              inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setVenue((prevState) => ({
                  ...prevState,
                  [e.target.name]: parseInt(e.target.value),
                }));
              }}
              name="endBuffer"
              label="End Buffer"
            ></FormField>
          </div>
        </div>
      </Card>
      <Card className="mt-6">
        <Subtitle1>Facility Information</Subtitle1>
        <div className="flex flex-col">
          <div className="flex flex-row w-full gap-4 mt-6">
            <FormFieldDropdown
              initialValue={venue.regionId ? venue.regionId.toString() : "0"}
              inputChange={(value) => {
                setVenue((prevState) => ({
                  ...prevState,
                  regionId: parseInt(value),
                }));
              }}
              name="regionId"
              label="Region"
              placeholder="Region"
            >
              {[{ id: 0, name: "Select Region" }, ...regions]}
            </FormFieldDropdown>
            <FormFieldDropdown
              initialValue={venue.vendorId ? venue.vendorId.toString() : "0"}
              inputChange={(value) => {
                setVenue((prevState) => ({
                  ...prevState,
                  vendorId: parseInt(value),
                }));
              }}
              name="vendorId"
              label="Vendor"
              placeholder="Vendor"
            >
              {[{ id: 0, name: "Select Vendor" }, ...vendorList]}
            </FormFieldDropdown>
            <FormFieldDropdown
              initialValue={venue.permit ? "1" : "0"}
              inputChange={(value) => {
                setVenue((prevState) => ({
                  ...prevState,
                  permit: value === "1" ? true : false,
                }));
              }}
              name="permit"
              label="Permit?"
              placeholder="Permit?"
            >
              {[
                { id: 0, name: "No" },
                { id: 1, name: "Yes" },
              ]}
            </FormFieldDropdown>
            <FormField
              initialValue={venue.intersection ? venue.intersection : ""}
              inputChange={handleChange}
              name="intersection"
              label="Intersection"
            ></FormField>
          </div>
          <div className="flex flex-row w-full gap-4 mt-6">
            <FormField
              initialValue={venue.address ? venue.address : ""}
              inputChange={handleChange}
              name="address"
              label="Street Address"
            ></FormField>
            <FormField
              initialValue={venue.city ? venue.city : ""}
              inputChange={handleChange}
              name="city"
              label="City"
            ></FormField>
            <FormFieldDropdown
              initialValue={venue.stateId ? venue.stateId.toString() : "0"}
              inputChange={(value) => {
                setVenue((prevState) => ({
                  ...prevState,
                  stateId: parseInt(value),
                }));
              }}
              name="stateId"
              label="Province"
              placeholder="Province/State"
            >
              {[{ id: 0, name: "Select Province" }, ...states]}
            </FormFieldDropdown>
            <FormField
              initialValue={venue.zipCode ? venue.zipCode : ""}
              inputChange={handleChange}
              name="zipCode"
              label="Postal Code"
            ></FormField>
            <FormFieldDropdown
              initialValue={venue.countryId ? venue.countryId.toString() : "0"}
              inputChange={(value) => {
                setVenue((prevState) => ({
                  ...prevState,
                  countryId: parseInt(value),
                }));
                dispatch(getStates(value ? value : null)).then((res: any) => {
                  const isStateExist = res.payload.find((item: any) => {
                    return item.id == venue.stateId;
                  });

                  if (!isStateExist) {
                    setVenue((prevState) => ({
                      ...prevState,
                      stateId: 0,
                    }));
                  }
                });
              }}
              name="countryId"
              label="Country"
              placeholder="Country"
            >
              {[{ id: 0, name: "Select Country" }, ...countries]}
            </FormFieldDropdown>
          </div>
        </div>
      </Card>
      <Card className="mt-6">
        <Subtitle1>Maps Information</Subtitle1>
        <Subtitle2 className="max-w-full">
          {`This Latitude & Longitude input is what controls where leagues are
          located in the discovery experience. Venues are linked at the league
          and session level, and the distance is calculated from this lat/long
          position. 
          This is probably the most important section.`}
        </Subtitle2>
        <div className="flex flex-row w-full gap-4 mt-6">
          <FormField
            initialValue={venue.latitude ? venue.latitude.toString() : "0"}
            inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setVenue((prevState) => ({
                ...prevState,
                [e.target.name]: parseFloat(e.target.value),
              }));
            }}
            name="latitude"
            label="Latitude"
            width="100px"
          ></FormField>
          <FormField
            initialValue={venue.longitude ? venue.longitude.toString() : "0"}
            inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setVenue((prevState) => ({
                ...prevState,
                [e.target.name]: parseFloat(e.target.value),
              }));
            }}
            name="longitude"
            label="Longitude"
            width="100px"
          ></FormField>
          <FormField
            initialValue={
              venue.locationLink ? venue.locationLink.toString() : ""
            }
            inputChange={handleChange}
            name="locationLink"
            label="Direct Link"
          ></FormField>
        </div>
      </Card>
      <Card className="mt-6">
        <Subtitle1>Facility Image</Subtitle1>
        <div className="flex flex-row gap-4 mt-6 lg:w-1/4 md:1/3">
          {fileUrl && (
            <img
              className="mr-2 bg-neutral-80"
              src={fileUrl}
              alt="image"
              width={120}
              height={120}
            />
          )}
          {!fileUrl && (
            <div className="w-[120px] h-[120px] bg-gray-300 rounded-2xl flex items-center justify-center">
              <FmdGoodIcon fontSize="large" />
            </div>
          )}
          <div className="flex flex-col gap-2">
            <FormFieldFile
              onChange={handleChangeimage}
              accept="image/png, image/gif, image/jpeg"
              multiple={false}
              text="Upload"
              icon={<FileUploadOutlinedIcon />}
            />
            <Button
              variant="secondary"
              onClick={() => {
                setFileUrl("");
                setFile(null);
                setVenue({ ...venue, facilityImage: "" });
              }}
            >
              <div className="flex font-normal">
                <div className="mr-1">
                  <DeleteOutlineOutlinedIcon />
                </div>
                Remove
              </div>
            </Button>
          </div>
        </div>
        <div className="flex flex-row w-full gap-4 mt-6">
          <FormField
            initialValue={venue.facilityDescription}
            inputChange={handleChange}
            name="facilityDescription"
            label="Facility Description"
            width="100px"
          ></FormField>
        </div>
      </Card>
      <div className="flex justify-end mt-6">
        <Button
          variant="primary"
          onClick={handleCreateVendor}
        >
          {isEditVenue ? <span> Edit Venue</span> : <span> Create Venue</span>}
        </Button>
      </div>
    </main>
  );
};

export default CreateVenue;
