import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import classes from "./Rules.module.css";
import { FormRow, FormRowSelect } from "../UI";
import { getSports } from "../../app/venueMasterSlice";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  CreateRuleInput,
  GetRuleBySportAndRegionIdQuery,
  ListAllOperations,
  RuleInput,
  useCreateRuleMutation,
  useGetRuleByIdLazyQuery,
  useGetRuleBySportAndRegionIdLazyQuery,
  useUpdateRuleMutation,
} from "../../generated/graphql";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import FormField from "../UI/FormField/FormField";
import Card from "../UI/Card/Card";
import Button from "../UI/Button/Button";
import FormFieldControlled from "../UI/FormField/FormFieldControlled";
import Alert from "../UI/Alerts/Alert";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import { z } from "zod";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Body1 from "../UI/Text/Body/Body1";

const RuleSchema = z.object({
  id: z.number().optional(),
  sportId: z.number(),
  regionId: z.number(),
  name: z.string(),
  rules: z.string(),
});

type RuleFormValues = z.infer<typeof RuleSchema>;

declare module "react-quill" {
  interface QuillStatic {
    register: (module: any, suppressWarning?: boolean) => void;
  }
}

const initialRuleData = {
  id: undefined,
  sportId: 0,
  regionId: 0,
  name: "",
  rules: "",
};

const Rules: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;

  const { isLoading, selectedRegions, sports }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  const [ruleData, setRuleData] = useState<RuleFormValues>(initialRuleData);
  const [allRuleData, setAllRuleData] = useState<
    GetRuleBySportAndRegionIdQuery["getRuleBySportAndRegionId"]
  >([]);

  const [getRuleBySportAndRegionId, { loading: loadingRules }] =
    useGetRuleBySportAndRegionIdLazyQuery({
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setAllRuleData(data.getRuleBySportAndRegionId);
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message));
      },
    });

  const [getRuleById, { loading: loadingRule }] = useGetRuleByIdLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  const [
    CreateRule,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateRuleMutation();
  const [
    UpdateRule,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateRuleMutation();

  // Get all the sports on load
  useEffect(() => {
    dispatch(getSports(""));
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      getRuleById({
        variables: {
          id: +id,
        },
        onCompleted: (data) => {
          setRuleData((prev) => ({
            ...prev,
            rules: data.getRuleById.rules,
            name: data.getRuleById.name,
            id: data.getRuleById.id,
            sportId: data.getRuleById.sportId,
            regionId: data.getRuleById.regionId ?? 0,
          }));
        },
      });
    }
  }, []);

  // When a user selects a sport or a region select all the rules that match that sport and region
  useEffect(() => {
    if (ruleData.sportId !== 0) {
      getRuleBySportAndRegionId({
        variables: {
          sportId: ruleData.sportId,
          regionId: ruleData.regionId ?? undefined,
        },
      });
    }
  }, [ruleData.sportId, ruleData.regionId]);

  const handleSubmit = () => {
    // submit content to database
    if (ruleData.id === undefined) {
      CreateRule({
        variables: {
          ruleArgs: {
            ruleInput: {
              name: ruleData.name,
              rules: ruleData.rules,
              sportId: ruleData.sportId,
              regionId: ruleData.regionId == 0 ? null : ruleData.regionId,
            },
          },
        },
        refetchQueries: [ListAllOperations.Query.getRuleBySportAndRegionId],
        onCompleted: (data) => {
          if (data.createRule.success) {
            if (data.createRule.rule) {
              setRuleData((prevState) => ({
                ...prevState,
                id: data.createRule.rule.id,
              }));
            }
            dispatch(displayAlertSuccess("Created Rule"));
          } else {
            dispatch(displayAlertError(data.createRule.message));
          }
        },
      });
    } else {
      UpdateRule({
        variables: {
          ruleArgs: {
            ruleInput: {
              name: ruleData.name,
              rules: ruleData.rules,
              sportId: ruleData.sportId,
              regionId: ruleData.regionId == 0 ? null : ruleData.regionId,
            },
            id: ruleData.id,
          },
        },
        refetchQueries: [ListAllOperations.Query.getRuleBySportAndRegionId],
        onCompleted: (data) => {
          if (data.updateRule.success) {
            dispatch(displayAlertSuccess("Updated Rule"));
          } else {
            dispatch(displayAlertError(data.updateRule.message));
          }
        },
      });
    }

    return;
  };

  function getRegionFromId(regionId: number) {
    const region = selectedRegions.find((region: any) => region.id == regionId);
    if (region) {
      return region.name;
    }
    return "(Missing Region)";
  }
  function getSportFromId(sportId: number) {
    const sport = sports.find((sport: any) => sport.id == sportId);
    if (sport) {
      return sport.name;
    }
    return "(Missing Sport)";
  }

  return (
    <main className="flex flex-col gap-4">
      <LoadingDialog open={createLoading || updateLoading || isLoading} />
      <div className="flex flex-row justify-between w-full">
        <Headline1Variable>Rules</Headline1Variable>
        <div className="mb-4 text-end">
          <Button
            variant="secondary"
            onClick={() => navigate("/ops/rules")}
          >
            <div className="flex flex-row gap-1">
              <ArrowBackOutlinedIcon />
              <Body1>Rule Report Page</Body1>
            </div>
          </Button>
        </div>
      </div>
      <div className="flex flex-row justify-start w-3/4 gap-4">
        <div className="max-w-60 min-w-60">
          {sports.length > 0 && (
            <FormFieldSelect
              name="sportId"
              id="sportId"
              value={ruleData.sportId ? ruleData.sportId.toString() : "0"}
              inputChange={(value) => {
                navigate("/ops/rule");
                setRuleData((prev) => ({
                  ...initialRuleData,
                  ["sportId"]: +value,
                }));
              }}
              label="Sport"
              placeholder="Select Sport"
              className="h-[46px] m-0"
            >
              {[...sports, ...[{ id: 0, name: "None" }]]}
            </FormFieldSelect>
          )}
        </div>
        <div className="max-w-60 min-w-60">
          <FormFieldSelect
            value={ruleData.regionId ? ruleData.regionId.toString() : "0"}
            inputChange={(value) => {
              navigate("/ops/rule");
              setRuleData((prev) => ({
                ...prev,
                rules: "",
                name: "",
                id: undefined,
                regionId: +value,
              }));
            }}
            name="regionId"
            id="regionId"
            label="Region"
            placeholder="Select Region"
            className="h-[46px] m-0 "
          >
            {selectedRegions.length > 0
              ? [...[{ id: 0, name: "All" }], ...selectedRegions]
              : [...[{ id: 0, name: "None" }]]}
          </FormFieldSelect>
        </div>
        <FormFieldSelect
          value={ruleData.id ? ruleData.id.toString() : "0"}
          inputChange={(value) => {
            const rule =
              allRuleData.find((rule) => +rule.id === +value) ??
              initialRuleData;
            navigate("/ops/rule");
            setRuleData((prev) => ({
              ...prev,
              rules: rule.rules,
              name: rule.name,
              id: rule.id,
              regionId: rule.regionId ?? 0,
            }));
          }}
          name="id"
          id="id"
          label="Name"
          labelClassName="whitespace-nowrap"
          placeholder="Name"
          className="h-[46px] m-0 w-full max-w-sm"
        >
          {allRuleData.length > 0
            ? [...[{ id: 0, name: "None" }], ...allRuleData]
            : [...[{ id: 0, name: "None" }]]}
        </FormFieldSelect>
      </div>
      {allRuleData.length === 0 && ruleData.sportId > 0 && !loadingRules && (
        <Alert
          variant="warning"
          size="small"
          persist={true}
          content={`There is no rule for ${
            ruleData.sportId && getSportFromId(ruleData.sportId)
          } 
            ${
              ruleData.regionId
                ? ` in ${getRegionFromId(ruleData.regionId)}`
                : "in any region"
            }. Please create a new rule.`}
        />
      )}
      {ruleData.sportId !== 0 && (
        <div className="flex flex-col gap-2 w-fit">
          <FormFieldControlled
            name="name"
            value={ruleData.name}
            label="Name of Rule"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setRuleData((prevState) => ({
                ...prevState,
                name: e.target.value,
              }));
            }}
          />
          <Card className="flex flex-col gap-2 w-min h-min">
            <div className={classes.text__editor}>
              <ReactQuill
                style={{ height: "400px", width: "700px" }}
                theme="snow" // you can use 'bubble' theme as well
                value={ruleData.rules}
                onChange={(text: string) =>
                  setRuleData((prev) => ({ ...prev, rules: text }))
                }
              />
            </div>
            <Button
              variant="primary"
              onClick={handleSubmit}
              className="w-60"
            >
              {ruleData.id === undefined ? "Create Rule" : "Update Rule"}
            </Button>
          </Card>
        </div>
      )}
    </main>
  );
};

export default Rules;
