import React, { useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Card, CardContent } from "../shadcn/card";
import Body1 from "../Text/Body/Body1";
import { FreeAgentTeam, Player } from "../../Admin/CreateIndyTeam";
import FormField from "../../UI/FormField/FormField";
import { Separator } from "../shadcn/separator";
import { Button, Chip } from "@mui/material";
import { Checkbox } from "../shadcn/checkbox";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

interface FreeAgentTeamBoardProps {
  teams: FreeAgentTeam[];
  setTeams: React.Dispatch<React.SetStateAction<FreeAgentTeam[]>>;
  handleDeleteTeam: (teamId: number) => void;
}

const FreeAgentTeamBoard: React.FC<FreeAgentTeamBoardProps> = ({
  teams,
  setTeams,
  handleDeleteTeam,
}) => {
  const [draggedPlayer, setDraggedPlayer] = useState<Player | null>(null);
  const [hoveredTeamId, setHoveredTeamId] = useState<number | null>(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const draggedCardRef = useRef<HTMLDivElement>(null);

  const onDragStart = (player: Player, e: React.DragEvent<HTMLDivElement>) => {
    setDraggedPlayer(player);
    if (draggedCardRef.current) {
      const rect = draggedCardRef.current.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const offsetY = e.clientY - rect.top;
      setMousePosition({ x: offsetX, y: offsetY });
    }
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>, order: number) => {
    e.preventDefault();
    setHoveredTeamId(order);
  };

  const onDragLeave = () => {
    setHoveredTeamId(null);
  };

  const onDrop = (targetTeamIndex: number) => {
    if (!draggedPlayer) return;

    const sourceDivision = teams.find((div) =>
      div.players.some(
        (player) => player.registrationId === draggedPlayer.registrationId
      )
    );
    const targetDivision = teams.find((div) => div.order === targetTeamIndex);

    if (
      !sourceDivision ||
      !targetDivision ||
      sourceDivision.order === targetDivision.order
    )
      return;

    setTeams((prevTeams) =>
      prevTeams.map((div) => {
        if (div.order === sourceDivision.order) {
          return {
            ...div,
            players: div.players.filter(
              (player) => player.registrationId !== draggedPlayer.registrationId
            ),
          };
        }
        if (div.order === targetDivision.order) {
          return { ...div, players: [...div.players, draggedPlayer] };
        }
        return div;
      })
    );

    setDraggedPlayer(null);
    setHoveredTeamId(null);
  };

  return (
    <div className="p-4">
      <div className="flex flex-row flex-wrap space-x-4">
        {teams.map((team) => (
          <motion.div
            key={team.order}
            className="w-64 p-2 rounded-lg min-w-64"
            onDragOver={(e) => onDragOver(e, team.order!)}
            onDragLeave={onDragLeave}
            onDrop={() => onDrop(team.order!)}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.3 }}
          >
            {team.id !== 0 && (
              <div className="flex flex-col gap-2 mb-2 items-end">
                <Button
                  style={{
                    width: "fit-content",
                    padding: "0",
                  }}
                  endIcon={<DeleteOutlineIcon htmlColor="#000" />}
                  onClick={async () => {
                    //Remove the team and add players back to the free agents
                    const playersInTeam = teams.find(
                      (t) => t.order === team.order
                    )?.players;
                    if (playersInTeam) {
                      setTeams((prevTeams) =>
                        prevTeams
                          .filter((t) => t.order !== team.order)
                          .map((t, index) =>
                            t.id === 0
                              ? {
                                  ...t,
                                  order: index + 1,
                                  players: [...t.players, ...playersInTeam],
                                }
                              : {
                                  ...t,
                                  order: index + 1,
                                }
                          )
                      );
                    }
                    if (team.id && team.id !== 0) handleDeleteTeam(team.id);
                  }}
                ></Button>
                <FormField
                  label="Team Name"
                  initialValue={team.name}
                  inputChange={(e) =>
                    setTeams((prevTeams) =>
                      prevTeams.map((t) =>
                        t.order === team.order
                          ? { ...t, name: e.target.value }
                          : t
                      )
                    )
                  }
                />
                <Separator />
              </div>
            )}
            <AnimatePresence>
              {team.players.map((player, index) => (
                <motion.div
                  key={player.registrationId}
                  layout
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  transition={{ duration: 0.2 }}
                >
                  <Card
                    ref={
                      draggedPlayer?.id === player.id ? draggedCardRef : null
                    }
                    draggable
                    onDragStart={(e) => onDragStart(player, e)}
                    className="mb-2 cursor-move"
                  >
                    <CardContent className="flex gap-2 p-2 items-center">
                      {team.id !== 0 && (
                        <Checkbox
                          checked={player.isCaptain}
                          tabIndex={-1}
                          color="primary"
                          onClick={() => {
                            setTeams((prevTeams) =>
                              prevTeams.map((t) =>
                                t.order === team.order
                                  ? {
                                      ...t,
                                      players: t.players.map((p) =>
                                        p.id === player.id
                                          ? { ...p, isCaptain: !p.isCaptain }
                                          : p
                                      ),
                                    }
                                  : t
                              )
                            );
                          }}
                        />
                      )}
                      <Chip
                        size="small"
                        sx={{ height: 30, width: 30 }}
                        label={player.genderIdentity.charAt(0)}
                        variant="outlined"
                      />
                      <div>
                        <Body1 className="truncate">{player.name}</Body1>
                        <div className="text-xs text-light">
                          {player.CPR?.length ? ` CPR: ${player.CPR}` : ""}
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                  {hoveredTeamId === team.id && index === team.id - 1 && (
                    <motion.div
                      className="h-2 mb-2 bg-blue-300 rounded"
                      animate={{ opacity: 1, height: 8 }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.2 }}
                    />
                  )}
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>
        ))}
      </div>
      {draggedPlayer && (
        <motion.div
          className="fixed pointer-events-none"
          style={{
            left: mousePosition.x,
            top: mousePosition.y,
            zIndex: 1000,
          }}
          initial={{ opacity: 0.8, scale: 1.05 }}
          animate={{ opacity: 0.8, scale: 1.05 }}
          transition={{ duration: 0.2 }}
        >
          <Card className="w-56">
            <CardContent className="p-2">
              <Body1 className="truncate">
                #{draggedPlayer.id} - {draggedPlayer.name}
              </Body1>
            </CardContent>
          </Card>
        </motion.div>
      )}
    </div>
  );
};

export default FreeAgentTeamBoard;
