import React, { ReactElement, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../app/store';
import PermissionDenied from './PermissionDenied';
import { UserPermission } from '../types/types';
import { EPermission, isPermissionGranted } from '../utils/permissions';
import { getUserDetails } from '../app/authSlice';
import { LoadingMaterialUI } from '../components/UI';

interface Props {
  // requiredPermission: RequiredPermission[] | EPermission | undefined;
  requiredPermission: EPermission;
  children: ReactNode;
}

interface ChildProps {
  requiredPermission?: EPermission;
}

const AuthorizedRoutes: React.FC<Props> = ({
  requiredPermission,
  children,
}): any => {
  const { user, token, isLoading } = useSelector(
    (state: RootState) => state.auth
  );
  const [permissionGranted, setPermissionGranted] =
    React.useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      const token = localStorage.getItem('jwtToken');
      if (!token) {
        navigate(`/register`);
        return;
      }
      const jwtToken = JSON.parse(token);
      dispatch(getUserDetails(jwtToken));
    }
  }, [user, dispatch]);

  useEffect(() => {
    isPermissionGranted(user?.permission, requiredPermission)
      ? setPermissionGranted(true)
      : setPermissionGranted(false);
  }, [user, requiredPermission]);

  if (isLoading) {
    return <LoadingMaterialUI />;
  }

  if (!permissionGranted) {
    return <PermissionDenied />;
  }

  // return <div>{children}</div>;
  return (
    <>
      {React.Children.map(children, (child) => {
        // Only clone if child is a valid React element
        if (React.isValidElement<Partial<ChildProps>>(child)) {
          return React.cloneElement(child, { requiredPermission });
        }
        return child;
      })}
    </>
  );
};

export default AuthorizedRoutes;
