import React, { FC, useEffect, useState } from "react";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import Card from "../UI/Card/Card";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import FormFieldDropdown from "../UI/FormField/FormFieldDropdown/FormFieldDropdown";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { LegalDocument } from "../../types/types";
import {
  createLegalDocument,
  getLegalDocumentByRegionAndType,
} from "../../app/venueMasterSlice";
import { getLegalDocumentByRegionAndTypeInput as LegalDocumentInput } from "../../app/venueMasterSlice";
import ReactQuill from "react-quill";
import FormField from "../UI/FormField/FormField";
import Button from "../UI/Button/Button";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Body1 from "../UI/Text/Body/Body1";
import Headline2 from "../UI/Text/Headline/Headline2";
import { useToast } from "../UI/Toast/use-toast";
import { displayAlertSuccess } from "../../app/globalSlice";

declare module "react-quill" {
  interface QuillStatic {
    register: (module: any, suppressWarning?: boolean) => void;
  }
}

const documentTypes = [
  { id: 1, name: "Player Waiver" },
  { id: 2, name: "Terms & Conditions" },
  { id: 3, name: "Privacy Policy" },
];

dayjs.extend(utc);
dayjs.extend(timezone);

const LegalDocumentManagement: React.FC = () => {
  const { selectedRegions }: any = useSelector(
    (state: RootState) => state.venueMaster
  );
  const { user }: any = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch<AppDispatch>();

  const [documentDetails, setDocumentDetails] = useState<LegalDocumentInput>({
    regionId: 0,
    legalDocumentTypeId: 0,
  });
  const [documentData, setDocumentData] = useState<Partial<LegalDocument>>();
  const [alertText, setAlertText] = useState<string>("");

  const getLegalDocument = () => {
    dispatch(getLegalDocumentByRegionAndType(documentDetails)).then(
      (response) => {
        if (response.payload) {
          setAlertText("");
          setDocumentData(response.payload as LegalDocument);
        } else {
          // If no document is found then alert the user and set an empty document
          setAlertText("No document found");
          setDocumentData({
            regionId: documentDetails.regionId,
            legalDocumentTypeId: documentDetails.legalDocumentTypeId,
            body: {
              body: "",
            },
            authorId: user.id,
            createdAt: undefined,
          });
        }
      }
    );
  };

  // Retrieve the document data from the database if it exists
  useEffect(() => {
    if (
      documentDetails.regionId > 0 &&
      documentDetails.legalDocumentTypeId > 0
    ) {
      getLegalDocument();
    } else {
      setDocumentData(undefined);
    }
  }, [documentDetails.regionId, documentDetails.legalDocumentTypeId]);

  useEffect(() => {
    if (selectedRegions.length > 0) {
      setDocumentDetails((prevState) => ({
        ...prevState,
        regionId: parseInt(selectedRegions[0].id),
      }));
    } else {
      setDocumentDetails((prevState) => ({
        regionId: 0,
        legalDocumentTypeId: prevState.legalDocumentTypeId,
      }));
      setDocumentData(undefined);
    }
  }, [selectedRegions]);

  // If the user presses save then create a new entry in the database
  const handleSubmit = () => {
    // Check that the region and document type and name are valid
    if (
      documentDetails.regionId > 0 &&
      documentDetails.legalDocumentTypeId > 0 &&
      documentData?.name !== "" &&
      documentData?.name
    ) {
      if (documentData?.body?.body) {
        // Create a new entry in the database
        dispatch(
          createLegalDocument({
            regionId: documentDetails.regionId,
            legalDocumentTypeId: documentDetails.legalDocumentTypeId,
            authorId: user.id,
            body: documentData.body.body,
            name: documentData.name,
          })
        )
          .then((response) => {
            if (response.payload) {
              dispatch(displayAlertSuccess("Document Saved"));
            }
          })
          .finally(() => {
            getLegalDocument();
          });
        setAlertText("");
      }
    } else {
      // If the region or document type or name is invalid then alert the user
      setAlertText("Region, document type, and name must be filled");
    }
  };

  return (
    <main>
      <div className="flex flex-row justify-between w-full mb-4">
        <Headline1Variable>Legal Document Management</Headline1Variable>
      </div>
      <Card className="mt-6">
        <div className="flex flex-row justify-between">
          <Subtitle1 className="pb-4">Legal Document Details</Subtitle1>
          {alertText}
        </div>
        <div className="flex flex-row gap-4">
          <FormFieldDropdown
            initialValue={documentDetails.regionId.toString()}
            inputChange={(value) => {
              setDocumentDetails((prevState) => ({
                ...prevState,
                regionId: parseInt(value),
              }));
            }}
            name="regionId"
            label="Region"
            placeholder="Region"
          >
            {[{ id: 0, name: "Select Region" }, ...selectedRegions]}
          </FormFieldDropdown>
          <FormFieldDropdown
            initialValue={documentDetails.legalDocumentTypeId.toString()}
            inputChange={(value) => {
              setDocumentDetails((prevState) => ({
                ...prevState,
                legalDocumentTypeId: parseInt(value),
              }));
            }}
            name="documentTypeId"
            label="Document Type"
            placeholder="Document Type"
          >
            {[{ id: 0, name: "Document Type" }, ...documentTypes]}
          </FormFieldDropdown>
        </div>
      </Card>
      <Card className="mt-6">
        <div className="flex flex-row gap-4">
          <Subtitle1 className="pb-4">Document Content</Subtitle1>
          {/* Name of the document */}
        </div>
        {documentData && (
          <div className="flex flex-col gap-4">
            <div className="flex flex-row items-center justify-center gap-16">
              <FormField
                initialValue={documentData?.name}
                name="name"
                inputChange={(e) =>
                  setDocumentData((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                  }))
                }
                label="Document Name"
              />
              <div className="flex flex-col gap-1 whitespace-nowrap">
                <Headline2 size="s-xs">Document Id:</Headline2>
                <Body1>{documentData.id}</Body1>
              </div>
              <div className="flex flex-col gap-1 whitespace-nowrap">
                <Headline2 size="s-xs">Author:</Headline2>
                <Body1>{documentData.authorName}</Body1>
              </div>
              <div className="flex flex-col gap-1 whitespace-nowrap">
                <Headline2 size="s-xs">Created At:</Headline2>
                <Body1>
                  {documentData.createdAt &&
                    dayjs(documentData.createdAt).format("MMMM D, YYYY h:mm A")}
                </Body1>
              </div>
            </div>
            <ReactQuill
              theme="snow"
              value={documentData.body?.body}
              onChange={(text: string) =>
                setDocumentData((prev) => ({
                  ...prev,
                  body: {
                    body: text,
                  },
                }))
              }
            />
            <Button
              variant="primary"
              onClick={handleSubmit}
              width="1/4"
            >
              Save
            </Button>
          </div>
        )}
      </Card>
    </main>
  );
};

export default LegalDocumentManagement;
