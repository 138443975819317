import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingMaterialUI } from "../../UI";
import {
  getMyAppliedShifts,
  deleteAppliedShift,
} from "../../../app/shiftSlice";
import classes from "./AppliedShifts.module.css";
import moment from "moment";
import { useTable, useSortBy } from "react-table";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { AppDispatch, RootState } from "../../../app/store";

const AppliedShifts = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { appliedShifts, isLoading } = useSelector(
    (state: RootState) => state.shift
  );
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState([]);

  const handleViewNotes = (shiftNotes: any) => {
    setModalContent(shiftNotes);
    setOpen(true);
  };

  const COLUMNS = [
    {
      Header: "ShiftID#",
      accessor: "myRequestedShift.shift.id",
    },
    {
      Header: "Date",
      id: "startLocal",
      accessor: (d: any) => {
        return (
          <div className={classes.date__attributes}>
            <div className={classes.date__day}>
              {moment(d.myRequestedShift.shift.startLocal)
                .local()
                .format("dddd")}
            </div>
            <div className={classes.date__date}>
              {moment(d.myRequestedShift.shift.startLocal)
                .local()
                .format("MMM,DD")}
            </div>
          </div>
        );
      },
    },
    {
      Header: "Shift Type",
      accessor: "myRequestedShift.shift.staffType.name",
    },
    {
      Header: "Time & Location",
      id: "location.name",
      accessor: (d: any) => {
        return (
          <div className={classes.timeAndLocation__attribute}>
            <div className={classes.timeAndLocation__time}>
              {moment(d.myRequestedShift.shift.startLocal).local().format("LT")}{" "}
              {" - "}
              {moment(d.myRequestedShift.shift.endLocal).local().format("LT")}
            </div>

            <div className={classes.timeAndLocation__location}>
              {d.location.name}
            </div>
          </div>
        );
      },
    },
    // {
    //   Header: 'League',
    //   accessor: 'league.Name',
    // },
    {
      Header: "Action",
      id: "action",
      accessor: (value: any) => {
        return (
          <div>
            <Button
              type="button"
              className={classes.delete__btn}
              onClick={() => {
                handleCancelAppliedShift(value.myRequestedShift.id);
              }}
            >
              Cancel Request
            </Button>
            <Button
              type="button"
              className={classes.delete__btn}
              onClick={() => {
                handleViewNotes(value.myRequestedShift.shift.shiftNotes);
              }}
            >
              View Notes
            </Button>
          </div>
        );
      },
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => appliedShifts, [isLoading]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columns,
        data: data,
        initialState: {
          hiddenColumns: ["myRequestedShift.shift.id"],
        },
      },
      useSortBy
    );

  useEffect(() => {
    dispatch(getMyAppliedShifts());
  }, [dispatch]);

  const handleCancelAppliedShift = (appliedShiftId: string) => {
    dispatch(deleteAppliedShift(appliedShiftId)).then(() => {
      dispatch(getMyAppliedShifts());
    });
  };

  // const displayedShifts = (
  //   <div className={classes.shift__container}>
  //     <table className={classes.shift__table}>
  //       <thead>
  //         <tr>
  //           <th>ShiftId#</th>
  //           <th>Start</th>
  //           <th>End</th>
  //           <th>Location/Site</th>
  //           <th>Actions</th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {appliedShifts.map((shift) => (
  //           <tr key={shift.id}>
  //             <td>{shift.shift.id}</td>
  //             <td>
  //               {moment(shift.shift.startLocal).format('ddd MMM,DD YYYY HH:mm')}
  //             </td>
  //             <td>{moment(shift.shift.endLocal).format('ddd MMM,DD YYYY HH:mm')}</td>
  //             <td>{shift.shift.location.name}</td>
  //             <td>
  //               <Button
  //                 type="button"
  //                 className={classes.delete__btn}
  //                 onClick={() => {
  //                   handleCancelAppliedShift(shift.id);
  //                 }}
  //               >
  //                 Cancel Request
  //               </Button>
  //             </td>
  //           </tr>
  //         ))}
  //       </tbody>
  //     </table>
  //   </div>
  // );

  const displayedShiftReactTableMaterialUI = (
    <TableContainer
      className={classes.shift__container}
      sx={{ maxHeight: "80vh", width: "90vw", maxWidth: "90%" }}
    >
      <Table
        stickyHeader
        aria-label="sticky table"
        {...getTableProps()}
        className={classes.shift__table}
      >
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <TableCell
                  sx={{
                    bgcolor: "var(--primary-blue-500)",
                    color: "var(--grey-50)",
                    fontSize: "16px",
                    textAlign: "center",
                    fontWeight: 550,
                  }}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <div className={classes.headAlign}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDownwardIcon />
                        ) : (
                          <ArrowUpwardIcon />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      sx={{ textAlign: "center" }}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const displayedShiftsCardFormat = (
    <div className={classes.card__container}>
      {appliedShifts.map((shift: any) => (
        <Card
          key={shift.myRequestedShift.id}
          sx={{ minWidth: "90vw", marginBottom: "10px" }}
          className={classes.shiftCard}
        >
          <CardContent className={classes.cardContent}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {moment(shift.myRequestedShift.shift.startLocal)
                .local()
                .format("dddd MMM,DD")}
            </Typography>
            <Typography variant="body2">
              {moment(shift.myRequestedShift.shift.startLocal)
                .local()
                .format("LT")}{" "}
              {" - "}
              {moment(shift.myRequestedShift.shift.endLocal)
                .local()
                .format("LT")}
            </Typography>
            <Typography variant="body2">
              {shift.myRequestedShift.shift.staffType.name}
            </Typography>
            <Typography
              variant="h6"
              component="div"
            >
              {shift.location.name}
            </Typography>
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Button
              type="button"
              className={classes.delete__btn}
              onClick={() => {
                handleViewNotes(shift.myRequestedShift.shift.shiftNotes);
              }}
            >
              View Notes
            </Button>
            <Button
              type="button"
              className={classes.delete__btn}
              onClick={() => {
                handleCancelAppliedShift(shift.myRequestedShift.id);
              }}
            >
              Cancel Request
            </Button>
          </CardActions>
        </Card>
      ))}
    </div>
  );

  if (isLoading) {
    return <LoadingMaterialUI />;
  }

  return (
    <main>
      <h3>My Shift Requests</h3>
      {/* {appliedShifts?.length !== 0 ? displayedShifts : <h4>No such records</h4>} */}
      {appliedShifts?.length !== 0 ? (
        displayedShiftReactTableMaterialUI
      ) : (
        <h4>No such records</h4>
      )}
      {displayedShiftsCardFormat}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          {modalContent.length === 0 ? (
            <div>
              <Typography>No Notes</Typography>
            </div>
          ) : (
            <div>
              {modalContent?.map((note: any, index: number) => (
                <div key={note.id}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {`Note: ${index + 1}`}
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mb: 1 }}
                  >
                    {note.note}
                  </Typography>
                </div>
              ))}
            </div>
          )}
        </Box>
      </Modal>
    </main>
  );
};

export default AppliedShifts;
