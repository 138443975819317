import React, { useEffect, useState } from "react";
import { FormRow, LoadingMaterialUI, CheckboxFormRow } from "../../UI";
import classes from "./ShiftNotes.module.css";
import {
  createShiftNotes,
  getShiftNotes,
  getEmailOptions,
} from "../../../app/shiftSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import { AppDispatch, RootState } from "../../../app/store";
import Button from "../../UI/Button/Button";
import { displayAlertError } from "../../../app/globalSlice";

const ShiftNotes = () => {
  const [inputNotes, setInputNotes] = useState("");
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [emailChecked, setEmailChecked] = useState<string[]>([]);

  const { isLoading, notes, emailOptions }: any = useSelector(
    (state: RootState) => state.shift
  );

  const { user } = useSelector((state: RootState) => state.auth);

  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputNotes(e.target.value);
  };

  const handleVisibilityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsVisible(!isVisible);
  };

  const handleEmailChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    let updatedList: any = [...emailChecked];
    if (e.target.checked) {
      updatedList = [...updatedList, e.target.value];
    } else {
      updatedList.splice(emailChecked.indexOf(e.target.value), 1);
    }
    setEmailChecked(updatedList);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const shiftId = params.id;

    if (!inputNotes) {
      dispatch(displayAlertError("Please add notes"));
      return;
    }

    dispatch(
      createShiftNotes({
        shiftId,
        inputNotes,
        isVisible,
        emailChecked,
      })
    ).then(() => dispatch(getShiftNotes(shiftId || "")));
  };

  useEffect(() => {
    const id = params.id || "";
    dispatch(getShiftNotes(id));

    // if (!emailOptions.length) {
    //   dispatch(getEmailOptions({}));
    // }
  }, [dispatch, params.id]);

  let previousNotes;

  previousNotes = (
    <div>
      <table>
        <thead>
          <tr>
            <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
              Note Id
            </th>
            <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
              Note
            </th>
            <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
              Created At
            </th>
            <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
              Created By
            </th>
          </tr>
        </thead>
        <tbody>
          {notes?.map((note: any) => (
            <tr key={note?.id}>
              <td className="px-6 py-3 text-sm font-medium text-gray-900 whitespace-nowrap">
                {note?.id}
              </td>
              <td className="px-6 py-3 text-sm font-medium text-gray-900 whitespace-nowrap">
                {note?.note}
              </td>
              <td className="px-6 py-3 text-sm font-medium text-gray-900 whitespace-nowrap">
                {moment(note.createdAt).local().format("YYYY-MM-DD")}
              </td>
              <td className="px-6 py-3 text-sm font-medium text-gray-900 whitespace-nowrap">
                {note.user?.email}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <main>
      <h3>LA Shift Notes</h3>
      <form
        className={classes.form}
        onSubmit={onSubmit}
      >
        <div>
          <div>
            <label
              htmlFor="notes"
              className={classes.notes__label}
            >
              Add a note
            </label>
          </div>
          <TextField
            className={classes.notes__textarea}
            value={inputNotes}
            fullWidth
            name="notes"
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            rows={8}
            sx={{ width: "600px", paddingBottom: "20px", paddingRight: "30px" }}
            onChange={handleNotesChange}
          ></TextField>
        </div>
        <CheckboxFormRow
          type="checkbox"
          name="isVisible"
          checked={isVisible}
          // defaultChecked={true}
          labelText="Note Visible"
          handleChange={handleVisibilityChange}
        />
        <div className={classes.btn__container}>
          <Button
            variant="primary"
            type="submit"
            className={classes.btn__submit}
          >
            Add Note
          </Button>
        </div>
      </form>
      <div className={classes.previousNotes}>
        <h4>Previous Notes</h4>
        {/* get all the notes rendered here  
        will use React.lazy here for seperate bundling of this section >> will decrease initial load time <OPTIMIZATION>*/}
        {isLoading && <LoadingMaterialUI />}

        {notes["shiftNotes"]?.length !== 0 ? (
          previousNotes
        ) : (
          <div>Nothing Yet</div>
        )}
      </div>
    </main>
  );
};

export default ShiftNotes;
