import { gql } from "@apollo/client";

const APPROVE_SCORE = gql`
  mutation ApproveScore($id: Int!) {
    approveScore(id: $id) {
      success
      message
    }
  }
`;

const CREATE_SCORE = gql`
  mutation ScoreCreate($createScoreArgs: CreateScoreInput!) {
    scoreCreate(createScoreArgs: $createScoreArgs) {
      code
      success
      message
      score {
        id
        gameId
        homeScore
        awayScore
        scoreStatusId
      }
    }
  }
`;

const GET_SCORE_STATUSES = gql`
  query ScoreStatuses {
    scoreStatuses {
      id
      name
    }
  }
`;

const RESET_SCORE = gql`
  mutation ScoreReset($scoreResetId: Int!) {
    scoreReset(id: $scoreResetId) {
      code
      message
      success
      score {
        id
        gameId
        homeScore
        awayScore
        scoreStatusId
      }
    }
  }
`;

export { APPROVE_SCORE, CREATE_SCORE, RESET_SCORE, GET_SCORE_STATUSES };
