import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import shiftReducer from "./shiftSlice";
import venueMasterReducer from "./venueMasterSlice";
import marketingReducer from "./marketingSlice";
import cxReducer from "./cxSlice";
import financeReducer from "./financeSlice";
import globalReducer from "./globalSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    shift: shiftReducer,
    venueMaster: venueMasterReducer,
    marketing: marketingReducer,
    cx: cxReducer,
    finance: financeReducer,
    global: globalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
