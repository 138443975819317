import React, { useEffect } from "react";
import classes from "./CapacityGroup.module.css";
import { FormRow, FormRowSelect } from "../UI";
import { Button, IconButton } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import {
  getVenueSizes,
  getVenueTypes,
  getSessionsForCapacityGroup,
  handleCapacityGroupFilters,
  getCapacityInfo,
  createCapacityGroup,
  cleanupCapacityGroupFilters,
} from "../../app/venueMasterSlice";
import { displayAlertError } from "../../app/globalSlice";

enum DayOfWeek {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

const initialCapacityGroup = {
  name: "",
  sessions: [{ id: 0, name: "" }],
};

const CapacityGroup = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [capacityGroup, setCapacityGroup] =
    React.useState(initialCapacityGroup);

  // shift sessionsCapacityGroup to local components state
  // const [sessionsCapacityGroup, setSessionsCapacityGroup] = React.useState([]);

  const {
    isLoading,
    venueTypes,
    venueSizes,
    capacityGroupFilters,
    sessionsForCapacityGroup,
    capacityInfo,
  }: any = useSelector((state: RootState) => state.venueMaster);

  const weekdays = [
    { id: 0, name: "Sunday" },
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
    { id: 7, name: "Select a day" },
  ];

  useEffect(() => {
    // fetch venue types
    dispatch(getVenueTypes(""));
    // fetch venue sizes
    dispatch(getVenueSizes(""));

    return () => {
      dispatch(cleanupCapacityGroupFilters(""));
    };
  }, [dispatch]);

  const handleChangeFilters = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      handleCapacityGroupFilters({
        ...capacityGroupFilters,
        [e.target.name]: +e.target.value || e.target.value,
      })
    );
  };

  const handleChangeCapacityGroup = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCapacityGroup({ ...capacityGroup, [e.target.name]: e.target.value });
  };

  const handleChangeSession = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number
  ) => {
    const { name, value } = e.target;
    const list = [...capacityGroup.sessions];
    list[i] = { id: +value, name: name };
    setCapacityGroup({ ...capacityGroup, sessions: list });

    // get Capacity info
    dispatch(getCapacityInfo({ sessions: list, ...capacityGroupFilters }));
  };

  const onSubmitFilters = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // error handling of any of filter Id is null/0
    if (capacityGroupFilters.dayOfWeek === 7) {
      dispatch(displayAlertError("Please select a day"));
      return;
    }
    if (capacityGroupFilters.venueSizeId === 0) {
      dispatch(displayAlertError("Please select a venue size"));
      return;
    }
    if (capacityGroupFilters.venueTypeId === 0) {
      dispatch(displayAlertError("Please select a venue type"));
      return;
    }

    // fetch sessions
    dispatch(getSessionsForCapacityGroup(capacityGroupFilters));
  };

  const addSession = (): void => {
    setCapacityGroup((prevState) => ({
      ...prevState,
      sessions: [...prevState.sessions, { id: 0, name: "" }],
    }));
  };

  const removeSession = (i: number): void => {
    let sessions;
    setCapacityGroup((prevState) => {
      sessions = prevState.sessions.filter((session, index) => index !== i);
      return {
        ...prevState,
        sessions: sessions,
      };
    });

    // get Capacity info
    dispatch(getCapacityInfo({ sessions, ...capacityGroupFilters }));
  };

  const onSubmitCreateCapacityGroup = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    // if no name, error
    if (capacityGroup.name === "") {
      dispatch(displayAlertError("Please enter a name for Capacity Group"));
      return;
    }

    // if sessions array is empty or any selected session has Id 0, error
    if (
      capacityGroup.sessions.length === 0 ||
      capacityGroup.sessions.find((session) => session.id === 0)
    ) {
      dispatch(displayAlertError("Select sessions for Capacity Group"));
      return;
    }

    dispatch(createCapacityGroup(capacityGroup)).then(() => {
      // reset capacity group
      dispatch(cleanupCapacityGroupFilters(""));
      setCapacityGroup(initialCapacityGroup);
    });
  };

  const capacityInfoTable = (
    <table className={classes.capacityGroups__table}>
      <thead>
        <tr>
          <th>Venue</th>
          <th>Venue Size</th>
          <th>Available Capacity</th>
        </tr>
      </thead>
      <tbody>
        {capacityInfo.map((venueWiseCapacity: any, index: number) => {
          return (
            <tr key={index}>
              <td>{`${venueWiseCapacity.venueInPlay} (${venueWiseCapacity.parentVenue})`}</td>
              <td>{venueWiseCapacity.venueSize}</td>
              <td>{venueWiseCapacity.capacity}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  return (
    <main>
      <h3>Create Capacity Group</h3>
      <h5>Filters Sessions*</h5>
      <div className={classes.capacityGroups__filters}>
        {/* Day of the week */}
        <FormRowSelect
          name="dayOfWeek"
          value={capacityGroupFilters.dayOfWeek}
          labelText="Day"
          list={weekdays}
          handleChange={handleChangeFilters}
        />
        {/* Venue Type */}
        <FormRowSelect
          name="venueTypeId"
          value={capacityGroupFilters.venueTypeId}
          labelText="Venue Type"
          list={venueTypes.concat({ id: 0, name: "Select a type" })}
          handleChange={handleChangeFilters}
        />
        {/* Venue Size */}
        <FormRowSelect
          name="venueSizeId"
          value={capacityGroupFilters.venueSizeId}
          labelText="Venue Size"
          list={venueSizes.concat({ id: 0, name: "Select a size" })}
          handleChange={handleChangeFilters}
        />
        {/* Apply filters */}
        <div className={classes.capacityGroups__filter_button}>
          <Button
            variant="contained"
            className={classes.btn__submit}
            onClick={(e) => onSubmitFilters(e)}
            sx={{
              color: "var(--white)",
              bgcolor: "var(--primary-blue-500)",
              "&:hover": { bgcolor: "var(--primary-blue-700)" },
            }}
          >
            Apply filters
          </Button>
        </div>
      </div>
      <fieldset className={classes.form_fieldset}>
        <legend>Create a Group</legend>
        <div className={classes.form_item}>
          {/* name */}
          <FormRow
            type="name"
            name="name"
            value={capacityGroup.name}
            labelText="Name"
            handleChange={handleChangeCapacityGroup}
            width="400px"
          />
          {/* Sessions with add button*/}
          <div>
            <h5>Add Sessions</h5>
            {capacityGroup.sessions.map((session, i) => (
              <div key={i}>
                <FormRowSelect
                  name="session"
                  value={session.id}
                  list={[
                    { id: 0, name: `<Select Session>` },
                    ...sessionsForCapacityGroup,
                  ]}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeSession(e, i)
                  }
                  width="400px"
                />
                {capacityGroup.sessions.length > 1 && (
                  <IconButton
                    sx={{
                      "&:hover": { color: "var(--primary-blue-500)" },
                      marginTop: "10px",
                      marginLeft: "-25px",
                    }}
                    onClick={() => {
                      removeSession(i);
                    }}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                )}
              </div>
            ))}
          </div>
          <div>
            <Button onClick={addSession}>Add Sessions</Button>
          </div>
          <div className={classes.btn_create}>
            <Button
              variant="contained"
              className={classes.btn__submit}
              onClick={(e) => onSubmitCreateCapacityGroup(e)}
              sx={{
                color: "var(--white)",
                bgcolor: "var(--primary-blue-500)",
                "&:hover": { bgcolor: "var(--primary-blue-700)" },
              }}
            >
              Create Group
            </Button>
          </div>
        </div>
      </fieldset>
      <h5>Capacity Info</h5>
      {/* Venue-wise capacities */}
      {capacityInfo.length < 1 ? (
        <p>No capacity info found</p>
      ) : (
        capacityInfoTable
      )}
    </main>
  );
};

export default CapacityGroup;
