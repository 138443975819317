import React from 'react';
import classes from './FormField.module.css';
import { FormRowProps } from './types';

const CheckboxFormRow: React.FC<FormRowProps> = ({
  type,
  name,
  value,
  handleChange,
  labelText,
  checked,
}) => {
  return (
    <div className={classes.form__row}>
      <label htmlFor={name} className={classes.form__label}>
        {labelText || name}
      </label>
      <input
        className={classes.form__input}
        type={type}
        value={value as string}
        name={name}
        onChange={handleChange}
        checked={checked}
      ></input>
    </div>
  );
};

export default CheckboxFormRow;
