import React, { ReactNode } from 'react';
import Headline1 from './Headline1';
import { cn } from '../../../../lib/utils';

interface Headline1Props {
  // The text inside the Headline
  children: ReactNode;
  // Extra css customization
  className?: string;
}

const Headline1Variable = ({ children, className }: Headline1Props) => {
  return (
    <>
      <Headline1 size="s-xs" className={cn('md:hidden', className)}>
        {children}
      </Headline1>
      <Headline1 size="xl" className={cn('max-md:hidden', className)}>
        {children}
      </Headline1>
    </>
  );
};

export default Headline1Variable;
