import { createContext, useContext, useState } from "react";

type EmailToolMarketingContextType = {
  selectedReportStatus: number;
  setSelectedReportStatus: React.Dispatch<React.SetStateAction<number>>;
};

const EmailToolMarketingContextDefault: EmailToolMarketingContextType = {
  selectedReportStatus: 0,
  setSelectedReportStatus: () => {},
};

const EmailToolMarketingContext = createContext<EmailToolMarketingContextType>(
  EmailToolMarketingContextDefault
);

export const EmailToolMarketingContextProvider = ({ children }: any) => {
  const [selectedReportStatus, setSelectedReportStatus] = useState<number>(0);

  const value = {
    selectedReportStatus,
    setSelectedReportStatus,
  };

  return (
    <EmailToolMarketingContext.Provider value={value}>
      {children}
    </EmailToolMarketingContext.Provider>
  );
};

export const useEmailToolMarketingContext = () =>
  useContext(EmailToolMarketingContext);
