import React, { useEffect, useState } from "react";
import Button from "../UI/Button/Button";
import Card from "../UI/Card/Card";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import FormField from "../UI/FormField/FormField";
import BreadCrumbs from "../UI/Breadcrumbs/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountries,
  getStates,
  getVendors,
  getVenue,
  getVenueSizes,
  getVenueTypes,
} from "../../app/venueMasterSlice";
import { AppDispatch, RootState } from "../../app/store";
import FormFieldDropdown from "../UI/FormField/FormFieldDropdown/FormFieldDropdown";
import { Venue } from "../../types/types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_VENDOR, UPDATE_VENDOR } from "../../graphql/queries/vendor";
import { useNavigate, useParams } from "react-router-dom";
import {
  CREATE_VENUE,
  UPDATE_VENUE,
  VENUES_EXCEPT_DESCENDANTS,
} from "../../graphql/queries/venues";
import Subtitle2 from "../UI/Text/Subtitle/Subtitle2";
import Body2 from "../UI/Text/Body/Body2";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";

const initialVenue: Venue = {
  id: 0,
  name: "",
  startBuffer: 0,
  endBuffer: 0,
  intersection: "",
  address: "",
  city: "",
  zipCode: "",
  locationLink: "",
  latitude: 0,
  longitude: 0,
  parentId: null,
  parentVenue: null,
  stateId: 0,
  countryId: 0,
  venueSetupId: 0,
  vendorId: 0,
  regionId: 0,
  type: 0,
  size: 0,
  islights: false,
  // for interface
  permit: false,
  facilityDescription: "",
  facilityImage: "",
};

const CreateSubVenue = () => {
  const publicUrl = process.env.PUBLIC_URL;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const params = useParams();
  const {
    isLoading,
    venueTypes,
    venueSizes,
    regions,
    vendorList,
    states,
    countries,
    venue: venueById,
  }: any = useSelector((state: RootState) => state.venueMaster);
  const [venue, setVenue] = useState<Venue>(initialVenue);
  const [venueOptions, setVenueOptions] = useState<Venue[]>([]);

  const [CreateVenue, { data, loading, error }] = useMutation(CREATE_VENUE);
  const [UpdateVenue] = useMutation(UPDATE_VENUE);
  const [
    getVenuesExceptDescendants,
    { data: venueData, loading: venueLoading, error: venueError },
  ] = useLazyQuery(VENUES_EXCEPT_DESCENDANTS, {
    variables: {
      venuesExceptDescendantsId: params.id ? +params.id : null,
    },
    onCompleted: (res) => {
      setVenueOptions(res.venuesExceptDescendants);
    },
  });

  const [isEditVenue, setIsEditVenue] = useState<string | null>(
    params.id ? params.id : null
  );
  const [parentVenueId, setParentVenueId] = useState<string | null>(
    params.parentId ? params.parentId : null
  );

  const handleCreateVendor = () => {
    if (isEditVenue) {
      UpdateVenue({
        variables: {
          venueArgs: {
            id: isEditVenue,
            venueInput: {
              name: venue.name,
              startBuffer: venue.startBuffer,
              endBuffer: venue.endBuffer,
              parentId: venue.parentId,
              venueSetupId: venue.venueSetupId,
              typeId: venue.type,
              sizeId: venue.size,
              islights: venue.islights,
              venueDetailId: venue.venueDetailId,
            },
          },
        },
      })
        .then((res) => {
          if (res.data.venueUpdate.success) {
            setVenue(initialVenue);
            dispatch(displayAlertSuccess(res.data.venueUpdate.message));
            setIsEditVenue(null);
            navigate(`${publicUrl}/ops/venues`);
          } else {
            dispatch(displayAlertError(res.data.venueUpdate.message));
          }
        })
        .catch((err) => {
          dispatch(displayAlertError(err.message));
        });
    } else {
      CreateVenue({
        variables: {
          venueArgs: {
            venueInput: {
              name: venue.name,
              startBuffer: venue.startBuffer,
              endBuffer: venue.endBuffer,
              parentId: venue.parentId,
              venueSetupId: venue.venueSetupId,
              typeId: venue.type,
              sizeId: venue.size,
              islights: venue.islights,
              venueDetailId: venue.venueDetailId, //CHECK VENUE DETAIL ID!
            },
          },
        },
      })
        .then((res) => {
          if (res.data.venueCreate.success) {
            setVenue(initialVenue);
            dispatch(displayAlertSuccess(res.data.venueCreate.message));
            setIsEditVenue(null);
            navigate(`${publicUrl}/ops/venues`);
          } else {
            dispatch(displayAlertError(res.data.venueCreate.message));
          }
        })
        .catch((err) => {
          dispatch(displayAlertError(err.message));
        });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVenue((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    dispatch(getVenueTypes(""));
    dispatch(getVenueSizes(""));
    dispatch(getVendors(""));
    dispatch(getCountries(""));
    dispatch(getStates(""));

    if (isEditVenue) {
      dispatch(getVenue(isEditVenue)).then((res: any) => {
        if (!res.payload) {
          dispatch(displayAlertError("Venue not found"));
          setIsEditVenue(null);
          //navigate(`${publicUrl}/ops/venue-management`);
        }
      });
      // Find all the venues except its own child venues
      getVenuesExceptDescendants();
    } else {
      if (parentVenueId) {
        dispatch(getVenue(parentVenueId)).then((res: any) => {
          if (!res.payload) {
            dispatch(displayAlertError("Venue not found"));
            setIsEditVenue(null);
            //navigate(`${publicUrl}/ops/venue-management`);
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    if (venueById) {
      if (isEditVenue) {
        setVenue({
          ...venueById,
          name: venueById.name,
          parentId: parentVenueId ? parseInt(parentVenueId) : null,
          type: venueById.typeId ? venueById.typeId : 0,
          size: venueById.sizeId ? venueById.sizeId : 0,
          // Venue Detail
          ...venueById.venueDetail,
          facilityDescription: venueById.venueDetail?.facilityDescription,
          facilityImage: venueById.venueDetail?.facilityImage,
        });
      } else {
        setVenue({
          ...venueById,
          name: "",
          parentId: venueById.id ? parseInt(venueById.id) : null,
          type: 0,
          size: 0,
          // Venue Detail
          ...venueById.venueDetail,
          facilityDescription: venueById.venueDetail?.facilityDescription,
          facilityImage: venueById.venueDetail?.facilityImage,
        });
        setVenueOptions([
          {
            id: parseInt(venueById.id),
            name: venueById.name,
            parentId: venueById.parentId,
          },
        ]);
      }
    }
  }, [venueById]);

  const pathsBreadcrumbs = [
    { name: "Venue", url: "/ops/venues" },
    { name: "Venue Details", url: "/ops/venues" },
  ];

  return (
    <main>
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/ops/venues"
      ></BreadCrumbs>
      <div className="flex flex-row justify-between w-full mt-6">
        <Headline1Variable>
          {isEditVenue ? "Edit Sub-Venue" : `Create Sub-Venue`}
        </Headline1Variable>
        <div className="h-10">
          <Button
            variant="primary"
            onClick={handleCreateVendor}
          >
            {isEditVenue ? (
              <span> Edit Venue</span>
            ) : (
              <span> Create Venue</span>
            )}
          </Button>
        </div>
      </div>
      <Card className="mt-6">
        <Subtitle1>Venue Details</Subtitle1>
        {/* <Body2>
          {venue.parentVenue ? `Parent Venue: ${venue.parentVenue?.name}` : ""}
        </Body2> */}
        <div className="flex flex-col">
          <div className="flex flex-row w-full gap-4 mt-6">
            <FormFieldDropdown
              disabled={isEditVenue ? false : true}
              initialValue={parentVenueId ? parentVenueId.toString() : "0"}
              inputChange={(value) => {
                console.log("🚀🚀🚀🚀 ~ CreateSubVenue ~ value:", value);
                setVenue((prevState) => {
                  return {
                    ...prevState,
                    parentId: parseInt(value),
                  };
                });
              }}
              name="parentId"
              label="Parent Venue"
              placeholder="Parent Venue"
            >
              {[{ id: 0, name: "Select Parent Venue" }, ...venueOptions]}
            </FormFieldDropdown>
          </div>
          <div className="flex flex-row w-full gap-4 mt-6">
            <FormField
              initialValue={venue.name ? venue.name : ""}
              inputChange={handleChange}
              name="name"
              label="Venue Name"
            ></FormField>
            {/* For top level Venue, parentID = null,
          For sub-Venue, params will decide the parentID
          In both cases, its not an input field, disabled
          */}

            {/* <FormField
              initialValue={venue.parentVenue ? venue.parentVenue?.name : ''}
              inputChange={(value) => {
              }}
              name="parentId"
              label="Parent Venue"
              placeholder="Parent Venue"
              disabled={true}
            ></FormField> */}
            <FormFieldDropdown
              initialValue={venue.type ? venue.type.toString() : "0"}
              inputChange={(value) => {
                setVenue((prevState) => {
                  return {
                    ...prevState,
                    type: parseInt(value),
                  };
                });
              }}
              name="venueType"
              label="Venue Type"
              placeholder="Venue Type"
            >
              {[{ id: 0, name: "Select Venue Type" }, ...venueTypes]}
            </FormFieldDropdown>
            <FormFieldDropdown
              initialValue={venue.size ? venue.size.toString() : "0"}
              inputChange={(value) => {
                setVenue((prevState) => {
                  return {
                    ...prevState,
                    size: parseInt(value),
                  };
                });
              }}
              name="venueSize"
              label="Venue Size"
              placeholder="Venue Size"
            >
              {[{ id: 0, name: "Select Venue Size" }, ...venueSizes]}
            </FormFieldDropdown>
          </div>
          <div className="flex flex-row w-full gap-4 mt-6">
            <FormFieldDropdown
              initialValue={venue.islights ? "1" : "0"}
              inputChange={(value) => {
                setVenue((prevState) => ({
                  ...prevState,
                  islights: value === "1" ? true : false,
                }));
              }}
              name="islights"
              label="Lights"
              placeholder="Lights"
            >
              {[
                { id: 0, name: "No" },
                { id: 1, name: "Yes" },
              ]}
            </FormFieldDropdown>
            {/* For top level Venue, parentID = null,
          For sub-Venue, params will decide the parentID
          In both cases, its not an input field, disabled
          */}
            <FormFieldDropdown
              initialValue={"0"}
              inputChange={(value) => {}}
              name="venueSetup"
              label="Venue Setup"
              placeholder="Venue Setup"
              disabled={true}
            >
              {[{ id: 0, name: "None" }]}
            </FormFieldDropdown>
            <FormField
              initialValue={
                venue.startBuffer ? venue.startBuffer.toString() : "0"
              }
              inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setVenue((prevState) => ({
                  ...prevState,
                  [e.target.name]: parseInt(e.target.value),
                }));
              }}
              name="startBuffer"
              label="Start Buffer"
            ></FormField>
            <FormField
              initialValue={venue.endBuffer ? venue.endBuffer.toString() : "0"}
              inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setVenue((prevState) => ({
                  ...prevState,
                  [e.target.name]: parseInt(e.target.value),
                }));
              }}
              name="endBuffer"
              label="End Buffer"
            ></FormField>
          </div>
        </div>
      </Card>
      {/* <Card className="mt-6">
        <Subtitle1 className="mb-4">Facility Information</Subtitle1>
        <Body2>{venue.region && `Region - ${venue.region?.name}`}</Body2>
        <Body2>{venue.vendor && `Vendor - ${venue.vendor?.name}`}</Body2>
        <Body2>
          {venue.permit && `Permit - ${venue.permit ? 'Yes' : 'No'}`}
        </Body2>
        <Body2>
          {venue.intersection && `Intersection - ${venue.intersection}`}
        </Body2>
        <Body2>{venue.address && `Address - ${venue.address}`}</Body2>
        <Body2>{venue.city && `City - ${venue.city}`}</Body2>
        <Body2>{venue.state?.name && `State - ${venue.state?.name}`}</Body2>
        <Body2>{venue.zipCode && `Zip Code - ${venue.zipCode}`}</Body2>
        <Body2>
          {venue.country?.name && `Country - ${venue.country?.name}`}
        </Body2>
      </Card>
      <Card className="mt-6">
        <Subtitle1 className="mb-4">Maps Information</Subtitle1>
        <Body2>{venue.latitude && `Latitude - ${venue.latitude}`}</Body2>
        <Body2>{venue.longitude && `Longitude - ${venue.longitude}`}</Body2>
        <Body2>
          {venue.locationLink && `Location Link - ${venue.locationLink}`}
        </Body2>
      </Card> */}
      {/* <Card className="mt-6">
        <Subtitle1 className="mb-4">Facility Image</Subtitle1>
        { !venue.facilityImage && <div className='w-[120px] h-[120px] bg-gray-300 rounded-2xl flex items-center justify-center'>
            <FmdGoodIcon fontSize="large" />
          </div>} 
        { venue.facilityImage && <img className="mr-2 bg-neutral-80" src={venue.facilityImage} alt='image' width={120} height={120}/>} 
        <Subtitle2>{`Facility Description - ${venue.facilityDescription}`}</Subtitle2>
      </Card> */}
      <div className="flex justify-end mt-6">
        <Button
          variant="primary"
          onClick={handleCreateVendor}
        >
          {isEditVenue ? <span> Edit Venue</span> : <span> Create Venue</span>}
        </Button>
      </div>
    </main>
  );
};

export default CreateSubVenue;
