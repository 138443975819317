import React, { ReactNode } from 'react';
import Headline2 from './Headline2';
import Headline1 from './Headline1';

const Headline2Variable = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Headline2
        size="s-xs"
        className="md:hidden"
      >
        {children}
      </Headline2>
      <Headline2
        size="xl-m"
        className="max-md:hidden"
      >
        {children}
      </Headline2>
    </>
  );
};

export default Headline2Variable;
