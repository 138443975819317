import { gql } from "@apollo/client";

const SEND_OPENAI_MESSAGE = gql`
  mutation SendMessage($messages: [AiMessageInput]!) {
    sendMessage(messages: $messages) {
      content
      role
    }
  }
`;

export { SEND_OPENAI_MESSAGE };
