import React, { useEffect } from "react";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import Button from "../UI/Button/Button";
import Card from "../UI/Card/Card";
import FormField from "../UI/FormField/FormField";
import { getAllPermissions, getPermissions } from "../../app/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import { useMutation } from "@apollo/client";
import { CREATE_PERMISSION } from "../../graphql/queries/user";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";

interface Permission {
  id?: string;
  name: string;
}

const initialPermission: Permission = {
  id: "",
  name: "",
};

const Permissions = () => {
  const [permission, setPermission] =
    React.useState<Permission>(initialPermission);
  const dispatch = useDispatch<AppDispatch>();
  const { permissions }: any = useSelector((state: RootState) => state.auth);
  const [CreatePermission, { data, loading, error }] =
    useMutation(CREATE_PERMISSION);

  useEffect(() => {
    dispatch(getAllPermissions());
  }, []);

  const handleCreatePermission = () => {
    CreatePermission({
      variables: {
        name: permission.name,
      },
    }).then((res: any) => {
      if (res.data.permissionCreate.success) {
        dispatch(displayAlertSuccess(res.data.permissionCreate.message));
        dispatch(getAllPermissions());
        // .then((data: any) => {
        //   if (data) {
        //     console.log('🚀🚀🚀🚀 ~ dispatch ~ data:', data);
        //     dispatch(getPermissions(data.payload));
        //   }
        // });
        setPermission(initialPermission);
      } else {
        dispatch(displayAlertError(res.data.permissionCreate.message));
      }
    });
  };

  return (
    <>
      <div className="flex items-center justify-between h-16 mt-5">
        <div>
          <Headline1Variable>Permission</Headline1Variable>
        </div>
        <div className="flex flex-row items-center gap-4 md:max-2xl:justify-end:items-end:self-end:content-end:justify-self-end">
          <Button
            variant="primary"
            onClick={handleCreatePermission}
            type="submit"
          >
            Save
          </Button>
        </div>
      </div>
      <Card className="mt-5">
        {/* Permission Form */}
        <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
          <FormField
            initialValue={permission.name ? permission.name : ""}
            inputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPermission({
                ...permission,
                name: event.target.value,
              });
            }}
            name="name"
            label="Permission Tag"
          />
        </div>
      </Card>
      <Card className="mt-5">
        {/* Permission List */}
        <div className="flex flex-col gap-4">
          {permissions && <Subtitle1>Permissions List</Subtitle1>}
          {permissions.map((permission: Permission) => (
            <div key={permission.id}>{permission.name}</div>
          ))}
        </div>
      </Card>
    </>
  );
};

export default Permissions;
