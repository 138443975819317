import React, { useEffect, useMemo } from "react";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import Button from "../../UI/Button/Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import { Month, Pagination, PayrollBatch } from "../../../types/types";
import { getPayrollBatches } from "../../../app/financeSlice";
import { Column } from "react-table";
import ActionsCell from "../../UI/ActionCell";
import BaseTable from "../../UI/Table/Table";
import TablePagination from "../../UI/Pagination/Pagination";
import { LoadingMaterialUI } from "../../UI";
import moment from "moment";
import { IconButton } from "@mui/material";
import Link1 from "../../UI/Text/Link/Link1";

const PayrollBatches = () => {
  const rootPath =
    process.env.NODE_ENV === "production" ? process.env.REACT_APP_URL : "";
  const publicUrl = process.env.PUBLIC_URL;

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { isLoading, payrollBatches, payrollBatchesCount }: any = useSelector(
    (state: RootState) => state.finance
  );

  //Pagination Page Size
  const pageSize = 10;
  const [pagination, setPagination] = React.useState<null | Pagination>(null);

  const redirectCreatePayrollBatch = () => {
    navigate(`${rootPath}/finance/createPayrollBatch/`);
  };

  const handleChangePagination = (value: number) => {
    setPagination({
      pageCount: pagination?.pageCount,
      pageSize: pagination?.pageSize,
      page: value - 1,
    });
  };

  const handleSelectedOption = (row: any, idx: number) => {
    if (idx === 1) {
      navigate(`${rootPath}/finance/createPayrollBatch/${row.original.id}`);
    }
    if (idx === 2) {
      navigate(`${rootPath}/finance/payrollBatch/${row.original.id}`);
    }
  };

  useEffect(() => {
    const page = 0;
    const pageCount = Math.ceil(payrollBatchesCount / pageSize);
    setPagination({
      page,
      pageSize,
      pageCount,
    });
  }, [payrollBatchesCount]);

  useEffect(() => {
    dispatch(
      getPayrollBatches({
        page: pagination?.page,
        pageSize: pagination?.pageSize,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (pagination) {
      dispatch(
        getPayrollBatches({
          page: pagination?.page,
          pageSize: pagination?.pageSize,
        })
      );
    }
  }, [pagination]);

  const COLUMNS: Column<PayrollBatch>[] = [
    {
      Header: "Name",
      id: "name",
      accessor: (d: any) => {
        return (
          <IconButton
            onClick={() => {
              navigate(`${publicUrl}/finance/payrollBatch/${d.id}`);
            }}
          >
            <Link1>{`${d["name"]}`}</Link1>
          </IconButton>
        );
      },
    },
    {
      Header: "Year",
      id: "year",
      accessor: (d: any) => {
        return <div>{d["year"]}</div>;
      },
    },
    {
      Header: "Month",
      id: "month",
      accessor: (d: any) => {
        return <div>{Month[d["month"]]}</div>;
      },
    },
    {
      Header: "Shift Cutoff Date",
      id: "shiftCutoffDate",
      accessor: (d: any) => {
        return <div>{d["shiftCutoffDate"]}</div>;
      },
    },
    {
      Header: "Status",
      id: "status",
      accessor: (d: any) => {
        return <div>{d.payrollBatchStatus.name}</div>;
      },
    },
    {
      Header: "Number of Shifts",
      id: "numOfShifts",
      accessor: (d: any) => {
        return <div>{d["numOfShifts"]}</div>;
      },
    },
    {
      Header: "Pay Date",
      id: "payDate",
      accessor: (d: any) => {
        return <div>{d["payDate"]}</div>;
      },
    },
    {
      Header: "Created At",
      id: "created At",
      accessor: (d: any) => {
        return (
          <div>{moment(d.regCloseDate).local().format("YYYY-MM-DD ")}</div>
        );
      },
    },
    {
      Header: " ",
      Cell: (data: any) => {
        let options = [];
        if (data.row.original.payrollBatchStatus.name === "Paid") {
          options.push({ id: 2, text: "View" });
        } else {
          options.push({ id: 1, text: "Edit" });
        }

        return (
          <ActionsCell
            row={data.row}
            options={options}
            handleSelectedOption={handleSelectedOption}
          />
        );
      },
    },
  ];

  const columns: any = useMemo(() => COLUMNS, []);
  const data = useMemo(() => payrollBatches, [payrollBatches]);

  return (
    <main>
      <div className="flex flex-row justify-between w-full mb-4">
        <Headline1Variable>Payroll Batches</Headline1Variable>
        <div className="mb-4 text-end">
          <Button
            variant="primary"
            onClick={redirectCreatePayrollBatch}
          >
            <AddIcon />
            New Payroll Batch
          </Button>
        </div>
      </div>
      {isLoading && <LoadingMaterialUI />}
      {payrollBatches.length > 0 && (
        <BaseTable
          columns={columns}
          data={data}
        />
      )}
      {!(payrollBatches.length > 0) && <div>No Data Found</div>}
      {pagination && (
        <div className="flex justify-end mt-4">
          <TablePagination
            page={pagination?.page || 0}
            pageCount={pagination?.pageCount || 0}
            onChange={handleChangePagination}
          />
        </div>
      )}
    </main>
  );
};

export default PayrollBatches;
