import { SEND_TEST_EMAIL } from '../../graphql/queries/email';
import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import Button from '../UI/Button/Button';

const TestPage = () => {
  const [sendTestEmail, { data, loading, error }]: any =
    useMutation(SEND_TEST_EMAIL);

  return (
    <>
      <h3>This is the home page</h3>
      <Button
        variant='primary'
        onClick={() => {
          sendTestEmail();
        }}
      >
        Send test email
      </Button>
    </>
  );
};

export default TestPage;
