import React, { useEffect, useMemo, useState } from "react";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import Button from "../UI/Button/Button";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { CircularProgress } from "@mui/material";
import { Column } from "react-table";
import { Session, ShoppingCart, UserAccount } from "../../types/types";
import { FormRowSelect, LoadingMaterialUI } from "../UI";
import FormField from "../UI/FormField/FormField";
import Headline2Variable from "../UI/Text/Headline/Headline2Variable";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_USER_BY_EMAIL } from "../../graphql/queries/user";
import {
  CREATE_SHOPPING_CART,
  SHOPPING_CART_ITEM_ADD,
  SHOPPING_CART_ITEM_REMOVE,
} from "../../graphql/queries/shoppingCart";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import BaseTable from "../UI/Table/Table";
import { GET_SESSION_BY_DAY_AND_LEAGUE_SEARCH } from "../../graphql/queries";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../UI/shadcn/dialog";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "../UI/Table/ShadcnTable";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";
import {
  GetSessionByDayAndLeagueSearchQuery,
  GetShoppingCartByUserIdQuery,
  useGetSessionByDayAndLeagueSearchLazyQuery,
  useGetShoppingCartByUserIdLazyQuery,
} from "../../generated/graphql";
import { numberToMoney } from "../../utils/financialHelpers";

const weekdaysArray: { id: number; name: string }[] = [
  { id: 0, name: "Sunday" },
  { id: 1, name: "Monday" },
  { id: 2, name: "Tuesday" },
  { id: 3, name: "Wednesday" },
  { id: 4, name: "Thursday" },
  { id: 5, name: "Friday" },
  { id: 6, name: "Saturday" },
];

type getSessionsByDayAndSearchInput = {
  dayOfWeek: number;
  leagueSearch: string;
  regions: number[];
};

type SessionsForLeagueTransfer =
  GetSessionByDayAndLeagueSearchQuery["getSessionByDayAndLeagueSearch"][0];

enum indyOrTeam {
  indy = 1,
  team = 2,
}

const CreateShoppingCart = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { registrationBatches, selectedRegions, sports }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const { isLoading }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  const [search, setSearch] = useState<string | null>(null);
  const [user, setUser] = useState<Partial<UserAccount> | null>(null);
  const [shoppingCart, setShoppingCart] = useState<
    GetShoppingCartByUserIdQuery["getShoppingCartByUserId"] | null
  >(null);
  const [open, setOpen] = useState(false);
  const [queriedSessions, setQueriedSessions] = useState<
    SessionsForLeagueTransfer[]
  >([]);
  const [queryMessage, setQueryMessage] = useState<string>("");
  const [sessionFilter, setSessionFilter] =
    useState<getSessionsByDayAndSearchInput>({
      regions: selectedRegions.map((region: any) => +region.id),
      dayOfWeek: 7,
      leagueSearch: "",
    });

  useEffect(() => {
    setSessionFilter({
      ...sessionFilter,
      regions: selectedRegions.map((region: any) => +region.id),
    });
  }, [selectedRegions]);

  useEffect(() => {}, [sessionFilter]);

  const [
    getUserByEmail,
    { data: userData, loading: userLoading, error: userError },
  ] = useLazyQuery(GET_USER_BY_EMAIL);

  const [
    getCartByUserId,
    { data: cartData, loading: cartLoading, error: cartError },
  ] = useGetShoppingCartByUserIdLazyQuery({
    fetchPolicy: "no-cache",
  });

  const [
    addShoppingCartItem,
    { data: updatedShoppingCart, loading: loadingAdd, error: errorAdd },
  ]: any = useMutation(SHOPPING_CART_ITEM_ADD, {
    fetchPolicy: "no-cache",
  });

  const [
    deleteShoppingCartItem,
    {
      data: deletedShoppingCartItem,
      loading: loadingDelete,
      error: errorDelete,
    },
  ]: any = useMutation(SHOPPING_CART_ITEM_REMOVE, {
    fetchPolicy: "no-cache",
  });

  const [
    getSessionsByDayAndSearch,
    { data: sessionData, loading: sessionLoading, error: sessionError },
  ] = useGetSessionByDayAndLeagueSearchLazyQuery({
    fetchPolicy: "no-cache",
  });

  const [
    createShoppingCart,
    {
      data: createCartData,
      loading: createCartLoading,
      error: createCartError,
    },
  ] = useMutation(CREATE_SHOPPING_CART);

  const retrieveExistingCart = async (userId: string) => {
    await getCartByUserId({
      variables: { userId: userId },
      onCompleted: (data) => {
        if (!data.getShoppingCartByUserId) {
          setShoppingCart(null);
        } else {
          setShoppingCart(data.getShoppingCartByUserId);
        }
      },
    });
  };

  const handleSearchCart = async () => {
    if (search && emailRegex.test(search)) {
      await getUserByEmail({
        variables: {
          email: search,
        },
      })
        .then((res) => {
          if (res.data.getUserByEmail === null) {
            dispatch(displayAlertError("User not found"));
            setShoppingCart(null);
            setUser(null);
          } else {
            retrieveExistingCart(res.data.getUserByEmail.id);
            setUser(res.data.getUserByEmail);
          }
        })
        .catch((err) => {
          dispatch(displayAlertError("User not found"));
        });
    } else {
      dispatch(displayAlertError("Invalid email"));
    }
  };

  const handleCreateCart = async () => {
    if (user) {
      await createShoppingCart({
        variables: {
          shoppingCartArgs: {
            shoppingCartInput: {
              userId: user.id,
              shoppingCartStatusId: 1,
            },
            shoppingCartItemsInput: [],
          },
        },
      }).then((res) => {
        if (user.id) retrieveExistingCart(user.id);
      });
    } else {
      dispatch(displayAlertError("Please search for a user first"));
    }
  };

  const calculateTaxFromCostBeforeTax = (
    costBeforeTax: number,
    taxRate: number
  ): number => {
    return costBeforeTax * taxRate;
  };

  const handleAddItem = async (
    session: SessionsForLeagueTransfer,
    typeOfSession: indyOrTeam
  ) => {
    // console.log(session);
    const txnFee =
      session.league.sportFormat.region.txnFee.find(
        (fee) => fee.productTypeId === typeOfSession
      )?.amount ?? null;
    const shoppingCartItem = [
      {
        itemAmount:
          typeOfSession === indyOrTeam.indy
            ? session.priceForIndy
            : session.priceForTeam,
        amountPaid: 0,
        discount: 0,
        tax: calculateTaxFromCostBeforeTax(
          typeOfSession === indyOrTeam.indy
            ? session.priceForIndy
            : session.priceForTeam,
          session.league.sportFormat.region.taxRate
        ),
        productId: +session.id,
        productTypeId: typeOfSession,
        discountTypeId: 1,
        txnFee: txnFee,
      },
    ];
    if (user) {
      await addShoppingCartItem({
        variables: {
          shoppingCartItemArgs: {
            userId: user.id,
            shoppingCartItemsInput: [...shoppingCartItem],
          },
        },
      }).then((res: any) => {
        dispatch(displayAlertSuccess("Item added to cart"));
      });
    } else {
      dispatch(displayAlertError("Please search for a user first"));
    }
  };

  const handleDeleteItem = async (id: number) => {
    if (user && shoppingCart) {
      await deleteShoppingCartItem({
        variables: {
          userId: user.id,
          shoppingCartItemId: +id,
          shoppingCartId: +shoppingCart.id,
        },
      }).then((res: any) => {
        if (user && user.id) retrieveExistingCart(user.id);
        dispatch(displayAlertSuccess("Item removed from cart"));
      });
    } else {
      dispatch(displayAlertError("Please search for a user first"));
    }
  };

  const handleCloseDialog = () => {
    if (user && user.id) retrieveExistingCart(user.id);
    setQueriedSessions([]);
    setQueryMessage("");
    setSessionFilter((prev) => ({
      ...prev,
      dayOfWeek: 7,
      leagueSearch: "",
    }));
  };

  const searchSessions = async () => {
    if (queryMessage) setQueryMessage("");
    const { dayOfWeek, regions, leagueSearch } = sessionFilter;
    const res = await getSessionsByDayAndSearch({
      variables: {
        dayOfWeek: dayOfWeek,
        regions: regions,
        leagueSearch: leagueSearch,
        userId: user?.id!,
      },
      onCompleted: (data) => {
        if (data.getSessionByDayAndLeagueSearch.length === 0) {
          setQueriedSessions([]);
          setQueryMessage("No sessions found");
        } else {
          setQueriedSessions(data.getSessionByDayAndLeagueSearch);
        }
      },
    }).then((res) => {});
  };

  // Shopping Cart table
  const shoppingCartColumns: Column<
    NonNullable<GetShoppingCartByUserIdQuery["getShoppingCartByUserId"]>
  >[] = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Date Created",
      accessor: "createdAt",
    },
  ];
  const shoppingCartData = useMemo(
    () => (shoppingCart ? [shoppingCart] : []),
    [shoppingCart]
  );

  const shoppingCartItemColumns: Column<
    NonNullable<
      GetShoppingCartByUserIdQuery["getShoppingCartByUserId"]
    >["shoppingCartItems"][0]
  >[] = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Date Created",
      accessor: "createdAt",
    },
    {
      Header: "Product Id",
      accessor: (d) => {
        return <div>{d.product.id}</div>;
      },
    },
    {
      Header: "Product Type",
      accessor: (d) => {
        return <div>{d.productType.name}</div>;
      },
    },
    {
      Header: "Description",
      accessor: (d) => {
        return <div>{d.product.league.name}</div>;
      },
    },
    {
      Header: "Amount",
      accessor: (d) => {
        return <>{numberToMoney(d.itemAmount)}</>;
      },
    },
    {
      Header: "Item Balance",
      accessor: (d) => {
        return <>{numberToMoney(d.amountPaid)}</>;
      },
    },
    {
      Header: "Tax",
      accessor: (d) => {
        return <>{numberToMoney(d.amountPaid)}</>;
      },
    },
    {
      Header: "Discount",
      accessor: "discount",
    },
    {
      Header: "Delete Item",
      accessor: (d: any) => {
        return (
          <Button
            variant="negative"
            onClick={() => handleDeleteItem(d.id)}
          >
            <DeleteOutlineOutlinedIcon
              fontSize="large"
              sx={{
                width: "18px",
                height: "18px",
              }}
            />
          </Button>
        );
      },
    },
  ];

  const shoppingCartItemData = useMemo(
    () => shoppingCart?.shoppingCartItems ?? [],
    [shoppingCart]
  );

  const queriedSessionsColumns: ColumnDef<any>[] = [
    {
      accessorKey: "id",
      header: "Id",
    },
    {
      header: "Region",
      accessorKey: "league.sportFormat.region.name",
    },
    {
      accessorKey: "league.name",
      header: "League Name",
    },
    {
      header: "Reg Batch Open",
      accessorKey: "registrationBatch.regOpenDate",
      cell: ({ row }) => {
        return (
          <div className="font-medium text-left">
            {new Date(
              row.original.registrationBatch.regOpenDate
            ).toLocaleDateString()}
          </div>
        );
      },
    },
    {
      header: "Reg Batch Close",
      accessorKey: "registrationBatch.regCloseDate",
      cell: ({ row }) => {
        return (
          <div className="font-medium text-left">
            {new Date(
              row.original.registrationBatch.regCloseDate
            ).toLocaleDateString()}
          </div>
        );
      },
    },
    {
      header: "Day of Week",
      accessorKey: "dayOfWeek",
      cell: ({ row }) => {
        return (
          <div className="font-medium text-left">
            {weekdaysArray.find((day) => day.id === row.original.dayOfWeek)
              ?.name ?? row.original.dayOfWeek}
          </div>
        );
      },
    },
    {
      header: "Start Date",
      accessorKey: "startDate",
      cell: ({ row }) => {
        return (
          <div className="font-medium text-left">
            {new Date(
              row.original.registrationBatch.regCloseDate
            ).toLocaleDateString()}
          </div>
        );
      },
    },
    {
      header: "FA Cost",
      accessorKey: "priceForIndy",
      cell: ({ row }) => {
        return (
          <div className="font-medium text-left">
            ${row.original.priceForIndy}
          </div>
        );
      },
    },
    {
      header: "Team Cost",
      accessorKey: "priceForTeam",
      cell: ({ row }) => {
        return (
          <div className="font-medium text-left">
            ${row.original.priceForTeam}
          </div>
        );
      },
    },
    {
      header: "Add FA",
      accessorKey: "availability.freeAgent",
      cell: ({ row }) => {
        return (
          <Button
            variant={
              row.original.availability.freeAgent !== "Available"
                ? "disabled"
                : "primary"
            }
            onClick={() => handleAddItem(row.original, indyOrTeam.indy)}
            disabled={row.original.availability.freeAgent !== "Available"}
          >
            <PersonAddAltOutlinedIcon
              fontSize="large"
              sx={{
                width: "18px",
                height: "18px",
              }}
            />
          </Button>
        );
      },
    },
    {
      header: "Add Team",
      accessorKey: "availability.team",
      cell: ({ row }) => {
        return (
          <Button
            variant={
              row.original.availability.team !== "Available"
                ? "disabled"
                : "primary"
            }
            onClick={() => handleAddItem(row.original, indyOrTeam.team)}
            disabled={row.original.availability.team !== "Available"}
          >
            <GroupAddOutlinedIcon
              fontSize="large"
              sx={{
                width: "18px",
                height: "18px",
              }}
            />
          </Button>
        );
      },
    },
  ];

  const queriedSessionsData = useMemo(() => {
    const today = new Date();
    if (queriedSessions) {
      const filteredSessions = queriedSessions.filter((session) => {
        // If today is between the reg open and close date
        const regOpen = new Date(session.registrationBatch.regOpenDate);
        const regClose = new Date(session.registrationBatch.regCloseDate);
        return regOpen <= today && today <= regClose;
      });
      return filteredSessions;
    } else {
      return [];
    }
  }, [queriedSessions]);

  if (isLoading) {
    return (
      <div>
        <LoadingMaterialUI />
      </div>
    );
  }

  return (
    <main>
      <div className="flex flex-col justify-between w-full gap-4 mb-4">
        <Headline1Variable>Create/View shopping cart</Headline1Variable>
        <div className="flex flex-col gap-4">
          <Headline2Variable>Search for a user</Headline2Variable>
          <div className="flex flex-row gap-4">
            <div className="w-64 h-[90%]">
              <FormField
                initialValue={search || ""}
                inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value.length > 2) setSearch(e.target.value);
                  else setSearch(null);
                }}
                name="startBuffer"
                placeholder="Search"
                width="full"
              ></FormField>
            </div>
            <Button
              variant="secondary"
              onClick={() => handleSearchCart()}
            >
              <div className="flex flex-row items-center gap-2">
                <SearchIcon
                  fontSize="large"
                  sx={{
                    width: "18px",
                    height: "18px",
                  }}
                />
                <span>Search</span>
              </div>
            </Button>
          </div>
          {user && (
            <div className="flex flex-row gap-4">
              <Subtitle1>
                User: {user.firstName} {user.lastName}
              </Subtitle1>
              <Subtitle1>Email: {user.email}</Subtitle1>
              <Subtitle1>
                Active Cart:{" "}
                {cartLoading
                  ? "Loading"
                  : shoppingCart
                  ? "Exists"
                  : "Does not exist"}
              </Subtitle1>
            </div>
          )}
        </div>
        <div className="flex flex-col w-full">
          {cartLoading || loadingDelete ? (
            <CircularProgress />
          ) : shoppingCartData.length > 0 && !cartLoading ? (
            <div className="flex flex-col gap-8">
              <div className="flex flex-col gap-4">
                <div>
                  <Headline2Variable>Shopping Cart</Headline2Variable>
                </div>
                {
                  <BaseTable
                    data={shoppingCartData}
                    columns={shoppingCartColumns}
                  />
                }
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex flex-row gap-4">
                  <Headline2Variable>Shopping Cart Items</Headline2Variable>
                  <Dialog
                    onOpenChange={(openState) => {
                      if (!openState) handleCloseDialog();
                    }}
                  >
                    <DialogTrigger className="px-6 py-2 text-black border-none cursor-pointer bg-primary-80 hover:shadow-lg rounded-xl">
                      <AddIcon
                        fontSize="large"
                        sx={{
                          width: "18px",
                          height: "18px",
                        }}
                      />
                      <span>Add Item</span>
                    </DialogTrigger>
                    <DialogContent className="flex flex-col absolute w-[90%] max-w-[90%] h-[85%] max-h-[85%] top-5 left-0 translate-x-[10%] translate-y-[10%]">
                      <DialogHeader>
                        <DialogTitle className="m-0">
                          Add items to cart
                        </DialogTitle>
                      </DialogHeader>
                      <div className="flex flex-col justify-between w-full h-full gap-4">
                        <div className="flex flex-col gap-4">
                          <div className="flex flex-row items-end gap-4 ">
                            <div className="w-64">
                              <FormFieldSelect
                                key={sessionFilter.dayOfWeek}
                                inputChange={(value) => {
                                  setSessionFilter({
                                    ...sessionFilter,
                                    ["dayOfWeek"]: +value,
                                  });
                                }}
                                label={"Day"}
                                placeholder="Select Day of Week"
                                value={sessionFilter.dayOfWeek.toString()}
                              >
                                {[{ id: 7, name: "All" }, ...weekdaysArray]}
                              </FormFieldSelect>
                            </div>
                            <div className="w-80 max-w-80">
                              <FormField
                                initialValue={sessionFilter.leagueSearch}
                                inputChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  setSessionFilter({
                                    ...sessionFilter,
                                    leagueSearch: e.target.value,
                                  })
                                }
                                name="leagueSearch"
                                placeholder="Search"
                                className="h-9"
                              ></FormField>
                            </div>
                            <div>
                              <Button
                                variant="primary"
                                onClick={() => searchSessions()}
                                className="w-60 max-h-9"
                              >
                                <div className="flex flex-row items-center gap-2">
                                  <SearchIcon
                                    fontSize="large"
                                    sx={{
                                      width: "18px",
                                      height: "18px",
                                    }}
                                  />
                                  <span>Search Session</span>
                                </div>
                              </Button>
                            </div>
                          </div>
                          {queryMessage && (
                            <Subtitle1>{queryMessage}</Subtitle1>
                          )}
                          {sessionLoading || loadingAdd ? (
                            <CircularProgress />
                          ) : (
                            <div className="max-h-[450px] overflow-scroll border rounded-md">
                              {queriedSessions.length > 0 && (
                                <DataTable
                                  data={queriedSessionsData}
                                  columns={queriedSessionsColumns}
                                />
                              )}
                            </div>
                          )}
                        </div>
                        <DialogClose className="flex justify-start">
                          <Button variant="secondary">Close</Button>
                        </DialogClose>
                      </div>
                    </DialogContent>
                  </Dialog>
                </div>
                {
                  <BaseTable
                    data={shoppingCartItemData}
                    columns={shoppingCartItemColumns}
                  />
                }
              </div>
            </div>
          ) : (
            user && (
              <div>
                <div>
                  <Headline2Variable>
                    No active cart found for user
                  </Headline2Variable>
                </div>
                <Button
                  variant="primary"
                  onClick={() => handleCreateCart()}
                >
                  <div className="flex flex-row items-center gap-2">
                    <AddIcon
                      fontSize="large"
                      sx={{
                        width: "18px",
                        height: "18px",
                      }}
                    />
                    <span>Create Cart</span>
                  </div>
                </Button>
              </div>
            )
          )}
        </div>
      </div>
    </main>
  );
};

export default CreateShoppingCart;
