import { gql } from "@apollo/client";

const GET_THIRD_PARTY_CONTRACTORS = gql`
  query ThirdPartyContractors($regions: [Int]) {
    thirdPartyContractors(regions: $regions) {
      email
      id
      name
      regionId
    }
  }
`;

const CREATE_THIRD_PARTY_CONTRACTOR = gql`
  mutation ThirdPartyContractorCreate(
    $thirdPartyContractorArgs: CreateThirdPartyContractor
  ) {
    thirdPartyContractorCreate(
      thirdPartyContractorArgs: $thirdPartyContractorArgs
    ) {
      code
      message
      success
    }
  }
`;

const UPDATE_THIRD_PARTY_CONTRACTOR = gql`
  mutation ThirdPartyContractorUpdate(
    $thirdPartyContractorArgs: UpdateThirdPartyContractor
  ) {
    thirdPartyContractorUpdate(
      thirdPartyContractorArgs: $thirdPartyContractorArgs
    ) {
      code
      message
      success
    }
  }
`;

const GET_THIRD_PARTY_CONTRACTORS_SHIFTS = gql`
  query ThirdPartyContractorShifts(
    $thirdPartyContractorFilters: ThirdPartyContractorFilters
  ) {
    thirdPartyContractorShifts(
      thirdPartyContractorFilters: $thirdPartyContractorFilters
    )
  }
`;
export {
  GET_THIRD_PARTY_CONTRACTORS,
  CREATE_THIRD_PARTY_CONTRACTOR,
  UPDATE_THIRD_PARTY_CONTRACTOR,
  GET_THIRD_PARTY_CONTRACTORS_SHIFTS,
};
