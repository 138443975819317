import React, { useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_PAYROLL_BATCH_BY_ID } from "../../../graphql/queries/payroll";
import { useParams } from "react-router-dom";
import { PayrollBatch as IPayrollBatch, Shift } from "@/src/types/types";
import { useSortBy, useTable, useRowSelect } from "react-table";
import { CheckboxReactTable } from "../../UI";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import classes from "./ShiftsFinance.module.css";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useSelector } from "react-redux";
import { RootState } from "@/src/app/store";
import BaseTable from "../../UI/Table/Table";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";
import { DataTable } from "../../UI/Table/DataTable";
import Button from "../../UI/Button/Button";
import { CSVLink } from "react-csv";
import Papa from "papaparse";

const PayrollBatch = () => {
  const params = useParams();
  const payrollBatchId = params.id;
  const [payrollBatchData, setPayrollBatchData] = useState<IPayrollBatch>();
  const { isLoading }: any = useSelector((state: RootState) => state.finance);

  const [getPayrollBatch, { data: payrollData, loading, error }] = useLazyQuery(
    GET_PAYROLL_BATCH_BY_ID,
    {
      variables: {
        payrollBatchId,
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    getPayrollBatch().then((data) => {
      setPayrollBatchData(data.data.payrollBatch);
    });
  }, []);

  const columnShifts: ColumnDef<Shift>[] = [
    {
      accessorKey: "id",
      header: "ID",
    },
    {
      accessorFn: (row) => {
        return `${dayjs.utc(row.startLocal).format("YYYY-MM-DD")}`;
      },
      header: "Date",
    },
    // day
    {
      header: "Day",
      accessorFn: (row) => {
        return `${dayjs.utc(row.startLocal).format("dddd")}`;
      },
    },
    {
      accessorFn: (row) => {
        return `${dayjs.utc(row.startLocal).format("h:mma")} - ${dayjs
          .utc(row.endLocal)
          .format("h:mma")}`;
      },
      header: "Time",
    },
    // Region
    {
      header: "Region",
      accessorFn: (row) => {
        return `${row.region?.name}`;
      },
    },
    {
      header: "Location",
      accessorFn: (row) => {
        return `${row.venue?.name}`;
      },
    },
    // {f and l name}
    {
      header: "Staff Name",
      accessorFn: (row) => {
        return `${row.user?.firstName} ${row.user?.lastName}`;
      },
    },
    // staff type
    {
      header: "Staff Type",
      accessorFn: (row) => {
        return `${row.staffType?.name}`;
      },
    },
    // shiftStatus
    {
      header: "Shift Status",
      accessorFn: (row) => {
        return `${row.shiftStatus?.value}`;
      },
    },
    // shiftReportStatus
    {
      header: "Shift Report Status",
      accessorFn: (row) => {
        return `${
          row.shiftReport?.shiftReportStatusId == 2
            ? "Reported"
            : "Not Reported"
        }`;
      },
    },
    // Pay rate
    {
      header: "Rate",
      accessorFn: (row) => {
        return `${row.rate}`;
      },
    },
    // hours
    {
      header: "Hours",
      accessorFn: (row) => {
        return `${row.hours}`;
      },
    },
    // amount
    {
      header: "Amount",
      accessorFn: (row) => {
        return `${row.amount}`;
      },
    },
    // Payroll Status
    {
      header: "Payroll Status",
      accessorFn: (row) => {
        return `${
          row.payrollBatch?.payrollBatchStatus?.name
            ? row.payrollBatch?.payrollBatchStatus?.name
            : "Not Paid"
        }`;
      },
    },
  ];

  const importTocsv = () => {
    if (!payrollBatchData) return;

    const headers = [
      { label: "ID", key: "ID" },
      { label: "Date", key: "Date" },
      { label: "Day", key: "Day" },
      { label: "Time", key: "Time" },
      { label: "Region", key: "Region" },
      { label: "Location", key: "Location" },
      { label: "Staff Name", key: "Staff Name" },
      { label: "Staff Type", key: "Staff Type" },
      { label: "Shift Status", key: "Shift Status" },
      { label: "Shift Report Status", key: "Shift Report Status" },
      { label: "Rate", key: "Rate" },
      { label: "Hours", key: "Hours" },
      { label: "Amount", key: "Amount" },
      { label: "Payroll Status", key: "Payroll Status" },
    ];

    const csvData = payrollBatchData?.shifts.map((shift) => {
      return {
        ID: shift.id,
        Date: dayjs.utc(shift.startLocal).format("YYYY-MM-DD"),
        Day: dayjs.utc(shift.startLocal).format("dddd"),
        Time: `${dayjs.utc(shift.startLocal).format("h:mma")} - ${dayjs
          .utc(shift.endLocal)
          .format("h:mma")}`,
        Region: shift.region?.name,
        Location: shift.venue?.name,
        StaffName: `${shift.user?.firstName} ${shift.user?.lastName}`,
        StaffType: shift.staffType?.name,
        ShiftStatus: shift.shiftStatus?.value,
        ShiftReportStatus: `${
          shift.shiftReport?.shiftReportStatusId == 2
            ? "Reported"
            : "Not Reported"
        }`,
        Rate: shift.rate,
        Hours: shift.hours,
        Amount: shift.amount,
        PayrollStatus: `${
          shift.payrollBatch?.payrollBatchStatus?.name
            ? shift.payrollBatch?.payrollBatchStatus?.name
            : "Not Paid"
        }`,
        UserId: shift.user?.id,
        VenueId: shift.venue?.id,
      };
    });

    const csv = Papa.unparse(csvData);
    // Create a blob from the CSV data
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a link element, trigger a click to download the CSV, and remove the link element
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "table_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <main>
      <div className="flex flex-row justify-between w-full mt-6">
        <Headline1Variable>Payroll Batch</Headline1Variable>
        <div className="h-10">
          <Button
            variant="primary"
            onClick={importTocsv}
          >
            Export to .csv
          </Button>
        </div>
      </div>
      {payrollBatchData?.shifts && (
        <DataTable
          data={payrollBatchData.shifts}
          columns={columnShifts}
        />
      )}
    </main>
  );
};

export default PayrollBatch;
