import { gql } from "@apollo/client";

const GET_CONTRACT_ATTACHMENTS_BY_CONTRACT_ID = gql`
  query GetContractAttachmentsByContractId($id: Int!) {
    getContractAttachmentsByContractId(id: $id) {
      id
      originalFileName
      gcpFileName
      publicUrl
    }
  }
`;
const CREATE_CONTRACT_ATTACHMENT = gql`
  mutation CreateContractAttachment(
    $createContractAttachmentInput: CreateContractAttachmentInput!
  ) {
    createContractAttachment(
      createContractAttachmentInput: $createContractAttachmentInput
    ) {
      code
      message
      success
    }
  }
`;
const DELETE_CONTRACT_ATTACHMENT = gql`
  mutation DeleteContractAttachment($id: Int!) {
    deleteContractAttachment(id: $id) {
      code
      message
      success
    }
  }
`;
export {
  GET_CONTRACT_ATTACHMENTS_BY_CONTRACT_ID,
  CREATE_CONTRACT_ATTACHMENT,
  DELETE_CONTRACT_ATTACHMENT,
};
