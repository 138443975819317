import React, { useState } from "react";
import { FormFieldSelectMulti } from "../UI/FormField/FormFieldSelectMulti/FormFieldSelectMulti";
import Button from "./Button/Button";
import FilterChip from "./Input/FilterChip";

type FilterCategory =
  | "regionIds"
  | "sportIds"
  | "regBatchIds"
  | "dayIds"
  | "genderIds"
  | "regTypeIds"
  | "playerRoleIds"
  | "leagueIds"
  | "sportFormatIds";

interface FilterProps {
  selectedItems: { id: number; name: string }[];
  filterCategory: FilterCategory;
  label: string;
  placeholder: string;
  filter: any;
  setFilter: (filter: any) => void;
}

const EmailMarketingFilterSelect: React.FC<FilterProps> = ({
  selectedItems,
  filterCategory,
  label,
  placeholder,
  filter,
  setFilter,
}) => {
  const setSelectedItems = (values: number[]) => {
    setFilter((prevState: any) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        [filterCategory]: values,
      },
    }));
  };

  const clearSelectedItems = () => {
    setFilter((prevState: any) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        [filterCategory]: [],
      },
    }));
  };

  const removeFilterItem = (id: number) => {
    setFilter((prevState: any) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        [filterCategory]: prevState.filter[filterCategory].filter(
          (itemId: number) => itemId !== id
        ),
      },
    }));
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row items-end gap-4">
        <div className="max-w-80 w-80">
          <FormFieldSelectMulti
            name={`${filterCategory}Select`}
            input={[
              ...selectedItems.map((item) => ({
                id: +item.id,
                name: item.name,
              })),
            ]}
            addAllOption={true}
            selectedItems={filter[filterCategory]}
            setSelectedItems={setSelectedItems}
            label={label}
            placeholder={placeholder}
          />
        </div>
        {filter[filterCategory].length > 0 && (
          <Button
            variant="negative"
            onClick={clearSelectedItems}
          >
            Clear
          </Button>
        )}
      </div>
      <div className="flex flex-row flex-wrap items-center justify-start w-full gap-2">
        {filter[filterCategory]
          .sort((a: number, b: number) => a - b)
          .map((id: number) => {
            const item = selectedItems.find((item) => +item.id === +id);
            return (
              <FilterChip
                key={id}
                text={item ? item.name : "Oops"}
                onDelete={() => removeFilterItem(id)}
              />
            );
          })}
      </div>
    </div>
  );
};

export default EmailMarketingFilterSelect;
