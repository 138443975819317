"use client";

import { FC, Fragment } from "react";
import { Pagination } from "@mui/material";

interface PaginationProps {
  /**
   * Current Page
   */
  page: number;
  /**
   * Page Count
   */
  pageCount: number;
  /**
   * onChange event
   */
  onChange: (page: number) => void;
}

const TablePagination: FC<PaginationProps> = ({
  page,
  pageCount,
  onChange,
  ...props
}: PaginationProps) => {
  return (
    <Fragment>
      <div className="my-auto text-xs text-disabled">
        Showing {page + 1} of {pageCount} pages
      </div>
      <Pagination
        page={page + 1}
        count={pageCount || 1}
        onChange={(event: any, value: number) => onChange(value)}
      />
    </Fragment>
  );
};

export default TablePagination;
