import { createSlice } from "@reduxjs/toolkit";
import { toast as toastStackable } from "sonner";
import { RootState } from "./store";

const initialState = {};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    displayAlertError: (state, action) => {
      toastStackable.error(action.payload);
    },
    displayAlertSuccess: (state, action) => {
      toastStackable.success(action.payload);
    },
    displayAlertWarning: (state, action) => {
      toastStackable.warning(action.payload);
    },
    displayAlertInfo: (state, action) => {
      toastStackable.info(action.payload);
    },
  },
});

export const {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
  displayAlertInfo,
} = globalSlice.actions;

export const globalSelector = (state: RootState) => state.global;

export default globalSlice.reducer;
