import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { client } from "../graphql";
import {
  GET_REFUND_REASONS,
  GET_REFUND_STATUSES,
  GET_REFUND_TYPES,
} from "../graphql/queries/refund";
import { PayrollBatch } from "../types/types";
import { GET_PAYROLL_BATCHES } from "../graphql/queries/payroll";

interface FinanceInitialState {
  isLoading: boolean;
  refundReasons: { id: number; name: string }[];
  refundStatuses: { id: number; name: string }[];
  refundTypes: { id: number; name: string }[];
  payrollBatches: PayrollBatch[];
  payrollBatchesCount: number;
}

const initialState: FinanceInitialState = {
  isLoading: false,
  refundReasons: [],
  refundStatuses: [],
  refundTypes: [],
  payrollBatches: [],
  payrollBatchesCount: 0,
};

export const getRefundReasons = createAsyncThunk(
  "finance/getRefundReasons",
  async (_, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_REFUND_REASONS,
        fetchPolicy: "cache-first",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      return response.data.getRefundReasons;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getRefundStatuses = createAsyncThunk(
  "finance/getRefundStatuses",
  async (_, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_REFUND_STATUSES,
        fetchPolicy: "cache-first",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      return response.data.getRefundStatuses;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getRefundTypes = createAsyncThunk(
  "finance/getRefundTypes",
  async (_, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_REFUND_TYPES,
        fetchPolicy: "cache-first",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      return response.data.getRefundTypes;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getPayrollBatches = createAsyncThunk(
  "finance/getPayrollBatches",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_PAYROLL_BATCHES,
        variables: {
          page: args.page,
          pageSize: args.pageSize,
        },
        fetchPolicy: "network-only",
      });

      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      return response.data.payrollBatches;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const financeSlice = createSlice({
  name: "finance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRefundReasons.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getRefundReasons.fulfilled, (state, action) => {
        state.isLoading = false;
        state.refundReasons = action.payload;
      })
      .addCase(getRefundReasons.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getRefundStatuses.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getRefundStatuses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.refundStatuses = action.payload;
      })
      .addCase(getRefundStatuses.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getRefundTypes.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getRefundTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.refundTypes = action.payload;
      })
      .addCase(getRefundTypes.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getPayrollBatches.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPayrollBatches.fulfilled, (state, action) => {
        state.isLoading = false;
        state.payrollBatches = action.payload.payrollBatches;
        state.payrollBatchesCount = action.payload.count;
      })
      .addCase(getPayrollBatches.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const financeSelector = (state: RootState) => state.finance;

export default financeSlice.reducer;
