import {
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { matchupValueToName } from "../Admin/Schedule/GameSchedule";
import dayjs from "dayjs";

const ScheduleBackup = ({ ...props }) => {
  const rows = props.rows;
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 650, margin: "10px 0px" }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell>GameID</TableCell>
            <TableCell align="right">Start Time Date</TableCell>
            <TableCell align="right">Venue</TableCell>
            <TableCell align="right">Home Team</TableCell>
            <TableCell align="right">Away Team</TableCell>
            <TableCell align="right">Player Visibility</TableCell>
            <TableCell align="right">Game Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.map((row: any) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="right">{row.id}</TableCell>
                <TableCell align="right">
                  {dayjs(row.startDateTimeLocal).format("MMMM DD, YYYY h:mm a")}
                </TableCell>
                <TableCell align="right">{row.venue.name}</TableCell>
                <TableCell align="right">
                  {row.gameType.id == 2
                    ? row.homeTeam === null
                      ? matchupValueToName(
                          row.gamePlayoffMatchup.homeTeamValue,
                          row.gamePlayoffMatchup.homeTeamType
                        )
                      : row.homeTeam.name
                    : row.homeTeam.name}
                </TableCell>
                <TableCell align="right">
                  {" "}
                  {row.gameType.id == 2
                    ? row.awayTeam === null
                      ? matchupValueToName(
                          row.gamePlayoffMatchup.awayTeamValue,
                          row.gamePlayoffMatchup.awayTeamType
                        )
                      : row.awayTeam.name
                    : row.awayTeam.name}
                </TableCell>
                <TableCell align="right">
                  {row.isVisible ? "Visible" : "Hidden"}
                </TableCell>
                <TableCell align="right">
                  {row.gameType?.gameTypeName}
                </TableCell>
              </TableRow>
            ))}
          {rows && rows.length === 0 && (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="right">No Data Found</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default ScheduleBackup;
