"use client";

import React, { FC, HTMLProps, useEffect, useState } from "react";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";
import { cn } from "../../../../lib/utils";

interface FormFieldViewOnlyProps extends HTMLProps<HTMLInputElement> {
  /**
   * The label for the formfieldViewOnly (text above the field)
   */
  label?: string;
  /**
   * The assitive text for the field (text under the field)
   */
  assistiveText?: string;
  /**
   * Set to true if the input should be disabled.
   */
  disabled?: boolean;
  /**
   * Set to true if the input should be showing an error state
   */
  error?: boolean;
  /**
   * Custom classname attribtues
   */
  className?: string;
  /**
   * Custom classname for the container
   */
  containerClassName?: string;
  /**
   * The text to display
   */
  text: string;
}

const FormFieldViewOnly: FC<FormFieldViewOnlyProps> = ({
  placeholder,
  label,
  assistiveText,
  disabled,
  error,
  className,
  containerClassName,
  text,
  ...props
}: FormFieldViewOnlyProps) => {
  return (
    <div className={cn(`flex flex-col w-full text-black`, containerClassName)}>
      <label
        className={`px-3 text-xs font-medium ${
          error && !disabled && "text-error-10"
        }`}
      >
        {label}
      </label>
      <div className="relative flex flex-row items-center w-full gap-2">
        <p
          className={cn(
            "w-full font-normal rounded-xl px-3 py-2",
            disabled
              ? "border focus:outline-none transition-colors focus-visible:outline-none pointer-events-none opacity-50 border-secondary-80"
              : error
              ? "border border-error-30"
              : "border border-secondary-80",
            className
          )}
        >
          {text}
        </p>
        {/* The error icon will appear to the right of the input if it's in an error state */}
        {error && (
          <ErrorOutlineOutlinedIcon className="absolute right-2 text-error-30" />
        )}
      </div>
      <div
        className={`px-3 text-xxs font-normal ${
          error && !disabled ? "text-error-10" : "text-light"
        }`}
      >
        {assistiveText}
      </div>
    </div>
  );
};

export default FormFieldViewOnly;
