import React from "react";
import Headline2Variable from "../../UI/Text/Headline/Headline2Variable";
import { useDivisionByScheduleIdQuery } from "../../../generated/graphql";
import Button from "../../UI/Button/Button";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertInfo,
  displayAlertWarning,
} from "../../../app/globalSlice";

const ScheduleTest: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <main>
      <Headline2Variable>Schedule Test</Headline2Variable>
      <Button
        variant="primary"
        onClick={() => {
          dispatch(displayAlertSuccess("Success"));
          dispatch(displayAlertError("Error"));
          dispatch(displayAlertInfo("Info"));
          dispatch(displayAlertWarning("Warning"));
        }}
      >
        alert
      </Button>
    </main>
  );
};

export default ScheduleTest;
