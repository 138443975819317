import { gql } from "@apollo/client";

const GET_COUNTRIES = gql`
  query Countries {
    countries {
      id
      name
    }
  }
`;

const GET_STATES = gql`
  query States {
    states {
      id
      name
      countryId
      stateCode
    }
  }
`;

const GET_COUNTRY_BY_ID = gql`
  query Country($countryId: ID!) {
    country(id: $countryId) {
      id
      name
    }
  }
`;

const GET_STATE_BY_ID = gql`
  query State($stateId: ID!) {
    state(id: $stateId) {
      id
      name
      countryId
      stateCode
    }
  }
`;

const GET_STATES_BY_COUNTRY_ID = gql`
  query StatesByCountry($countryId: ID) {
    statesByCountryId(countryId: $countryId) {
      id
      name
      countryId
      stateCode
    }
  }
`;

export {
  GET_COUNTRIES,
  GET_STATES,
  GET_COUNTRY_BY_ID,
  GET_STATE_BY_ID,
  GET_STATES_BY_COUNTRY_ID,
};
