import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FormFieldSelect } from "../FormField/FormFieldDropdown/FormFieldSelectV2";
import Button from "../Button/Button";
import Headline2Variable from "../Text/Headline/Headline2Variable";
import Subtitle1 from "../Text/Subtitle/Subtitle1";
import Disclaimer from "../Alerts/Disclaimer";

interface ReplaceTeamsDialogProps {
  teams: any[];
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onReplaceTeams: (teamA: number, teamB: number) => void;
  type: "Swap" | "Replace";
}

/**
 * A dialog that allows the user to swap two teams
 * @param teams Array of teams
 * @param open Controls whether the dialog is open or not
 * @param onOpenChange Function to change the open state
 * @param onReplaceTeams Function to replace the teams
 * @param type Type of dialog
 * @returns
 */
const ReplaceTeamsDialog = ({
  teams, // Array of teams
  open,
  onReplaceTeams,
  onOpenChange,
  type,
}: ReplaceTeamsDialogProps) => {
  const [teamA, setTeamA] = useState("");
  const [teamB, setTeamB] = useState("");
  const [error, setError] = useState("");

  const handleSwapTeams = () => {
    if (teamA === teamB) return setError("Teams cannot be the same.");
    onReplaceTeams(+teamA, +teamB);
    setError("");
    onOpenChange(false);
  };

  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      sx={{
        zIndex: 30,
      }}
    >
      <div className="flex flex-row md:justify-between">
        <DialogTitle
          sx={{
            paddingBottom: "0px",
          }}
        >
          <Headline2Variable>{type} Teams</Headline2Variable>
        </DialogTitle>
      </div>
      <DialogContent>
        {type === "Swap" && (
          <Subtitle1 className="mb-2">
            Swap the selected Team A with Team B.
          </Subtitle1>
        )}
        {error && (
          <Disclaimer
            content={error}
            variant="error"
            persist={true}
            title="Error"
            size="large"
          />
        )}
        <div className="flex gap-5 mb-3">
          <FormFieldSelect
            className="w-[250px]"
            placeholder="Select Team"
            label={type === "Swap" ? "Team A" : "Team to Replace"}
            value={teamA}
            inputChange={(value) => setTeamA(value)}
          >
            {teams}
          </FormFieldSelect>
          <FormFieldSelect
            className="w-[250px]"
            placeholder="Select Team"
            label={type === "Swap" ? "Team B" : "Team to Add"}
            value={teamB}
            inputChange={(value) => setTeamB(value)}
          >
            {teams}
          </FormFieldSelect>
        </div>
        <DialogActions>
          <Button
            variant="secondary"
            onClick={() => onOpenChange(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSwapTeams}
          >
            {type} Teams
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ReplaceTeamsDialog;
