import { gql } from "@apollo/client";

const GET_ALL_GENDER_IDENTITY = gql`
  query GenderIdentity {
    genderIdentity {
      id
      name
    }
  }
`;

export { GET_ALL_GENDER_IDENTITY };
