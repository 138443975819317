import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import BreadCrumbs from "../../UI/Breadcrumbs/Breadcrumbs";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import Button from "../../UI/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../../UI/Card/Card";
import FormField from "../../UI/FormField/FormField";
import FormFieldDateSelect from "../../UI/FormField/FormFieldDate/FormFieldDateSelect";
import moment from "moment";
import FormFieldDropdown from "../../UI/FormField/FormFieldDropdown/FormFieldDropdown";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_PAYROLL_BATCH,
  GET_PAYROLL_BATCH_DETAILS_BY_ID,
  UPDATE_PAYROLL_BATCH,
} from "../../../graphql/queries/payroll";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import { getPayrollBatches } from "../../../app/financeSlice";
import {
  displayAlertError,
  displayAlertSuccess,
} from "../../../app/globalSlice";

const monthArray: any = [
  { id: 0, name: "Select a month" },
  { id: 1, name: "January" },
  { id: 2, name: "February" },
  { id: 3, name: "March" },
  { id: 4, name: "April" },
  { id: 5, name: "May" },
  { id: 6, name: "June" },
  { id: 7, name: "July" },
  { id: 8, name: "August" },
  { id: 9, name: "September" },
  { id: 10, name: "October" },
  { id: 11, name: "November" },
  { id: 12, name: "December" },
];

const initialPayrollBatch = {
  name: "",
  year: 0,
  month: 0,
  shiftCutoffDate: "",
};

const CreatePayrollBatches = () => {
  const { isLoading }: any = useSelector((state: RootState) => state.shift);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const rootPath =
    process.env.NODE_ENV === "production" ? process.env.REACT_APP_URL : "";
  const params = useParams();
  const isEditBatch = params.id;
  const pathsBreadcrumbs = [
    { name: "Payroll Batches", url: "/finance/payrollBatches" },
  ];
  const [payrollBatch, setPayrollBatch]: any =
    React.useState(initialPayrollBatch);

  const [createPayrollBatch, { loading: loadingCreatePayrollBatch }] =
    useMutation(CREATE_PAYROLL_BATCH, {
      onCompleted: (data) => {
        if (data) {
          console.log(data);
        }
      },
    });
  const [updatePayrollBatch, { loading: loadingUpdatePayrollBatch }] =
    useMutation(UPDATE_PAYROLL_BATCH, {
      onCompleted: (data) => {
        if (data) {
          console.log(data);
        }
      },
    });

  const [getPayrollBatch, { data: payrollData, loading, error }] = useLazyQuery(
    GET_PAYROLL_BATCH_DETAILS_BY_ID,
    {
      variables: {
        payrollBatchId: isEditBatch,
      },
      fetchPolicy: "network-only",
    }
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPayrollBatch({ ...payrollBatch, [name]: value });
  };

  const handleSubmitForm = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (isEditBatch) {
      const res = await updatePayrollBatch({
        variables: {
          payrollBatchUpdateId: isEditBatch,
          payrollBatchArgs: {
            name: payrollBatch.name,
            year: payrollBatch.year,
            month: payrollBatch.month,
            shiftCutoffDate: payrollBatch.shiftCutoffDate,
          },
        },
      });

      if (res) {
        if (res.data.payrollBatchUpdate.success) {
          dispatch(displayAlertSuccess("Payroll Batch updated successfully"));
        } else {
          dispatch(displayAlertError("Something went wrong"));
        }
        dispatch(
          getPayrollBatches({
            page: null,
            pageSize: null,
          })
        );
        navigate(`${rootPath}/finance/payrollBatches/`);
      }
    } else {
      const res = await createPayrollBatch({
        variables: {
          payrollBatchArgs: {
            name: payrollBatch.name,
            year: payrollBatch.year,
            month: payrollBatch.month,
            shiftCutoffDate: payrollBatch.shiftCutoffDate,
          },
        },
      });
      if (res) {
        if (res.data.payrollBatchCreate.success) {
          dispatch(displayAlertSuccess("Payroll Batch created successfully"));
        } else {
          dispatch(displayAlertError("Something went wrong"));
        }
        dispatch(
          getPayrollBatches({
            page: null,
            pageSize: null,
          })
        );
        navigate(`${rootPath}/finance/payrollBatches/`);
      }
    }
  };

  useEffect(() => {
    if (isEditBatch) {
      getPayrollBatch().then((data) => {
        if (data.data.payrollBatch) {
          setPayrollBatch(data.data.payrollBatch);
        } else {
          navigate(`${rootPath}/finance/payrollBatches/`);
          dispatch(displayAlertError("No data found"));
        }
      });
    }
  }, []);

  if (isLoading || loadingCreatePayrollBatch || loadingUpdatePayrollBatch)
    return (
      <LoadingDialog
        open={
          isLoading || loadingCreatePayrollBatch || loadingUpdatePayrollBatch
        }
      />
    );

  return (
    <main>
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/finance/payrollBatches"
      ></BreadCrumbs>
      <div className="flex flex-row justify-between w-full mt-6">
        <Headline1Variable>
          {isEditBatch ? "Edit Payroll Batch" : "Create New Payroll Batch"}
        </Headline1Variable>
        <div className="h-10">
          <Button
            variant="primary"
            onClick={(e) => handleSubmitForm(e)}
          >
            {isEditBatch ? (
              <span> Edit Payroll Batch</span>
            ) : (
              <span> Create Payroll Batch</span>
            )}
          </Button>
        </div>
      </div>
      <form autoComplete="off">
        <Card className="mt-6">
          <div className="flex flex-col">
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <FormField
                type="name"
                name="name"
                value={payrollBatch.name}
                label="Name"
                inputChange={handleChange}
                initialValue={payrollBatch.name ? payrollBatch.name : ""}
              />
            </div>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <FormFieldDropdown
                inputChange={(value: string) => {
                  setPayrollBatch((prevState: any) => ({
                    ...prevState,
                    year: value === "0" ? null : parseInt(value),
                  }));
                }}
                name="year"
                placeholder="Year"
                label="Year"
                initialValue={
                  payrollBatch.year.toString()
                    ? payrollBatch.year.toString()
                    : "0"
                }
              >
                {Array.from(
                  { length: 3 },
                  (_, i) => new Date().getFullYear() + 1 - i
                ).map((year) => {
                  return { id: year.toString(), name: year.toString() };
                })}
              </FormFieldDropdown>
              <FormFieldDropdown
                initialValue={
                  payrollBatch.month ? payrollBatch.month.toString() : "0"
                }
                name="month"
                placeholder="All"
                inputChange={(value: string) => {
                  setPayrollBatch((prevState: any) => ({
                    ...prevState,
                    month: value === "0" ? null : parseInt(value),
                  }));
                }}
                label="Month"
              >
                {monthArray}
              </FormFieldDropdown>
            </div>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <FormFieldDateSelect
                initialValue={
                  payrollBatch.shiftCutoffDate
                    ? moment(payrollBatch.shiftCutoffDate).format("YYYY-MM-DD")
                    : null
                }
                inputChange={(e) => {
                  setPayrollBatch({
                    ...payrollBatch,
                    shiftCutoffDate: moment(e.target.value).format(
                      "YYYY-MM-DD"
                    ),
                  });
                }}
                name="shiftCutoffDate"
                label="Shift Cutoff Date"
              />
            </div>
          </div>
        </Card>
      </form>
    </main>
  );
};

export default CreatePayrollBatches;
