import { Button as MuiButton } from "@mui/material";
import Button from "../UI/Button/Button";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import classes from "./ContractManagement.module.css";
import { Column } from "react-table";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
  getGenderFormat,
  getLeagues,
  getSports,
} from "../../app/venueMasterSlice";
import { LeagueFilter } from "../../app/venueMasterSlice";
import BaseTable from "../UI/Table/Table";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";

const Leagues: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { leagues, isLoading, selectedRegions, genderFormat, sports }: any =
    useSelector((state: RootState) => state.venueMaster);

  const [leagueFilter, setLeagueFilter] = React.useState<LeagueFilter>({
    regionId: 0,
    sportId: 0,
    genderFormatId: 0,
  });
  const [leagueFiltered, setLeagueFiltered] = React.useState<any[]>([]);

  const rootPath =
    process.env.NODE_ENV === "production" ? process.env.REACT_APP_URL : "";
  const redirectCreateLeague = () => {
    navigate(`${rootPath}/ops/league/`);
  };
  const redirectEditLeagues = (LeaguesId: number) => {
    navigate(`${rootPath}/ops/league/${LeaguesId}`, {
      state: { LeaguesId },
    });
  };

  useEffect(() => {
    if (selectedRegions.length) {
      dispatch(getLeagues(""));
      dispatch(getGenderFormat(""));
      dispatch(getSports(""));
    }
  }, [selectedRegions]);

  useEffect(() => {
    const filteredLeagues = leagues.filter((league: any) => {
      let show = true;
      if (
        leagueFilter.regionId &&
        league.sportFormat?.region?.id != leagueFilter.regionId
      ) {
        // console.log('regionId not match');
        show = false;
      }
      if (
        show &&
        leagueFilter.sportId &&
        league.sportFormat?.sport.id != leagueFilter.sportId
      ) {
        show = false;
      }
      if (
        show &&
        leagueFilter.genderFormatId &&
        league.sportFormat?.genderFormat?.id != leagueFilter.genderFormatId
      ) {
        show = false;
      }
      return show;
    });
    setLeagueFiltered(filteredLeagues);
  }, [leagueFilter]);

  useEffect(() => {
    setLeagueFiltered(leagues);
  }, [leagues]);

  const leagueData = useMemo(() => {
    if (leagueFiltered) {
      return leagueFiltered;
    } else {
      return [];
    }
  }, [leagueFiltered]);
  const leagueColumn: Column<any>[] = [
    {
      Header: "Region",
      accessor: "sportFormat.region.name",
    },
    {
      Header: "Name",
      id: "name",
      accessor: (d: any) => {
        return (
          <a
            className="cursor-pointer"
            onClick={() => redirectEditLeagues(d.id)}
          >
            {d.name}
          </a>
        );
      },
    },
    {
      Header: "Sport Format",
      accessor: "sportFormat.name",
    },
    {
      Header: "Division Template",
      accessor: "divisionName",
    },
    {
      Header: "Game Duration",
      accessor: "gameDuration",
    },
    {
      Header: "Game Timeslot",
      accessor: "gameTimeslot",
    },
    {
      Header: "Games Per Week",
      accessor: "gamesPerOccurrence",
    },
    {
      Header: "Options",
      accessor: (d: any) => {
        return (
          <div className={classes.format__options}>
            <div className={classes.format__option}>
              <MuiButton
                sx={{ color: "black" }}
                onClick={() => redirectEditLeagues(d.id)}
              >
                {<EditIcon />}{" "}
              </MuiButton>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <main className="flex flex-col gap-4">
      <Headline1Variable>Leagues</Headline1Variable>
      <div className="flex flex-row items-end gap-4">
        <div className="w-64">
          <FormFieldSelect
            defaultValue="0"
            inputChange={(value) => {
              setLeagueFilter((prevState) => {
                return {
                  ...prevState,
                  sportId: parseInt(value),
                };
              });
            }}
            name="sportId"
            label="Sport"
            placeholder="Sport"
            value={leagueFilter.sportId.toString()}
          >
            {[
              { id: 0, name: "All Sports" },
              ...sports.map((sport: any) => {
                return { id: sport.id, name: sport.name };
              }),
            ]}
          </FormFieldSelect>
        </div>
        <div className="w-64">
          <FormFieldSelect
            defaultValue="0"
            inputChange={(value) => {
              setLeagueFilter((prevState) => {
                return {
                  ...prevState,
                  regionId: parseInt(value),
                };
              });
            }}
            name="regionId"
            label="Region"
            placeholder="Region"
            value={leagueFilter.regionId.toString()}
          >
            {[
              { id: 0, name: "All Regions" },
              ...selectedRegions.map((region: any) => {
                return { id: region.id, name: region.name };
              }),
            ]}
          </FormFieldSelect>
        </div>
        <div className="w-64">
          <FormFieldSelect
            inputChange={(value) => {
              setLeagueFilter((prevState) => {
                return {
                  ...prevState,
                  genderFormatId: parseInt(value),
                };
              });
            }}
            name="genderFormatId"
            label="Gender Format"
            placeholder="Gender Format"
            value={leagueFilter.genderFormatId.toString()}
            defaultValue="0"
          >
            {[
              { id: 0, name: "All Gender Formats" },
              ...genderFormat.map((genderFormat: any) => {
                return { id: genderFormat.id, name: genderFormat.name };
              }),
            ]}
          </FormFieldSelect>
        </div>

        <div>
          <Button
            variant="secondary"
            onClick={() => {
              setLeagueFilter({
                regionId: 0,
                sportId: 0,
                genderFormatId: 0,
              });
            }}
          >
            Reset
          </Button>
        </div>
        <div>
          <Button
            variant="primary"
            onClick={redirectCreateLeague}
          >
            <AddIcon />
            New League
          </Button>
        </div>
      </div>
      <div>
        {
          <BaseTable
            data={leagueData}
            columns={leagueColumn}
          />
        }
      </div>
    </main>
  );
};

export default Leagues;
