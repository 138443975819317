import { gql } from '@apollo/client';

const CREATE_SHIFT_REPORT = gql`
  mutation ShiftReportCreate($shiftReportArgs: ShiftReportCreateInput) {
    shiftReportCreate(shiftReportArgs: $shiftReportArgs) {
      code
      message
      shiftReport {
        id
      }
      success
    }
  }
`;

export { CREATE_SHIFT_REPORT };
