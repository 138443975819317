import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOpenShifts,
  createRequestedShifts,
  getAllLocations,
  handleOpenShiftFilters,
  getStaffType,
} from "../../../app/shiftSlice";
import { FormRow, FormRowSelect, LoadingMaterialUI } from "../../UI";
import classes from "./OpenShifts.module.css";
import moment from "moment";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Modal,
  Typography,
} from "@mui/material";
import { useTable, useSortBy } from "react-table";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { AppDispatch, RootState } from "../../../app/store";
import dayjs from "dayjs";

const OpenShifts = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    openShifts,
    isLoading,
    openShiftFilters,
    locationsOptions,
    staffTypeOptions,
  } = useSelector((state: RootState) => state.shift);

  const { user } = useSelector((state: RootState) => state.auth);

  const [appliedShifts, setAppliedShifts] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState([]);

  const handleViewNotes = (shiftNotes: any) => {
    setModalContent(shiftNotes);
    setOpen(true);
  };

  const handleApplyShift = (shiftId: number) => {
    dispatch(createRequestedShifts({ shiftId: +shiftId })).then(() => {
      // the value of openShiftFilters doesnt get updated here, when we first enter the link
      dispatch(getOpenShifts(openShiftFilters));
    });
  };

  useEffect(() => {
    if (!locationsOptions.length) {
      dispatch(getAllLocations());
    }
    if (!staffTypeOptions.length) {
      dispatch(getStaffType());
    }
  }, [dispatch, locationsOptions, staffTypeOptions]);

  useEffect(() => {
    dispatch(getOpenShifts(openShiftFilters));
  }, [dispatch, user, openShiftFilters]);

  const addAppliedShifts = (shiftId: number) => {
    setAppliedShifts((prevState): any => [...prevState, shiftId]);
  };

  const filterAppliedShifts = (shiftId: string) => {
    if (appliedShifts.includes(shiftId)) {
      return true;
    }
    return false;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      handleOpenShiftFilters({
        ...openShiftFilters,
        [e.target.name]: +e.target.value || e.target.value,
      })
    );
  };

  // const displayedShifts = (
  //   <div className={classes.shift__container}>
  //     <table className={classes.shift__table}>
  //       <thead>
  //         <tr>
  //           <th>ShiftID#</th>
  //           <th>Start</th>
  //           <th>End</th>
  //           <th>Location/Site</th>
  //           <th>Actions</th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {openShifts.map((shift) => (
  //           <tr key={shift.id}>
  //             <td>{shift.id}</td>
  //             <td>{moment(shift.startLocal).format('ddd MMM,DD YYYY, HH:mm')}</td>
  //             <td>{moment(shift.endLocal).format('ddd MMM,DD YYYY, HH:mm')}</td>
  //             <td>{shift.location.name}</td>
  //             <td>
  //               <Button
  //                 type="button"
  //                 className={classes.delete__btn}
  //                 onClick={() => {
  //                   addAppliedShifts(shift.id);
  //                   handleApplyShift(shift.id);
  //                 }}
  //                 disabled={filterAppliedShifts(shift.id)}
  //               >
  //                 Apply
  //               </Button>
  //             </td>
  //           </tr>
  //         ))}
  //       </tbody>
  //     </table>
  //   </div>
  // );
  const COLUMNS = [
    {
      Header: "ShiftID#",
      accessor: "openShift.id",
    },
    {
      Header: "Date",
      id: "openShift.startLocal",
      accessor: (d: any) => {
        return (
          <div className={classes.date__attributes}>
            <div className={classes.date__day}>
              {dayjs(d.openShift.startLocal).format("dddd")}
            </div>
            <div className={classes.date__date}>
              {dayjs(d.openShift.startLocal).format("MMM,DD")}
            </div>
          </div>
        );
      },
    },
    {
      Header: "Shift Type",
      accessor: "openShift.staffType.name",
    },
    {
      Header: "Time & Location",
      id: "location.name",
      accessor: (d: any) => {
        return (
          <div className={classes.timeAndLocation__attribute}>
            <div className={classes.timeAndLocation__time}>
              {moment(d.openShift.startLocal).local().format("LT")} {" - "}
              {moment(d.openShift.endLocal).local().format("LT")}
            </div>

            <div className={classes.timeAndLocation__location}>
              {d.location.name}
            </div>
          </div>
        );
      },
    },
    {
      Header: "Hours",
      accessor: (value: any) => {
        return value.openShift.hours;
      },
    },
    {
      Header: "Action",
      id: "action",
      accessor: (value: any) => {
        return (
          <div>
            <Button
              type="button"
              className={classes.delete__btn}
              onClick={() => {
                addAppliedShifts(value.openShift.id);
                handleApplyShift(value.openShift.id);
              }}
              disabled={filterAppliedShifts(value.openShift.id)}
            >
              Apply
            </Button>
            <Button
              type="button"
              className={classes.delete__btn}
              onClick={() => {
                handleViewNotes(value.openShift.shiftNotes);
              }}
            >
              View Notes
            </Button>
          </div>
        );
      },
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => openShifts, [isLoading]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columns,
        data: data,
        initialState: {
          hiddenColumns: ["openShift.id"],
        },
      },
      useSortBy
    );

  const displayedShiftReactTableMaterialUI = (
    <TableContainer
      className={classes.shift__container}
      sx={{ maxHeight: "80vh", width: "90vw", maxWidth: "90%" }}
    >
      <Table
        stickyHeader
        aria-label="sticky table"
        {...getTableProps()}
        className={classes.shift__table}
      >
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <TableCell
                  sx={{
                    bgcolor: "var(--primary-blue-500)",
                    color: "var(--grey-50)",
                    fontSize: "16px",
                    textAlign: "center",
                    fontWeight: 550,
                  }}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <div className={classes.headAlign}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDownwardIcon />
                        ) : (
                          <ArrowUpwardIcon />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      sx={{ textAlign: "center" }}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const displayedShiftsCardFormat = (
    <div className={classes.card__container}>
      {openShifts.map((shift: any) => (
        <Card
          key={shift.openShift.id}
          sx={{ minWidth: "90vw", marginBottom: "10px" }}
          className={classes.shiftCard}
        >
          <CardContent className={classes.cardContent}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {moment(shift.openShift.startLocal)
                .local()
                .format("dddd, MMM DD")}
            </Typography>
            <Typography variant="body2">
              {moment(shift.openShift.startLocal).local().format("LT")} {" - "}
              {moment(shift.openShift.endLocal).local().format("LT")} {"("}
              {moment(shift.openShift.endLocal).diff(
                moment(shift.openShift.startLocal),
                "hours"
              )}
              {" Hours)"}
            </Typography>
            <Typography variant="body2">
              {shift.openShift.staffType.name}
            </Typography>
            <Typography
              variant="h6"
              component="div"
            >
              {shift.location.Name}
            </Typography>
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Button
              type="button"
              className={classes.delete__btn}
              onClick={() => {
                handleViewNotes(shift.openShift.shiftNotes);
              }}
            >
              View Notes
            </Button>
            <Button
              type="button"
              className={classes.delete__btn}
              onClick={() => {
                addAppliedShifts(shift.openShift.id);
                handleApplyShift(shift.openShift.id);
              }}
              disabled={filterAppliedShifts(shift.openShift.id)}
            >
              Apply
            </Button>
          </CardActions>
        </Card>
      ))}
    </div>
  );

  if (isLoading) {
    return <LoadingMaterialUI />;
  }

  return (
    <main>
      <h3>All Open Shifts</h3>
      <div className={classes.shifts__filters}>
        <div>
          <FormRow
            type="date"
            name="fromDate"
            value={openShiftFilters.fromDate}
            labelText="Start Date"
            handleChange={handleChange}
          />
          <p className={classes.para}>to</p>
          <FormRow
            type="date"
            name="toDate"
            value={openShiftFilters.toDate}
            labelText="End Date"
            handleChange={handleChange}
          />
        </div>
        <div>
          <FormRowSelect
            name="location"
            //value={openShiftFilters.location ? openShiftFilters.location : '0'}
            value={openShiftFilters.location}
            labelText="Location"
            list={[{ id: 0, name: "All Locations" }, ...locationsOptions]}
            handleChange={handleChange}
          />
        </div>
        <div>
          <FormRowSelect
            name="staffType"
            value={openShiftFilters.staffType}
            labelText="Staff Type"
            list={[{ id: 0, name: "All" }, ...staffTypeOptions]}
            handleChange={handleChange}
          />
        </div>
      </div>
      {openShifts?.length !== 0 ? (
        displayedShiftReactTableMaterialUI
      ) : (
        <h4>No such records</h4>
      )}
      {displayedShiftsCardFormat}
      {/* {openShifts?.length !== 0 ? displayedShifts : <h4>No such records</h4>} */}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          {modalContent.length === 0 ? (
            <div>
              <Typography>No Notes</Typography>
            </div>
          ) : (
            <div>
              {modalContent?.map((note: any, index: number) => (
                <div key={note.id}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {`Note: ${index + 1}`}
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mb: 1 }}
                  >
                    {note.note}
                  </Typography>
                </div>
              ))}
            </div>
          )}
        </Box>
      </Modal>
    </main>
  );
};

export default OpenShifts;
