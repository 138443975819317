import { gql } from "@apollo/client";

const GET_SHIFT_STATUS = gql`
  query ShiftStatuses {
    shiftStatuses {
      id
      value
    }
  }
`;

export { GET_SHIFT_STATUS };
