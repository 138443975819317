import React, { useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "../../UI/shadcn/dialog";
import {
  GetWaitlistSessionsByDayAndLeagueSearchQuery,
  useCreateWaitListMutation,
  useGetWaitlistSessionsByDayAndLeagueSearchLazyQuery,
} from "../../../../src/generated/graphql";
import Headline2Variable from "../Text/Headline/Headline2Variable";
import { FormFieldSelect } from "../FormField/FormFieldDropdown/FormFieldSelectV2";
import SearchIcon from "@mui/icons-material/Search";
import Button from "../../UI/Button/Button";
import FormField from "../../UI/FormField/FormField";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../src/app/store";
import { CircularProgress } from "@mui/material";
import { DataTable } from "../Table/ShadcnTable";
import { ColumnDef } from "@tanstack/react-table";
import { GroupAddOutlined } from "@mui/icons-material";
import {
  displayAlertError,
  displayAlertSuccess,
} from "../../../app/globalSlice";

interface WaitlistSessionsDialogProps {
  userId: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

type getSessionsByDayAndSearchInput = {
  dayOfWeek: number;
  leagueSearch: string;
  regions: number[];
};

/**
 * A dialog that allows the user to swap two teams
 * @param open Controls whether the dialog is open or not
 * @param onOpenChange Function to change the open state
 * @param userId The user ID
 * @returns
 */
const WaitlistSessionsDialog = ({
  open,
  onOpenChange,
  userId,
}: WaitlistSessionsDialogProps) => {
  const weekdaysArray: { id: number; name: string }[] = [
    { id: 0, name: "Sunday" },
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
  ];

  const { selectedRegions }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  const dispatch = useDispatch<AppDispatch>();

  const [sessionFilter, setSessionFilter] =
    useState<getSessionsByDayAndSearchInput>({
      regions: selectedRegions.map((region: any) => +region.id),
      dayOfWeek: 7,
      leagueSearch: "",
    });

  const [getWaitlistedSessions, { loading: sessionsLoading, data: sessions }] =
    useGetWaitlistSessionsByDayAndLeagueSearchLazyQuery({
      fetchPolicy: "no-cache",
    });

  const [createWaitlist, { loading: createWaitlistLoading }] =
    useCreateWaitListMutation({
      fetchPolicy: "no-cache",
    });

  const searchSessions = async () => {
    getWaitlistedSessions({
      variables: {
        regions: sessionFilter.regions,
        dayOfWeek: sessionFilter.dayOfWeek,
        leagueSearch: sessionFilter.leagueSearch,
        userId: userId,
      },
    });
  };

  const submitWaitlist = async (sessionId: number, productTypeId: number) => {
    createWaitlist({
      variables: {
        waitlistInput: {
          sessionId: sessionId,
          userId: userId,
          productTypeId: productTypeId,
        },
      },
      onCompleted: (data) => {
        if (data.createWaitList.success) {
          onOpenChange(false);
          dispatch(displayAlertSuccess(data.createWaitList.message));
        } else {
          dispatch(displayAlertError(data.createWaitList.message));
        }
      },
    });
  };

  const queriedSessionsColumns: ColumnDef<
    GetWaitlistSessionsByDayAndLeagueSearchQuery["getWaitlistSessionsByDayAndLeagueSearch"][0]
  >[] = [
    {
      accessorKey: "id",
      header: "Id",
    },
    {
      header: "Region",
      accessorKey: "league.sportFormat.region.name",
    },
    {
      accessorKey: "league.name",
      header: "League Name",
    },
    {
      header: "Reg Batch",
      accessorKey: "registrationBatch.name",
      cell: ({ row }) => {
        return (
          <div className="font-medium text-left">
            {row.original.registrationBatch.name}
          </div>
        );
      },
    },
    {
      header: "Reg Batch Dates",
      cell: ({ row }) => {
        return (
          <div className="font-medium text-left">
            {new Date(
              row.original.registrationBatch.regOpenDate
            ).toLocaleDateString()}{" "}
            -{" "}
            {new Date(
              row.original.registrationBatch.regCloseDate
            ).toLocaleDateString()}
          </div>
        );
      },
    },
    {
      header: "Day of Week",
      accessorKey: "dayOfWeek",
      cell: ({ row }) => {
        return (
          <div className="font-medium text-left">
            {weekdaysArray.find((day) => day.id === row.original.dayOfWeek)
              ?.name ?? row.original.dayOfWeek}
          </div>
        );
      },
    },
    {
      header: "Start Date",
      accessorKey: "startDate",
      cell: ({ row }) => {
        return (
          <div className="font-medium text-left">
            {new Date(
              row.original.registrationBatch.regCloseDate
            ).toLocaleDateString()}
          </div>
        );
      },
    },
    {
      header: "Free Agent",
      accessorKey: "free agent",
      cell: ({ row }) => {
        return (
          <div className="font-medium text-left">
            <Button
              variant={
                row.original.availability?.freeAgent !== "Waitlist"
                  ? "disabled"
                  : "primary"
              }
              onClick={() => {
                submitWaitlist(row.original.id, 1);
              }}
              disabled={row.original.availability?.freeAgent === "Available"}
            >
              <GroupAddOutlined
                fontSize="large"
                sx={{
                  width: "18px",
                  height: "18px",
                }}
              />
            </Button>
          </div>
        );
      },
    },
    {
      header: "Team",
      accessorKey: "team",
      cell: ({ row }) => {
        return (
          <div className="font-medium text-left">
            <Button
              variant={
                row.original.availability?.team !== "Waitlist"
                  ? "disabled"
                  : "primary"
              }
              onClick={() => {
                submitWaitlist(row.original.id, 2);
              }}
              disabled={row.original.availability?.team === "Available"}
            >
              <GroupAddOutlined
                fontSize="large"
                sx={{
                  width: "18px",
                  height: "18px",
                }}
              />
            </Button>
          </div>
        );
      },
    },
  ];

  const queriedSessionsData = useMemo(() => {
    const today = new Date();
    if (sessions?.getWaitlistSessionsByDayAndLeagueSearch) {
      const filteredSessions =
        sessions.getWaitlistSessionsByDayAndLeagueSearch.filter((session) => {
          // If today is between the reg open and close date
          const regOpen = new Date(session.registrationBatch.regOpenDate);
          const regClose = new Date(session.registrationBatch.regCloseDate);
          return regOpen <= today && today <= regClose;
        });
      return filteredSessions;
    } else {
      return [];
    }
  }, [sessions]);

  return (
    <Dialog
      open={open}
      onOpenChange={(value) => onOpenChange(value)}
    >
      <DialogContent className="flex flex-col flex-grow w-fit max-w-[80%] max-h-[80%] overflow-scroll">
        <DialogHeader>
          <Headline2Variable>Add to Waitlist</Headline2Variable>
        </DialogHeader>
        <div className="flex flex-row items-end gap-4 h-full ">
          <div className="w-64">
            <FormFieldSelect
              key={
                sessionFilter.regions.length > 0
                  ? sessionFilter.regions[0]
                  : "0"
              }
              inputChange={(value) => {
                setSessionFilter({
                  ...sessionFilter,
                  regions:
                    value === "0"
                      ? selectedRegions.map((region: any) => +region.id)
                      : [+value],
                });
              }}
              label={"Region"}
              placeholder="Select Region"
              value={
                sessionFilter.regions.length > 1
                  ? "0"
                  : sessionFilter.regions[0]?.toString()
              }
            >
              {[{ id: 0, name: "All" }, ...selectedRegions]}
            </FormFieldSelect>
          </div>
          <div className="w-64">
            <FormFieldSelect
              key={sessionFilter.dayOfWeek}
              inputChange={(value) => {
                setSessionFilter({
                  ...sessionFilter,
                  ["dayOfWeek"]: +value,
                });
              }}
              label={"Day"}
              placeholder="Select Day of Week"
              value={sessionFilter.dayOfWeek.toString()}
            >
              {[{ id: 7, name: "All" }, ...weekdaysArray]}
            </FormFieldSelect>
          </div>
          <div className="w-80 max-w-80">
            <FormField
              initialValue={sessionFilter.leagueSearch}
              inputChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSessionFilter({
                  ...sessionFilter,
                  leagueSearch: e.target.value,
                })
              }
              name="leagueSearch"
              placeholder="Search"
              className="h-9"
            ></FormField>
          </div>
          <div>
            <Button
              variant="primary"
              onClick={() => {
                searchSessions();
              }}
              className="w-60 max-h-9"
            >
              <div className="flex flex-row items-center gap-2">
                <SearchIcon
                  fontSize="large"
                  sx={{
                    width: "18px",
                    height: "18px",
                  }}
                />
                <span>Search Session</span>
              </div>
            </Button>
          </div>
        </div>
        {sessionsLoading || createWaitlistLoading ? (
          <CircularProgress />
        ) : (
          <div className="max-h-[450px] overflow-scroll border rounded-md">
            {sessions?.getWaitlistSessionsByDayAndLeagueSearch &&
              sessions?.getWaitlistSessionsByDayAndLeagueSearch?.length > 0 && (
                <DataTable
                  data={queriedSessionsData}
                  columns={queriedSessionsColumns}
                />
              )}
          </div>
        )}
        <DialogFooter>
          <Button
            variant="secondary"
            onClick={() => onOpenChange(false)}
          >
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default WaitlistSessionsDialog;
