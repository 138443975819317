import { gql } from "@apollo/client";

const GET_EMAIL_TEMPLATE_INSTANCE = gql`
  query GetEmailTemplateInstance($emailTemplateInstanceStatusId: Int!) {
    getEmailTemplateInstance(
      emailTemplateInstanceStatusId: $emailTemplateInstanceStatusId
    ) {
      id
      body
      name
      subject
      emailTemplateInstanceStatusId
      updatedBy
      updatedAt
      emailMarketingFilter {
        recipientCount
      }
      emailBatch {
        timeToSend
      }
    }
  }
`;
const GET_EMAIL_TEMPLATE_INSTANCE_BY_ID = gql`
  query GetEmailTemplateInstanceById($id: Int!) {
    getEmailTemplateInstanceById(id: $id) {
      id
      body
      name
      subject
      updatedBy
      updatedAt
    }
  }
`;

const GET_EMAIL_EMAIL_TEMPLATE_INSTANCE_STATUSES = gql`
  query GetEmailTemplateInstanceStatuses {
    getEmailTemplateInstanceStatuses {
      id
      name
    }
  }
`;

const CREATE_EMAIL_TEMPLATE_INSTANCE = gql`
  mutation CreateEmailTemplateInstance(
    $createEmailTemplateInstanceInput: CreateEmailTemplateInstanceInput!
  ) {
    createEmailTemplateInstance(
      createEmailTemplateInstanceInput: $createEmailTemplateInstanceInput
    ) {
      success
      message
      emailTemplateInstance {
        id
        body
        name
        subject
        updatedBy
        updatedAt
      }
    }
  }
`;

const UPDATE_EMAIL_TEMPLATE_INSTANCE = gql`
  mutation UpdateEmailTemplateInstance(
    $updateEmailTemplateInstanceInput: UpdateEmailTemplateInstanceInput!
  ) {
    updateEmailTemplateInstance(
      updateEmailTemplateInstanceInput: $updateEmailTemplateInstanceInput
    ) {
      success
      message
      emailTemplateInstance {
        id
        body
        name
        subject
        updatedBy
        updatedAt
      }
    }
  }
`;

const DELETE_EMAIL_TEMPLATE_INSTANCE = gql`
  mutation DeleteEmailTemplateInstance($id: Int!) {
    deleteEmailTemplateInstance(id: $id) {
      success
      message
    }
  }
`;

export {
  GET_EMAIL_TEMPLATE_INSTANCE,
  GET_EMAIL_TEMPLATE_INSTANCE_BY_ID,
  CREATE_EMAIL_TEMPLATE_INSTANCE,
  UPDATE_EMAIL_TEMPLATE_INSTANCE,
};
