import React, { useEffect, useMemo } from "react";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import Button from "../UI/Button/Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import { Column } from "react-table";
import ActionsCell from "../UI/ActionCell";
import BaseTable from "../UI/Table/Table";
import TablePagination from "../UI/Pagination/Pagination";
import { LoadingMaterialUI } from "../UI";
import moment from "moment";
import { IconButton } from "@mui/material";
import Link1 from "../UI/Text/Link/Link1";
import { getAllContracts } from "../../app/venueMasterSlice";
import {
  Contract,
  ContractApprovalStatus,
  ContractPermitStatus,
  ContractType,
  Pagination,
} from "../../types/types";

const ContractManagement: React.FC = () => {
  const rootPath =
    process.env.NODE_ENV === "production" ? process.env.REACT_APP_URL : "";
  const publicUrl = process.env.PUBLIC_URL;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { isLoading, contractList, contractListCount } = useSelector(
    (state: RootState) => state.venueMaster
  );
  //Pagination Page Size
  const pageSize = 10;
  const [pagination, setPagination] = React.useState<null | Pagination>(null);

  const redirectCreateContract = () => {
    navigate(`${rootPath}/ops/create-contract/`);
  };

  const handleSelectedOption = (row: any, idx: number) => {
    if (idx === 1) {
      navigate(`${rootPath}/ops/edit-contract/${row.original.id}`);
    }
  };

  const handleChangePagination = (value: number) => {
    setPagination({
      pageCount: pagination?.pageCount,
      pageSize: pagination?.pageSize,
      page: value - 1,
    });
  };

  useEffect(() => {
    const page = 0;
    const pageCount = Math.ceil(contractListCount / pageSize);
    setPagination({
      page,
      pageSize,
      pageCount,
    });
  }, [contractListCount]);

  useEffect(() => {
    dispatch(
      getAllContracts({
        page: pagination?.page,
        pageSize: pagination?.pageSize,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (pagination) {
      dispatch(
        getAllContracts({
          page: pagination?.page,
          pageSize: pagination?.pageSize,
        })
      );
    }
  }, [pagination]);

  const COLUMNS: Column<Contract>[] = [
    {
      Header: "ContractId#",
      accessor: "id",
    },
    {
      Header: "Vendor",
      id: "vendor",
      accessor: (d: any) => {
        return <div>{d.vendor.name}</div>;
      },
    },
    {
      Header: "Region",
      id: "region",
      accessor: (d: any) => {
        return <div>{d.region.name}</div>;
      },
    },
    {
      Header: "Type",
      id: "type",
      accessor: (d: any) => {
        return <div>{d.type.name}</div>;
      },
    },
    {
      Header: "Name",
      id: "name",
      accessor: (d: any) => {
        return <div>{d.name}</div>;
      },
    },
    {
      Header: "Permit Status",
      id: "status",
      accessor: (d: any) => {
        return <div>{d.permitStatus.name}</div>;
      },
    },
    {
      Header: "Approval Status",
      id: "approvalStatus",
      accessor: (d: any) => {
        return <div>{d.approvalStatus.name}</div>;
      },
    },
    {
      Header: "Created",
      id: "createdAt",
      accessor: (d: any) => {
        return <div>{moment(d.createdAt).local().format("YYYY-MM-DD")}</div>;
      },
    },
    {
      Header: "Last Edit",
      id: "updatedAt",
      accessor: (d: any) => {
        return <div>{moment(d.updatedAt).local().format("YYYY-MM-DD")}</div>;
      },
    },
    {
      Header: " ",
      Cell: (data: any) => {
        let options = [];
        options.push({ id: 1, text: "Edit" });

        return (
          <ActionsCell
            row={data.row}
            options={options}
            handleSelectedOption={handleSelectedOption}
          />
        );
      },
    },
  ];

  const columns: any = useMemo(() => COLUMNS, []);
  const data = useMemo(() => contractList, [contractList]);

  return (
    <main>
      <div className="flex flex-row justify-between w-full mb-4">
        <Headline1Variable>Contracts</Headline1Variable>
        <div className="mb-4 text-end">
          <Button
            variant="primary"
            onClick={redirectCreateContract}
          >
            <AddIcon />
            New Contract
          </Button>
        </div>
      </div>
      {isLoading && <LoadingMaterialUI />}
      {contractList.length > 0 && (
        <BaseTable
          columns={columns}
          data={data}
        />
      )}
      {!(contractList.length > 0) && <div>No Data Found</div>}
      {pagination && (
        <div className="flex justify-end mt-4">
          <TablePagination
            page={pagination?.page || 0}
            pageCount={pagination?.pageCount || 0}
            onChange={handleChangePagination}
          />
        </div>
      )}
    </main>
  );
};

export default ContractManagement;
