import { gql } from '@apollo/client';

const GET_LOCATIONS = gql`
  query Locations {
    locations {
      id
      name
    }
  }
`;

export { GET_LOCATIONS };
