import {
  Dialog,
  DialogPortal,
  DialogContent,
  DialogOverlay,
} from '@radix-ui/react-dialog';
import { Loader2 } from 'lucide-react';
import Headline2Variable from '../Text/Headline/Headline2Variable';

interface LoadingDialogProps {
  /**
   * Controls whether the dialog is open or not
   */
  open: boolean;
  /**
   * Text to display in the dialog
   */
  text?: string;
}

/**
 * A loading dialog that is displayed when the user is waiting for a response from the server
 * @param open controls whether the dialog is open or not
 * @param text text to display in the dialog
 * @returns
 */
const LoadingDialog = ({ open, text = 'Loading' }: LoadingDialogProps) => {
  return (
    <Dialog open={open}>
      <DialogPortal>
        <DialogOverlay className='fixed inset-0 z-50 bg-white/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 outline-none' />
        <DialogContent className='fixed left-[50%] top-[50%] z-50 grid w-full translate-x-[-50%] translate-y-[-50%] duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] outline-none'>
          <div className='flex flex-col items-center justify-center gap-6'>
            <Loader2 className='w-32 h-32 text-primary-90 animate-spin' />
            <Headline2Variable>{text}</Headline2Variable>
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

export default LoadingDialog;
