import { gql } from "@apollo/client";

const GET_VENDORS = gql`
  query Vendors($vendorFilters: VendorFilters!, $page: Int, $pageSize: Int) {
    vendors(vendorFilters: $vendorFilters, page: $page, pageSize: $pageSize) {
      id
      name
      vendorType {
        id
        name
      }
      paymentMethod
      primaryContact
      primaryEmail
      primaryPhone
      afterHoursPhone
      createdAt
      updatedAt
    }
  }
`;

const GET_VENDORS_COUNT = gql`
  query VendorsCount($vendorFilters: VendorFilters!) {
    vendorsCount(vendorFilters: $vendorFilters)
  }
`;

const GET_VENDOR_TYPES = gql`
  query VendorTypes {
    vendorTypes {
      id
      name
    }
  }
`;

const GET_VENDOR_BY_ID = gql`
  query VendorById($vendorByIdId: ID) {
    vendorById(id: $vendorByIdId) {
      afterHoursPhone
      id
      name
      paymentMethod
      primaryContact
      primaryEmail
      primaryPhone
      vendorType {
        id
        name
      }
    }
  }
`;

const CREATE_VENDOR = gql`
  mutation VendorCreate($vendorArgs: VendorInput!) {
    vendorCreate(vendorArgs: $vendorArgs) {
      success
      message
      vendor {
        id
        name
        paymentMethod
        primaryContact
        primaryEmail
        primaryPhone
        afterHoursPhone
        vendorType {
          id
          name
        }
      }
    }
  }
`;

const UPDATE_VENDOR = gql`
  mutation VendorUpdate($vendorUpdateId: ID!, $vendorArgs: VendorInput!) {
    vendorUpdate(id: $vendorUpdateId, vendorArgs: $vendorArgs) {
      success
      message
      vendor {
        id
      }
    }
  }
`;

const DELETE_VENDOR = gql`
  mutation VendorDelete($vendorDeleteId: ID!) {
    vendorDelete(id: $vendorDeleteId) {
      success
      message
    }
  }
`;

export {
  GET_VENDORS,
  GET_VENDOR_TYPES,
  GET_VENDOR_BY_ID,
  CREATE_VENDOR,
  UPDATE_VENDOR,
  DELETE_VENDOR,
  GET_VENDORS_COUNT,
};
