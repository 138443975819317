import { cn } from '../../../../lib/utils';
import React, { ReactNode } from 'react';

interface Caption1Props {
  // The text inside the Headline
  children: ReactNode;
  // Extra css customization
  className?: string;
  whiteSpace?:
    | 'normal'
    | 'pre'
    | 'nowrap'
    | 'pre-wrap'
    | 'pre-line'
    | 'break-spaces';
}

const Caption1 = ({
  children,
  whiteSpace,
  className,
  ...props
}: Caption1Props) => {
  return (
    <p
      className={cn(
        'text-txt-1 font-[500] text-[12px] tracking-[0.003em] leading-[1.6]',
        className
      )}
      style={{ whiteSpace: whiteSpace ? whiteSpace : 'nowrap' }}
    >
      {children}
    </p>
  );
};

export default Caption1;
