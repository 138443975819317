import { Button } from '@mui/material';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../app/store';
import {
  getRegions,
  getVenueOverview,
  getVenueTypes,
  handleVenueOverviewFilters,
} from '../../app/venueMasterSlice';
import classes from './VenueOverview.module.css';
import { FormRow, FormRowSelect, LoadingMaterialUI } from '../UI';

import { VenuesListType } from '../../types/types';
import CollapseVenue from '../UI/CollapseVenue';

const VenueOverview: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const weekdays = [
    { id: 0, name: 'Sunday' },
    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' },
  ];

  const { isLoading, venueOverviewFilter, venueTypes, venueOverview, selectedRegions }: any =
    useSelector((state: RootState) => state.venueMaster);

  const [dateRange, setDateRange] = React.useState({
    startDate: '',
    endDate: '',
    day: 0,
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      handleVenueOverviewFilters({
        ...venueOverviewFilter,
        [e.target.name]: +e.target.value || e.target.value,
      })
    );
  };

  useEffect(() => {
    dispatch(getVenueTypes(''));
  }, [dispatch]);

  const onSubmit = () => {
    dispatch(getVenueOverview(venueOverviewFilter));
    setDateRange({
      startDate: venueOverviewFilter.startDate,
      endDate: venueOverviewFilter.endDate,
      day: venueOverviewFilter.day,
    });
  };

  const list_to_tree = (list: VenuesListType[]) => {
    const map: any = {};
    let node;
    const roots = [];
    let i = 0;

    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parentId !== null) {
        // if you have dangling branches check that map[node.parentId] exists
        if (list[map[node.parentId]]) {
          list[map[node.parentId]].children.push(node);
        }
      } else {
        roots.push(node);
      }
    }
    return roots;
  };

  let temp: VenuesListType[] = venueOverview.map((venue: VenuesListType) => ({
    ...venue,
  }));

  const venueOverviewData = list_to_tree(temp);

  const venueOverviewTable = (
    <Fragment>
      {venueOverviewData &&
        venueOverviewData.map((row: VenuesListType) => (
          <CollapseVenue
            row={row}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            day={dateRange.day}
          />
        ))}
    </Fragment>
  );
  if (isLoading) {
    return <LoadingMaterialUI />;
  }

  return (
    <Fragment>
      <div className={classes.filters}>
        <FormRowSelect
          name="regionId"
          value={venueOverviewFilter.regionId}
          handleChange={handleChange}
          list={selectedRegions}
          labelText="Region"
        />
        <FormRowSelect
          name="typeId"
          value={venueOverviewFilter.typeId}
          handleChange={handleChange}
          list={[...venueTypes, ...[{ id: '0', name: 'All' }]]}
          labelText="Venue Type"
        />
        <FormRowSelect
          name="day"
          value={venueOverviewFilter.day}
          handleChange={handleChange}
          list={weekdays}
          labelText="Weekday"
        />
        <FormRow
          type="date"
          name="startDate"
          value={venueOverviewFilter.startDate}
          handleChange={handleChange}
          labelText="Start date"
        />
        <FormRow
          type="date"
          name="endDate"
          value={venueOverviewFilter.endDate}
          handleChange={handleChange}
          labelText="End Date"
        />
        <div className={classes.btn__container}>
          <Button
            variant="contained"
            className={classes.btn__submit}
            onClick={onSubmit}
            sx={{
              color: 'var(--white)',
              bgcolor: 'var(--primary-blue-500)',
              '&:hover': { bgcolor: 'var(--primary-blue-700)' },
            }}
          >
            Apply filters
          </Button>
        </div>
      </div>
      {venueOverview.length > 0 && (
        <div className={classes.table__contracts}>{venueOverviewTable}</div>
      )}
      {!(venueOverview.length > 0) && (
        <div className={classes.table__contracts}>No Data Found</div>
      )}
    </Fragment>
  );
};

export default VenueOverview;
