import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { client } from "../graphql";
import { User, Users, PrivacySetting } from "../types/types";
import {
  GET_ALL_USERS,
  GET_ALL_USERS_COUNT,
  GET_PRIVACY_SETTINGS,
  GET_USER_BY_ID,
} from "../graphql/queries/user";
import { toast as toastStackable } from "sonner";

interface CXInitialState {
  isLoading: boolean;
  userList: Users[];
  userListCount: number;
  userById: User;
  userListAutoComplete: Users[];
  isLoadingUserlist: boolean;
  privacySettings: PrivacySetting[];
}

const initialState: CXInitialState = {
  isLoading: false,
  userList: [],
  userListCount: 0,
  userById: {
    id: "",
  },
  userListAutoComplete: [],
  isLoadingUserlist: false,
  privacySettings: [],
};

export const getUsers = createAsyncThunk(
  "cx/getUsers",
  async (usersArgs: any, thunkAPI) => {
    try {
      console.log("getting user");
      const response = await client.query({
        query: GET_ALL_USERS,
        variables: {
          ...usersArgs,
        },
        fetchPolicy: "no-cache",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const users = response.data.getUsers;
      console.log(users);
      return users;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getUsersCount = createAsyncThunk(
  "cx/getUsersCount",
  async (usersCountArgs: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_ALL_USERS_COUNT,
        variables: {
          ...usersCountArgs,
        },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const usersCount = response.data.getUsersCount;
      return usersCount;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getUsersAutocomplete = createAsyncThunk(
  "cx/getUsersAutocomplete",
  async (usersArgs: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_ALL_USERS,
        variables: {
          ...usersArgs,
        },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const users = response.data.getUsers;
      return users;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getUserById = createAsyncThunk(
  "cx/getUserById",
  async (userId: string, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_USER_BY_ID,
        variables: {
          userByIdGetId: userId,
        },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const user = response.data.userByIdGet;
      return user;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getPrivacySettings = createAsyncThunk(
  "cx/getPrivacySettings",
  async (_: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_PRIVACY_SETTINGS,

        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const privacySettings = response.data.privacySettings;
      return privacySettings;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const cxSlice = createSlice({
  name: "cx",
  initialState,
  reducers: {
    updateUserById: (state, action) => {
      state.userById = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state, action) => {
        state.isLoadingUserlist = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.isLoadingUserlist = false;
        state.userList = action.payload;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isLoadingUserlist = false;
        toastStackable.error(action.error.message ?? "Error fetching users");
      })
      .addCase(getUsersCount.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUsersCount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userListCount = action.payload;
      })
      .addCase(getUsersCount.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Error fetching users count"
        );
      })
      .addCase(getUsersAutocomplete.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUsersAutocomplete.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userListAutoComplete = action.payload;
      })
      .addCase(getUsersAutocomplete.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Error fetching users autocomplete"
        );
      })
      .addCase(getUserById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userById = action.payload;
      })
      .addCase(getUserById.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Error fetching user by id"
        );
      })
      .addCase(getPrivacySettings.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPrivacySettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.privacySettings = action.payload;
      })
      .addCase(getPrivacySettings.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Error fetching privacy settings"
        );
      });
  },
});

export const cxSelector = (state: RootState) => state.cx;

export const { updateUserById } = cxSlice.actions;

export default cxSlice.reducer;
