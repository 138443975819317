import React, { useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Card, CardContent } from "../shadcn/card";
import { z } from "zod";
import { DivisionReseed, Team } from "../../Admin/ReseedDivisions";
import Subtitle1 from "../Text/Subtitle/Subtitle1";
import Body1 from "../Text/Body/Body1";

interface DivisionAndTeamBoardProps {
  divisions: DivisionReseed[];
  setDivisions: React.Dispatch<React.SetStateAction<DivisionReseed[]>>;
}

const DivisionAndTeamBoard: React.FC<DivisionAndTeamBoardProps> = ({
  divisions,
  setDivisions,
}) => {
  const [draggedTeam, setDraggedTeam] = useState<Team | null>(null);
  const [hoveredDivisionId, setHoveredDivisionId] = useState<number | null>(
    null
  );
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const draggedCardRef = useRef<HTMLDivElement>(null);

  const onDragStart = (team: Team, e: React.DragEvent<HTMLDivElement>) => {
    setDraggedTeam(team);
    if (draggedCardRef.current) {
      const rect = draggedCardRef.current.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const offsetY = e.clientY - rect.top;
      setMousePosition({ x: offsetX, y: offsetY });
    }
  };

  const onDragOver = (
    e: React.DragEvent<HTMLDivElement>,
    divisionId: number
  ) => {
    e.preventDefault();
    setHoveredDivisionId(divisionId);
  };

  const onDragLeave = () => {
    setHoveredDivisionId(null);
  };

  const onDrop = (targetDivisionId: number) => {
    if (!draggedTeam) return;

    const sourceDivision = divisions.find((div) =>
      div.teams.some((team) => team.id === draggedTeam.id)
    );
    const targetDivision = divisions.find((div) => div.id === targetDivisionId);

    if (
      !sourceDivision ||
      !targetDivision ||
      sourceDivision.id === targetDivision.id
    )
      return;

    setDivisions((prevDivisions) =>
      prevDivisions.map((div) => {
        if (div.id === sourceDivision.id) {
          return {
            ...div,
            teams: div.teams.filter((team) => team.id !== draggedTeam.id),
          };
        }
        if (div.id === targetDivision.id) {
          return { ...div, teams: [...div.teams, draggedTeam] };
        }
        return div;
      })
    );

    setDraggedTeam(null);
    setHoveredDivisionId(null);
  };

  return (
    <div className="p-4">
      <div className="flex flex-row flex-wrap space-x-4">
        {divisions.map((division) => (
          <motion.div
            key={division.id}
            className="w-64 p-2 rounded-lg min-w-64"
            onDragOver={(e) => onDragOver(e, division.id!)}
            onDragLeave={onDragLeave}
            onDrop={() => onDrop(division.id!)}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.3 }}
          >
            <Subtitle1>{division.name}</Subtitle1>
            <AnimatePresence>
              {division.teams.map((team, index) => (
                <motion.div
                  key={team.id}
                  layout
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  transition={{ duration: 0.2 }}
                >
                  <Card
                    ref={draggedTeam?.id === team.id ? draggedCardRef : null}
                    draggable
                    onDragStart={(e) => onDragStart(team, e)}
                    className="mb-2 cursor-move"
                  >
                    <CardContent className="p-2">
                      <Body1 className="truncate">
                        #{team.id} - {team.name}
                      </Body1>
                    </CardContent>
                  </Card>
                  {hoveredDivisionId === division.id &&
                    index === division.teams.length - 1 && (
                      <motion.div
                        className="h-2 mb-2 bg-blue-300 rounded"
                        animate={{ opacity: 1, height: 8 }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.2 }}
                      />
                    )}
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>
        ))}
      </div>
      {draggedTeam && (
        <motion.div
          className="fixed pointer-events-none"
          style={{
            left: mousePosition.x,
            top: mousePosition.y,
            zIndex: 1000,
          }}
          initial={{ opacity: 0.8, scale: 1.05 }}
          animate={{ opacity: 0.8, scale: 1.05 }}
          transition={{ duration: 0.2 }}
        >
          <Card className="w-56">
            <CardContent className="p-2">
              <Body1 className="truncate">
                #{draggedTeam.id} - {draggedTeam.name}
              </Body1>
            </CardContent>
          </Card>
        </motion.div>
      )}
    </div>
  );
};

export default DivisionAndTeamBoard;
