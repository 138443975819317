import * as React from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SwitchButtonProps } from './types';

const SwitchButton: React.FC<SwitchButtonProps> = ({
  labelStart,
  labelEnd,
  checked,
  handleChange,
  height,
  width,
}) => {
  const widthSize: number = Number(width) || 28;
  const heightSize: number = Number(height) || 16;
  const SwitchMaterial = styled(Switch)(({ theme }: any) => ({
    width: widthSize,
    height: heightSize,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 1,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 3,
      '&.Mui-checked': {
        transform: `translateX(${widthSize / 2}px)`,
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === 'dark' ? '#177ddc' : '#00e0c6',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: heightSize / 1.3,
      height: heightSize / 1.3,
      borderRadius: 25,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 25,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(255,255,255,.35)'
          : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));

  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      <Typography>{labelStart}</Typography>
      <SwitchMaterial checked={checked} onChange={handleChange} />
      <Typography>{labelEnd}</Typography>
    </Stack>
  );
};

export default SwitchButton;
