import React from 'react';

const Base = () => {
  return (
    <>
      <h3>This is the home page</h3>
    </>
  );
};

export default Base;
