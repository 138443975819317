import { gql } from "@apollo/client";

const GET_VENUE_OVERVIEW = gql`
  query VenueOverview($venueOverviewFilters: VenueOverviewFilters) {
    venueOverview(venueOverviewFilters: $venueOverviewFilters) {
      overview
      name
      id
      parentId
    }
  }
`;

const GET_VENUES_HIERARCHY = gql`
  query VenuesHierarchy($ids: [ID!]) {
    venuesHierarchy(ids: $ids) {
      id
      name
      parentId
      typeId
      sizeId
    }
  }
`;

const GET_VENUES_BY_VENDORID = gql`
  query VenuesByVendorId($venuesByVendorIdId: ID) {
    venuesByVendorId(id: $venuesByVendorIdId) {
      id
      name
    }
  }
`;

const GET_VENUES_COUNT = gql`
  query VenuesCount($venueFilters: VenueFilters) {
    venuesCount(venueFilters: $venueFilters)
  }
`;

const GET_VENUES_MANAGEMENT = gql`
  query VenuesManagement(
    $venueFilters: VenueFilters
    $page: Int
    $pageSize: Int
  ) {
    venues(venueFilters: $venueFilters, page: $page, pageSize: $pageSize) {
      id
      name
      createdAt
      updatedAt
      parentVenue {
        id
        name
      }
      venueType {
        id
        name
      }
      venueSize {
        id
        name
      }
      venueDetail {
        address
        zipCode
        region {
          id
          name
        }
        vendor {
          id
          name
        }
      }
    }
  }
`;

const GET_VENUE_BY_ID = gql`
  query VenueById($venueByIdId: ID) {
    venueById(id: $venueByIdId) {
      id
      name
      startBuffer
      endBuffer
      parentId
      venueSetupId
      sizeId
      typeId
      islights
      venueDetailId
      venueDetail {
        address
        city
        country {
          id
          name
        }
        countryId
        facilityDescription
        facilityImage
        id
        intersection
        latitude
        locationLink
        longitude
        region {
          id
          name
        }
        regionId
        state {
          id
          name
        }
        stateId
        vendor {
          id
          name
        }
        vendorId
        zipCode
      }
      parentVenue {
        id
        name
      }
    }
  }
`;

const CREATE_VENUE = gql`
  mutation VenueCreate($venueArgs: CreateVenueInput!) {
    venueCreate(venueArgs: $venueArgs) {
      code
      success
      message
      venue {
        id
        name
      }
    }
  }
`;

const UPDATE_VENUE = gql`
  mutation VenueUpdate($venueArgs: UpdateVenueInput!) {
    venueUpdate(venueArgs: $venueArgs) {
      code
      success
      message
      venue {
        id
        name
      }
    }
  }
`;
const GET_VENUES_TREE = gql`
  query VenuesTree($regionId: Int) {
    venuesTree(regionId: $regionId) {
      id
      name
      venueName
    }
  }
`;

const VENUES_EXCEPT_DESCENDANTS = gql`
  query VenuesExceptDescendants($venuesExceptDescendantsId: Int!) {
    venuesExceptDescendants(id: $venuesExceptDescendantsId) {
      id
      name
    }
  }
`;

export {
  GET_VENUE_OVERVIEW,
  GET_VENUES_HIERARCHY,
  GET_VENUES_BY_VENDORID,
  GET_VENUES_COUNT,
  GET_VENUES_MANAGEMENT,
  GET_VENUE_BY_ID,
  CREATE_VENUE,
  UPDATE_VENUE,
  GET_VENUES_TREE,
  VENUES_EXCEPT_DESCENDANTS,
};
