import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const regionToTimezoneMapping: { [key: number]: string } = {
  1: "America/Toronto",
  2: "America/Toronto",
  3: "America/Toronto",
  4: "America/Toronto",
  5: "America/Toronto",
  6: "America/Toronto",
  7: "America/Toronto",
  8: "America/Toronto",
  9: "America/Toronto",
  10: "America/Toronto",
  11: "America/Toronto",
  12: "America/Toronto",
  13: "America/Winnipeg",
  14: "America/Toronto",
  15: "America/Toronto",
  16: "America/Toronto",
  17: "America/Toronto",
  18: "America/Toronto",
  19: "America/Toronto",
};

function convertLocalToJSDate(regionId: number, dateString: string): Date {
  const timezone = regionToTimezoneMapping[regionId];
  if (!timezone) {
    throw new Error("Invalid regionId");
  }

  // Parse the date string as if it were in the given timezone
  const date = dayjs.tz(dateString, timezone);

  // Extract the local components
  const year = date.year();
  const month = date.month(); // Month is 0-indexed in dayjs
  const day = date.date();
  const hour = date.hour();
  const minute = date.minute();
  const second = date.second();

  // Create a new Date object with those components
  const localDate = new Date(year, month, day, hour, minute, second);

  return localDate;
}

function localToUtcTime(region: number): Date {
  // Get the current time in the specified region
  const regionLocalDateTime = dayjs()
    .tz(regionToTimezoneMapping[region])
    .format("YYYY-MM-DDTHH:mm:ss");

  // Convert the local datetime of the region to a Date object, without timezone information
  const datetime = new Date(regionLocalDateTime + "Z");
  return datetime;
}

function dateTimeStringToUtcDate(datetime: string, region: number): Date {
  const date = dayjs.tz(datetime, regionToTimezoneMapping[region]).toDate();
  return date;
}

/**
 * Checks if the local time is after the current time
 * @param datetime The local datetime that will be compared to the current datetime
 * @param region The region this local time occurs in
 * @returns a boolean of true if the the passed in time is after the current time false otherwise.
 */
function isLocalDateTimePastCurrentTime(
  datetime: string,
  region: number
): boolean {
  const date = dayjs
    .tz(
      dayjs(datetime).format("YYYY-MM-DD HH:mm:ss"),
      regionToTimezoneMapping[region]
    )
    .toDate();
  return dayjs().toISOString() >= date.toISOString();
}

function dateWithoutTimezone(date: Date): string {
  const formattedDate = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
  return formattedDate;
}

export {
  localToUtcTime,
  dateTimeStringToUtcDate,
  dateWithoutTimezone,
  isLocalDateTimePastCurrentTime,
  convertLocalToJSDate,
};
