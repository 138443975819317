import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "../Button/Button";
import Headline2Variable from "../Text/Headline/Headline2Variable";
import { VenuesTreeQuery } from "@/src/generated/graphql";
import Body1 from "../Text/Body/Body1";
import { FormFieldSelect } from "../FormField/FormFieldDropdown/FormFieldSelectV2";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  AutoScheduleGame,
  TeamList,
  Template,
} from "../../Admin/Schedule/ScheduleNew";
import axios from "axios";
import {
  Table as ShadcnTable,
  TableBody as ShadcnTableBody,
  TableCell as ShadcnTableCell,
  TableHead as ShadcnTableHead,
  TableHeader as ShadcnTableHeader,
  TableRow as ShadcnTableRow,
} from "../../UI/shadcn/table";
import Subtitle1 from "../Text/Subtitle/Subtitle1";
import { Separator } from "../shadcn/separator";
import { LoaderCircle } from "lucide-react";
import Disclaimer from "../Alerts/Disclaimer";

dayjs.extend(customParseFormat);

interface AutoScheduleDialogProps {
  venues: VenuesTreeQuery["venuesTree"];
  teams: TeamList[];
  numberOfTeams: number;
  numberOfWeeks: number;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSubmitAutoSchedule: (games: AutoScheduleGame[]) => void;
}

/**
 * A dialog that allows the user to swap two teams
 * @param venues Array of venue options
 * @param numberOfTeams Number of teams in the division
 * @param numberOfWeeks Number of weeks in the division
 * @param teamst\ Array of team options
 * @param open Controls whether the dialog is open or not
 * @param onOpenChange Function to change the open state
 * @param onSubmitAutoSchedule Function to submit the auto schedule
 * @returns
 */
const AutoScheduleDialog = ({
  venues,
  teams,
  numberOfTeams,
  numberOfWeeks,
  open,
  onOpenChange,
  onSubmitAutoSchedule,
}: AutoScheduleDialogProps) => {
  const baseURL = `${
    process.env.REACT_APP_ENV === "production"
      ? "https://admin.jamsports.com"
      : process.env.REACT_APP_ENV === "staging"
      ? "https://admin.jamitall.com"
      : "http://localhost:3001"
  }`;
  const numberOfTimeslots = Math.ceil(numberOfTeams / 2);
  const templateInit = Array.from({ length: numberOfTimeslots }).map(
    (_, index) => ({
      startDateTimeLocal: "18:00",
      venueId: "",
    })
  );
  const hours = Array.from({ length: 24 }, (_, i) => i + 1);
  const minutes = Array.from({ length: 60 / 5 }, (_, i) => i * 5);
  const [template, setTemplate] = useState<Template[]>(templateInit);
  const [loadingAutoSchedule, setLoadingAutoSchedule] = useState(false);
  const [gamesByWeek, setGamesByWeek] = useState<AutoScheduleGame[]>([]);
  const [timeDistribution, setTimeDistribution] = useState<{
    [timeSlot: string]: {
      [teamName: string]: { count: number };
    };
  }>({});
  const [gamesDistribution, setGamesDistribution] = useState<{
    [homeTeamName: string]: {
      [awayTeamName: string]: { count: number };
    };
  }>({});
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // unique teams in division
    const teamIds: number[] = teams.map((team) => team.id);

    // extract unique venueIds from gamesByWeek
    const venueIds: number[] = [];
    if (gamesByWeek.length === 0) return;

    gamesByWeek.map((game) => {
      if (!venueIds.includes(game.venueId)) {
        venueIds.push(game.venueId);
      }
    });

    // Initialize venueDistribution 2-D array
    const venueDistribution: {
      [venueId: string]: {
        [teamName: string]: { count: number };
      };
    } = {};
    venueIds.forEach((venueId) => {
      venueDistribution[venueId] = {};
      teamIds.forEach((teamId) => {
        venueDistribution[venueId][teamId] = {
          count: 0,
        };
      });
    });

    // 2-D array of teamIds and venueIds from gamesByWeek

    gamesByWeek.forEach((game) => {
      if (game.homeTeamId) {
        if (!venueDistribution[game.venueId][game.homeTeamId]) {
          venueDistribution[game.venueId][game.homeTeamId] = {
            count: 0,
          };
        }

        venueDistribution[game.venueId][game.homeTeamId] = {
          count: venueDistribution[game.venueId][game.homeTeamId].count
            ? venueDistribution[game.venueId][game.homeTeamId].count + 1
            : 0,
        };
      }

      if (game.awayTeamId) {
        if (!venueDistribution[game.venueId][game.awayTeamId]) {
          venueDistribution[game.venueId][game.awayTeamId] = {
            count: 0,
          };
        }

        venueDistribution[game.venueId][game.awayTeamId] = {
          count: venueDistribution[game.venueId][game.awayTeamId]
            ? venueDistribution[game.venueId][game.awayTeamId].count + 1
            : 0,
        };
      }
    });

    // extract unique timeSlots from gamesByWeek
    const timeSlots: string[] = [];

    // Collect unique time slots
    gamesByWeek.forEach((game) => {
      const timeSlot = game.startDateTimeLocal;
      if (!timeSlots.includes(timeSlot)) {
        timeSlots.push(timeSlot);
      }
    });

    timeSlots.sort();

    // Initialize the timeDistribution object
    const timeDistribution: {
      [timeSlot: string]: {
        [teamName: string]: { count: number };
      };
    } = {};

    gamesByWeek.forEach((game) => {
      const timeSlot = game.startDateTimeLocal;

      // Initialize the timeSlot in timeDistribution if not already present
      if (!timeDistribution[timeSlot]) {
        timeDistribution[timeSlot] = {};
      }

      // Initialize home team count if it doesn't exist, then increment
      if (game.homeTeamId) {
        if (!timeDistribution[timeSlot][game.homeTeamId]) {
          timeDistribution[timeSlot][game.homeTeamId] = { count: 1 };
        } else {
          timeDistribution[timeSlot][game.homeTeamId].count += 1;
        }
      }

      // Initialize away team count if it doesn't exist, then increment
      if (game.awayTeamId) {
        if (!timeDistribution[timeSlot][game.awayTeamId]) {
          timeDistribution[timeSlot][game.awayTeamId] = { count: 1 };
        } else {
          timeDistribution[timeSlot][game.awayTeamId].count += 1;
        }
      }
    });

    // Map team IDs to team names
    const teamIdToName: { [id: string]: string } = {};
    teams.forEach((team) => {
      teamIdToName[team.id] = team.name;
    });

    // Create a new timeDistribution that uses team names instead of IDs
    const newTimeDistribution: {
      [timeSlot: string]: {
        [teamName: string]: { count: number };
      };
    } = {};

    Object.keys(timeDistribution).forEach((timeSlot) => {
      newTimeDistribution[timeSlot] = {};
      Object.keys(timeDistribution[timeSlot]).forEach((teamId) => {
        const teamName = teamIdToName[teamId];
        newTimeDistribution[timeSlot][teamName] =
          timeDistribution[timeSlot][teamId];
      });
    });

    //Games Distribution by matchups
    const newGamesDistribution: {
      [homeTeamName: string]: {
        [awayTeamName: string]: { count: number };
      };
    } = {};

    teams.forEach((team) => {
      newGamesDistribution[team.name] = {};
      teams.forEach((team2) => {
        newGamesDistribution[team.name][team2.name] = {
          count: 0,
        };
      });
    });

    gamesByWeek.forEach((game) => {
      if (game.homeTeamId !== null || game.awayTeamId !== null) {
        const homeTeamName = teamIdToName[game.homeTeamId || 0];
        const awayTeamName = teamIdToName[game.awayTeamId || 0];
        if (!homeTeamName || !awayTeamName) return;
        if (!newGamesDistribution[homeTeamName][awayTeamName]) {
          newGamesDistribution[homeTeamName][awayTeamName] = {
            count: 0,
          };
        }
        if (!newGamesDistribution[awayTeamName][homeTeamName]) {
          newGamesDistribution[awayTeamName][homeTeamName] = {
            count: 0,
          };
        }

        newGamesDistribution[homeTeamName][awayTeamName] = {
          count: newGamesDistribution[homeTeamName][awayTeamName].count + 1,
        };
        newGamesDistribution[awayTeamName][homeTeamName] = {
          count: newGamesDistribution[awayTeamName][homeTeamName].count + 1,
        };
      }
    });

    setTimeDistribution(newTimeDistribution);
    setGamesDistribution(newGamesDistribution);
  }, [gamesByWeek]);

  const handleSubmitAutoSchedule = async () => {
    //Check if all venues are selected
    const missingVenues = template.filter((temp) => !temp.venueId);
    if (missingVenues.length > 0) {
      setError("Please select a venue for all time slots");
      return;
    }
    setError(null);
    setLoadingAutoSchedule(true);
    const url = `${baseURL}/api/v1/autoschedule/${numberOfTeams}/${numberOfWeeks}`;
    try {
      const response = await axios.post(
        url,
        {},
        {
          headers: {
            "Content-type": "application/json",
          },
          withCredentials: true,
        }
      );
      if (response.data.status === "success") {
        setError(null);
        const games = response.data.data;
        const gamesByWeekUpdated: AutoScheduleGame[] = [];
        const mapNumberofTeamsToTeamIds = teams.reduce(
          (acc: any, team, index) => {
            acc[index + 1] = team.id;
            return acc;
          },
          {}
        );
        games.forEach((gamesByWeek: any) => {
          gamesByWeek.forEach((game: number[], index: number) => {
            gamesByWeekUpdated.push({
              homeTeamId: mapNumberofTeamsToTeamIds[game[0]],
              awayTeamId: mapNumberofTeamsToTeamIds[game[1]],
              venueId: +template[index].venueId,
              startDateTimeLocal: template[index].startDateTimeLocal,
            });
          });
        });
        setGamesByWeek(gamesByWeekUpdated);
      } else {
        setError(response.data.data);
      }
      setLoadingAutoSchedule(false);
    } catch (error: any) {
      console.log(error);
      setLoadingAutoSchedule(false);
    }
  };

  const createTimeDistributionRows = () => {
    const rows: any[] = [];
    Object.keys(timeDistribution).forEach((timeSlot) => {
      const row: any = [];
      row.push(<ShadcnTableCell>{timeSlot}</ShadcnTableCell>);
      Object.keys(timeDistribution[timeSlot]).forEach((teamName) => {
        row.push(
          <ShadcnTableCell>
            <div>{timeDistribution[timeSlot][teamName].count || 0}</div>
          </ShadcnTableCell>
        );
      });
      rows.push(<ShadcnTableRow>{row}</ShadcnTableRow>);
    });
    return rows;
  };

  const createGamesDistributionRows = () => {
    const rows: any[] = [];
    Object.keys(gamesDistribution).forEach((homeTeamName) => {
      const row: any = [];
      row.push(<ShadcnTableCell>{homeTeamName}</ShadcnTableCell>);
      Object.keys(gamesDistribution[homeTeamName]).forEach((awayTeamName) => {
        row.push(
          <ShadcnTableCell>
            <div>{gamesDistribution[homeTeamName][awayTeamName].count}</div>
          </ShadcnTableCell>
        );
      });
      rows.push(<ShadcnTableRow>{row}</ShadcnTableRow>);
    });
    return rows;
  };

  return (
    <Dialog
      open={open}
      maxWidth="xl"
      aria-labelledby="responsive-dialog-title"
      sx={{
        zIndex: 30,
      }}
    >
      <div className="flex flex-row md:justify-between">
        <DialogTitle
          sx={{
            paddingBottom: "0px",
          }}
        >
          <Headline2Variable>Auto Schedule</Headline2Variable>
          <Body1>
            Select the time slot and venue distribution for each week, for{" "}
            {numberOfTeams} teams over {numberOfWeeks} weeks.
          </Body1>
        </DialogTitle>
      </div>
      <DialogContent className="max-h-[65vh]">
        <div className="flex flex-col gap-3 mb-3 h-fit scroll-m-0">
          {error && (
            <Disclaimer
              variant="error"
              persist={true}
              title={error}
              size="small"
            />
          )}
          {template.map((temp, index) => {
            const dateValue = dayjs(temp.startDateTimeLocal, "HH:mm");
            const hour = dateValue.hour();
            return (
              <div className="flex gap-2">
                <div className="flex gap-1 w-1/2 min-w-1/2">
                  <FormFieldSelect
                    defaultValue="0"
                    inputChange={(value) => {
                      let newHour = value;
                      const newValue = dayjs(dateValue.hour(newHour)).format(
                        "HH:mm"
                      );
                      setTemplate((prev) => {
                        const newTemplate = [...prev];
                        newTemplate[index] = {
                          ...newTemplate[index],
                          startDateTimeLocal: newValue,
                        };
                        return newTemplate;
                      });
                    }}
                    name="hour"
                    label="Hour"
                    placeholder="hour"
                    value={hour.toString()}
                  >
                    {hours.map((h) => ({ id: h.toString(), name: h }))}
                  </FormFieldSelect>
                  <div className="pt-5">:</div>
                  <FormFieldSelect
                    defaultValue="0"
                    inputChange={(value) => {
                      let newMinute = value;
                      setTemplate((prev) => {
                        const newTemplate = [...prev];
                        newTemplate[index] = {
                          ...newTemplate[index],
                          startDateTimeLocal: dayjs(
                            dateValue.minute(newMinute)
                          ).format("HH:mm"),
                        };
                        return newTemplate;
                      });
                    }}
                    name="minutes"
                    label="Minutes"
                    placeholder="minutes"
                    value={dateValue.minute().toString()}
                  >
                    {minutes.map((h) => ({ id: h.toString(), name: h }))}
                  </FormFieldSelect>
                </div>
                <FormFieldSelect
                  inputChange={(value) => {
                    setTemplate((prev) => {
                      const newTemplate = [...prev];
                      newTemplate[index] = {
                        ...newTemplate[index],
                        venueId: value,
                      };
                      return newTemplate;
                    });
                  }}
                  label="Select venue"
                  placeholder="Venues"
                  value={temp.venueId}
                >
                  {venues}
                </FormFieldSelect>
              </div>
            );
          })}
          {loadingAutoSchedule && (
            <LoaderCircle className="animate-spin h-[50px] w-[50px] min-w-[25px] min-h-[25px] text-primary-70 m-auto" />
          )}
          {gamesByWeek.length !== 0 && !loadingAutoSchedule && (
            <div className="max-h-[70vh]">
              <div className="text-end mt-2">
                <Button
                  variant="primary"
                  onClick={() => {
                    handleSubmitAutoSchedule();
                  }}
                >
                  Regenerate
                </Button>
              </div>
              <div className="flex flex-col gap-2">
                <Subtitle1>Time Slot Distribution</Subtitle1>
                <Separator />
                <ShadcnTable>
                  <ShadcnTableHeader>
                    <ShadcnTableRow>
                      <ShadcnTableHead className="pl-0">
                        TimeSlots
                      </ShadcnTableHead>
                      {teams.map((team) => {
                        return (
                          <ShadcnTableHead className="pl-0">
                            {team.name}
                          </ShadcnTableHead>
                        );
                      })}
                    </ShadcnTableRow>
                  </ShadcnTableHeader>
                  <ShadcnTableBody className="text-center">
                    {createTimeDistributionRows()}
                  </ShadcnTableBody>
                </ShadcnTable>
              </div>
              <div className="flex flex-col gap-2">
                <Subtitle1>Games Distribution</Subtitle1>
                <Separator />
                <ShadcnTable>
                  <ShadcnTableHeader>
                    <ShadcnTableRow>
                      <ShadcnTableHead className="pl-0"></ShadcnTableHead>
                      {/* TEAM NAMES */}
                      {teams.map((team) => {
                        return (
                          <ShadcnTableHead className="pl-0">
                            {team.name}
                          </ShadcnTableHead>
                        );
                      })}
                    </ShadcnTableRow>
                  </ShadcnTableHeader>
                  <ShadcnTableBody className="text-center">
                    {createGamesDistributionRows()}
                  </ShadcnTableBody>
                </ShadcnTable>
              </div>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="secondary"
          onClick={() => onOpenChange(false)}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (gamesByWeek.length === 0) handleSubmitAutoSchedule();
            else onSubmitAutoSchedule(gamesByWeek);
          }}
        >
          {gamesByWeek.length === 0 ? "Generate Schedule" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AutoScheduleDialog;
