import React, { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../app/store';

interface Props {
  children: ReactNode;
}

// Will make it use interchangebly for page and even a component!
const ProtectedRoute: React.FC<Props> = ({ children }) => {
  const { token } = useSelector((state: RootState) => state.auth);
  let location = useLocation();

  if (!token) {
    return (
      <Navigate to={`/register`} state={{ from: location }} replace></Navigate>
    );
  }

  return <div>{children}</div>;
};

export default ProtectedRoute;
