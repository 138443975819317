import { gql } from "@apollo/client";

const CREATE_RULE = gql`
  mutation createRule($ruleArgs: CreateRuleInput!) {
    createRule(ruleArgs: $ruleArgs) {
      message
      code
      success
      rule {
        id
      }
    }
  }
`;

const UPDATE_RULE = gql`
  mutation updateRule($ruleArgs: UpdateRuleInput!) {
    updateRule(ruleArgs: $ruleArgs) {
      message
      code
      success
    }
  }
`;

const GET_RULE_BY_SPORT_AND_REGION_ID = gql`
  query getRuleBySportAndRegionId($sportId: Int!, $regionId: Int) {
    getRuleBySportAndRegionId(sportId: $sportId, regionId: $regionId) {
      id
      name
      rules
      sportId
      regionId
    }
  }
`;

const GET_RULE = gql`
  query getRulesAll {
    getRulesAll {
      id
      name
      regionId
      sportId
    }
  }
`;

const GET_RULE_PAGINATED = gql`
  query getRulesPaginated(
    $page: Int
    $pageSize: Int
    $sportId: Int
    $regionId: Int
  ) {
    getRulesPaginated(
      page: $page
      pageSize: $pageSize
      sportId: $sportId
      regionId: $regionId
    ) {
      count
      rules {
        id
        name
        regionId
        sportId
        sport {
          name
        }
        region {
          name
        }
      }
    }
  }
`;

const GET_RULE_BY_ID = gql`
  query getRuleById($id: Int!) {
    getRuleById(id: $id) {
      id
      name
      regionId
      sportId
      rules
      sport {
        name
      }
      region {
        name
      }
    }
  }
`;

export { GET_RULE };
