import { Regions, RequiredPermission, UserPermission } from "../types/types";

export enum EPermission {
  CREATE_SHIFT = "Shift_Create",
  EDIT_SHIFT = "Shift_Action",
  MANAGE_SHIFT_STAFF = "ShiftStaff_Management",
  VIEW_SHIFT_STAFF_PAYRATES = "ShiftStaff_PayRates_View",
  REFUND_APPROVAL = "RefundApproval",
  FINANCE_PAYROLL = "FinancePayroll",
}

// Match permission name only
export const isPermissionGranted = (
  currentPerm: UserPermission[] | null | undefined,
  requiredPerm: EPermission
): boolean => {
  if (!currentPerm) {
    return false;
  }

  let flag = false;

  for (let i = 0; i < currentPerm.length; i++) {
    if (currentPerm[i].permission.name === requiredPerm) {
      flag = true;
      break;
    }
  }

  return flag;
};

// return Regions in which user has permission
// return [] means No region
// return null means All region
// return [Regions] means specific regions
export const filterPermittedRegions = (
  currentPerm: UserPermission[] | null | undefined,
  requiredPerm: EPermission
): Regions[] | null => {
  if (!currentPerm) {
    return [];
  }

  let regions: any = [];

  for (let i = 0; i < currentPerm.length; i++) {
    if (currentPerm[i].permission.name === requiredPerm) {
      if (!currentPerm[i].region) {
        regions = null;
        break;
      }
      regions.push(currentPerm[i].region);
    }
  }
  return regions;
};

export const isPermitted = (
  currentPerm: UserPermission[] | null | undefined,
  requiredPerm: RequiredPermission | null | undefined
): boolean => {
  if (!currentPerm) {
    return false;
  }

  if (!requiredPerm) {
    return true;
  }

  const permittedRegion = filterPermittedRegions(
    currentPerm,
    requiredPerm.permission
  );

  let isPermissionGranted: boolean = false;

  if (permittedRegion === null) {
    isPermissionGranted = true;
  } else if (permittedRegion.length === 0) {
    isPermissionGranted = false;
  } else {
    permittedRegion?.some((region) => {
      return +region.id === requiredPerm.regionId;
    })
      ? (isPermissionGranted = true)
      : (isPermissionGranted = false);
  }

  return isPermissionGranted;
};

// Match permission name and RegionName/RegionId
// return true is any of the required permission is found
// export const isPermitted = (
//   currentPerm: UserPermission[] | null | undefined,
//   requiredPerm: RequiredPermission[] | null | undefined
// ): boolean => {
//   if (!currentPerm) {
//     return false;
//   }

//   if (!requiredPerm) {
//     return true;
//   }

//   let flag = false;
//   for (let i = 0; i < requiredPerm.length; i++) {
//     const required = requiredPerm[i];

//     const found = currentPerm.find((perm) => {
//       // if current region is null
//       // just permission names should match
//       if (!perm.region) {
//         return perm.permission.name === required.permission;
//       }

//       // if current region is not null
//       // regions should match and permission names should match
//       if (perm.region) {
//         return (
//           perm.permission.name === required.permission &&
//           perm.region.name === required.region
//         );
//       }

//       return false;
//     });

//     if (found) {
//       flag = true;
//       break;
//     }
//   }

//   return flag;
// };
