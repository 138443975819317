import { createContext, useContext, useState } from "react";

type GlobalContextType = {
  emailTemplateInstanceId: number | undefined;
  setEmailTemplateInstanceId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
};

const GlobalContextDefault: GlobalContextType = {
  emailTemplateInstanceId: undefined,
  setEmailTemplateInstanceId: () => {},
};

const GlobalContext = createContext<GlobalContextType>(GlobalContextDefault);

export const GlobalContextProvider = ({ children }: any) => {
  const [emailTemplateInstanceId, setEmailTemplateInstanceId] = useState<
    number | undefined
  >();

  const value = {
    emailTemplateInstanceId,
    setEmailTemplateInstanceId,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
