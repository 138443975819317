import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { loginUser, sendMFACode, verifyMFA } from "../app/authSlice";
import { AppDispatch, RootState } from "../app/store";
import FormRow from "../components/UI/FormRow";
import classes from "./Register.module.css";
import { Button } from "@mui/material";
import { LoadingMaterialUI } from "../components/UI";
import CheckBox from "../components/UI/Checkbox/Checkbox";
import { displayAlertError } from "../app/globalSlice";

interface UserCredential {
  email: string;
  password: string;
  rememberMe: boolean;
}

const initialState: UserCredential = {
  email: "",
  password: "",
  rememberMe: false,
};

const Register = () => {
  const [values, setValues] = useState(initialState);
  const dispatch = useDispatch<AppDispatch>();
  const { user, isLoading } = useSelector((state: RootState) => state.auth);
  const [mfaCode, setMfaCode] = useState("");
  const [showMfaInput, setShowMfaInput] = useState(false);
  const [showCommunicationMethod, setShowCommunicationMethod] = useState(false);
  const [communicationMethod, setCommunicationMethod] = useState("");
  const [communicationCredentials, setCommunicationCredentials] = useState({
    email: "",
    phoneNumber: "",
  });

  const navigate = useNavigate();
  const location = useLocation();

  const { from } = location.state || { from: { pathname: "/" } };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const submitUserCreds = (e: React.MouseEvent<HTMLButtonElement>) => {
    // e.preventDefault();
    const { email, password } = values;
    if (!email || !password) {
      dispatch(displayAlertError("Please fill out all fields"));
      return;
    }

    dispatch(loginUser({ email, password }))
      .then((result) => {
        if (result.payload?.status === "success") {
          setShowCommunicationMethod(true);
          setCommunicationCredentials(result.payload.user);
          // setShowMfaInput(true);
          return;
        }
        dispatch(displayAlertError(result.payload || "Something went wrong"));
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(displayAlertError("Something went wrong"));
      });
    return;

    // dispatch(registerUser({ firstName, lastName, email, password, regionId }));
    // return;
  };

  const submitCommunicationMethod = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    const { email, password } = values;

    if (communicationMethod !== "email" && communicationMethod !== "sms") {
      dispatch(displayAlertError("Please provide a delivery method"));
      return;
    }

    dispatch(sendMFACode({ email, password, communicationMethod }))
      .then((res) => {
        if (res.payload?.status === "success") {
          setShowCommunicationMethod(false);
          setShowMfaInput(true);
          // navigate(from);
          return;
        }
        dispatch(displayAlertError(res.payload || "Something went wrong"));
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(displayAlertError("Something went wrong"));
      });
  };

  const submitMfaCode = (e: React.MouseEvent<HTMLButtonElement>) => {
    // e.preventDefault();
    const { email, password, rememberMe } = values;

    if (!email || !password || !mfaCode) {
      dispatch(displayAlertError("Something went wrong. Please try again."));
      return;
    }

    dispatch(verifyMFA({ email, password, mfaCode, rememberMe }))
      .then((res) => {
        if (res.payload?.status === "success") {
          navigate(from);
          return;
        }
        dispatch(displayAlertError(res.payload || "Something went wrong"));
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(displayAlertError("Something went wrong"));
      });
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
    return () => {
      setShowMfaInput(false);
      setShowCommunicationMethod(false);
    };
  }, [user, navigate]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <LoadingMaterialUI />
      </div>
    );
  }

  if (showCommunicationMethod) {
    return (
      <main>
        <form className={classes.form}>
          <h3>{"Select your delivery method"}</h3>
          <p>
            Please select the method you would like to receive the verification
            code.
          </p>
          <div>
            {/* Radio button for choosing from email and SMS */}
            {communicationCredentials.email && (
              <div className="flex gap-4">
                <input
                  type="radio"
                  id="email"
                  name="communicationMethod"
                  value="email"
                  onChange={(e) => setCommunicationMethod(e.target.value)}
                />
                <label htmlFor="email">{`Email to ${communicationCredentials.email}`}</label>
              </div>
            )}
            {communicationCredentials.phoneNumber && (
              <div className="flex gap-4">
                <input
                  type="radio"
                  id="sms"
                  name="communicationMethod"
                  value="sms"
                  onChange={(e) => setCommunicationMethod(e.target.value)}
                />
                <label htmlFor="sms">{`SMS to ${communicationCredentials.phoneNumber}`}</label>
              </div>
            )}
          </div>
          <Button
            type="submit"
            className={classes.btn_login}
            disabled={isLoading}
            onClick={(e) => {
              e.preventDefault();
              submitCommunicationMethod(e);
            }}
            sx={{
              width: "350px",
              color: "var(--white)",
              bgcolor: "var(--primary-blue-500)",
              "&:hover": { bgcolor: "var(--primary-blue-700)" },
            }}
          >
            {"Submit"}
          </Button>
        </form>
      </main>
    );
  }

  if (showMfaInput) {
    return (
      <main>
        <form className={classes.form}>
          <h3>{"Verify"}</h3>
          <p>Please enter the 6 digit code sent out to you.</p>
          <FormRow
            name="mfaCode"
            value={mfaCode || ""}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMfaCode(e.target.value);
            }}
            labelText="Verification Code"
            width="400px"
            paddingBottom="0px"
            paddingRight="0px"
          />
          <Button
            type="submit"
            className={classes.btn_login}
            disabled={isLoading}
            onClick={(e) => submitMfaCode(e)}
            sx={{
              width: "350px",
              color: "var(--white)",
              bgcolor: "var(--primary-blue-500)",
              "&:hover": { bgcolor: "var(--primary-blue-700)" },
            }}
          >
            {"Submit"}
          </Button>
        </form>
      </main>
    );
  }

  return (
    <main>
      <form className="flex flex-col gap-5 items-center w-1/2 mt-[10%] mx-auto">
        <h3>{"Login"}</h3>
        <FormRow
          type="email"
          name="email"
          value={values.email}
          handleChange={handleChange}
          labelText="Email"
          width="400px"
          paddingBottom="0px"
          paddingRight="0px"
          autocomplete="email"
          placeholder="Email"
          id="email"
        />
        <FormRow
          type="password"
          name="password"
          value={values.password}
          handleChange={handleChange}
          labelText="Password"
          width="400px"
          paddingBottom="0px"
          paddingRight="0px"
          autocomplete="current-password"
          placeholder="Password"
          id="password"
        />
        <div>
          <CheckBox
            defaultChecked={values.rememberMe}
            label="Remember Me"
            inputChange={(val: boolean) => {
              setValues({
                ...values,
                rememberMe: val,
              });
            }}
          ></CheckBox>
        </div>
        <Button
          type="submit"
          className={classes.btn_login}
          disabled={isLoading}
          onClick={(e) => submitUserCreds(e)}
          sx={{
            width: "350px",
            color: "var(--white)",
            bgcolor: "var(--primary-blue-500)",
            "&:hover": { bgcolor: "var(--primary-blue-700)" },
          }}
        >
          Login
        </Button>
      </form>
    </main>
  );
};

export default Register;
