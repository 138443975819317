import { gql } from "@apollo/client";

const GET_STAFF_MEMBERS = gql`
  query StaffMembers {
    staffMembers {
      id
      lastName
      firstName
    }
  }
`;

const GET_USER = gql`
  mutation GetUser($jwt: String) {
    getUser(jwt: $jwt) {
      id
      email
      firstName
      lastName
      isSuperAdmin
      marketingEmail
      scoreReminderEmail
      gameReminderEmail
      registrationOpeningEmail
      sportSpecificEmail
      sportEmailIds
      permission {
        id
        permission {
          id
          name
        }
        userId
        region {
          id
          name
        }
      }
    }
  }
`;

const GET_USER_BY_EMAIL = gql`
  query GetUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      id
      email
      firstName
      lastName
    }
  }
`;

const GET_ALL_USERS = gql`
  query GetUsers($page: Int, $pageSize: Int, $userFilter: String) {
    getUsers(page: $page, pageSize: $pageSize, userFilter: $userFilter) {
      users {
        id
        birthDate
        email
        firstName
        genderIdentity {
          name
        }
        lastName
        phoneNumber
        lastLogin
        updatedAt
        createdAt
      }
      count
    }
  }
`;

const GET_USER_BY_ID = gql`
  query UserByIdGet($userByIdGetId: ID!) {
    userByIdGet(id: $userByIdGetId) {
      id
      email
      firstName
      lastName
      birthDate
      genderIdentity {
        id
        name
      }
      phoneNumber
      isAdmin
      isSuperAdmin
      isStaff
      createdAt
      updatedAt
      lastLogin
      permission {
        id
        permission {
          id
          name
        }
        userId
        region {
          id
          name
        }
      }
      privacyGender
      privacyPhoneNumber
      regionId
      marketingEmail
      scoreReminderEmail
      gameReminderEmail
      registrationOpeningEmail
      sportSpecificEmail
      sportEmailIds
    }
  }
`;

const GET_ALL_USERS_COUNT = gql`
  query GetUsersCount($userFilter: String) {
    getUsersCount(userFilter: $userFilter)
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($updateUserId: ID!, $user: UserInput) {
    updateUser(id: $updateUserId, user: $user) {
      id
      firstName
      lastName
      email
      birthDate
      createdAt
      updatedAt
      phoneNumber
      isStaff
      isSuperAdmin
      isAdmin
      lastLogin
      permission {
        id
        permission {
          id
          name
        }
        userId
        region {
          id
          name
        }
      }
      marketingEmail
      scoreReminderEmail
      gameReminderEmail
      registrationOpeningEmail
      sportSpecificEmail
      sportEmailIds
    }
  }
`;

const GET_PERMISSIONS = gql`
  query Permissions {
    permissions {
      name
      id
    }
  }
`;

const CREATE_PERMISSION = gql`
  mutation PermissionCreate($name: String!) {
    permissionCreate(name: $name) {
      code
      message
      permission {
        id
        name
      }
      success
    }
  }
`;

const GET_PRIVACY_SETTINGS = gql`
  query PrivacySettings {
    privacySettings {
      id
      name
    }
  }
`;

const UPDATE_USER_NOTIFICATION_SETTINGS = gql`
  mutation Mutation(
    $marketingEmail: Boolean
    $scoreReminderEmail: Boolean
    $gameReminderEmail: Boolean
    $registrationOpeningEmail: Boolean
    $sportSpecificEmail: Boolean
    $sportEmailIds: [Int]
    $updateNotificationSettingsId: ID!
  ) {
    updateNotificationSettings(
      marketingEmail: $marketingEmail
      scoreReminderEmail: $scoreReminderEmail
      gameReminderEmail: $gameReminderEmail
      registrationOpeningEmail: $registrationOpeningEmail
      sportSpecificEmail: $sportSpecificEmail
      sportEmailIds: $sportEmailIds
      id: $updateNotificationSettingsId
    ) {
      id
      email
      firstName
      lastName
      birthDate
      genderIdentity {
        id
        name
      }
      phoneNumber
      isAdmin
      isSuperAdmin
      isStaff
      createdAt
      updatedAt
      lastLogin
      permission {
        id
        permission {
          id
          name
        }
        userId
        region {
          id
          name
        }
      }
      privacyGender
      privacyPhoneNumber
      regionId
      marketingEmail
      scoreReminderEmail
      gameReminderEmail
      registrationOpeningEmail
      sportSpecificEmail
      sportEmailIds
    }
  }
`;

export {
  GET_STAFF_MEMBERS,
  GET_USER,
  GET_USER_BY_EMAIL,
  GET_ALL_USERS,
  GET_ALL_USERS_COUNT,
  GET_USER_BY_ID,
  UPDATE_USER,
  GET_PERMISSIONS,
  CREATE_PERMISSION,
  GET_PRIVACY_SETTINGS,
  UPDATE_USER_NOTIFICATION_SETTINGS,
};
