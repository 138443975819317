export const header = `
<!DOCTYPE html>
<html>
  <head>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1"
    />
    <meta
      name="color-scheme"
      content="light dark"
    />
    <meta
      name="supported-color-schemes"
      content="light dark"
    />
    <title>Email Template</title>
    <style type="text/css">
      body,
      table,
      td,
      a {
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
      }
      table,
      td {
        mso-table-lspace: 0pt;
        mso-table-rspace: 0pt;
        padding: 0px;
      }
      img {
        -ms-interpolation-mode: bicubic;
      }

      /* Reset Styles */
      img {
        border: 0;
        height: auto;
        line-height: 100%;
        outline: none;
        text-decoration: none;
      }
      table {
        border-collapse: collapse !important;
      }
      body {
        height: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        width: 100% !important;
        align-items: center;
        justify-content: center;
      }
      .mceStandardButton,
      .mceStandardButton td,
      .mceStandardButton td a {
        mso-hide: all !important;
      }

      /* iOS Blue Links */
      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: none !important;
        font-size: inherit !important;
        font-family: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
      }

      /* Media Queries */
      @media screen and (max-width: 600px) {
        .mobile-wrapper {
          width: 100% !important;
          padding: 0 !important;
        }
      }

      /* General Styles */
      body {
        font-family: 'Helvetica', Arial, sans-serif;
        font-size: 16px;
        color: #000000;
        background-color: #f0f4f8;
      }
      a {
        color: #1a0eab;
      }
      .darkmode-body {
        color: #000000;
        background-color: #ffffff;
      }
      .darkmode-header {
        background-color: #182958;
      }
      @media (prefers-color-scheme: dark) {
        .darkmode-header {
          background-color: #4c5d8d !important;
        }
        .darkmode-body {
          color: #ffffff !important;
          background-color: rgb(50, 50, 50) !important;
        }
        body {
          color: #ffffff !important;
        }
        a {
          color: #2b88ec !important;
        }
      }
    </style>
  </head>
  <body style="margin: 0; padding: 0; width: 100%; align-items: center">
    <table
      style="
        display: block;
        border-collapse: collapse;
        max-width: 600px;
        align-self: center;
        margin-left: auto;
        margin-right: auto;
      "
    >
      <tr>
        <td>
          <!-- Header -->
          <table
            style="width: 100%; display: block; border-collapse: collapse"
            class="darkmode-header"
          >
            <tr>
              <td
                style="height: 20px"
                colspan="3"
              >
                &nbsp;
              </td>
            </tr>
            <tr>
              <td style="height: 20px; width: 20px">&nbsp;</td>
              <td>
              <!--[if mso]>
              <img
                src="https://storage.googleapis.com/tempest_catchall/images/JAMsports_logo.png"
                alt="JAMsports Logo"
                width="120"
                height="auto"
                style="width: 120px; height: auto; display: block;"
              />
              <![endif]-->
              <!--[if !mso]>-->
              <img
                style="width: 120px; display: block"
                src="https://storage.googleapis.com/tempest_catchall/images/JAMsports_logo.png"
                alt="JAMsports Logo"
                border="0"
                width="120"
                height="auto"
              />
              <!--<![endif]-->
              </td>
              <td style="height: 20px; width: 20px">&nbsp;</td>
            </tr>
            <tr>
              <td
                style="height: 20px"
                colspan="3"
              >
                &nbsp;
              </td>
            </tr>
          </table>

          <!-- Body -->
          <table
            style="width: 600px"
            class="darkmode-body"
            width="600"
          >
            <tr>
              <td
                colspan="3"
                style="height: 20px; width: 20px"
              >
                &nbsp;
              </td>
            </tr>
            <tr>
              <td style="width: 20px">&nbsp;</td>

              <td valign="top">`;
