import React from 'react';
import Navbar from '../components/Home/Navbar';
import LeftNavDrawer from '../components/Home/LeftNavDrawer';
import classes from "./Home.module.css";

const Error = () => {
  return (
    <div className={classes.app}>
    <Navbar />
      {/* <Topbar /> */}
      <LeftNavDrawer/>
      <div className={classes.app__body}>
        <div className={classes.app__main}>
        <h3>Oh! Page not found...</h3>
        </div>
      </div>
    </div>
  );
};

export default Error;
