import React from "react";

const EventHome = () => {
  return (
    <>
      <h3>This is the OPS Event home page</h3>
    </>
  );
};

export default EventHome;
