import React, { useEffect, useState } from "react";
import classes from "./CreateShift.module.css";
import { FormRow, FormRowSelect, LoadingMaterialUI } from "../../UI";
import {
  getAllLocations,
  getStaffType,
  getAllStaffMembers,
  getEmailOptions,
  getThirdPartyContractors,
  addShift,
  getVenuesTree,
  getStaffTypeByRegion,
} from "../../../app/shiftSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Box, Modal, TextField, Button as MuiButton } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AppDispatch, RootState } from "../../../app/store";
import MultipleDatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import BreadCrumbs from "../../UI/Breadcrumbs/Breadcrumbs";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import Button from "../../UI/Button/Button";
import Card from "../../UI/Card/Card";
import Subtitle1 from "../../UI/Text/Subtitle/Subtitle1";
import FormField from "../../UI/FormField/FormField";
import FormFieldDropdown from "../../UI/FormField/FormFieldDropdown/FormFieldDropdown";
import FormFieldDateSelect from "../../UI/FormField/FormFieldDate/FormFieldDateSelect";
import TextArea from "../../UI/FormField/TextArea/TextArea";
import Subtitle2 from "../../UI/Text/Subtitle/Subtitle2";
import FormFieldDate from "../../UI/FormField/FormFieldDate/FormFieldDate";
import dayjs from "dayjs";
import {
  EPermission,
  filterPermittedRegions,
} from "../../../utils/permissions";
import { Regions } from "../../../types/types";
import { displayAlertError } from "../../../app/globalSlice";

const initialState = {
  locationId: 0,
  staffTypeId: 0,
  startDate: "",
  endDate: "",
  exclusionDateFields: [],
  startTime: new Date(),
  endTime: new Date(),
  isShiftOvernight: false,
  assignedStaffId: "0",
  contractorId: 1,
  isContractedShift: 0,
  emailChecked: [],
  rate: 0,
  notes: "",
  isVisible: true,
  regionId: "0",
};

export interface CreateShiftProps {
  requiredPermission?: EPermission;
}

const CreateShift: React.FC<CreateShiftProps> = ({ requiredPermission }) => {
  const [values, setValues] = useState<any>(initialState);
  const [openResetModal, setOpenResetModal] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const {
    locationsOptions,
    staffTypeOptions,
    staffMembersOptions,
    thirdPartyContractorOptions,
    emailOptions,
    isLoading,
    venues,
  } = useSelector((state: RootState) => state.shift);
  const { selectedRegions }: any = useSelector(
    (state: RootState) => state.venueMaster
  );
  const [permittedRegions, setPermittedRegions] = useState<any>([]);
  const { user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (!locationsOptions.length) {
      dispatch(getAllLocations());
    }
    // if (!staffTypeOptions.length) {
    //   dispatch(getStaffType());
    // }
    if (!staffMembersOptions.length) {
      dispatch(getAllStaffMembers());
    }
    if (!thirdPartyContractorOptions.length) {
      dispatch(getThirdPartyContractors());
    }
  }, [dispatch, selectedRegions]);

  useEffect(() => {
    return () => {
      setValues(initialState);
    };
  }, []);

  useEffect(() => {
    if (!requiredPermission) {
      setPermittedRegions(selectedRegions);
      return;
    }
    // set Permitted Regions
    const permittedRegion = filterPermittedRegions(
      user?.permission,
      requiredPermission
    );
    if (permittedRegion === null) {
      setPermittedRegions(selectedRegions);
      return;
    }
    if (permittedRegion?.length === 0) {
      // Navigate to Permission Denied
      return;
    }
    const displayedRegion: Regions[] = selectedRegions.filter((region: any) =>
      permittedRegion?.find(
        (regionPermitted: any) => regionPermitted.id === region.id
      )
    );
    setPermittedRegions(displayedRegion);
  }, [dispatch, requiredPermission, selectedRegions]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: +e.target.value || e.target.value,
    });
  };

  const handleStaffTypeChange = ({
    name,
    value,
  }: {
    name: string;
    value: string;
  }) => {
    let baseRate;
    let isContractedShift;
    for (let i = 0; i < staffTypeOptions.length; i++) {
      if (+staffTypeOptions[i].id === +value) {
        baseRate = staffTypeOptions[i].baseRate;
        isContractedShift = staffTypeOptions[i].isContractor;
      }
    }
    setValues({
      ...values,
      rate: baseRate || 0,
      isContractedShift: isContractedShift ? 1 : 0,
      [name]: value,
    });
  };

  const handleStartTimeChange = (date: Date) => {
    // const startTime = moment(date).format('HH:mm');
    // console.log(startTime);
    setValues({ ...values, startTime: date });
  };

  const handleEndTimeChange = (date: Date) => {
    // const endTime = moment(date).format('HH:mm');
    // console.log(endTime);
    setValues({ ...values, endTime: date });
  };

  const handleEmailChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    let updatedList: any = [...values.emailChecked];
    if (e.target.checked) {
      updatedList = [...updatedList, e.target.value];
    } else {
      updatedList.splice(values.emailChecked.indexOf(e.target.value), 1);
    }
    setValues({ ...values, emailChecked: updatedList });
  };

  const handleResetShiftModal = () => {
    setValues(initialState);
    setOpenResetModal(!openResetModal);
  };

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const {
      locationId,
      staffTypeId,
      startDate,
      endDate,
      startTime,
      endTime,
      isShiftOvernight,
      regionId,
    } = values;

    if (regionId == 0) {
      dispatch(displayAlertError("Choose a region"));
      return;
    }

    if (!locationId) {
      dispatch(displayAlertError("Choose a venue"));
      return;
    }

    if (!staffTypeId) {
      dispatch(displayAlertError("Choose a Staff Type"));
      return;
    }

    if (!Date.parse(startDate)) {
      dispatch(displayAlertError("Please enter valid start Date"));
      return;
    }

    if (!Date.parse(endDate)) {
      dispatch(displayAlertError("Please enter valid end Date"));
      return;
    }

    if (startDate > endDate) {
      dispatch(displayAlertError(`End Date can not be before Start Date`));
      return;
    }

    if (
      !moment(startTime, "HH:mm").isValid() ||
      !moment(endTime, "HH:mm").isValid()
    ) {
      dispatch(displayAlertError("Entered timings are incorrect"));
      return;
    }

    if (startTime > endTime && !isShiftOvernight) {
      dispatch(
        displayAlertError(
          `End Time can't be before Start Time, check "Shift Extends Overnight" box!`
        )
      );
      return;
    }

    dispatch(addShift({ ...values, adminId: "" })).then(() => {
      setOpenResetModal(!openResetModal);
    });
  };

  if (isLoading) {
    return (
      <div>
        <LoadingMaterialUI />
      </div>
    );
  }

  const pathsBreadcrumbs = [
    { name: "All Shifts", url: "/staffing/all-shifts" },
  ];

  return (
    <main>
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/staffing/all-shifts"
      ></BreadCrumbs>
      <form className={classes.form}>
        <div className="flex flex-row justify-between w-full mt-6">
          <Headline1Variable>{"Create Staff Shifts"}</Headline1Variable>
          <div className="h-10">
            <Button
              variant="primary"
              type="submit"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => onSubmit(e)}
            >
              <span> Create New Shifts</span>
            </Button>
          </div>
        </div>

        <Card className="mt-6">
          <div className="flex flex-col">
            <Subtitle2>Venue Details</Subtitle2>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <FormFieldDropdown
                name="regionId"
                label="Region"
                placeholder="Region"
                initialValue={
                  values.regionId ? values.regionId.toString() : "0"
                }
                value={values.regionId}
                inputChange={(value: string) => {
                  setValues({
                    ...values,
                    regionId: +value,
                  });
                  dispatch(getVenuesTree({ regionId: +value }));
                  dispatch(getStaffTypeByRegion(+value));
                }}
              >
                {[...permittedRegions, ...[{ id: 0, name: "Select Region" }]]}
              </FormFieldDropdown>
              <FormFieldDropdown
                name="locationId"
                label="Venue"
                placeholder="Venue"
                initialValue={
                  values.locationId ? values.locationId.toString() : "0"
                }
                value={values.locationId}
                inputChange={(value: string) => {
                  setValues({
                    ...values,
                    locationId: +value,
                  });
                }}
              >
                {[
                  { id: 0, name: "Select Venue (Choose Region first)" },
                  ...venues,
                ]}
              </FormFieldDropdown>
              {/* <div>
            <FormRowSelect
              name="staffTypeId"
              value={values.staffTypeId}
              labelText="Shift Staff Type"
              list={[
                { id: 0, name: 'Select Staff Type (Choose Region first)' },
                ...staffTypeOptions,
              ]}
              handleChange={handleStaffTypeChange}
            />
          </div> */}
            </div>
          </div>
        </Card>
        <Card className="mt-6">
          <Subtitle2>Date and Timings</Subtitle2>
          <div className="flex flex-col gap-2">
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <FormFieldDate
                label="Start Date"
                name="startDate"
                initialValue={
                  values.startDate
                    ? dayjs(values.startDate).toDate()
                    : undefined
                }
                dateChange={(date) => {
                  setValues({
                    ...values,
                    startDate: moment(date).format("YYYY-MM-DD"),
                  });
                }}
              />
              {/* <FormFieldDateSelect
                initialValue={
                  values.startDate
                    ? moment(values.startDate).format('YYYY-MM-DD')
                    : null
                }
                inputChange={(e) => {
                  setValues({
                    ...values,
                    startDate: moment(e.target.value).format('YYYY-MM-DD'),
                  });
                }}
                name="startDate"
                label="Start Date"
              /> */}
              <FormFieldDate
                label="End Date"
                name="endDate"
                initialValue={
                  values.endDate ? dayjs(values.endDate).toDate() : undefined
                }
                dateChange={(date) => {
                  setValues({
                    ...values,
                    endDate: moment(date).format("YYYY-MM-DD"),
                  });
                }}
              />
              {/* <FormFieldDateSelect
                initialValue={
                  values.endDate
                    ? moment(values.endDate).format('YYYY-MM-DD')
                    : null
                }
                inputChange={(e) => {
                  setValues({
                    ...values,
                    endDate: moment(e.target.value).format('YYYY-MM-DD'),
                  });
                }}
                name="endDate"
                label="End Date"
              /> */}
            </div>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <div>
                <label className={`px-3 text-xs font-medium text-black`}>
                  {`Start Time`}
                </label>
                <DatePicker
                  // wrapperClassName={classes.datepicker_date}
                  className={classes["my-custom-input"]}
                  name="startTime"
                  selected={values.startTime}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={5}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  onChange={(date: Date) => {
                    handleStartTimeChange(date);
                  }}
                />
              </div>
              <div>
                <label className={`px-3 text-xs font-medium text-black`}>
                  {`End Time`}
                </label>
                <DatePicker
                  // wrapperClassName={classes['datepicker_date']}
                  className={classes["my-custom-input"]}
                  name="endTime"
                  selected={values.endTime}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={5}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  onChange={(date: Date) => {
                    handleEndTimeChange(date);
                  }}
                />
              </div>
            </div>
            <div className="flex items-center gap-6 align-middle">
              <input
                // className={classes.form__input}
                defaultChecked={values.isShiftOvernight}
                type="checkbox"
                id="isShiftOvernight"
                name="isShiftOvernight"
                onChange={() => {
                  setValues({
                    ...values,
                    isShiftOvernight: !values.isShiftOvernight,
                  });
                }}
              />
              <label
                // className={classes.form__label}
                htmlFor="isShiftOvernight"
              >
                Shift Extends Overnight
              </label>
            </div>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <div className="md:col-span-2">
                <div className={`flex flex-col w-full text-black`}>
                  <div className={`px-3 text-xs font-medium`}>
                    Exclusion Dates
                  </div>
                  <MultipleDatePicker
                    multiple
                    plugins={[<DatePanel />]}
                    inputClass={classes["multi-DatePicker-input"]}
                    containerClassName={classes["multi-DatePicker-container"]}
                    value={values.exclusionDateFields}
                    name="exclusionDateFields"
                    //onChange throws Date as DateObject only
                    onChange={(selectedDates: DateObject[]) => {
                      if (Array.isArray(selectedDates)) {
                        const dates = selectedDates?.map((date) => {
                          return new Date(date.format()).toISOString();
                        });
                        setValues({ ...values, exclusionDateFields: dates });
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Card className="mt-6">
          <Subtitle2>Staff Details</Subtitle2>
          <div className="flex flex-col gap-2">
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <FormFieldDropdown
                name="staffTypeId"
                label="Staff Type"
                placeholder="Staff Type"
                initialValue={
                  values.staffTypeId ? values.staffTypeId.toString() : "0"
                }
                value={values.staffTypeId}
                inputChange={(value: string) => {
                  handleStaffTypeChange({
                    name: "staffTypeId",
                    value,
                  });
                }}
              >
                {[
                  { id: 0, name: "Select Staff Type (Choose Region first)" },
                  ...staffTypeOptions,
                ]}
              </FormFieldDropdown>
              {!values.isContractedShift && (
                <FormFieldDropdown
                  name="assignedStaffId"
                  label="Assign Staff"
                  placeholder="Assign Staff"
                  initialValue={
                    values.assignedStaffId
                      ? values.assignedStaffId.toString()
                      : "0"
                  }
                  value={values.assignedStaffId}
                  inputChange={(value: string) => {
                    setValues({
                      ...values,
                      assignedStaffId: value,
                    });
                  }}
                >
                  {[{ id: 0, name: "Open" }, ...staffMembersOptions]}
                </FormFieldDropdown>
              )}
              {values.isContractedShift === 1 ? (
                <FormFieldDropdown
                  name="contractorId"
                  label="Third Party Contractor"
                  placeholder="Third Party Contractor"
                  initialValue={
                    values.contractorId ? values.contractorId.toString() : "0"
                  }
                  value={values.contractorId}
                  inputChange={(value: string) => {
                    setValues({
                      ...values,
                      contractorId: +value,
                    });
                  }}
                >
                  {[{ id: 0, name: "Open" }, ...thirdPartyContractorOptions]}
                </FormFieldDropdown>
              ) : (
                ""
              )}
              <FormField
                name="rate"
                label="Hourly Rate ($)"
                initialValue={values.rate ? values.rate : 0}
                value={values.rate}
                inputChange={(e) => {
                  setValues({
                    ...values,
                    rate: +e.target.value,
                  });
                }}
              ></FormField>
            </div>
          </div>
        </Card>
        <Card className="mt-6">
          <TextArea
            className="mb-4"
            name="notes"
            label="Notes"
            inputChange={handleChange}
            value={values.notes}
            initialValue={values.notes ? values.notes : ""}
            rows={8}
            cols={50}
          ></TextArea>
          {/* <div className="flex items-center gap-6 align-middle">
            <input
              // className={classes.form__input}
              defaultChecked={values.isVisible}
              type="checkbox"
              id="isVisible"
              name="isVisible"
              onChange={() => {
                setValues({ ...values, isVisible: !values.isVisible });
              }}
            />
            <label
              // className={classes.form__label}
              htmlFor="isVisible"
            >
              Visible to LAs
            </label>
          </div> */}
        </Card>
        <div className={classes.form__center}>
          {/* <div className={classes.notify}>
            <label htmlFor="admin">Who do you want to notify?</label>
            <p>The LA (if assigned) will be automatically notified.</p>
            {emailOptions.map((email: any, index: number) => {
              return (
                <label key={index} htmlFor="admin">
                  <input
                    type="checkbox"
                    value={email.Email}
                    onChange={handleEmailChecked}
                  />
                  <span>{email.FirstName}</span>
                </label>
              );
            })}
          </div> */}
          <div className="flex flex-row-reverse gap-3 mt-5">
            <Button
              variant="primary"
              type="submit"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => onSubmit(e)}
            >
              <span> Create New Shifts</span>
            </Button>
            {/* <MuiButton
              variant="contained"
              type="submit"
              className={classes.btn__submit}
              disabled={isLoading}
              sx={{
                color: 'var(--primary-blue-500)',
                bgcolor: 'var(--primary-red-500)',
                '&:hover': { bgcolor: 'var(--primary-red-600)' },
                marginTop: '1rem',
              }}
            >
              Create Shift and Notify Checked Users
            </MuiButton> */}
          </div>
        </div>
      </form>
      <Modal
        open={openResetModal}
        onClose={() => setOpenResetModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className={classes.btn__container__modal}>
            <Button
              variant="primary"
              onClick={handleResetShiftModal}
            >
              New Blank Shift
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                setOpenResetModal(false);
              }}
            >
              Pre-populate Shift
            </Button>
          </div>
        </Box>
      </Modal>
    </main>
  );
};

export default CreateShift;
