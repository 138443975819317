import { cn } from '../../../../lib/utils';
import React, { ReactNode } from 'react';

interface Headline2Props {
  // The text inside the Headline
  children: ReactNode;
  // The size of headline
  size: 'xl-m' | 's-xs';
  // Extra css customization
  className?: string;
}

const Headline2 = ({ children, size, className, ...props }: Headline2Props) => {
  if (size === 'xl-m') {
    return (
      <p
        className={cn(
          'text-txt-1 font-[600] text-[25px] tracking-[0.0025em] leading-[1.5]',
          className
        )}
      >
        {children}
      </p>
    );
  } else {
    return (
      <p
        className={cn(
          'text-txt-1 font-[500] text-[20px] tracking-[0.0015em] leading-[1.5]',
          className
        )}
      >
        {children}
      </p>
    );
  }
};

export default Headline2;
