import React, { useEffect, useMemo, useState } from "react";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTable, useSortBy, Column } from "react-table";
import moment from "moment";
import Link1 from "../UI/Text/Link/Link1";
import TablePagination from "../UI/Pagination/Pagination";
import { LoadingMaterialUI } from "../UI";
import ActionsCell from "../UI/ActionCell";
import FormField from "../UI/FormField/FormField";
import { Pagination, Users } from "../../types/types";
import Button from "../UI/Button/Button";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_USERS } from "../../graphql/queries/user";

const options = [{ id: 1, text: "View" }];
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const Players = () => {
  const publicUrl = process.env.PUBLIC_URL;
  //Pagination Page Size
  const pageSize = 50;
  const { isLoadingUserList }: any = useSelector(
    (state: RootState) => state.cx
  );
  const navigate = useNavigate();

  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    pageSize: pageSize,
    pageCount: 1,
  });
  const [userFilter, setUserFilter] = useState<string | null>(null);
  const [usersListAutoComplete, setUsersListAutoComplete] = useState<Users[]>(
    []
  );
  const [userList, setUserList] = useState<Users[]>([]);

  const handleChangePagination = (value: number) => {
    setPagination({
      ...pagination,
      page: value - 1,
    });
  };

  const [
    getUserList,
    { data: userListData, loading: userListLoading, error: userListError },
  ] = useLazyQuery(GET_ALL_USERS, {
    onCompleted: (data) => {
      const pageCount = Math.ceil(data.getUsers.count / pageSize);
      setPagination((prevState: Pagination) => {
        return {
          ...prevState,
          pageCount: pageCount,
        };
      });
      setUserList(data.getUsers.users);
      setUsersListAutoComplete([]);
    },
    fetchPolicy: "no-cache",
  });
  const [
    getUserAutoCompleteList,
    {
      data: userAutoCompleteListData,
      loading: userAutoCompleteListLoading,
      error: userAutoCompleteListError,
    },
  ] = useLazyQuery(GET_ALL_USERS, {
    onCompleted: (data) => {
      setUsersListAutoComplete(data.getUsers.users);
    },
    fetchPolicy: "no-cache",
  });

  function resetPage() {
    setUserFilter("");
    getUserList({
      variables: {
        userFilter: "",
        page: pagination.page,
        pageSize: pagination.pageSize,
      },
    });
  }

  useEffect(() => {
    getUserList({
      variables: {
        userFilter: userFilter,
        page: pagination.page,
        pageSize: pagination.pageSize,
      },
    });
  }, []);

  useEffect(() => {
    getUserList({
      variables: {
        userFilter: userFilter,
        page: pagination.page,
        pageSize: pagination.pageSize,
      },
    });
  }, [pagination.page, pagination.pageSize]);

  useEffect(() => {
    getUserAutoCompleteList({
      variables: {
        userFilter: userFilter,
        page: null,
        pageSize: null,
      },
    });
    if (userFilter && emailRegex.test(userFilter)) {
      getUserList({
        variables: {
          userFilter: userFilter,
          page: pagination.page,
          pageSize: pagination.pageSize,
        },
      });
    }
  }, [userFilter]);

  const handleSelectedOption = (row: any, idx: number) => {
    if (idx === 1) {
      navigate(`${publicUrl}/players/user/${row.values.id}`, {
        state: { vendorId: row.values.id },
      });
    }
  };

  const COLUMNS: Column<Users>[] = [
    {
      Header: "id#",
      accessor: "id",
    },
    {
      Header: "Name",
      id: "name",
      accessor: (d: any) => {
        return (
          <IconButton
            onClick={() => {
              navigate(`${publicUrl}/players/user/${d.id}`);
            }}
          >
            <Link1>{`${d["firstName"]} ${d["lastName"]}`}</Link1>
          </IconButton>
        );
      },
    },
    {
      Header: "Email",
      id: "email",
      accessor: (d: any) => {
        return <div className="truncate text-nowrap">{d.email}</div>;
      },
    },
    {
      Header: "Phone Number",
      id: "phoneNumber",
      accessor: (d: any) => {
        return <div className="truncate">{d.phoneNumber}</div>;
      },
    },
    {
      Header: "Gender",
      id: "gender",
      accessor: (d: any) => {
        return <div>{d.genderIdentity?.name}</div>;
      },
    },
    {
      Header: "Last Login",
      id: "lastLogin",
      accessor: (d: any) => {
        return (
          <div>
            {d.lastLogin
              ? moment(d.lastLogin).local().format("YYYY-MM-DD")
              : ""}
          </div>
        );
      },
    },
    {
      Header: "Created",
      id: "createdAt",
      accessor: (d: any) => {
        return <div>{moment(d.createdAt).local().format("YYYY-MM-DD")}</div>;
      },
    },
    // {
    //   Header: 'Last Edit',
    //   id: 'updatedAt',
    //   accessor: (d: any) => {
    //     return <div>{moment(d.updatedAt).local().format('YYYY-MM-DD')}</div>;
    //   },
    // },
    {
      Header: " ",
      Cell: (data: any) => {
        return (
          <ActionsCell
            row={data.row}
            options={options}
            handleSelectedOption={handleSelectedOption}
          />
        );
      },
    },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => {
    return userList;
  }, [userList]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columns,
        data: data,
        initialState: {
          hiddenColumns: ["id"],
        },
      },
      useSortBy
    );
  const usersTable = (
    <TableContainer
      sx={{
        maxHeight: "80vh",
        width: "95vw",
        maxWidth: "100%",
        bgcolor: "white",
        padding: "20px",
        paddingTop: "0px",
        borderRadius: "12px",
      }}
    >
      <Table
        stickyHeader
        aria-label="sticky table"
        {...getTableProps()}
      >
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  sx={{
                    bgcolor: "white",
                    color: "var(--textColor)",
                    fontSize: "16px",
                    fontWeight: 550,
                    borderColor: "var(--table-border)",
                    textAlign: "left",
                    paddingBottom: "5px",
                  }}
                  {...column.getHeaderProps()}
                >
                  <div>{column.render("Header")}</div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow
                hover
                sx={{
                  borderBottom: "5px",
                  borderWidth: "2px",
                }}
                role="checkbox"
                tabIndex={-1}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      sx={{
                        textAlign: "left",
                        fontSize: "15px",
                        color: "var(--textColor)",
                        borderColor: "var(--table-border)",
                        paddingBottom: "5px",
                        paddingTop: "5px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: "200px",
                      }}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <div className="flex justify-end mt-4">
        <TablePagination
          page={pagination.page ?? 0}
          pageCount={pagination.pageCount ?? 0}
          onChange={handleChangePagination}
        />
      </div>
    </TableContainer>
  );

  return (
    <main>
      <div className="flex flex-row justify-between w-full mb-4">
        <Headline1Variable>Player Search</Headline1Variable>
        <div className="flex flex-row items-end h-full gap-4">
          <div className="w-96 h-[90%]">
            <FormField
              initialValue={userFilter || ""}
              inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length > 2) setUserFilter(e.target.value);
                else setUserFilter(null);
              }}
              onKeyDown={(event) => {
                if (
                  usersListAutoComplete.length > 0 &&
                  userFilter &&
                  event.key === "Enter"
                ) {
                  getUserList({
                    variables: {
                      userFilter: userFilter,
                      page: pagination.page,
                      pageSize: pagination.pageSize,
                    },
                  });
                  setUsersListAutoComplete([]);
                }
              }}
              name="search"
              placeholder="Search"
              label="Search by Email/Phone Number"
              width="full"
            ></FormField>
            {usersListAutoComplete.length > 0 && userFilter ? (
              <div className="absolute z-10 w-96">
                <div className="max-w-md p-4 bg-white border rounded-lg shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                  <div className="flow-root">
                    <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                      {usersListAutoComplete.map((e: any) => (
                        <li
                          className="py-3 cursor-pointer sm:py-2"
                          onClick={() => {
                            setUserFilter(e.email);

                            setUsersListAutoComplete([]);
                          }}
                        >
                          <div className="flex items-center space-x-4 rounded-md">
                            <div className="flex-1 min-w-0">
                              <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                {e.firstName} {e.lastName}
                              </p>
                              <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                {e.email} - {e.phoneNumber}
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <Button
            className="h-[90%] mb-1"
            variant="primary"
            onClick={() => {
              getUserList({
                variables: {
                  userFilter: userFilter,
                  page: pagination.page,
                  pageSize: pagination.pageSize,
                },
              });
              setUsersListAutoComplete([]);
            }}
          >
            <span> Search</span>
          </Button>
          <Button
            className="h-[90%] mb-1"
            variant="secondary"
            onClick={resetPage}
          >
            <span>Clear All</span>
          </Button>
        </div>
      </div>
      {isLoadingUserList && <LoadingMaterialUI />}
      {!isLoadingUserList && (
        <>
          {userList.length > 0 && <div>{usersTable}</div>}
          {!(userList.length > 0) && <div>No Data Found</div>}
        </>
      )}
    </main>
  );
};

export default Players;
