import React, {ReactNode} from "react";

interface Link1Props {
    // The text inside the Headline
    children: ReactNode;
    // Extra css customization
    className?: string;
}


const Link1 = ({
    children,
    className,
    ...props
  }: Link1Props) => {
    return <p className={"text-link underline font-[500] text-[16px] tracking-[0.005em] leading-[1.6]" + className}>{children}</p>
}

export default Link1