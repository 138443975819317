"use client";

import React, { FC, HTMLProps, useEffect, useState } from "react";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";
import { cn } from "../../../lib/utils";

interface FormFieldControlledProps extends HTMLProps<HTMLInputElement> {
  /**
   * The placeholder for the formfieldControlled
   */
  placeholder?: string;
  /**
   * The label for the formfieldControlled (text above the field)
   */
  label?: string;
  /**
   * The assitive text for the field (text under the field)
   */
  assistiveText?: string;
  /**
   * Set to true if the input should be disabled.
   */
  disabled?: boolean;
  /**
   * Set to true if the input should be showing an error state
   */
  error?: boolean;
  /**
   * The function that is called whenever the input changes
   */
  onChange: (data: any) => void;
  /**
   * The value of the component
   */
  value: string;
  /**
   * Custom classname attribtues
   */
  className?: string;
}

const FormFieldControlled: FC<FormFieldControlledProps> = ({
  placeholder,
  label,
  assistiveText,
  disabled,
  error,
  onChange,
  value,
  className,
  ...props
}: FormFieldControlledProps) => {
  const mode = disabled
    ? "border-2 border-neutral-60 bg-neutral-90 focus:outline-none"
    : error
    ? "border border-error-30 hover:border-error-30 focus:outline-2 focus:outline-error-30"
    : "border border-secondary-80 hover:border-secondary-50 focus:outline-2 focus:outline-primary-80";

  return (
    <div className={`flex flex-col w-full text-black`}>
      {label && (
        <label
          className={`px-3 text-xs font-medium ${
            error && !disabled && "text-error-10"
          }`}
        >
          {label}
        </label>
      )}
      <div className="relative flex flex-row items-center gap-2">
        <input
          disabled={disabled}
          placeholder={placeholder}
          className={cn(
            "w-full font-normal rounded-xl px-3 py-2",
            disabled
              ? "border-2 border-neutral-60 bg-neutral-90 focus:outline-none"
              : error
              ? "border border-error-30 hover:border-error-30 focus:outline-2 focus:outline-error-30"
              : "border border-secondary-80 hover:border-secondary-50 focus:outline-2 focus:outline-primary-80",
            className
          )}
          {...props}
          onChange={onChange}
          value={value}
          onWheel={(e) => e.currentTarget.blur()}
        />
        {/* The error icon will appear to the right of the input if it's in an error state */}
        {error && (
          <ErrorOutlineOutlinedIcon className="absolute right-2 text-error-30" />
        )}
      </div>
      {assistiveText && (
        <div
          className={`px-3 text-xxs font-normal ${
            error && !disabled ? "text-error-10" : "text-light"
          }`}
        >
          {assistiveText}
        </div>
      )}
    </div>
  );
};

export default FormFieldControlled;
