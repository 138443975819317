import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const FinancePromoCodesHome = () => {
  return (
    <main>
      <h3>This is the FINANCE Promo Codes home page</h3>
      <div style={{ display: "flex", gap: "1rem" }}></div>
    </main>
  );
};

export default FinancePromoCodesHome;
