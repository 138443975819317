import { cn } from '../../../../lib/utils';
import React, { ReactNode } from 'react';

interface Body2Props {
  // The text inside the Headline
  children: ReactNode;
  // Extra css customization
  className?: string;
}

const Body2 = ({ children, className, ...props }: Body2Props) => {
  return (
    <p
      className={cn(
        'text-txt-1 font-[400] text-[14px] tracking-[0.0025em] leading-[1.6]',
        className
      )}
    >
      {children}
    </p>
  );
};

export default Body2;
