import { gql } from "@apollo/client";

const GET_CAPACITY_INFO = gql`
  query VenueWiseCapacity($venueWiseCapacityArgs: VenueWiseCapacityArgs) {
    venueWiseCapacity(venueWiseCapacityArgs: $venueWiseCapacityArgs) {
      parentVenue
      venueInPlay
      venueSize
      capacity
    }
  }
`;

const CREATE_CAPACITY_GROUP = gql`
  mutation CapacityGroupCreate($capacityGroupArgs: CreateCapacityGroupInput!) {
    capacityGroupCreate(capacityGroupArgs: $capacityGroupArgs) {
      code
      success
      message
      capacityGroup {
        id
        name
      }
    }
  }
`;

const GET_CAPACITY_GROUPS = gql`
  query CapacityGroups {
    capacityGroups {
      id
      name
      sessions {
        venues {
          id
          name
        }
        id
        dayOfWeek
        startDate
        endDate
      }
    }
  }
`;

const GET_CG_TO_DIVISION = gql`
  query CapacityGroup($capacityGroupId: ID!) {
    capacityGroup(id: $capacityGroupId) {
      id
      name
      sessions {
        divisions {
          id
          name
          vmColor
          numberOfWeeks
        }
        league {
          gameTimeslot
          gameDuration
        }
        setCapacity
      }
    }
  }
`;

const GET_CAPACITY_GROUP_OVERVIEW = gql`
  query CapacityGroupOverview(
    $capacityGroupOverviewFilters: CapacityGroupOverviewFilters
  ) {
    capacityGroupOverview(
      capacityGroupOverviewFilters: $capacityGroupOverviewFilters
    ) {
      id
      name
      parentId
      overview
    }
  }
`;

export {
  GET_CAPACITY_INFO,
  CREATE_CAPACITY_GROUP,
  GET_CAPACITY_GROUPS,
  GET_CG_TO_DIVISION,
  GET_CAPACITY_GROUP_OVERVIEW,
};
