"use client";

import React, { FC, HTMLProps, useEffect, useState } from "react";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";
import { cn } from "../../../lib/utils";

interface FormFieldProps extends HTMLProps<HTMLInputElement> {
  /**
   * The placeholder for the formfield
   */
  placeholder?: string;
  /**
   * The label for the formfield (text above the field)
   */
  label?: string;
  /**
   * The assitive text for the field (text under the field)
   */
  assistiveText?: string;
  /**
   * Set to true if the input should be disabled.
   */
  disabled?: boolean;
  /**
   * Set to true if the input should be showing an error state
   */
  error?: boolean;
  /**
   * The function that is called whenever the input changes
   */
  inputChange: (data: any) => void;
  /**
   * An intitial value for the form to have
   */
  initialValue?: string;
  /**
   * Custom classname attribtues
   */
  className?: string;

  visibility?: string;
  type?: string;
  /**
   *  The minimum value for the input
   */
  min?: number;
  /**
   *  The maximum value for the input
   */
  max?: number;
}

const FormField: FC<FormFieldProps> = ({
  placeholder,
  label,
  assistiveText,
  disabled,
  error,
  inputChange,
  initialValue,
  className,
  visibility,
  type = "string",
  min,
  max,
  ...props
}: FormFieldProps) => {
  const [inputVal, setInputVal] = useState(initialValue ? initialValue : "");

  useEffect(() => {
    setInputVal(initialValue ? initialValue : "");
  }, [initialValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    inputChange(e);
    setInputVal(e.target.value);
  };

  return (
    <div
      className={`flex flex-col w-full text-black ${
        visibility === "hidden" ? "invisible" : "visible"
      }`}
    >
      {label && (
        <label
          className={`px-3 text-xs font-medium ${
            error && !disabled && "text-error-10"
          }`}
        >
          {label}
        </label>
      )}
      <div className="relative flex flex-row items-center gap-2">
        <input
          type={type === "currency" ? "number" : type}
          step={type === "currency" ? 0.01 : undefined}
          disabled={disabled}
          placeholder={placeholder}
          className={cn(
            "w-full font-normal rounded-xl px-3 py-2",
            disabled
              ? "border-2 border-neutral-60 bg-neutral-90 focus:outline-none"
              : error
              ? "border border-error-30 hover:border-error-30 focus:outline-2 focus:outline-error-30"
              : "border border-secondary-80 hover:border-secondary-50 focus:outline-2 focus:outline-primary-80",
            className
          )}
          {...props}
          min={min || 0}
          max={max}
          value={inputVal}
          onChange={handleChange}
          onWheel={(e) => e.currentTarget.blur()}
        />
        {/* The error icon will appear to the right of the input if it's in an error state */}
        {error && (
          <ErrorOutlineOutlinedIcon className="absolute right-2 text-error-30" />
        )}
      </div>
      {assistiveText && (
        <div
          className={`px-3 text-xxs font-normal ${
            error && !disabled ? "text-error-10" : "text-light"
          }`}
        >
          {assistiveText}
        </div>
      )}
    </div>
  );
};

export default FormField;
