import { gql } from "@apollo/client";

const GET_ALL_DAY_OF_WEEK = gql`
  query DayOfWeek {
    dayOfWeek {
      id
      name
    }
  }
`;

export { GET_ALL_DAY_OF_WEEK };
