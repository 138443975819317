import { gql } from "@apollo/client";

const UPDATE_PLAYOFF_GAMES = gql`
  mutation UpdatePlayoffGames(
    $playoffGameTeamsInput: [PlayoffGameTeamsInput]!
  ) {
    updatePlayoffGames(playoffGameTeamsInput: $playoffGameTeamsInput) {
      code
      success
      message
    }
  }
`;

const UPDATE_GAME = gql`
  mutation UpdateGame($updateGameId: Int!, $gameInput: UpdateGameInput!) {
    updateGame(id: $updateGameId, gameInput: $gameInput) {
      code
      message
      success
    }
  }
`;

const GET_GAMES_WITH_PENDING_SCORES = gql`
  query GetGamesWithPendingScores(
    $gameScoreReportInput: GameScoreReportInput!
  ) {
    getGamesWithPendingScores(gameScoreReportInput: $gameScoreReportInput) {
      gameReportData {
        id
        homeTeam {
          id
          name
        }
        awayTeam {
          id
          name
        }
        venue {
          name
        }
        startDateTimeLocal
        latestScore {
          id
          homeScore
          awayScore
          scoreStatus {
            name
            id
          }
          submittingTeamId
          submittingUser {
            firstName
            lastName
          }
          dateSubmitted
          confirmedByUser {
            firstName
            lastName
          }
          dateConfirmed
        }
      }
      count
    }
  }
`;

const GET_GAMES_WITH_FINAL_SCORES = gql`
  query GetGamesWithFinalScores($gameScoreReportInput: GameScoreReportInput!) {
    getGamesWithFinalScores(gameScoreReportInput: $gameScoreReportInput) {
      gameReportData {
        id
        homeTeam {
          id
          name
        }
        awayTeam {
          id
          name
        }
        venue {
          name
        }
        startDateTimeLocal
        latestScore {
          id
          homeScore
          awayScore
          scoreStatus {
            name
            id
          }
          submittingTeamId
          submittingUser {
            firstName
            lastName
          }
          dateSubmitted
          confirmedByUser {
            firstName
            lastName
          }
          dateConfirmed
        }
      }
      count
    }
  }
`;

const GET_GAMES_WITH_NO_SCORES = gql`
  query GetGamesWithNoScores($gameScoreReportInput: GameScoreReportInput!) {
    getGamesWithNoScores(gameScoreReportInput: $gameScoreReportInput) {
      gameReportData {
        id
        homeTeam {
          id
          name
        }
        awayTeam {
          id
          name
        }
        venue {
          name
        }
        startDateTimeLocal
      }
      count
    }
  }
`;

const GET_GAMES_SCORE_HISTORY = gql`
  query Game($id: Int!) {
    game(id: $id) {
      id
      venue {
        name
      }
      reportedScores {
        id
        gameId
        homeScore
        awayScore
        submittingUserId
        scoreStatus {
          id
          name
        }
        scoreStatusId
        dateSubmitted
        dateConfirmed
        isWinByDefault
        submittingTeamId
        confirmedByUserId
        submittingUser {
          id
          firstName
          lastName
        }
        submittingTeam {
          id
          name
        }
        confirmedByUser {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

const GET_GAMES_WITH_SYSTEM_GENERATED_SCORES = gql`
  query GetGamesWithSystemGeneratedScores(
    $gameScoreReportInput: GameScoreReportInput!
  ) {
    getGamesWithSystemGeneratedScores(
      gameScoreReportInput: $gameScoreReportInput
    ) {
      count
      gameReportData {
        id
        homeTeam {
          id
          name
        }
        awayTeam {
          id
          name
        }
        startDateTimeLocal
        latestScore {
          id
          homeScore
          awayScore
          scoreStatus {
            name
            id
          }
          submittingTeamId
          submittingUser {
            firstName
            lastName
          }
          dateSubmitted
          confirmedByUser {
            firstName
            lastName
          }
          dateConfirmed
        }
      }
    }
  }
`;

const GET_GAMES_WITH_ADMIN_OVERRIDE_SCORES = gql`
  query GetGamesWithAdminOverrideScores(
    $gameScoreReportInput: GameScoreReportInput!
  ) {
    getGamesWithAdminOverrideScores(
      gameScoreReportInput: $gameScoreReportInput
    ) {
      count
      gameReportData {
        id
        homeTeam {
          id
          name
        }
        awayTeam {
          id
          name
        }
        startDateTimeLocal
        latestScore {
          id
          homeScore
          awayScore
          scoreStatus {
            name
            id
          }
          submittingTeamId
          submittingUser {
            firstName
            lastName
          }
          dateSubmitted
          confirmedByUser {
            firstName
            lastName
          }
          dateConfirmed
        }
      }
    }
  }
`;

const DELETE_GAME = gql`
  mutation DeleteGame($id: Int!) {
    deleteGame(id: $id) {
      code
      message
      success
    }
  }
`;

export {
  UPDATE_PLAYOFF_GAMES,
  UPDATE_GAME,
  GET_GAMES_WITH_FINAL_SCORES,
  GET_GAMES_WITH_PENDING_SCORES,
  GET_GAMES_WITH_NO_SCORES,
  GET_GAMES_SCORE_HISTORY,
  GET_GAMES_WITH_SYSTEM_GENERATED_SCORES,
  GET_GAMES_WITH_ADMIN_OVERRIDE_SCORES,
  DELETE_GAME,
};
