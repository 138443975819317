import { gql } from "@apollo/client";

const GET_CONTENT_CATEGORIES = gql`
  query ContentCategories {
    contentCategories {
      id
      name
    }
  }
`;

const GET_CONTENT_BY_CATEGORY_ID = gql`
  query Content($category: ID!, $selectedRegions: [Int]!) {
    content(category: $category, selectedRegions: $selectedRegions) {
      code
      content {
        content
        contentCategory {
          id
          name
        }
        categoryId
        id
        isDefault
        region {
          id
          name
        }
        regionId
        title
      }
      success
      message
    }
  }
`;

const CREATE_CONTENT = gql`
  mutation ContentCreate($contentArgs: CreateContentInput!) {
    contentCreate(contentArgs: $contentArgs) {
      code
      content {
        id
        regionId
        title
        content
        region {
          id
          name
          abbreviation
        }
        contentCategory {
          id
          name
        }
      }
      message
      success
    }
  }
`;

const UPDATE_CONTENT = gql`
  mutation ContentUpdate($contentArgs: UpdateContentInput!) {
    contentUpdate(contentArgs: $contentArgs) {
      code
      success
      message
      content {
        id
        regionId
        title
        content
        region {
          id
          name
          abbreviation
        }
        contentCategory {
          id
          name
        }
        categoryId
        isDefault
      }
    }
  }
`;

export {
  GET_CONTENT_CATEGORIES,
  GET_CONTENT_BY_CATEGORY_ID,
  CREATE_CONTENT,
  UPDATE_CONTENT,
};
