import { gql } from "@apollo/client";

const GET_DATE_GROUPED_SHIFTS = gql`
  query AllShiftsGrouped($shiftFilter: ShiftFilter) {
    allShifts(shiftFilter: $shiftFilter) {
      date
      shifts {
        id
        endLocal
        startLocal
        rate
        userId
        locationId
        staffTypeId
        regionId
        shiftStatusId
        shiftPaymentStatusId
        region {
          id
          name
        }
        venue {
          id
          name
        }
        staffType {
          id
          name
        }
        shiftStatus {
          id
          value
        }
        user {
          id
          firstName
          lastName
          email
        }
        shiftNotes {
          id
          note
        }
        shiftReport {
          id
          shiftReportStatusId
          userId
          contents
          shiftId
          departure
          arrival
          createdAt
        }
      }
    }
  }
`;

const CREATE_SHIFT = gql`
  mutation ShiftCreate($createShiftInput: CreateShiftInput) {
    shiftCreate(createShiftInput: $createShiftInput) {
      code
      message
      shift {
        id
      }
      success
    }
  }
`;

const UPDATE_SHIFT = gql`
  mutation ShiftUpdate($updateShiftInput: UpdateShiftInput) {
    shiftUpdate(updateShiftInput: $updateShiftInput) {
      code
      message
      success
    }
  }
`;

const DELETE_SHIFT = gql`
  mutation ShiftDelete($shiftDeleteId: Int) {
    shiftDelete(id: $shiftDeleteId) {
      code
      message
      shift {
        id
      }
      success
    }
  }
`;

const GET_OPEN_SHIFTS = gql`
  query OpenShifts($openShiftsFilter: OpenShiftFilter) {
    openShifts(openShiftsFilter: $openShiftsFilter) {
      countRequestedShifts
      league {
        name
      }
      openShift {
        id
        staffType {
          name
        }
        startLocal
        endLocal
        hours
        shiftNotes {
          id
          note
        }
      }
      location {
        id
        name
      }
    }
  }
`;

const ASSIGN_SHIFT = gql`
  mutation ShiftAssign($assignShiftInput: AssignShiftInput) {
    shiftAssign(assignShiftInput: $assignShiftInput) {
      code
      message
      success
      shift {
        id
      }
    }
  }
`;

const MY_SHIFT = gql`
  query MyShifts {
    myShifts
  }
`;

const UPDATE_SHIFT_STATUS = gql`
  mutation ShiftStatusUpdate($shiftId: ID!, $shiftStatusId: ID!) {
    shiftStatusUpdate(shiftId: $shiftId, shiftStatusId: $shiftStatusId) {
      code
      message
      shiftStatus {
        id
      }
      success
    }
  }
`;

const GET_SHIFTS_NOT_PAID = gql`
  query ShiftsNotPaid {
    shiftsNotPaid
  }
`;

const UPDATE_SHIFTS_PAID = gql`
  mutation ShiftsPaidUpdate($shiftsId: [ID]) {
    shiftsPaidUpdate(shiftsId: $shiftsId) {
      code
      message
      success
    }
  }
`;

const GET_SHIFTS_BEFORE_CUTOFF_DATE = gql`
  query ShiftsBeforeCutoffDate(
    $date: String
    $shiftPaymentStatusId: String
    $regionIds: [Int]
    $regionId: String
    $page: Int
    $pageSize: Int
  ) {
    shiftsBeforeCutoffDate(
      date: $date
      shiftPaymentStatusId: $shiftPaymentStatusId
      regionIds: $regionIds
      regionId: $regionId
      page: $page
      pageSize: $pageSize
    ) {
      shifts {
        id
        startLocal
        endLocal
        hours
        amount
        contractorId
        isVisible
        locationId
        rate
        region {
          id
          name
        }
        shiftReport {
          shiftReportStatusId
        }
        shiftStatus {
          id
          value
        }
        venue {
          id
          name
        }
        weekday
        user {
          id
          firstName
          lastName
        }
        staffType {
          id
          name
        }
        payrollBatch {
          payrollBatchStatus {
            id
            name
          }
        }
      }
      count
    }
  }
`;

const GET_SHIFT_PAYMENT_STATUSES = gql`
  query ShiftPaymentStatuses {
    shiftPaymentStatuses {
      id
      name
    }
  }
`;

const SHIFTS_READY_FOR_PAYMENT = gql`
  mutation ShiftsReadyForPayment($shiftsId: [ID]) {
    shiftsReadyForPayment(shiftsId: $shiftsId) {
      code
      message
      success
    }
  }
`;

const SHIFTS_MARKED_AS_PAID = gql`
  mutation ShiftsMarkedAsPaid($shiftsId: [ID], $payrollBatchId: String) {
    shiftsMarkedAsPaid(shiftsId: $shiftsId, payrollBatchId: $payrollBatchId) {
      code
      success
      message
    }
  }
`;

const SHIFTS_SUMMARY = gql`
  query ShiftsSummary($shiftsId: [ID]) {
    shiftsSummary(shiftsId: $shiftsId) {
      code
      message
      shiftSummary {
        numOfShifts
        totalAmount
        totalHours
      }
      success
    }
  }
`;

export {
  CREATE_SHIFT,
  UPDATE_SHIFT,
  DELETE_SHIFT,
  ASSIGN_SHIFT,
  GET_SHIFTS_NOT_PAID,
  UPDATE_SHIFTS_PAID,
  GET_DATE_GROUPED_SHIFTS,
  GET_OPEN_SHIFTS,
  MY_SHIFT,
  UPDATE_SHIFT_STATUS,
  GET_SHIFTS_BEFORE_CUTOFF_DATE,
  GET_SHIFT_PAYMENT_STATUSES,
  SHIFTS_READY_FOR_PAYMENT,
  SHIFTS_MARKED_AS_PAID,
  SHIFTS_SUMMARY,
};
