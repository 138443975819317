export const emailButton = (link: string, buttonText: string) => `
<table
  align="center"
  border="0"
  cellpadding="0"
  cellspacing="0"
  role="presentation"
>
  <!-- Outlook-specific button (VML) -->
  <!--[if mso]>
    <tr>
      <td align="center">
        <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="${link}" style="height:56px;v-text-anchor:middle;width:195px;" arcsize="4%" stroke="f" fillcolor="#182958">
          <v:stroke color="#ffffff" weight="3px" />
          <w:anchorlock/>
          <center style="color:#ffffff;font-family:sans-serif;font-size:16px;font-weight:bold;letter-spacing:1px;">${buttonText}</center>
        </v:roundrect>
      </td>
    </tr>
  <![endif]-->
  <!-- Non-Outlook button -->
  <!--[if !mso]>-->
    <tr class="mceStandardButton">
      <td style="background-color: #182958; border-radius: 8px; text-align: center;" valign="top">
        <a href="${link}" target="_blank" style="background-color: #182958; border: 3px solid #ffffff; border-radius: 8px; color: #ffffff; display: block; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: bold; padding: 16px 28px; text-decoration: none; min-width: 30px; text-align: center; direction: ltr; letter-spacing: 1px;">${buttonText}</a>
      </td>
    </tr>
  <!--<![endif]-->
</table>`;
