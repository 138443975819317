"use client";

import React, { FC, useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import * as Checkbox from "@radix-ui/react-checkbox";
import { cn } from "../../../lib/utils";
import { Loader2 } from "lucide-react";

interface CheckboxProps {
  /**
   * Should the box be checked by default?
   */
  defaultChecked?: boolean;
  /**
   * Optional label for the checkbox
   */
  label?: string;
  /**
   * Handles passing the state of the checkbox to it's parent component
   */
  inputChange: (val: boolean) => void;
  id?: string;
  error?: boolean;
  className?: string;
  checkedState?: boolean | undefined;
  /**
   * Classname options for the label
   */
  labelClassname?: string;
  /**
   * Optionally replace the checkbox with a loading icon
   */
  loading?: boolean;
}

const CheckBox: FC<CheckboxProps> = ({
  id = "c1",
  defaultChecked = false,
  label,
  error,
  inputChange,
  className,
  checkedState = undefined,
  labelClassname,
  loading = false,
  ...props
}: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(defaultChecked);

  useEffect(() => {
    setIsChecked(defaultChecked);
  }, [defaultChecked]);

  return (
    <div className="flex items-center">
      {loading ? (
        <Loader2 className="animate-spin h-[25px] w-[25px] min-w-[25px] min-h-[25px] text-primary-50" />
      ) : (
        <Checkbox.Root
          className={cn(
            "hover:bg-secondary-opacity-08 flex h-[25px] w-[25px] min-w-[25px] min-h-[25px] border-2 border-neutral-20 appearance-none items-center justify-center rounded-sm bg-white outline-none focus:outline-none data-[state=checked]:bg-primary-50 data-[state=checked]:border-none transition-all duration-200 ease-in-out cursor-pointer",
            error && "border-error-30",
            className
          )}
          defaultChecked={defaultChecked}
          onCheckedChange={(check: any) => {
            inputChange(check);
            setIsChecked(check);
          }}
          id={id}
          checked={checkedState === undefined ? isChecked : checkedState}
        >
          <Checkbox.Indicator className="text-white">
            <CheckIcon />
          </Checkbox.Indicator>
        </Checkbox.Root>
      )}

      {!!label && (
        <label
          className={cn(
            "text-base font-normal tracking-wide pl-4 cursor-pointer",
            labelClassname
          )}
          htmlFor={id}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default CheckBox;
