import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface ActionMenuProps {
  row: any;
  options: { id: number; text: string }[];
  handleSelectedOption: (event: any, id: number) => void;
  disabled?: boolean;
}

const ActionsCell: React.FC<ActionMenuProps> = ({
  row,
  options,
  handleSelectedOption,
  disabled = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        disabled={disabled}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem
            key={option.id}
            onClick={(event) => {
              handleSelectedOption(row, option.id);
            }}
          >
            <Typography textAlign="center">{option.text}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ActionsCell;
