import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import {
  CapacityGroup,
  ContractDetails,
  Gameslot,
  Division,
  GenderFormat,
  GenderIdentity,
  League,
  Leaguetype,
  OfficiatedStatus,
  Regions,
  RegistrationBatch,
  Session,
  Sport,
  SportFormat,
  StandingPolicy,
  TieBreakPolicy,
  Vendor,
  VendorType,
  Venue,
  VenueSize,
  VenueTypes,
  CapacityGroupToDivision,
  ShoppingCart,
  Registration,
  Rule,
  State,
  Country,
  LegalDocument,
  RegistrationReport,
  ScheduleStatus,
  GameStatus,
  Schedule,
  RegistrationType,
  TeamPlayerRole,
  RegSkillChoice,
  Contract,
  ScoreStatus,
} from "../types/types";
import { parseISO } from "date-fns";
import moment from "moment";
import { client } from "../graphql";
import {
  GET_GENDER_FORMAT,
  GET_LEAGUE_TYPES,
  GET_REGIONS,
  GET_SPORTS,
  GET_STANDING_POLICIES,
  GET_TIE_BREAK_POLICIES,
  GET_OFFICIATED_STATUSES,
  GET_SPORTS_FORMAT,
  GET_SPORT_FORMAT_BY_ID,
  GET_VENUES,
  GET_VENUE_TYPE,
  GET_LEAGUES,
  GET_REGISTRATION_BATCH,
  GET_REGISTRATION_REPORT,
  GET_SESSIONS,
  GET_REGISTRATION_BATCH_BY_ID,
  GET_LEAGUE_BY_ID,
  GET_SESSION_BY_ID,
  GET_REGISTRATION_REPORT_COUNT,
  GET_REGISTRATION_TYPES,
} from "../graphql/queries";
import { GET_VENUE_SIZE } from "../graphql/queries/venueSize";
import {
  GET_SESSIONS_FOR_CAPACITY_GROUP,
  GET_SESSIONS_FOR_DIVISION,
} from "../graphql/queries/session";
import {
  CREATE_CAPACITY_GROUP,
  GET_CAPACITY_GROUPS,
  GET_CAPACITY_GROUP_OVERVIEW,
  GET_CAPACITY_INFO,
  GET_CG_TO_DIVISION,
} from "../graphql/queries/capacityInfo";
import {
  GET_GAMESLOTS,
  GET_GAMESLOT_BY_ID,
  UPDATE_GAMESLOT,
} from "../graphql/queries/gameslot";
import {
  GET_VENUES_BY_VENDORID,
  GET_VENUES_COUNT,
  GET_VENUES_HIERARCHY,
  GET_VENUES_MANAGEMENT,
  GET_VENUE_BY_ID,
  GET_VENUES_TREE,
  GET_VENUE_OVERVIEW,
} from "../graphql/queries/venues";
import {
  CREATE_DIVISION,
  DELETE_DIVISION,
  GET_DIVISIONS,
  GET_DIVISION_BY_ID,
  GET_DIVISION_SCHEDULE_BY_ID,
  UPDATE_DIVISION,
} from "../graphql/queries/divisions";
import { GET_SHOPPING_CARTS } from "../graphql/queries/shoppingCart";
import {
  GET_ALL_REGISTRATION_TYPES,
  GET_FREE_AGENT_REG_BY_SESSIONID,
} from "../graphql/queries/registration";
import {
  CREATE_CONTRACT,
  GET_CONTRACTS,
  GET_CONTRACTS_COUNT,
  GET_CONTRACT_BY_ID,
  UPDATE_CONTRACT,
} from "../graphql/queries/contract";
import {
  GET_VENDORS,
  GET_VENDOR_BY_ID,
  GET_VENDORS_COUNT,
  GET_VENDOR_TYPES,
} from "../graphql/queries/vendor";
import dayjs from "dayjs";
import axios from "axios";
import { GET_RULE } from "../graphql/queries/rule";
import {
  GET_COUNTRIES,
  GET_STATES,
  GET_STATES_BY_COUNTRY_ID,
} from "../graphql/queries/geography";
import {
  CREATE_LEGAL_DOCUMENT,
  GET_LEGAL_DOCUMENT_BY_REGION_AND_TYPE,
} from "../graphql/queries/legalDocument";
import { multipartFetch } from "./authSlice";
import {
  GET_SCHEDULE_BY_ID,
  GET_SCHEDULE_STATUSES,
} from "../graphql/queries/schedule";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { GET_GAME_STATUSES } from "../graphql/queries/gameStatus";
import { CHECK_REPEATED_TEAM_NAMES } from "../graphql/queries/team";
import { GET_ALL_GENDER_IDENTITY } from "../graphql/queries/genderIdentity";
import { GET_ALL_DAY_OF_WEEK } from "../graphql/queries/dayOfWeek";
import { GET_ALL_TEAM_PLAYER_ROLES } from "../graphql/queries/team";
import { GET_REG_SKILL_CHOICES } from "../graphql/queries/regSkillChoice";
import { toast as toastStackable } from "sonner";
import { GET_SCORE_STATUSES } from "../graphql/queries/scores";
import {
  RegSkillChoicesDocument,
  useScheduleStatusesLazyQuery,
} from "../generated/graphql";

export enum DayOfWeek {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

interface VenueOverviewFilter {
  startDate: string;
  endDate: string;
  regionId: number;
  day: number;
  typeId: number;
}

interface SportFormatFilter {
  regions: [number] | null;
  sportId: number | null;
  genderFormatId: number | null;
  officiatedStatusId: number | null;
}

type DayOfWeekType = {
  id: number;
  name: string;
};

export interface SessionFilter {
  regions: number | null;
  sportId: number | null;
  leagueId: number | null;
  genderFormatId: number | null;
  officiatedStatusId: number | null;
  dayOfWeek: number | null;
  registrationBatchId: number | null;
}

export interface LeagueFilter {
  regionId: number;
  sportId: number;
  genderFormatId: number;
}

interface CapacityGroupFilter {
  dayOfWeek: number;
  venueTypeId: number;
  venueSizeId: number;
}

interface CapacityInfo {
  parentVenue: string;
  venueInPlay: string;
  venueSize: string;
  capacity: string;
}

interface GameslotFilter {
  venueId: number | null;
  capacityGroupId: number | null;
}

interface InitialStateVenueMaster {
  isLoading: boolean;
  isLoadingSchedule: boolean;
  isEditContract: boolean;
  isEditVenue: boolean;
  vendorList: Vendor[];
  vendorListCount: number;
  vendorTypes: VendorType[];
  venueList: Venue[];
  vendor: Vendor | {};
  venue: Venue | {};
  venueListManagement: Venue[];
  venueListCount: number;
  contract: Contract | {};
  // From Backend
  // define type for contractList
  contractList: any[];
  contractListCount: number;
  isEditSession: boolean;
  venueOverviewFilter: VenueOverviewFilter;
  venueTypes: VenueTypes[];
  venueOverview: [];
  regions: Regions[];
  selectedRegions: Regions[];
  leagueTypes: Leaguetype[];
  genderFormat: GenderFormat[];
  genderIdentity: GenderIdentity[];
  sports: Sport[];
  standingPolicies: StandingPolicy[];
  tieBreakPolicies: TieBreakPolicy[];
  officiatedStatuses: OfficiatedStatus[];
  sportsFormatFilter: SportFormatFilter;
  sportsFormat: SportFormat[];
  sportsFormatCount: number;
  sportFormat: SportFormat | {};
  venueSizes: VenueSize[];
  venues: Venue[];
  leagues: League[];
  registrationBatches: RegistrationBatch[];
  registrationBatchesCount: number;
  registrationReport: RegistrationReport[];
  registrationReportCount: number;
  sessionFilter: SessionFilter;
  sessions: Session[];
  registrationBatch: RegistrationBatch | {};
  league: League | {};
  session: Session | {};
  sessionVenues: Venue[];
  capacityGroupFilters: CapacityGroupFilter;
  sessionsForCapacityGroup: [];
  capacityInfo: CapacityInfo[];
  gameslots: Gameslot[];
  gameslot: Gameslot | {};
  gameslotFilter: GameslotFilter;
  capacityGroups: CapacityGroup[];
  venuesHierarchy: Venue[] | [];
  divisions: Division[];
  divisionListCount: number;
  sessionsForDivision: [];
  divisionToEdit: Division | {};
  divisionScheduleToEdit: Division | {};
  divisionsForCapacityGroup: CapacityGroupToDivision | {};
  capacityGroupOverview: [];
  shoppingCarts: ShoppingCart[];
  shoppingCartsCount: number;
  freeAgentsRegBySession: Registration | [];
  rule: Rule | {};
  rules: Rule[];
  states: State[];
  countries: Country[];
  imageUpload: string | null;
  legalDocument: Partial<LegalDocument> | {};
  scheduleStatuses: ScheduleStatus[];
  gameStatuses: GameStatus[];
  scheduleById: Schedule | {};
  repeatedTeamNames: string[] | [];
  dayOfWeek: DayOfWeekType[];
  teamPlayerRoles: TeamPlayerRole[];
  registrationTypes: RegistrationType[];
  regSkillChoices: RegSkillChoice[];
  scoreStatuses: ScoreStatus[];
}

const initialContractState: Contract = {
  id: 0,
  regionId: 0,
  vendorId: 0,
  typeId: 0,
  permitStatusId: 0,
  approvalStatusId: 0,

  approverId: "",
  name: "",
  grossCost: 0,
  taxes: 0,
  notes: "",
  attachments: "",

  contractItems: [
    {
      venue: 0,
      day: 1,
      startDate: new Date(),
      endDate: new Date(),
      startTime: new Date(),
      endTime: new Date(),
      exclusionDates: [],
    },
  ],
};

const initialVenueTypes: VenueTypes = {
  id: 0,
  name: "",
};

const initialVenueSize: VenueSize = {
  id: 0,
  name: "",
};

const initialRule: Rule = {
  id: 0,
  name: "",
  sportId: 0,
  regionId: 0,
};

const initialState: InitialStateVenueMaster = {
  isLoading: false,
  isLoadingSchedule: false,
  isEditContract: false,
  isEditVenue: false,
  vendorList: [],
  vendorListCount: 0,
  vendorTypes: [],
  vendor: {},
  venue: {},
  venueList: [],
  contractList: [],
  contractListCount: 0,
  contract: initialContractState,
  isEditSession: false,
  venueOverviewFilter: {
    startDate: dayjs().format("YYYY-MM-DD"),
    endDate: dayjs().add(7, "days").format("YYYY-MM-DD"),
    day: 1,
    regionId: 1,
    typeId: 0,
  },
  venueTypes: [initialVenueTypes],
  venueOverview: [],
  regions: [],
  selectedRegions: [],
  leagueTypes: [],
  genderFormat: [],
  sports: [],
  standingPolicies: [],
  tieBreakPolicies: [],
  officiatedStatuses: [],
  sportsFormatFilter: {
    regions: null,
    sportId: null,
    officiatedStatusId: null,
    genderFormatId: null,
  },
  sportsFormat: [],
  sportsFormatCount: 0,
  sportFormat: {},
  venues: [],
  venueListManagement: [],
  venueListCount: 0,
  leagues: [],
  registrationBatches: [],
  registrationBatchesCount: 0,
  registrationReport: [],
  registrationReportCount: 0,
  sessionFilter: {
    dayOfWeek: null,
    genderFormatId: null,
    leagueId: null,
    officiatedStatusId: null,
    regions: null,
    registrationBatchId: null,
    sportId: null,
  },
  sessions: [],
  registrationBatch: {},
  league: {},
  session: {},
  sessionVenues: [],
  venueSizes: [initialVenueSize],
  capacityGroupFilters: {
    dayOfWeek: 7,
    venueTypeId: 0,
    venueSizeId: 0,
  },
  sessionsForCapacityGroup: [],
  capacityInfo: [],
  gameslots: [],
  gameslot: {},
  gameslotFilter: {
    venueId: null,
    capacityGroupId: null,
  },
  capacityGroups: [],
  venuesHierarchy: [],
  divisions: [],
  divisionListCount: 0,
  sessionsForDivision: [],
  divisionToEdit: {},
  divisionScheduleToEdit: {},
  divisionsForCapacityGroup: {},
  capacityGroupOverview: [],
  shoppingCarts: [],
  shoppingCartsCount: 0,
  freeAgentsRegBySession: [],
  rule: {},
  rules: [initialRule],
  states: [],
  countries: [],
  imageUpload: null,
  legalDocument: {},
  scheduleStatuses: [],
  gameStatuses: [],
  scheduleById: {},
  repeatedTeamNames: [],
  genderIdentity: [],
  dayOfWeek: [],
  teamPlayerRoles: [],
  registrationTypes: [],
  regSkillChoices: [],
  scoreStatuses: [],
};

export const baseURL = `${
  process.env.REACT_APP_ENV === "production"
    ? "https://admin.jamsports.com"
    : process.env.REACT_APP_ENV === "staging"
    ? "https://admin.jamitall.com"
    : "http://localhost:3001"
}/api/v1`;

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z$/;

function isIsoDateString(value: any): boolean {
  return value && typeof value === "string" && isoDateFormat.test(value);
}

export function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== "object")
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) {
      body[key] = parseISO(value);
    } else if (typeof value === "object") {
      handleDates(value);
    }
  }
}

// authFetch.interceptors.response.use((originalResponse) => {
//   handleDates(originalResponse.data);
//   return originalResponse;
// });

axios.interceptors.response.use((originalResponse) => {
  handleDates(originalResponse.data);
  return originalResponse;
});

// Get Vendors
export const getVendors = createAsyncThunk(
  "venueMaster/getVendors",
  async (args: any, thunkAPI) => {
    try {
      const { page, pageSize } = args;
      const response: any = await client.query({
        query: GET_VENDORS,
        variables: {
          vendorFilters: {
            vendorTypeId: null,
          },
          page,
          pageSize,
        },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error: any) => error.message).join(", ")
        );
      }
      const vendors = response.data.vendors;
      return vendors;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Vendor By Id
export const getVendor = createAsyncThunk(
  "venueMaster/getVendor",
  async (args: any, thunkAPI) => {
    try {
      const vendorId = args;
      const response: any = await client.query({
        query: GET_VENDOR_BY_ID,
        variables: {
          vendorByIdId: vendorId,
        },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error: any) => error.message).join(", ")
        );
      }
      const vendors = response.data.vendorById;
      return vendors;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Vendor Types
export const getVendorTypes = createAsyncThunk(
  "venueMaster/getVendorTypes",
  async (_, thunkAPI) => {
    try {
      const response: any = await client.query({
        query: GET_VENDOR_TYPES,
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error: any) => error.message).join(", ")
        );
      }

      const vendorTypes = response.data.vendorTypes;
      return vendorTypes;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Vendors Count
export const getVendorsCount = createAsyncThunk(
  "venueMaster/getVendorsCount",
  async (_, thunkAPI) => {
    try {
      const response: any = await client.query({
        query: GET_VENDORS_COUNT,
        variables: {
          vendorFilters: {
            vendorTypeId: null,
          },
        },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error: any) => error.message).join(", ")
        );
      }
      const vendors = response.data.vendorsCount;
      return vendors;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Venues By Vendors
export const getAllVenuesByVendorId = createAsyncThunk(
  "venueMaster/getAllVenuesByVendorId",
  async (args: any, thunkAPI) => {
    try {
      const vendorId = args;
      const response: any = await client.query({
        query: GET_VENUES_BY_VENDORID,
        variables: {
          venuesByVendorIdId: vendorId,
        },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error: any) => error.message).join(", ")
        );
      }
      const vendors = response.data.venuesByVendorId || [];
      return vendors;
    } catch (error) {
      // return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Create Contract
export const createContract = createAsyncThunk(
  "venueMaster/createContract",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.mutate({
        mutation: CREATE_CONTRACT,
        variables: {
          contractArgs: { ...args },
        },
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      return response.data.contractCreate;
    } catch (error) {
      // return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Edit Contract
export const editContract = createAsyncThunk(
  "venueMaster/editContract",
  async (args: { contractId: any; contract: Contract }, thunkAPI) => {
    const { contractId, contract } = args;
    try {
      const response = await client.mutate({
        mutation: UPDATE_CONTRACT,
        variables: {
          contractArgs: { ...contract, id: contractId },
        },
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      return response.data.contractUpdate;
    } catch (error) {
      // return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Get Contract Count
export const getContractsCount = createAsyncThunk(
  "venueMaster/getContractsCount",
  async (_, thunkAPI) => {
    try {
      const response: any = await client.query({
        query: GET_CONTRACTS_COUNT,
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error: any) => error.message).join(", ")
        );
      }
      const contractCount = response.data.contractsCount;
      return contractCount;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get All Contract Details
export const getAllContracts = createAsyncThunk(
  "venueMaster/getAllContracts",
  async (args: any, thunkAPI) => {
    try {
      const response: any = await client.query({
        query: GET_CONTRACTS,
        variables: {
          page: args.page,
          pageSize: args.pageSize,
        },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error: any) => error.message).join(", ")
        );
      }
      const contracts = response.data.contracts;
      console.log("🚀🚀🚀🚀 ~ contracts:", contracts);
      return contracts;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get contract by contractId with contract Items
export const getContractById = createAsyncThunk(
  "venueMaster/getContractById",
  async (contractId: number, thunkAPI) => {
    try {
      const response: any = await client.query({
        query: GET_CONTRACT_BY_ID,
        variables: {
          id: contractId,
        },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error: any) => error.message).join(", ")
        );
      }
      const contracts = { ...response.data.contractById };
      contracts.contractItems = contracts.contractItems.map((item: any) => ({
        ...item,
        startTime: dayjs.utc(item.startTime).format("YYYY-MM-DD HH:mm:ss"),
        endTime: dayjs.utc(item.endTime).format("YYYY-MM-DD HH:mm:ss"),
      }));
      return contracts;
    } catch (error) {
      // return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Create Contract
export const uploadAttachments = createAsyncThunk(
  "venueMaster/uploadAttachments",
  async (args: any, thunkAPI) => {
    let urlContractUpload = `${baseURL}/upload`;
    try {
      const response = await multipartFetch.post(urlContractUpload);
      return response.data;
    } catch (error) {
      process.env.NODE_ENV === "development" && console.log(error);
    }
  }
);

export const uploadImages = createAsyncThunk(
  "venueMaster/uploadImages",
  async (args: any, thunkAPI) => {
    let urlContractUpload = `${baseURL}/upload`;
    try {
      const response = await multipartFetch.post(urlContractUpload, args, {
        headers: { "content-type": "multipart/form-data" },
      });
      return response.data;
    } catch (error) {
      process.env.NODE_ENV === "development" && console.log(error);
    }
  }
);

// Get venue types
export const getVenueTypes = createAsyncThunk(
  "venueMaster/getVenueTypes",
  async (_: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_VENUE_TYPE,
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const venueType = response.data.venueTypes;
      return venueType;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get venue sizes
export const getVenueSizes = createAsyncThunk(
  "venueMaster/getVenueSizes",
  async (_: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_VENUE_SIZE,
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const venueSize = response.data.venueSizes;
      return await venueSize;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Venue By Id
export const getVenue = createAsyncThunk(
  "venueMaster/getVenue",
  async (args: any, thunkAPI) => {
    try {
      const venueId = args;
      const response: any = await client.query({
        query: GET_VENUE_BY_ID,
        variables: {
          venueByIdId: venueId,
        },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error: any) => error.message).join(", ")
        );
      }
      const venue = response.data.venueById;
      return venue;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get venue Overview
export const getVenueOverview = createAsyncThunk(
  "venueMaster/getVenueOverview",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_VENUE_OVERVIEW,
        variables: { venueOverviewFilters: args },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const venueOverview = response.data.venueOverview;
      return venueOverview;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Regions
export const getRegions = createAsyncThunk(
  "venueMaster/getRegions",
  async (_: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_REGIONS,
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const regions = response.data.regions;
      return regions;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get sessions for Capacity Group
export const getSessionsForCapacityGroup = createAsyncThunk(
  "venueMaster/getSessionsForCapacityGroup",
  async (args: any, thunkAPI) => {
    try {
      const response = await client
        .query({
          query: GET_SESSIONS_FOR_CAPACITY_GROUP,
          variables: { sessionFilters: args },
          fetchPolicy: "network-only",
        })
        .then((response: any) => {
          if (response.errors) {
            throw new Error(
              response.errors.map((error: any) => error.message).join(", ")
            );
          }
          return response.data.sessions.map((session: any) => {
            return {
              id: session.id,
              name: `${DayOfWeek[session.dayOfWeek]} ${
                session.league.name
              } (${moment(session.startDate).format("MMM DD, YYYY")})`,
            };
          });
        });
      return await response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Sports
export const getSports = createAsyncThunk(
  "venueMaster/getSports",
  async (_: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_SPORTS,
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const sports = response.data.sports;
      return sports;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Capacity Info
export const getCapacityInfo = createAsyncThunk(
  "venueMaster/getCapacityInfo",
  async (args: any, thunkAPI) => {
    const { sessions, venueSizeId, venueTypeId } = args;
    const sessionIds = sessions.map((session: any) => session.id);

    try {
      const response = await client.query({
        query: GET_CAPACITY_INFO,
        variables: {
          venueWiseCapacityArgs: { sessionIds, venueSizeId, venueTypeId },
        },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const venueWiseCapacity = response.data.venueWiseCapacity;
      return await venueWiseCapacity;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get League type
export const getLeagueType = createAsyncThunk(
  "venueMaster/getLeagueType",
  async (_: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_LEAGUE_TYPES,
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const leagueTypes = response.data.leagueTypes;
      return leagueTypes;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Create Capacity Group
export const createCapacityGroup = createAsyncThunk(
  "venueMaster/createCapacityGroup",
  async (capacityGroupArgs: any, thunkAPI) => {
    const { name, sessions } = capacityGroupArgs;

    const sessionIds = sessions.map((session: any) => session.id);
    try {
      const response = await client.mutate({
        mutation: CREATE_CAPACITY_GROUP,
        variables: {
          capacityGroupArgs: { name, sessionIds },
        },
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Gender Format
export const getGenderFormat = createAsyncThunk(
  "venueMaster/getGenderFormat",
  async (_: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_GENDER_FORMAT,
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const genderFormat = response.data.genderFormats;
      return genderFormat;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
// Get Gender Identities
export const getAllGenderIdentity = createAsyncThunk(
  "venueMaster/getAllGenderIdentity",
  async (_: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_ALL_GENDER_IDENTITY,
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const genderFormat = response.data.genderIdentity;
      return genderFormat;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Gender Identities
export const getAllDayOfWeek = createAsyncThunk(
  "venueMaster/getAllDayOfWeek",
  async (_: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_ALL_DAY_OF_WEEK,
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const dayOfWeek = response.data.dayOfWeek;
      return dayOfWeek;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Gender Identities
export const getAllTeamPlayerRoles = createAsyncThunk(
  "venueMaster/getAllTeamPlayerRoles",
  async (_: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_ALL_TEAM_PLAYER_ROLES,
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const teamPlayerRoles = response.data.teamPlayerRoles.map(
        (teamPlayerRole: any) => {
          return {
            id: +teamPlayerRole.id,
            name: teamPlayerRole.teamPlayerRoleName,
          };
        }
      );
      return teamPlayerRoles;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Gender Identities
export const getAllRegistrationTypes = createAsyncThunk(
  "venueMaster/getAllRegistrationTypes",
  async (_: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_ALL_REGISTRATION_TYPES,
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const registrationTypes = response.data.registrationTypes;
      return registrationTypes;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Standing Policies
export const getStandingPolicies = createAsyncThunk(
  "venueMaster/getStandingPolicies",
  async (_: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_STANDING_POLICIES,
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const standingPolicies = response.data.standingsPolicies;
      return standingPolicies;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Standing Policies
export const getTieBrakePolicies = createAsyncThunk(
  "venueMaster/getTieBreakPolicies",
  async (_: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_TIE_BREAK_POLICIES,
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const tieBreakPolicies = response.data.tieBreakPolicies;
      return tieBreakPolicies;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Officiated Status
export const getOfficiatedStatus = createAsyncThunk(
  "venueMaster/getOfficitatedStatus",
  async (_: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_OFFICIATED_STATUSES,
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const officiatedStatus = response.data.officiatedStatuses;
      return officiatedStatus;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Sport Formats
export const getSportsFormat = createAsyncThunk(
  "venueMaster/getSportsFormats",
  async (args: any, thunkAPI) => {
    try {
      const filters = { ...args.filters };
      if (!args.filters.regions) {
        const state: any = thunkAPI.getState();
        const regionsId = state.venueMaster.selectedRegions.map(
          (region: any) => +region.id
        );
        filters.regions = regionsId;
      }
      const response = await client.query({
        query: GET_SPORTS_FORMAT,
        variables: {
          sportFormatFilters: filters,
          page: args.page,
          pageSize: args.pageSize,
        },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const sportsFormat = response.data.sportFormats;
      return sportsFormat;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Sport Format by Id
export const getSportFormatById = createAsyncThunk(
  "venueMaster/getSportFormatById",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_SPORT_FORMAT_BY_ID,
        variables: { sportFormatId: args },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const sportFormat = response.data.sportFormat;
      return sportFormat;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Venues
export const getVenues = createAsyncThunk(
  "venueMaster/getVenues",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_VENUES,
        variables: { venueFilters: args },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const venues = response.data.venues;
      return venues;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Venues Tree
export const getVenuesTree = createAsyncThunk(
  "venueMaster/getVenuesTree",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_VENUES_TREE,
        variables: args,
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const venues = response.data.venuesTree;
      return venues;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Venues Management
export const getVenuesManagement = createAsyncThunk(
  "venueMaster/getVenuesManagement",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_VENUES_MANAGEMENT,
        variables: {
          venueFilters: args.venueFilters,
          page: args.page,
          pageSize: args.pageSize,
        },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const venues = response.data.venues;
      return venues;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Venues Count
export const getVenuesCount = createAsyncThunk(
  "venueMaster/getVenuesCount",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_VENUES_COUNT,
        variables: { venueFilters: args },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const venues = response.data.venuesCount;
      return venues;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Leagues
export const getLeagues = createAsyncThunk(
  "venueMaster/getLeagues",
  async (args: any, thunkAPI) => {
    try {
      const filters: any = {};
      const state: any = thunkAPI.getState();
      const regionsId = state.venueMaster.selectedRegions.map(
        (region: any) => +region.id
      );
      const sportId = args.sportId;
      const regionId = args.regionId;
      filters.regions = regionId
        ? regionId
        : regionsId.length
        ? regionsId
        : null;
      filters.sportId = sportId;
      const response = await client.query({
        query: GET_LEAGUES,
        variables: { leagueFilters: filters },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const leagues = response.data.leagues;
      return leagues;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get RegistrationBatch
export const getRegistrationBatch = createAsyncThunk(
  "venueMaster/getRegistrationBatch",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_REGISTRATION_BATCH,
        variables: {
          page: args.page,
          pageSize: args.pageSize,
        },
        fetchPolicy: "no-cache",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const registrationBatches = response.data.registrationBatches;
      return registrationBatches;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getRegistrationReport = createAsyncThunk(
  "venueMaster/getRegistrationReport",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_REGISTRATION_REPORT,
        variables: {
          registrationReportInput: args.registrationReportInput,
          page: args.page,
          pageSize: args.pageSize,
        },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      return response.data.registrationReport;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getRegistrationTypes = createAsyncThunk(
  "venueMaster/getRegistrationTypes",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_REGISTRATION_TYPES,
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      return response.data.registrationTypes;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getRegistrationReportCount = createAsyncThunk(
  "venueMaster/getRegistrationReportCount",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_REGISTRATION_REPORT_COUNT,
        variables: {
          registrationReportInput: args.registrationReportInput,
        },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      return response.data.registrationReportCount;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Sport Formats
export const getSessions = createAsyncThunk(
  "venueMaster/getSessions",
  async (args: any, thunkAPI) => {
    try {
      const filters = { ...args };
      if (!args.regions) {
        const state: any = thunkAPI.getState();
        const regionsId = state.venueMaster.selectedRegions.map(
          (region: any) => +region.id
        );
        filters.regions = regionsId;
      }

      const response = await client.query({
        query: GET_SESSIONS,
        variables: { sessionFilters: filters },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const sessions = response.data.sessions;
      return sessions;
      // return [];
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Rules
export const getRules = createAsyncThunk(
  "venueMaster/getRules",
  async (_: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_RULE,
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const rules = response.data.getRulesAll;
      return rules;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Registration Batch By Id
export const getRegistrationBatchById = createAsyncThunk(
  "venueMaster/getRegistrationBatchById",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_REGISTRATION_BATCH_BY_ID,
        variables: { registrationBatchId: args },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const res = response.data.registrationBatch;
      console.log("🚀🚀🚀🚀 ~ registrationBatch:", res);
      const registrationBatch = {
        ...res,
        regOpenTime: new Date(res.regOpenDate),
        regCloseTime: new Date(res.regCloseDate),
      };
      return registrationBatch;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get League By Id
export const getLeagueById = createAsyncThunk(
  "venueMaster/getLeagueById",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_LEAGUE_BY_ID,
        variables: { leagueId: args },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const league = response.data.league;
      return league;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Session By Id
export const getSessionById = createAsyncThunk(
  "venueMaster/getSessionById",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_SESSION_BY_ID,
        variables: { sessionId: args },
        fetchPolicy: "no-cache",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const session = response.data.session;
      return session;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Gameslots
export const getGameslots = createAsyncThunk(
  "venueMaster/getGameslots",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_GAMESLOTS,
        variables: { gameslotFilters: args },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const gameslots = response.data.gameslots;
      return gameslots;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Update Gameslots
export const updateGameslots = createAsyncThunk(
  "venueMaster/updateGameslots",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: UPDATE_GAMESLOT,
        variables: { gameslotArgs: args },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Divisions
export const getDivisions = createAsyncThunk(
  "venueMaster/getDivisions",
  async (args: any, thunkAPI) => {
    try {
      const filters: any = {};
      const state: any = thunkAPI.getState();
      const regionsId = state.venueMaster.selectedRegions.map(
        (region: any) => +region.id
      );
      filters.regions = regionsId;
      const response = await client.query({
        query: GET_DIVISIONS,
        variables: { divisionFilters: filters },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const divisions = response.data.divisions;
      return divisions;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Gameslot By Id
export const getGameslotById = createAsyncThunk(
  "venueMaster/getGameslotById",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_GAMESLOT_BY_ID,
        variables: { gameslotId: args },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const gameslotById = response.data.gameslot;
      return gameslotById;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get sessions for Divisions
export const getSessionsForDivision = createAsyncThunk(
  "venueMaster/getSessionsForDivision",
  async (args: any, thunkAPI) => {
    try {
      const response = await client
        .query({
          query: GET_SESSIONS_FOR_DIVISION,
          variables: { sessionFilters: args },
          fetchPolicy: "network-only",
        })
        .then((response: any) => {
          if (response.errors) {
            throw new Error(
              response.errors.map((error: any) => error.message).join(", ")
            );
          }
          return response.data.sessions.map((session: any) => {
            return {
              id: session.id,
              name: `${DayOfWeek[session.dayOfWeek]} ${
                session.league.name
              } (${moment(session.startDate).format("MMM DD, YYYY")})`,
              startDate: session.startDate,
              endDate: session.endDate,
              numberOfWeeks: session.numberOfWeeks,
              weeksOfPlayoffs: session.weeksOfPlayoffs,
              exceptionDates: session.exceptionDates,
              teams: session.teams,
              divisions: session.divisions,
            };
          });
        });
      return await response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Create Division
export const createDivision = createAsyncThunk(
  "venueMaster/createDivision",
  async (divisionArgs: any, thunkAPI) => {
    try {
      const response = await client.mutate({
        mutation: CREATE_DIVISION,
        variables: {
          divisionArgs: { divisionInput: divisionArgs },
        },
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Capacity Groups
export const getCapacityGroups = createAsyncThunk(
  "venueMaster/getCapacityGroups",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_CAPACITY_GROUPS,
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const capacityGroups = response.data.capacityGroups;
      return capacityGroups;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Division By Id
export const getDivisionById = createAsyncThunk(
  "venueMaster/getDivisionById",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_DIVISION_BY_ID,
        variables: { divisionId: args },
        fetchPolicy: "no-cache",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const division = response.data.division;
      return division;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Division Schedule By Id
export const getDivisionScheduleById = createAsyncThunk(
  "venueMaster/getDivisionScheduleById",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_DIVISION_SCHEDULE_BY_ID,
        variables: { divisionId: args },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const division = response.data.division;
      return division;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getScheduleStatuses = createAsyncThunk(
  "venueMaster/getScheduleStatuses",
  async (_: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_SCHEDULE_STATUSES,
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      return response.data.scheduleStatuses;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Update Division
export const updateDivision = createAsyncThunk(
  "venueMaster/updateDivision",
  async (divisionArgs: any, thunkAPI) => {
    try {
      const response = await client.mutate({
        mutation: UPDATE_DIVISION,
        variables: {
          divisionArgs: { ...divisionArgs },
        },
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Venues Hierarchies
export const getVenuesHierachy = createAsyncThunk(
  "venueMaster/getVenuesHierarchy",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_VENUES_HIERARCHY,
        variables: { ids: args },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const venuesHierarchy = response.data.venuesHierarchy;
      return venuesHierarchy;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Delete Division
export const deleteDivision = createAsyncThunk(
  "venueMaster/deleteDivision",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.mutate({
        mutation: DELETE_DIVISION,
        variables: { divisionDeleteId: args },
        fetchPolicy: "network-only",
      });

      if (response.errors) {
        throw new Error(
          response.errors.map((error: any) => error.message).join(", ")
        );
      }
      const division = response.data.divisionDelete;
      return division;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Divisions By CG
export const getCGToDivision = createAsyncThunk(
  "venueMaster/getCGToDivision",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_CG_TO_DIVISION,
        variables: { capacityGroupId: args },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const divisionsForCapacityGroup = response.data.capacityGroup;
      return divisionsForCapacityGroup;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get capacity Group Overview
export const getCapacityGroupOverview = createAsyncThunk(
  "venueMaster/getCapacityGroupOverview ",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_CAPACITY_GROUP_OVERVIEW,
        variables: { capacityGroupOverviewFilters: args },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const capacityGroupOverview = response.data.capacityGroupOverview;
      return capacityGroupOverview;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Shopping Carts
export const getShoppingCarts = createAsyncThunk(
  "venueMaster/getShoppingCarts",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_SHOPPING_CARTS,
        variables: {
          shoppingCartFilters: args.filters,
          page: args.page,
          pageSize: args.pageSize,
        },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const shoppingCarts = response.data.shoppingCarts;
      return shoppingCarts;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get free agents registration by session id
export const getFreeAgentsRegBySessionId = createAsyncThunk(
  "venueMaster/getFreeAgentsRegBySessionId ",
  async (args: any, thunkAPI) => {
    try {
      const response = await client
        .query({
          query: GET_FREE_AGENT_REG_BY_SESSIONID,
          variables: { sessionId: args },
          fetchPolicy: "no-cache",
        })
        .then((response: any) => {
          if (response.errors) {
            throw new Error(
              response.errors.map((error: any) => error.message).join(", ")
            );
          }
          return response.data.freeAgentRegistrationBySessionId;
        });
      return await response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Countries
export const getCountries = createAsyncThunk(
  "venueMaster/getCountries",
  async (_: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_COUNTRIES,
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error: any) => error.message).join(", ")
        );
      }
      const countries = response.data.countries;
      return countries;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
// Type for getLegalDocumentByRegionAndType
export type getLegalDocumentByRegionAndTypeInput = {
  regionId: number;
  legalDocumentTypeId: number;
};
// Get a legal document by regionId and legalDocumentTypeId
export const getLegalDocumentByRegionAndType = createAsyncThunk(
  "venueMaster/getLegalDocumentByRegionAndType",
  async (args: getLegalDocumentByRegionAndTypeInput, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_LEGAL_DOCUMENT_BY_REGION_AND_TYPE,
        variables: {
          regionId: args.regionId,
          legalDocumentTypeId: args.legalDocumentTypeId,
        },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error: any) => error.message).join(", ")
        );
      }
      const legalDocument = response.data.getLegalDocumentsByRegionAndType;
      return legalDocument;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// get states
export const getStates = createAsyncThunk(
  "venueMaster/getStates",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_STATES_BY_COUNTRY_ID,
        variables: { countryId: args },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error: any) => error.message).join(", ")
        );
      }
      const states = response.data.statesByCountryId;
      return states;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Reg Skill Choices
export const getRegSkillChoices = createAsyncThunk(
  "venueMaster/getRegSkillChoices",
  async (_: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: RegSkillChoicesDocument,
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error: any) => error.message).join(", ")
        );
      }
      const regSkillChoices = response.data.regSkillChoices;
      return regSkillChoices;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Type for createLegalDocument
export type createLegalDocumentInput = {
  regionId: number;
  legalDocumentTypeId: number;
  name: string;
  body: string;
  authorId: string;
};
// Create a new legal document using regionId, legalDocumentTypeId, name, body, and authorId
export const createLegalDocument = createAsyncThunk(
  "venueMaster/createLegalDocument",
  async (args: createLegalDocumentInput, thunkAPI) => {
    try {
      const response = await client.mutate({
        mutation: CREATE_LEGAL_DOCUMENT,
        variables: {
          legalDocumentArgs: {
            regionId: args.regionId,
            legalDocumentTypeId: args.legalDocumentTypeId,
            name: args.name,
            body: args.body,
            authorId: args.authorId,
          },
        },
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error: any) => error.message).join(", ")
        );
      }
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Game Statuses
export const getGameStatuses = createAsyncThunk(
  "venueMaster/getGameStatuses",
  async (_: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_GAME_STATUSES,
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const gamestatuses = response.data.gameStatuses;
      return gamestatuses;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Schedule By Id
export const getScheduleById = createAsyncThunk(
  "venueMaster/getScheduleById",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_SCHEDULE_BY_ID,
        variables: args,
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const scheduleById = response.data.scheduleById;
      return scheduleById;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Schedule By Id
export const checkRepeatedTeamNames = createAsyncThunk(
  "venueMaster/checkRepeatedTeamNames",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: CHECK_REPEATED_TEAM_NAMES,
        variables: args,
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const repeatedNames = response.data.teamNameCheck;
      return repeatedNames;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Score Statuses
export const getScoreStatuses = createAsyncThunk(
  "venueMaster/getScoreStatuses",
  async (_: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_SCORE_STATUSES,
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error: any) => error.message).join(", ")
        );
      }
      const scoreStatuses = response.data.scoreStatuses;
      return scoreStatuses;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const venueMasterSlice = createSlice({
  name: "venueMaster",
  initialState,
  reducers: {
    clearSessionById: (state, action) => {
      state.session = {};
    },
    toggleView: (state, action) => {
      state.isEditContract = action.payload;
    },
    handleContractValues: (state, action) => {
      state.contract = {
        ...initialContractState,
        regionId: action.payload.regionId,
        vendorId: action.payload.vendorId,
        type: action.payload.type,
        name: action.payload.name,
        status: action.payload.status,
        approver: action.payload.approver,
        grossCost: action.payload.grossCost,
        taxes: action.payload.taxes,
        notes: action.payload.notes,
        contractItems: [...action.payload.contractItems],
      };
    },
    handleVenueOverviewFilters: (state, action) => {
      state.venueOverviewFilter.endDate = action.payload.endDate;
      state.venueOverviewFilter.startDate = action.payload.startDate;
      state.venueOverviewFilter.day = action.payload.day;
      state.venueOverviewFilter.regionId = action.payload.regionId;
      state.venueOverviewFilter.typeId = action.payload.typeId;
    },
    handleSportsFormatFilter: (state, action) => {
      state.sportsFormatFilter.regions = action.payload.regions;
      state.sportsFormatFilter.genderFormatId = action.payload.genderFormatId;
      state.sportsFormatFilter.officiatedStatusId =
        action.payload.officiatedStatusId;
      state.sportsFormatFilter.sportId = action.payload.sportId;
    },
    handleSessionFilter: (state, action) => {
      state.sessionFilter = action.payload;
    },
    handleCapacityGroupFilters: (state, action) => {
      state.capacityGroupFilters.dayOfWeek = action.payload.dayOfWeek;
      state.capacityGroupFilters.venueTypeId = action.payload.venueTypeId;
      state.capacityGroupFilters.venueSizeId = action.payload.venueSizeId;
    },
    cleanupCapacityGroupFilters: (state, _) => {
      state.capacityGroupFilters = {
        dayOfWeek: 7,
        venueTypeId: 0,
        venueSizeId: 0,
      };
      state.sessionsForCapacityGroup = [];
      state.capacityInfo = [];
    },
    handleGameslotFilter: (state, action) => {
      state.gameslotFilter.venueId = action.payload.venueId;
      state.gameslotFilter.capacityGroupId = action.payload.capacityGroupId;
    },
    cleanupCapacityOverview: (state, _) => {
      state.divisionsForCapacityGroup = {};
      state.capacityGroupOverview = [];
    },
    handleSelectedRegions: (state, action) => {
      state.selectedRegions = action.payload;
    },
    handleResetImageUpload: (state, _) => {
      state.imageUpload = null;
    },
    cleanupDivisionToEdit: (state, _) => {
      state.divisionToEdit = {};
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getVendors.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getVendors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.vendorList = action.payload;
      })
      .addCase(getVendors.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.error.message ?? "Failed to fetch vendors");
      })
      .addCase(getAllVenuesByVendorId.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllVenuesByVendorId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.venueList = action.payload;
      })
      .addCase(getAllVenuesByVendorId.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch venues by vendor id"
        );
      })
      .addCase(createContract.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createContract.fulfilled, (state, action) => {
        state.isLoading = false;
        toastStackable.success(action.payload);
      })
      .addCase(createContract.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to create contract"
        );
      })
      .addCase(editContract.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(editContract.fulfilled, (state, action) => {
        state.isLoading = false;
        toastStackable.success(action.payload);
      })
      .addCase(editContract.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.error.message ?? "Failed to edit contract");
      })
      .addCase(getAllContracts.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllContracts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contractList = action.payload.contracts;
        state.contractListCount = action.payload.count;
      })
      .addCase(getAllContracts.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch contracts"
        );
      })
      .addCase(getContractById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getContractById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contract = action.payload;
      })
      .addCase(getContractById.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch contract by id"
        );
      })
      .addCase(uploadAttachments.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(uploadAttachments.fulfilled, (state, action) => {
        // const attachments = state.contract.attachments;
        // const payloadAttachments = action.payload.files.toString();
        // state.isLoading = false;
        // state.contract = {
        //   ...state.contract,
        //   attachments:
        //     attachments !== ""
        //       ? attachments + "," + payloadAttachments
        //       : payloadAttachments,
        // };
      })
      .addCase(uploadAttachments.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to upload attachments"
        );
      })
      .addCase(uploadImages.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(uploadImages.fulfilled, (state, action) => {
        const payloadAttachments = action.payload.files.toString();
        state.isLoading = false;
        state.imageUpload = payloadAttachments;
        toastStackable.success(action.payload);
      })
      .addCase(uploadImages.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.error.message ?? "Failed to upload images");
      })
      .addCase(getVenueTypes.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getVenueTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.venueTypes = action.payload;
      })
      .addCase(getVenueTypes.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch venue types"
        );
      })
      .addCase(getVenueSizes.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getVenueSizes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.venueSizes = action.payload;
      })
      .addCase(getVenueSizes.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch venue sizes"
        );
      })
      .addCase(getVenueOverview.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getVenueOverview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.venueOverview = action.payload;
      })
      .addCase(getVenueOverview.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch venue overview"
        );

        state.venueOverview = [];
      })
      .addCase(getRegions.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getRegions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.regions = action.payload;
      })
      .addCase(getRegions.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.error.message ?? "Failed to fetch regions");
      })
      .addCase(getLeagueType.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getLeagueType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.leagueTypes = action.payload;
      })
      .addCase(getLeagueType.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch league types"
        );
      })
      .addCase(getSports.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getSports.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sports = action.payload;
      })
      .addCase(getSports.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.error.message ?? "Failed to fetch sports");
      })
      .addCase(getGenderFormat.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getGenderFormat.fulfilled, (state, action) => {
        state.isLoading = false;
        state.genderFormat = action.payload;
      })
      .addCase(getGenderFormat.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch Gender Format"
        );
      })
      .addCase(getAllGenderIdentity.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllGenderIdentity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.genderIdentity = action.payload;
      })
      .addCase(getAllGenderIdentity.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch gender identity"
        );
      })
      .addCase(getAllDayOfWeek.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllDayOfWeek.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dayOfWeek = action.payload;
      })
      .addCase(getAllDayOfWeek.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch day of week"
        );
      })
      .addCase(getAllTeamPlayerRoles.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllTeamPlayerRoles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.teamPlayerRoles = action.payload;
      })
      .addCase(getAllTeamPlayerRoles.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch team player roles"
        );
      })
      .addCase(getAllRegistrationTypes.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllRegistrationTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.registrationTypes = action.payload;
      })
      .addCase(getAllRegistrationTypes.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch registration types"
        );
      })
      .addCase(getStandingPolicies.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getStandingPolicies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.standingPolicies = action.payload;
      })
      .addCase(getStandingPolicies.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch standing policies"
        );
      })
      .addCase(getTieBrakePolicies.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTieBrakePolicies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tieBreakPolicies = action.payload;
      })
      .addCase(getTieBrakePolicies.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch tie break policies"
        );
      })
      .addCase(getOfficiatedStatus.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getOfficiatedStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.officiatedStatuses = action.payload;
      })
      .addCase(getOfficiatedStatus.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch officiated status"
        );
      })
      .addCase(getSportsFormat.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getSportsFormat.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sportsFormat = action.payload.sportsFormat;
        state.sportsFormatCount = action.payload.count;
      })
      .addCase(getSportsFormat.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch sports format"
        );
      })
      .addCase(getSportFormatById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getSportFormatById.fulfilled, (state, action) => {
        state.isLoading = false;
        const sportFormat = {
          name: action.payload.name,
          abbreviation: action.payload.abbreviation,
          weeksOfPlayOffs: action.payload.weeksOfPlayOffs,
          defaultEmail: action.payload.defaultEmail,
          peopleOnField: action.payload.peopleOnField,
          minMen: action.payload.minMen,
          minWomen: action.payload.minWomen,
          maxRosterSizeTeam: action.payload.maxRosterSizeTeam,
          maxRosterSizeIndy: action.payload.maxRosterSizeIndy,
          minMenIndy: action.payload.minMenIndy,
          minWomenIndy: action.payload.minWomenIndy,
          regionId: action.payload.region.id,
          genderFormatId: action.payload.genderFormat.id,
          venueTypeId: action.payload.venueType.id,
          leagueTypeId: action.payload.leagueType.id,
          tieBreakPolicyId: action.payload.tieBreakPolicy.id,
          standingsPolicyId: action.payload.standingsPolicy.id,
          sportId: action.payload.sport.id,
          officiatedStatusId: action.payload.officiatedStatus.id,
          venueSizeId: action.payload.venueSize.id,
          ruleId: action.payload.ruleId || "0",
          staffRequired: action.payload.staffRequired,
        };
        state.sportFormat = sportFormat;
      })
      .addCase(getSportFormatById.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch sport format by id"
        );
      })
      .addCase(getVenues.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getVenues.fulfilled, (state, action) => {
        state.isLoading = false;
        state.venues = action.payload;
      })
      .addCase(getVenues.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.error.message ?? "Failed to fetch venues");
      })
      .addCase(getVenuesTree.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getVenuesTree.fulfilled, (state, action) => {
        state.isLoading = false;
        state.venues = action.payload;
      })
      .addCase(getVenuesTree.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch venues tree"
        );
      })
      .addCase(getVenuesCount.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getVenuesCount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.venueListCount = action.payload;
      })
      .addCase(getVenuesCount.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch venues count"
        );
      })
      .addCase(getVenuesManagement.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getVenuesManagement.fulfilled, (state, action) => {
        state.isLoading = false;
        state.venueListManagement = action.payload;
      })
      .addCase(getVenuesManagement.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch venues management"
        );
      })
      .addCase(getLeagues.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getLeagues.fulfilled, (state, action) => {
        state.isLoading = false;
        state.leagues = action.payload;
      })
      .addCase(getLeagues.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.error.message ?? "Failed to fetch leagues");
      })
      .addCase(getRegistrationBatch.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getRegistrationBatch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.registrationBatches = action.payload.registrationBatches;
        state.registrationBatchesCount = action.payload.count;
      })
      .addCase(getRegistrationBatch.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch registration batch"
        );
      })
      .addCase(getRegistrationReport.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getRegistrationReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.registrationReport = action.payload;
      })
      .addCase(getRegistrationReport.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch registration report"
        );
      })
      .addCase(getRegistrationReportCount.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getRegistrationReportCount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.registrationReportCount = action.payload;
      })
      .addCase(getRegistrationReportCount.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch registration report count"
        );
      })
      .addCase(getSessions.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getSessions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sessions = action.payload;
      })
      .addCase(getSessions.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch sessions"
        );
      })
      .addCase(getRules.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getRules.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rules = action.payload;
      })
      .addCase(getRules.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.error.message ?? "Failed to fetch rules");
      })
      .addCase(getRegistrationBatchById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getRegistrationBatchById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.registrationBatch = action.payload;
      })
      .addCase(getRegistrationBatchById.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch registration batch by id"
        );
      })
      .addCase(getLeagueById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getLeagueById.fulfilled, (state, action) => {
        state.isLoading = false;
        const league = {
          name: action.payload.name,
          regionId: action.payload.sportFormat.region.id,
          sportId: action.payload.sportFormat.sport.id,
          sportFormatId: action.payload.sportFormat.id,
          gameDuration: action.payload.gameDuration,
          gameTimeslot: action.payload.gameTimeslot,
          gamesPerOccurrence: action.payload.gamesPerOccurrence,
          leagueNotes: action.payload.leagueNotes,
          divisionName: action.payload.divisionName,
          venues: action.payload.venues
            ? action.payload.venues.map((venue: Venue) => venue.id)
            : [],
        };
        state.league = league;
      })
      .addCase(getLeagueById.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch league by id"
        );
      })
      .addCase(getSessionById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getSessionById.fulfilled, (state, action) => {
        state.isLoading = false;
        const session = {
          leagueId: action.payload.league.id,
          dayOfWeek: action.payload.dayOfWeek,
          numberOfWeeks: action.payload.numberOfWeeks,
          weeksOfPlayoffs: action.payload.weeksOfPlayoffs,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          exceptionDates: action.payload.exceptionDates,
          registrationBatchId: action.payload.registrationBatch.id,
          startTimes: action.payload.startTimes,
          priceForTeam: action.payload.priceForTeam,
          priceForIndy: action.payload.priceForIndy,
          maxCapacity: action.payload.maxCapacity,
          setCapacity: action.payload.setCapacity,
          indyTeams: action.payload.indyTeams,
          sessionNotes: action.payload.sessionNotes,
          venues: action.payload.venues
            ? action.payload.venues.map((venue: Venue) => venue.id)
            : [],
          regSkillChoices: action.payload.regSkillChoices
            ? action.payload.regSkillChoices.map(
                (regSkillChoice: RegSkillChoice) => regSkillChoice.id
              )
            : [],
        };
        state.sessionVenues = action.payload.league.venues;
        state.session = session;
      })
      .addCase(getSessionById.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch session by id"
        );
      })
      .addCase(getSessionsForCapacityGroup.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getSessionsForCapacityGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sessionsForCapacityGroup = action.payload;
      })
      .addCase(getSessionsForCapacityGroup.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch sessions for capacity group"
        );

        state.sessionsForCapacityGroup = [];
      })
      .addCase(getCapacityInfo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCapacityInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.capacityInfo = action.payload;
      })
      .addCase(getCapacityInfo.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch capacity info"
        );

        state.capacityInfo = [];
      })
      .addCase(createCapacityGroup.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createCapacityGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        toastStackable.success(action.payload.capacityGroupCreate.message);
      })
      .addCase(createCapacityGroup.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to create capacity group"
        );
      })
      .addCase(getGameslots.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getGameslots.fulfilled, (state, action) => {
        state.isLoading = false;
        state.gameslots = action.payload;
      })
      .addCase(getGameslots.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch gameslots"
        );
      })
      .addCase(getDivisions.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDivisions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.divisions = action.payload;
      })
      .addCase(getDivisions.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch divisions"
        );
      })
      .addCase(getGameslotById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getGameslotById.fulfilled, (state, action) => {
        state.isLoading = false;
        const gameslot = {
          date: action.payload.date,
          duration: action.payload.duration,
          venueId: action.payload.venue.id,
          capacityGroupId: action.payload.capacityGroup.id,
        };
        state.gameslot = gameslot;
      })
      .addCase(getGameslotById.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch gameslot by id"
        );
        state.capacityInfo = [];
      })
      .addCase(getSessionsForDivision.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getSessionsForDivision.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sessionsForDivision = action.payload;
      })
      .addCase(getSessionsForDivision.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch sessions for division"
        );
      })
      .addCase(getCapacityGroups.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCapacityGroups.fulfilled, (state, action) => {
        state.isLoading = false;
        state.capacityGroups = action.payload;
      })
      .addCase(getCapacityGroups.rejected, (state, action) => {
        state.sessionsForDivision = [];
      })
      .addCase(createDivision.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createDivision.fulfilled, (state, action) => {
        state.isLoading = false;
        toastStackable.success(action.payload.divisionCreate.message);
      })
      .addCase(createDivision.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to create division"
        );
      })
      .addCase(updateDivision.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateDivision.fulfilled, (state, action) => {
        state.isLoading = false;
        toastStackable.success(action.payload.divisionUpdate.message);
      })
      .addCase(updateDivision.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to update division"
        );
      })
      .addCase(getVenuesHierachy.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getVenuesHierachy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.venuesHierarchy = action.payload;
      })
      .addCase(getVenuesHierachy.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch venues hierarchy"
        );
      })
      .addCase(getDivisionById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDivisionById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.divisionToEdit = action.payload;
      })
      .addCase(getDivisionById.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch division by id"
        );
      })
      .addCase(getDivisionScheduleById.pending, (state, action) => {
        state.isLoadingSchedule = true;
      })
      .addCase(getDivisionScheduleById.fulfilled, (state, action) => {
        state.isLoadingSchedule = false;
        state.divisionScheduleToEdit = action.payload;
      })
      .addCase(getDivisionScheduleById.rejected, (state, action) => {
        state.isLoadingSchedule = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch division schedule by id"
        );
      })
      .addCase(getScheduleStatuses.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getScheduleStatuses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.scheduleStatuses = action.payload;
      })
      .addCase(getScheduleStatuses.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch schedule statuses"
        );
      })
      .addCase(deleteDivision.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteDivision.fulfilled, (state, action) => {
        state.isLoading = false;
        toastStackable.success(action.payload.message);
      })
      .addCase(deleteDivision.rejected, (state, action) => {
        console.log(action.payload);
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to delete division"
        );
      })
      .addCase(getCGToDivision.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCGToDivision.fulfilled, (state, action) => {
        state.isLoading = false;
        state.divisionsForCapacityGroup = action.payload;
      })
      .addCase(getCGToDivision.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch divisions for capacity group"
        );
      })
      .addCase(getCapacityGroupOverview.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCapacityGroupOverview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.capacityGroupOverview = action.payload;
      })
      .addCase(getCapacityGroupOverview.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch capacity group overview"
        );
      })
      .addCase(getShoppingCarts.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getShoppingCarts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.shoppingCarts = action.payload.shoppingCarts;
        state.shoppingCartsCount = action.payload.count;
      })
      .addCase(getShoppingCarts.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch shopping carts"
        );
      })
      .addCase(getFreeAgentsRegBySessionId.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getFreeAgentsRegBySessionId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.freeAgentsRegBySession = action.payload;
      })
      .addCase(getFreeAgentsRegBySessionId.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ??
            "Failed to fetch free agents registration by session id"
        );
      })
      .addCase(getVendorTypes.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getVendorTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.vendorTypes = action.payload;
      })
      .addCase(getVendorTypes.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch vendor types"
        );
      })
      .addCase(getVendor.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getVendor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.vendor = action.payload;
      })
      .addCase(getVendor.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.error.message ?? "Failed to fetch vendor");
      })
      .addCase(getVenue.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getVenue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.venue = action.payload;
      })
      .addCase(getVenue.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.error.message ?? "Failed to fetch venue");
      })
      .addCase(getVendorsCount.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getVendorsCount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.vendorListCount = action.payload;
      })
      .addCase(getVendorsCount.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch vendor list count"
        );
      })
      .addCase(getCountries.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.countries = action.payload;
      })
      .addCase(getCountries.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch countries"
        );
      })
      .addCase(getStates.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getStates.fulfilled, (state, action) => {
        state.isLoading = false;
        state.states = action.payload;
      })
      .addCase(getStates.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.error.message ?? "Failed to fetch states");
      })
      .addCase(getGameStatuses.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getGameStatuses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.gameStatuses = action.payload;
      })
      .addCase(getGameStatuses.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch game statuses"
        );
      })
      .addCase(getScheduleById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getScheduleById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.scheduleById = action.payload;
      })
      .addCase(getScheduleById.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch schedule by id"
        );
      })
      .addCase(getRegSkillChoices.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getRegSkillChoices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.regSkillChoices = action.payload;
      })
      .addCase(getRegSkillChoices.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch reg skill choices"
        );
      })
      .addCase(checkRepeatedTeamNames.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(checkRepeatedTeamNames.fulfilled, (state, action) => {
        state.isLoading = false;
        state.repeatedTeamNames = action.payload;
      })
      .addCase(checkRepeatedTeamNames.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch repeated team names"
        );
      })
      .addCase(getContractsCount.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getContractsCount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contractListCount = action.payload;
      })
      .addCase(getContractsCount.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Failed to fetch contracts count"
        );
      })
      .addCase(getScoreStatuses.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getScoreStatuses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.scoreStatuses = action.payload;
      })
      .addCase(getScoreStatuses.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const venueMasterSelector = (state: RootState) => state.venueMaster;

export const {
  clearSessionById,
  toggleView,
  handleContractValues,
  handleVenueOverviewFilters,
  handleSportsFormatFilter,
  handleSessionFilter,
  handleCapacityGroupFilters,
  cleanupCapacityGroupFilters,
  cleanupCapacityOverview,
  handleSelectedRegions,
  handleResetImageUpload,
  cleanupDivisionToEdit,
} = venueMasterSlice.actions;

export default venueMasterSlice.reducer;
