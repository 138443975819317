import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormRow, FormRowSelect, LoadingMaterialUI } from "../../UI";
import classes from "./StaffTypeManagement.module.css";
import {
  getStaffType,
  createStaffType,
  updateStaffType,
} from "../../../app/shiftSlice";
import { AppDispatch, RootState } from "../../../app/store";
import { Regions, StaffType } from "../../../types/types";
import {
  EPermission,
  filterPermittedRegions,
} from "../../../utils/permissions";
import { displayAlertError } from "../../../app/globalSlice";

const initialState = {
  name: "",
  baseRate: 0,
  isContractor: false,
  regionId: "",
};

const initialStateEdit = {
  idEdit: 0,
  nameEdit: "",
  baseRateEdit: 0,
  regionIdEdit: "",
};

export interface StaffTypeManagementProps {
  requiredPermission?: EPermission;
}

const ShiftTypeManagement: React.FC<StaffTypeManagementProps> = ({
  requiredPermission,
}) => {
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(initialStateEdit);
  const { user } = useSelector((state: RootState) => state.auth);
  const [values, setValues] = useState(initialState);
  // const [editValues, setEditValues] = useState(initialStateEdit);

  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, staffTypeOptions } = useSelector(
    (state: RootState) => state.shift
  );
  const { selectedRegions }: any = useSelector(
    (state: RootState) => state.venueMaster
  );
  const [permittedRegions, setPermittedRegions] = useState<any>([]);

  const handleEditActionModal = (staffType: StaffType) => {
    const staffName: string = staffType.isContractor
      ? staffType.name.split(" (TPC)")[0]
      : staffType.name;
    setModalContent({
      idEdit: staffType.id,
      nameEdit: staffName,
      baseRateEdit: staffType.baseRate,
      regionIdEdit: staffType.regionId.toString(),
    });
    setOpen(true);
  };

  const handleEditAction = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(updateStaffType(modalContent)).then(() => {
      dispatch(getStaffType());
    });
    setOpen(false);
  };

  const handleChangeOnEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModalContent({ ...modalContent, [e.target.name]: e.target.value });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { name, baseRate } = values;

    if (!name ?? !baseRate) {
      dispatch(displayAlertError("Please provide all the values"));
      return;
    }

    dispatch(createStaffType(values)).then(() => dispatch(getStaffType()));

    setValues({ ...values, name: "", baseRate: 0 });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: +e.target.value || e.target.value,
    });
  };

  let existingStaffType;

  existingStaffType = (
    <div>
      <table className={classes.staff__table}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Base Rate</th>
            <th>Region</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {staffTypeOptions?.map((staffType: StaffType) => {
            const region = selectedRegions.find(
              (region: any) => +region.id === staffType.regionId
            );
            return (
              <tr key={staffType.id}>
                <td>{staffType.name}</td>
                <td>{staffType.baseRate}</td>
                <td>{region?.name}</td>
                <td>
                  <Button
                    type="button"
                    onClick={() => {
                      handleEditActionModal(staffType);
                    }}
                  >
                    Edit
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  useEffect(() => {
    dispatch(getStaffType());
    if (!requiredPermission) {
      setPermittedRegions(selectedRegions);
      return;
    }
    // set Permitted Regions
    const permittedRegion = filterPermittedRegions(
      user?.permission,
      requiredPermission
    );
    if (permittedRegion === null) {
      setPermittedRegions(selectedRegions);
      return;
    }
    if (permittedRegion?.length === 0) {
      // Navigate to Permission Denied
      return;
    }
    const displayedRegion: Regions[] = selectedRegions.filter((region: any) =>
      permittedRegion?.find(
        (regionPermitted: any) => regionPermitted.id === region.id
      )
    );
    setPermittedRegions(displayedRegion);
  }, [dispatch, requiredPermission]);

  useEffect(() => {
    dispatch(getStaffType());
  }, [selectedRegions]);

  return (
    <main>
      <h3>Staff Type Management</h3>
      <form
        className={classes.form}
        onSubmit={onSubmit}
      >
        <div className={classes.form__center}>
          <div>
            <FormRowSelect
              name="regionId"
              value={values.regionId}
              handleChange={handleChange}
              list={[...permittedRegions]}
              labelText="Region"
            />
          </div>
          <div>
            <FormRow
              name="name"
              value={values.name}
              labelText="Staff Type"
              handleChange={handleChange}
            />
          </div>
          <div>
            <FormRow
              type="number"
              name="baseRate"
              value={values.baseRate}
              labelText="Rate ($)"
              handleChange={handleChange}
              adornment="$"
            />
          </div>
          <div className={classes.form__row}>
            <label
              className={classes.form__label}
              htmlFor="isContractor"
            >
              Third Party Contractor
            </label>
            <input
              className={classes.form__input}
              defaultChecked={values.isContractor}
              type="checkbox"
              id="isContractor"
              name="isContractor"
              onChange={() => {
                setValues({
                  ...values,
                  isContractor: !values.isContractor,
                });
              }}
            />
          </div>
          <div className={classes.btn__container}>
            <Button
              variant="contained"
              type="submit"
              className={classes.btn__submit}
              disabled={isLoading}
              sx={{
                color: "var(--primary-blue-500)",
                bgcolor: "var(--primary-red-500)",
                "&:hover": { bgcolor: "var(--primary-red-600)" },
              }}
            >
              Create Staff Type
            </Button>
          </div>
        </div>
      </form>
      <div className={classes.existingStaffType}>
        <h4>Staff Types</h4>
        {isLoading && <LoadingMaterialUI />}

        {staffTypeOptions.length !== 0 ? (
          existingStaffType
        ) : (
          <div>Nothing Yet</div>
        )}
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          {
            <div>
              {
                <form
                  className={classes.form}
                  onSubmit={onSubmit}
                >
                  <div className={classes.form__center}>
                    <div>
                      <FormRowSelect
                        name="regionIdEdit"
                        value={modalContent.regionIdEdit}
                        handleChange={handleChange}
                        list={[...permittedRegions]}
                        labelText="Region"
                      />
                    </div>
                    <div>
                      <FormRow
                        name="nameEdit"
                        defaultValue={modalContent.nameEdit}
                        labelText="Staff Type"
                        handleChange={handleChangeOnEdit}
                      />
                    </div>
                    <div>
                      <FormRow
                        type="number"
                        name="baseRateEdit"
                        defaultValue={modalContent.baseRateEdit}
                        labelText="Rate ($)"
                        handleChange={handleChangeOnEdit}
                        adornment="$"
                      />
                    </div>
                    <div className={classes.btn__container}>
                      <Button
                        variant="contained"
                        type="submit"
                        className={classes.btn__submit}
                        disabled={isLoading}
                        sx={{
                          color: "var(--primary-blue-500)",
                          bgcolor: "var(--primary-red-500)",
                          "&:hover": { bgcolor: "var(--primary-red-600)" },
                          marginRight: "10px",
                        }}
                        onClick={handleEditAction}
                      >
                        Edit Staff Type
                      </Button>
                      <Button
                        variant="contained"
                        type="button"
                        className={classes.btn__cancel}
                        sx={{
                          color: "var(--primary-blue-500)",
                          bgcolor: "var(--primary-red-500)",
                          "&:hover": { bgcolor: "var(--primary-red-600)" },
                          marginRight: "10px",
                        }}
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              }
            </div>
          }
        </Box>
      </Modal>
    </main>
  );
};

export default ShiftTypeManagement;
