import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import { Button, TextField } from "@mui/material";
import Papa from "papaparse";
import FormFieldFile from "../../UI/FormField/FormFieldFile/FormFieldFile";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FormField from "../../UI/FormField/FormField";
import { sendEmail } from "../../../app/marketingSlice";
import { LoadingMaterialUI } from "../../UI";
import TextArea from "../../UI/FormField/TextArea/TextArea";
import Subtitle1 from "../../UI/Text/Subtitle/Subtitle1";
import Subtitle2 from "../../UI/Text/Subtitle/Subtitle2";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../../app/globalSlice";

export interface EmailFormState {
  name: string;
  from: string;
  subject: string;
  body: string;
  to: string[];
}

const EmailTool = () => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading }: any = useSelector((state: RootState) => state.marketing);
  const [emailForm, setEmailForm] = useState<EmailFormState>({
    name: "",
    from: "",
    subject: "",
    body: "",
    to: [],
  });
  const [fileName, setFileName] = useState<string>("No file selected");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEmailForm({ ...emailForm, [name]: value });
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    const fileName = file ? file.name : "";
    setFileName(fileName);
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          const emails: any = result.data.flat();
          console.log("🚀🚀🚀🚀 ~ handleFileUpload ~ emails:", emails);
          setEmailForm({ ...emailForm, to: emails });
        },
        skipEmptyLines: true,
      });
    }
  };

  const handleEmailSend = () => {
    if (
      !emailForm.name ||
      !emailForm.from ||
      !emailForm.subject ||
      !emailForm.body
    ) {
      dispatch(displayAlertWarning("Please fill out all fields"));
      return;
    }

    if (emailForm.to.length === 0) {
      dispatch(displayAlertWarning("Please upload the email list"));
      return;
    }

    if (!emailRegex.test(emailForm.from)) {
      dispatch(displayAlertWarning("Please enter a valid email"));
      return;
    }

    if (emailForm.to.some((email) => !emailRegex.test(email))) {
      dispatch(displayAlertWarning("Invalid CSV file format"));
      return;
    }

    dispatch(sendEmail(emailForm)).then((res) => {
      console.log("🚀🚀🚀🚀 ~ dispatch ~ res:", res);

      if (res?.payload) {
        dispatch(displayAlertSuccess("Emails sent successfully!"));
        setEmailForm({ name: "", from: "", subject: "", body: "", to: [] });
        setFileName("No file selected");
      } else {
        dispatch(displayAlertError("Emails failed to send"));
      }
    });
  };

  if (isLoading) {
    return <LoadingMaterialUI />;
  }

  return (
    <div>
      <h2>Email Tool</h2>
      <div className="flex-col w-1/2 gap-4">
        <FormField
          className="mb-4"
          name="name"
          value={emailForm.name}
          label="Sender's Name"
          inputChange={handleChange}
          initialValue={emailForm.name ? emailForm.name : ""}
        />
        <FormField
          className="mb-4"
          name="from"
          value={emailForm.from}
          label="Sender's Email"
          inputChange={handleChange}
          initialValue={emailForm.from ? emailForm.from : ""}
        />
        <div className="flex flex-col">
          <FormFieldFile
            className="mb-4"
            onChange={(e) => {
              handleFileUpload(e);
            }}
            accept=".csv"
            multiple={false}
            text="Upload Email List"
            icon={<FileUploadOutlinedIcon />}
          />
          <Subtitle2 className="flex flex-row-reverse max-w-full">
            {fileName}
          </Subtitle2>
        </div>
        <FormField
          className="mb-4"
          name="subject"
          value={emailForm.subject}
          label="Subject"
          inputChange={handleChange}
          initialValue={emailForm.subject ? emailForm.subject : ""}
        />
        <TextArea
          className="mb-4"
          name="body"
          label="Body"
          inputChange={handleChange}
          value={emailForm.body}
          initialValue={emailForm.body ? emailForm.body : ""}
          rows={8}
          cols={50}
        ></TextArea>
        <Button
          sx={{
            color: "var(--black)",
            bgcolor: "var(--primary-green-400)",
            "&:hover": { bgcolor: "var(--primary-green-500)" },
            borderRadius: "0.5rem",
          }}
          onClick={handleEmailSend}
        >
          Send Email
        </Button>
      </div>
    </div>
  );
};

export default EmailTool;
