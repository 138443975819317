"use client";

import React, { FC, useEffect, useState } from "react";
import { cn } from "../../../lib/utils";
import CheckIcon from "@mui/icons-material/Check";
import * as Checkbox from "@radix-ui/react-checkbox";

interface CheckboxProps {
  /**
   * Should the box be checked by default?
   */
  defaultChecked?: boolean;
  /**
   * Optional label for the checkbox
   */
  label?: string;
  /**
   * Handles passing the state of the checkbox to it's parent component
   */
  inputChange: (val: boolean) => void;
  id?: string;
  error?: boolean;
  className?: string;
  checkedState?: boolean | undefined;
  isDisabled?: boolean;
  /**
   * Classname for the label
   */
  labelClassName?: string;
}

const CheckBox: FC<CheckboxProps> = ({
  id = "c1",
  defaultChecked = false,
  label,
  error,
  inputChange,
  className,
  checkedState = undefined,
  isDisabled = false,
  labelClassName,
  ...props
}: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(defaultChecked);

  useEffect(() => {
    setIsChecked(defaultChecked);
  }, [defaultChecked]);

  return (
    <div className="flex items-center w-full">
      <Checkbox.Root
        className={cn(
          "hover:bg-secondary-opacity-08 flex h-[25px] w-[25px] min-w-[25px] min-h-[25px] border-2 border-neutral-20 appearance-none items-center justify-center rounded-sm bg-white outline-none focus:outline-none data-[state=checked]:bg-primary-50 data-[state=checked]:border-none data-[state=checked]:hover:bg-primary-60 data-[state=checked]:active:bg-primary-70",
          error && "border-error-30",
          className
        )}
        defaultChecked={defaultChecked}
        onCheckedChange={(check: any) => {
          inputChange(check);
          setIsChecked(check);
        }}
        id={id}
        checked={checkedState === undefined ? isChecked : checkedState}
        disabled={isDisabled}
      >
        <Checkbox.Indicator className="text-white">
          <CheckIcon />
        </Checkbox.Indicator>
      </Checkbox.Root>
      {!!label && (
        <label
          className={cn(
            "pl-4 text-base font-normal tracking-wide truncate",
            labelClassName
          )}
          htmlFor={id}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default CheckBox;
