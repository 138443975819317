import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  MenuItem,
  Menu,
  Toolbar,
  Typography,
  Checkbox,
} from '@mui/material';
import Logo from '../../assets/logos/JAMsports_logo.png';
import React, { useState } from 'react';
import { getUser, logout, removeUserToLocalStorage } from '../../app/authSlice';
import { useNavigate } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { MenuProps } from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../app/store';
import { getRegions, handleSelectedRegions } from '../../app/venueMasterSlice';
import { useMutation } from '@apollo/client';
import { GET_USER } from '../../graphql/queries/user';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';

const settings = [
  // { id: 1, text: 'Profile', to: `/profile` },
  { id: 3, text: 'Logout', to: `/logout` },
];

const Navbar = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { regions }: any = useSelector((state: RootState) => state.venueMaster);
  const { user, token }: any = useSelector((state: RootState) => state.auth);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElRegion, setAnchorElRegion] = useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = useState<number | null>(null);
  const [selectedRegions, setSelectedRegions] = React.useState([]);
  const open = Boolean(anchorElRegion);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(parseInt(event.currentTarget.id));
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(null);
  };

  const handleClickRegion = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElRegion(event.currentTarget);
  };

  const handleCloseRegion = () => {
    setAnchorElRegion(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleUserMenuOptions = (
    event: React.MouseEvent<HTMLElement>,
    idx: number
  ) => {
    if (idx === 3) {
      handleLogout();
    }
  };

  const pathname: string = window.location.pathname;

  const handleLogout = () => {
    dispatch(logout());
    removeUserToLocalStorage();
    navigate(`/register`);
  };

  const onChangeRegions = (e: any, index: number) => {
    const regionsCopy: any = JSON.parse(JSON.stringify(selectedRegions));
    regionsCopy[index].selected = e.target.checked;
    setSelectedRegions(regionsCopy);
  };

  const onClickChangeAll = (state: boolean) => {
    const regionsCopy: any = JSON.parse(JSON.stringify(selectedRegions));
    const selectAllRegions = regionsCopy.map((region: any) => ({
      ...region,
      selected: state,
    }));
    setSelectedRegions(selectAllRegions);
  };

  const [GetUser, { data, loading, error }]: any = useMutation(GET_USER);

  React.useEffect(() => {
    dispatch(getRegions(''));
    if (!user) {
      // get User details from token on refresh page
      const token = localStorage.getItem('jwtToken');
      if (!token) {
        navigate(`/register`);
        return;
      }
      const jwtToken = JSON.parse(token);

      GetUser({
        variables: { jwt: jwtToken },
      }).then((res: any) => {
        dispatch(getUser(res.data.getUser));
      });
    }
  }, [dispatch]);

  React.useEffect(() => {
    const regionsCopy: any = [...regions];
    const selectedRegions = regionsCopy.map((region: any) => ({
      ...region,
      selected: true,
    }));
    setSelectedRegions(selectedRegions);
  }, [regions]);

  React.useEffect(() => {
    const selectedRegionsArray = selectedRegions.filter(
      (region: any) => region.selected === true
    );
    dispatch(handleSelectedRegions(selectedRegionsArray));
  }, [selectedRegions]);

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }: any) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light'
          ? 'rgb(55, 65, 81)'
          : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: 'var(--primary-blue-500)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar className="flex justify-between">
        {/* Part 1: JAM ICON */}
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{
            mr: 2,
            paddingBottom: '5px',
            paddingTop: '5px',
          }}
        >
          <Avatar
            variant="square"
            alt="JAM LOGO"
            src={Logo}
            sx={{ height: '37px', width: '100px' }}
          ></Avatar>
        </IconButton>
        <div className="flex">
          <Button
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            disableElevation
            onClick={handleClickRegion}
            style={{ color: 'white', paddingRight: '20px' }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Regions
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            anchorEl={anchorElRegion}
            open={open}
            onClose={handleCloseRegion}
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
          >
            <>
              <MenuItem
                style={{ padding: '0px 10px', justifyContent: 'center' }}
                disableRipple
              >
                <Button
                  size="small"
                  variant="text"
                  onClick={() => onClickChangeAll(true)}
                >
                  SELECT ALL
                </Button>
              </MenuItem>
              <MenuItem
                style={{ padding: '0px 10px', justifyContent: 'center' }}
                disableRipple
              >
                <Button
                  size="small"
                  variant="text"
                  style={{ color: 'gray' }}
                  onClick={() => onClickChangeAll(false)}
                >
                  DESELECT ALL
                </Button>
              </MenuItem>
              {selectedRegions &&
                selectedRegions.map((region: any, index: number) => (
                  <MenuItem
                    key={region.id}
                    style={{ padding: '0px 10px' }}
                    disableRipple
                  >
                    <Checkbox
                      checked={region.selected}
                      style={{ padding: '5px 10px' }}
                      onChange={(e) => onChangeRegions(e, index)}
                    />
                    {region.name}
                  </MenuItem>
                ))}
            </>
          </StyledMenu>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton
              onClick={handleOpenUserMenu}
              sx={{ p: 0, color: 'white' }}
            >
              <PersonOutlineRoundedIcon color="inherit" />
            </IconButton>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting.id}
                  onClick={(event) => {
                    handleUserMenuOptions(event, setting.id);
                  }}
                >
                  <Typography textAlign="center">{setting.text}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
