import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { client } from "../graphql";
import {
  GET_CONTENT_BY_CATEGORY_ID,
  GET_CONTENT_CATEGORIES,
} from "../graphql/queries/contentManagement";
import { SEND_EMAILS } from "../graphql/queries/email";
import { toast as toastStackable } from "sonner";

interface ContentCategory {
  id: number;
  name: string;
}

type Content = {
  id: number;
  category: number;
  region: number | null;
  title: string;
  content: string;
  isDefault: boolean;
};

interface MarketingInitialState {
  isLoading: boolean;
  contentCategories: ContentCategory[];
  contentEdit: Content | {};
}

const initialState: MarketingInitialState = {
  isLoading: false,
  contentCategories: [],
  contentEdit: {},
};

const baseURL = `${
  process.env.NODE_ENV == "production"
    ? "https://admin.jamsports.com"
    : "http://localhost:3001"
}/api/v1`;

export const getContentCategories = createAsyncThunk(
  "marketing/getContentCategories",
  async (_, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_CONTENT_CATEGORIES,
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const contentCategories = response.data.contentCategories;
      return contentCategories;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getContent = createAsyncThunk(
  "marketing/getContent",
  async (contentArgs: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_CONTENT_BY_CATEGORY_ID,
        variables: {
          category: contentArgs.category,
          selectedRegions: contentArgs.selectedRegions,
        },
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const content = response.data.content;
      return content;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const sendEmail = createAsyncThunk(
  "marketing/sendEmail",
  async (emailArgs: any, thunkAPI) => {
    try {
      const response = await client.mutate({
        mutation: SEND_EMAILS,
        variables: {
          name: emailArgs.name,
          from: emailArgs.from,
          to: emailArgs.to,
          subject: emailArgs.subject,
          body: emailArgs.body,
        },
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const email = response.data.sendEmail;
      return email;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const marketingSlice = createSlice({
  name: "marketing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContentCategories.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getContentCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contentCategories = action.payload;
      })
      .addCase(getContentCategories.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Error fetching content categories"
        );
      })
      .addCase(getContent.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getContent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contentEdit = action.payload;
      })
      .addCase(getContent.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.error.message ?? "Error fetching content");
      })
      .addCase(sendEmail.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(sendEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        toastStackable.success("Email sent successfully");
      })
      .addCase(sendEmail.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.error.message ?? "Error sending email");
      });
  },
});

export const marketingSelector = (state: RootState) => state.marketing;

export default marketingSlice.reducer;
