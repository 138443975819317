import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormRow, FormRowSelect, LoadingMaterialUI } from "../../UI";
import classes from "./ThirdPartyContractorManagement.module.css";
import {
  getThirdPartyContractors,
  createThirdPartyContractor,
  updateThirdPartyContractor,
} from "../../../app/shiftSlice";
import { AppDispatch, RootState } from "../../../app/store";
import { Regions, ThirdPartyContractors } from "../../../types/types";
import {
  EPermission,
  filterPermittedRegions,
} from "../../../utils/permissions";
import { displayAlertError } from "../../../app/globalSlice";

const initialState = {
  name: "",
  email: "",
  regionId: "",
};

const initialStateEdit = {
  idEdit: "",
  nameEdit: "",
  emailEdit: "",
  regionIdEdit: "",
};

export interface ThirdPartyManagementProps {
  requiredPermission?: EPermission;
}

const ThirdPartyContractorManagement: React.FC<ThirdPartyManagementProps> = ({
  requiredPermission,
}) => {
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(initialStateEdit);
  const { user } = useSelector((state: RootState) => state.auth);
  const [values, setValues] = useState(initialState);
  // const [editValues, setEditValues] = useState(initialStateEdit);

  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, thirdPartyContractorOptions } = useSelector(
    (state: RootState) => state.shift
  );

  const { selectedRegions }: any = useSelector(
    (state: RootState) => state.venueMaster
  );
  const [permittedRegions, setPermittedRegions] = useState<any>([]);

  useEffect(() => {
    if (!requiredPermission) {
      setPermittedRegions(selectedRegions);
      return;
    }
    // set Permitted Regions
    const permittedRegion = filterPermittedRegions(
      user?.permission,
      requiredPermission
    );
    if (permittedRegion === null) {
      setPermittedRegions(selectedRegions);
      return;
    }
    if (permittedRegion?.length === 0) {
      // Navigate to Permission Denied
      return;
    }
    const displayedRegion: Regions[] = selectedRegions.filter((region: any) =>
      permittedRegion?.find(
        (regionPermitted: any) => regionPermitted.id === region.id
      )
    );
    setPermittedRegions(displayedRegion);
  }, [dispatch, requiredPermission]);

  const handleEditActionModal = (
    thirdPartyContractor: ThirdPartyContractors
  ) => {
    setModalContent({
      idEdit: thirdPartyContractor.id,
      nameEdit: thirdPartyContractor.name,
      emailEdit: thirdPartyContractor.email,
      regionIdEdit: thirdPartyContractor.regionId.toString(),
    });
    setOpen(true);
  };

  const handleEditAction = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(updateThirdPartyContractor(modalContent)).then(() => {
      dispatch(getThirdPartyContractors());
    });
    setOpen(false);
  };

  const handleChangeOnEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModalContent({ ...modalContent, [e.target.name]: e.target.value });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { name, email } = values;
    if (!name || !email) {
      dispatch(displayAlertError("Please provide all the values"));
      return;
    }

    dispatch(createThirdPartyContractor(values)).then(() => {
      dispatch(getThirdPartyContractors());
      setValues(initialState);
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: +e.target.value || e.target.value,
    });
  };

  let existingThirdPartyContractor;

  existingThirdPartyContractor = (
    <div>
      <table className={classes.staff__table}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Region</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {thirdPartyContractorOptions?.map(
            (thirdPartyContractor: ThirdPartyContractors) => {
              const region = selectedRegions.find(
                (region: any) => +region.id === thirdPartyContractor.regionId
              );
              return (
                <tr key={thirdPartyContractor.id}>
                  <td>{thirdPartyContractor.name}</td>
                  <td>{thirdPartyContractor.email}</td>
                  <td>{region?.name}</td>
                  <td>
                    <Button
                      type="button"
                      onClick={() => {
                        handleEditActionModal(thirdPartyContractor);
                      }}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </div>
  );

  useEffect(() => {
    if (!thirdPartyContractorOptions.length) {
      dispatch(getThirdPartyContractors());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getThirdPartyContractors());
  }, [selectedRegions]);

  return (
    <main>
      <h3>Third Party Contractor Management</h3>
      <form
        className={classes.form}
        onSubmit={onSubmit}
      >
        <div className={classes.form__center}>
          <div>
            <FormRowSelect
              name="regionId"
              value={values.regionId}
              handleChange={handleChange}
              list={[...permittedRegions]}
              labelText="Region"
            />
          </div>
          <div>
            <FormRow
              name="name"
              value={values.name}
              labelText="Name"
              handleChange={handleChange}
            />
          </div>
          <div>
            <FormRow
              type="email"
              name="email"
              value={values.email}
              labelText="Email"
              handleChange={handleChange}
            />
          </div>
          <div className={classes.btn__container}>
            <Button
              variant="contained"
              type="submit"
              className={classes.btn__submit}
              disabled={isLoading}
              sx={{
                color: "var(--primary-blue-500)",
                bgcolor: "var(--primary-red-500)",
                "&:hover": { bgcolor: "var(--primary-red-600)" },
              }}
            >
              Create A Contractor
            </Button>
          </div>
        </div>
      </form>
      <div className={classes.existingThirdPartyContractor}>
        <h4>Existing Third Party Contractors</h4>
        {isLoading && <LoadingMaterialUI />}

        {thirdPartyContractorOptions.length !== 0 ? (
          existingThirdPartyContractor
        ) : (
          <div>Nothing Yet</div>
        )}
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          {
            <div>
              {
                <form
                  className={classes.form}
                  onSubmit={onSubmit}
                >
                  <div className={classes.form__center}>
                    <div>
                      <FormRowSelect
                        name="regionIdEdit"
                        value={modalContent.regionIdEdit}
                        handleChange={handleChangeOnEdit}
                        list={[
                          ...permittedRegions,
                          ...[{ id: 0, name: "All" }],
                        ]}
                        labelText="Region"
                      />
                    </div>
                    <div>
                      <FormRow
                        name="nameEdit"
                        defaultValue={modalContent.nameEdit}
                        labelText="Name"
                        handleChange={handleChangeOnEdit}
                      />
                    </div>
                    <div>
                      <FormRow
                        type="email"
                        name="emailEdit"
                        defaultValue={modalContent.emailEdit}
                        labelText="Email"
                        handleChange={handleChangeOnEdit}
                      />
                    </div>
                    <div className={classes.btn__container}>
                      <Button
                        variant="contained"
                        type="submit"
                        className={classes.btn__submit}
                        disabled={isLoading}
                        sx={{
                          color: "var(--primary-blue-500)",
                          bgcolor: "var(--primary-red-500)",
                          "&:hover": { bgcolor: "var(--primary-red-600)" },
                          marginRight: "10px",
                        }}
                        onClick={handleEditAction}
                      >
                        Edit contractor
                      </Button>
                      <Button
                        variant="contained"
                        type="button"
                        className={classes.btn__cancel}
                        sx={{
                          color: "var(--primary-blue-500)",
                          bgcolor: "var(--primary-red-500)",
                          "&:hover": { bgcolor: "var(--primary-red-600)" },
                          marginRight: "10px",
                        }}
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              }
            </div>
          }
        </Box>
      </Modal>
    </main>
  );
};

export default ThirdPartyContractorManagement;
