import React, { useEffect, useState } from "react";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import Button from "../../UI/Button/Button";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_SHIFTS_BEFORE_CUTOFF_DATE,
  GET_SHIFT_PAYMENT_STATUSES,
  SHIFTS_READY_FOR_PAYMENT,
} from "../../../graphql/queries/shifts";
import {
  PayrollBatch,
  Regions,
  Shift,
  ShiftPaymentStatus,
} from "@/src/types/types";
import { DataTable } from "../../UI/Table/DataTable";
import { ColumnDef, RowSelectionState } from "@tanstack/react-table";
import { Checkbox } from "../../UI/shadcn/checkbox";
import dayjs from "dayjs";
import { GET_UNPAID_PAYROLL_BATCHES } from "../../../graphql/queries/payroll";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import {
  EPermission,
  filterPermittedRegions,
} from "../../../utils/permissions";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import {
  ShiftsBeforeCutoffDateQuery,
  useShiftsBeforeCutoffDateLazyQuery,
} from "../../../generated/graphql";
import {
  displayAlertError,
  displayAlertSuccess,
} from "../../../app/globalSlice";

interface ShiftsFilter {
  payrollBatchId: string;
  shiftCutoffDate: string;
  shiftPaymentStatusId: string;
  regionId: string;
  regionIds: number[];
  page?: number;
}

export interface ShiftsForReviewProps {
  requiredPermission?: EPermission;
}

const ShiftsForReview: React.FC<ShiftsForReviewProps> = ({
  requiredPermission,
}) => {
  const { isLoading } = useSelector((state: RootState) => state.shift);
  const { selectedRegions } = useSelector(
    (state: RootState) => state.venueMaster
  );
  const { user } = useSelector((state: RootState) => state.auth);

  const pageSize = 50;

  const dispatch = useDispatch<AppDispatch>();
  const [shiftsBeforeCutoffDate, setShiftsBeforeCutoffDate] =
    useState<ShiftsBeforeCutoffDateQuery["shiftsBeforeCutoffDate"]["shifts"]>();
  const [selectedShifts, setSelectedShifts] = useState<number[]>([]);
  const [shiftFilters, setShiftFilters] = useState<ShiftsFilter>({
    payrollBatchId: "0",
    shiftCutoffDate: "",
    shiftPaymentStatusId: "0",
    regionId: "0",
    regionIds: [],
  });
  const [payrollBatches, setPayrollBatches] = useState<PayrollBatch[]>([]);
  const [shiftPaymentStatuses, setShiftPaymentStatuses] = useState<
    ShiftPaymentStatus[]
  >([]);
  const [permittedRegions, setPermittedRegions] = useState<any>([]);

  const [getShiftsBeforeCutoffDate, { data: shiftsData, loading, error }] =
    useShiftsBeforeCutoffDateLazyQuery({
      fetchPolicy: "no-cache",
    });
  const [
    getUnpaidPayrollBatches,
    { data: payrollBatchData, loading: loadingPayroll, error: errorPayroll },
  ] = useLazyQuery(GET_UNPAID_PAYROLL_BATCHES, {
    fetchPolicy: "network-only",
  });
  const [
    getShiftPaymentStatuses,
    {
      data: shiftPaymentStatusData,
      loading: loadingShiftPaymentStatus,
      error: errorShiftPaymentStatus,
    },
  ] = useLazyQuery(GET_SHIFT_PAYMENT_STATUSES);

  const [shiftsReadyForPayment, { loading: loadingShiftsReadyForPayment }] =
    useMutation(SHIFTS_READY_FOR_PAYMENT, {});

  async function handleShiftsReadyForPayment() {
    const res = await shiftsReadyForPayment({
      variables: {
        shiftsId: selectedShifts,
      },
    });
    if (res) {
      if (res.data.shiftsReadyForPayment.success) {
        dispatch(displayAlertSuccess("All shifts are ready for payment"));
      } else if (res.data.shiftsReadyForPayment.message) {
        dispatch(displayAlertSuccess(res.data.shiftsReadyForPayment.message));
      } else {
        dispatch(displayAlertError("Something went wrong"));
      }
    } else {
      dispatch(displayAlertError("Something went wrong"));
    }
  }

  useEffect(() => {
    getShiftPaymentStatuses().then((data) => {
      if (data.data.shiftPaymentStatuses) {
        const statuses = data.data.shiftPaymentStatuses.filter(
          (status: ShiftPaymentStatus) => {
            return status.id == 1 || status.id == 2;
          }
        );
        setShiftPaymentStatuses(statuses);
      }
    });
    getUnpaidPayrollBatches().then((data) => {
      setPayrollBatches(data.data.payrollBatchesUnpaid);
    });
  }, []);

  useEffect(() => {
    console.log(shiftFilters);
    if (Number(shiftFilters.payrollBatchId) <= 0) {
      return;
    }
    getShiftsBeforeCutoffDate({
      variables: {
        date: shiftFilters?.shiftCutoffDate || "",
        shiftPaymentStatusId: shiftFilters?.shiftPaymentStatusId || "",
        regionId: shiftFilters?.regionId || "0",
        regionIds: shiftFilters?.regionIds || [],
        page: shiftFilters?.page || 0,
        pageSize: pageSize,
      },
    }).then((data) => {
      if (!data.data) {
        dispatch(displayAlertError("Something went wrong"));
        return;
      }
      const shifts = data.data.shiftsBeforeCutoffDate.shifts;
      if (shiftFilters.page === 0) setShiftsBeforeCutoffDate(shifts);
      else
        setShiftsBeforeCutoffDate((prevState) => [
          ...(prevState || []),
          ...shifts,
        ]);
    });
  }, [shiftFilters]);

  useEffect(() => {
    if (!requiredPermission) {
      setPermittedRegions(selectedRegions);
      return;
    }
    // set Permitted Regions
    const permittedRegion = filterPermittedRegions(
      user?.permission,
      requiredPermission
    );
    if (permittedRegion === null) {
      setPermittedRegions(selectedRegions);
      return;
    }
    if (permittedRegion?.length === 0) {
      // Navigate to Permission Denied
      return;
    }
    const displayedRegion: Regions[] = selectedRegions.filter((region: any) =>
      permittedRegion?.find(
        (regionPermitted: any) => regionPermitted.id === region.id
      )
    );

    const permittedRegionIds = displayedRegion.map((region) => +region.id);

    setPermittedRegions(displayedRegion);
    setShiftFilters((prevState) => ({
      ...prevState,
      regionIds: permittedRegionIds,
      page: 0,
    }));
  }, [dispatch, requiredPermission, selectedRegions]);

  const columnShifts: ColumnDef<
    ShiftsBeforeCutoffDateQuery["shiftsBeforeCutoffDate"]["shifts"][0]
  >[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            table.getIsSomePageRowsSelected()
          }
          onCheckedChange={(value) => {
            setSelectedShifts(
              value ? shiftsBeforeCutoffDate?.map((row) => +row.id) ?? [] : []
            );

            table.setRowSelection((current) => {
              const newSelection: RowSelectionState = {};
              for (let i = 0; i < table.getRowCount(); i++) {
                const row = table.getRow(i.toString());
                newSelection[i] = !!value;
              }
              return newSelection;
            });
          }}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => {
        return (
          <>
            <Checkbox
              checked={row.getIsSelected()}
              onCheckedChange={(value) => {
                if (value) {
                  setSelectedShifts((shifts) => [...shifts, +row.original.id]);
                } else {
                  setSelectedShifts((prevSelectedShifts) =>
                    prevSelectedShifts?.filter((id) => +id !== +row.original.id)
                  );
                }
                row.toggleSelected(!!value);
              }}
              aria-label="Select row"
            />
          </>
        );
      },
    },
    {
      accessorKey: "id",
      header: "ID",
    },
    {
      accessorFn: (row) => {
        return `${dayjs.utc(row.startLocal).format("YYYY-MM-DD")}`;
      },
      header: "Date",
    },
    // day
    {
      header: "Day",
      accessorFn: (row) => {
        return `${dayjs.utc(row.startLocal).format("dddd")}`;
      },
    },
    {
      accessorFn: (row) => {
        return `${dayjs.utc(row.startLocal).format("h:mma")} - ${dayjs
          .utc(row.endLocal)
          .format("h:mma")}`;
      },
      header: "Time",
    },
    // Region
    {
      header: "Region",
      accessorFn: (row) => {
        return `${row.region?.name}`;
      },
    },
    {
      header: "Location",
      accessorFn: (row) => {
        return `${row.venue?.name}`;
      },
    },
    // {f and l name}
    {
      header: "Staff Name",
      accessorFn: (row) => {
        return `${row.user?.firstName} ${row.user?.lastName}`;
      },
    },
    // staff type
    {
      header: "Staff Type",
      accessorFn: (row) => {
        return `${row.staffType?.name}`;
      },
    },
    // shiftStatus
    {
      header: "Shift Status",
      accessorFn: (row) => {
        return `${row.shiftStatus?.value}`;
      },
    },
    // shiftReportStatus
    {
      header: "Shift Report Status",
      accessorFn: (row) => {
        return `${
          row.shiftReport?.shiftReportStatusId === "2"
            ? "Reported"
            : "Not Reported"
        }`;
      },
    },
    // Pay rate
    {
      header: "Rate",
      accessorFn: (row) => {
        return `${row.rate?.toFixed(2)}`;
      },
    },
    // hours
    {
      header: "Hours",
      accessorFn: (row) => {
        return `${row.hours?.toFixed(2)}`;
      },
    },
    // amount
    {
      header: "Amount",
      accessorFn: (row) => {
        return `${row.amount?.toFixed(2)}`;
      },
    },
    // Payroll Status
    {
      header: "Payroll Status",
      accessorFn: (row) => {
        return `${
          row.payrollBatch?.payrollBatchStatus?.name
            ? row.payrollBatch?.payrollBatchStatus?.name
            : "Not Paid"
        }`;
      },
    },
  ];

  if (
    loading ||
    isLoading ||
    loadingPayroll ||
    loadingShiftPaymentStatus ||
    loadingShiftsReadyForPayment
  )
    return <LoadingDialog open={loading} />;
  if (error) return <div>Something went wrong</div>;

  const handlePageChange = () => {
    setShiftFilters((prevState) => ({
      ...prevState,
      page: (shiftFilters.page || 0) + 1,
    }));
  };

  const paginationButton = () => {
    if (!shiftsData) return false;
    else if (
      shiftsData.shiftsBeforeCutoffDate.count >= pageSize &&
      ((shiftFilters.page || 0) + 1) * pageSize <
        shiftsData.shiftsBeforeCutoffDate.count
    ) {
      return true;
    }
  };
  return (
    <main>
      <Headline1Variable>Shifts For Review</Headline1Variable>
      <div className="flex flex-row flex-wrap items-end gap-4 mb-4">
        <div className="w-64">
          <FormFieldSelect
            name="payrollBatch"
            label="Payroll Batch"
            placeholder="Payroll Batch"
            value={shiftFilters.payrollBatchId}
            inputChange={(value: string) => {
              const selectedPayrollBatch = payrollBatches.find(
                (payrollBatch) => {
                  return payrollBatch.id == +value;
                }
              );
              const cutoffDate = selectedPayrollBatch?.shiftCutoffDate;

              setShiftFilters((prevState) => ({
                ...prevState,
                payrollBatchId: value,
                shiftCutoffDate: cutoffDate?.toString() ?? "",
                page: 0,
              }));
            }}
          >
            {[{ id: "0", name: "Select Payroll Batch" }, ...payrollBatches]}
          </FormFieldSelect>
        </div>
        <div className="w-64">
          <FormFieldSelect
            name="shiftPaymentStatusId"
            label="Shift Payment Status"
            placeholder="Shift Payment Status"
            value={shiftFilters.shiftPaymentStatusId}
            inputChange={(value: string) => {
              setShiftFilters((prevState) => ({
                ...prevState,
                shiftPaymentStatusId: value,
                page: 0,
              }));
            }}
          >
            {[
              { id: "0", name: "Select Shift Payment Status" },
              ...shiftPaymentStatuses,
            ]}
          </FormFieldSelect>
        </div>
        <div className="w-64">
          <FormFieldSelect
            name="regionId"
            label="Region"
            placeholder="Region"
            value={shiftFilters.regionId}
            inputChange={(value: string) => {
              if (value === "0") {
                const selectedRegion = permittedRegions.map((region: any) => {
                  return +region.id;
                });
                if (selectedRegion) {
                  setShiftFilters((prevState) => ({
                    ...prevState,
                    regionId: value,
                    regionIds: selectedRegion,
                    page: 0,
                  }));
                }
              } else {
                setShiftFilters((prevState) => ({
                  ...prevState,
                  regionId: value,
                  regionIds: [+value],
                  page: 0,
                }));
              }
            }}
          >
            {[{ id: "0", name: "All" }, ...permittedRegions]}
          </FormFieldSelect>
        </div>
        <div>
          <Button
            variant="secondary"
            onClick={() => {
              setShiftFilters({
                payrollBatchId: "0",
                shiftCutoffDate: "",
                shiftPaymentStatusId: "0",
                regionId: "0",
                regionIds: [],
                page: 0,
              });
              setShiftsBeforeCutoffDate([]);
              setSelectedShifts([]);
            }}
          >
            Clear All
          </Button>
        </div>
      </div>
      <div className="flex flex-row items-end w-full gap-4 mb-2">
        <Button
          className="w-[280px] h-fit"
          // variant="primary"
          variant={selectedShifts.length > 0 ? "primary" : "disabled"}
          disabled={!selectedShifts.length}
          onClick={handleShiftsReadyForPayment}
        >
          Ready for Payment
        </Button>
      </div>
      <div>
        {shiftsBeforeCutoffDate && (
          <DataTable
            data={shiftsBeforeCutoffDate}
            columns={columnShifts}
          />
        )}
      </div>
      {paginationButton() && (
        <div className="pb-10 mt-3">
          <Button
            variant="primary"
            onClick={() => handlePageChange()}
          >
            Load 50 More
          </Button>
        </div>
      )}
    </main>
  );
};

export default ShiftsForReview;
