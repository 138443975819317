'use client';

import React, { FC, HTMLProps, useEffect, useState } from 'react';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { cn } from '../../../../lib/utils';

interface FormFieldProps extends HTMLProps<HTMLInputElement> {
  /**
   * The placeholder for the formfield
   */
  placeholder?: string;
  /**
   * The label for the formfield (text above the field)
   */
  label?: string;
  /**
   * The assitive text for the field (text under the field)
   */
  assistiveText?: string;
  /**
   * Set to true if the input should be disabled.
   */
  disabled?: boolean;
  /**
   * Set to true if the input should be showing an error state
   */
  error?: boolean;
  /**
   * The function that is called whenever the input changes
   */
  inputChange: (data: any) => void;
  /**
   * An intitial value for the form to have
   */
  initialValue?: string;
  /**
   * Custom classname attribtues
   */
  className?: string;

  visibility?: string;
  type?: string;
  /**
   *  The number of rows
   */
  rows: number;
  /**
   *  The number of columns
   */
  cols: number;
}

const TextArea: FC<FormFieldProps> = ({
  name,
  placeholder,
  label,
  assistiveText,
  disabled,
  error,
  inputChange,
  initialValue,
  className,
  visibility,
  type = 'string',
  rows,
  cols,
  ...props
}: FormFieldProps) => {
  const [inputVal, setInputVal] = useState(initialValue ? initialValue : '');

  useEffect(() => {
    setInputVal(initialValue ? initialValue : '');
  }, [initialValue]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    inputChange(e);
    setInputVal(e.target.value);
  };

  const mode = disabled
    ? 'border-2 border-neutral-60 bg-neutral-90 focus:outline-none'
    : error
    ? 'border border-error-30 hover:border-error-30 focus:outline-2 focus:outline-error-30'
    : 'border border-secondary-80 hover:border-secondary-50 focus:outline-2 focus:outline-primary-80';

  return (
    <div
      className={`flex flex-col w-full text-black ${
        visibility === 'hidden' ? 'invisible' : 'visible'
      }`}
    >
      {label && (
        <label
          className={`px-3 text-xs font-medium ${
            error && !disabled && 'text-error-10'
          }`}
        >
          {label}
        </label>
      )}
      <div className="relative flex flex-row items-center gap-2">
        <textarea
          name={name}
          placeholder={placeholder}
          className={cn(
            'w-full font-normal rounded-xl px-3 py-2',
            disabled
              ? 'border-2 border-neutral-60 bg-neutral-90 focus:outline-none'
              : error
              ? 'border border-error-30 hover:border-error-30 focus:outline-2 focus:outline-error-30'
              : 'border border-secondary-80 hover:border-secondary-50 focus:outline-2 focus:outline-primary-80',
            className
          )}
          rows={rows || 4}
          cols={cols || 50}
          value={inputVal}
          onChange={(e) => handleChange(e)}
          onWheel={(e) => e.currentTarget.blur()}
        />
        {/* The error icon will appear to the right of the input if it's in an error state */}
        {error && (
          <ErrorOutlineOutlinedIcon className="absolute right-2 text-error-30" />
        )}
      </div>
      {assistiveText && (
        <div
          className={`px-3 text-xxs font-normal ${
            error && !disabled ? 'text-error-10' : 'text-light'
          }`}
        >
          {assistiveText}
        </div>
      )}
    </div>
  );
};

export default TextArea;
