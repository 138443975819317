import React from "react";
import {
  Table as TableBase,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTable, useSortBy, Column, Row } from "react-table";
import TablePagination from "../Pagination/Pagination";
import { Pagination } from "../../../types/types";

type TableProps<T extends object> = {
  columns: readonly Column<T>[];
  data: readonly T[] | undefined;
  pagination?: Pagination;
  handleChangePagination?: (value: number) => void;
  showPagination?: boolean;
  hiddenColumns?: string[];
};

function BaseTable<T extends object>({
  columns,
  data,
  pagination,
  handleChangePagination,
  showPagination = true,
  hiddenColumns = [],
}: TableProps<T>) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
  } = useTable<T>(
    {
      columns,
      data: data || [],
      initialState: {
        hiddenColumns: hiddenColumns,
      },
    },
    useSortBy
  );

  const hasFooter = footerGroups
    .map((group) =>
      group.headers.map((header) => {
        return (
          header.Footer?.toString().includes("function emptyRenderer()") ??
          false
        );
      })
    )
    .some((group) => group.includes(false));

  return (
    <TableContainer
      sx={{
        width: "95vw",
        maxWidth: "100%",
        bgcolor: "white",
        borderRadius: "12px",
        maxHeight: "100vh",
      }}
    >
      <TableBase
        stickyHeader
        aria-label="sticky table"
        sx={{
          maxHeight: "70vh",
        }}
        {...getTableProps()}
      >
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow
              {...headerGroup.getHeaderGroupProps()}
              sx={{ zIndex: 1 }}
            >
              {headerGroup.headers.map((column) => (
                <TableCell
                  sx={{
                    bgcolor: "white",
                    color: "var(--textColor)",
                    fontSize: "16px",
                    textAlign: "left",
                    fontWeight: 550,
                    borderColor: "var(--table-border)",
                    zIndex: "inherit",
                  }}
                  {...column.getHeaderProps()}
                >
                  <div>{column.render("Header")}</div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row: Row<T>) => {
            prepareRow(row);
            return (
              <TableRow
                hover
                sx={{
                  borderBottom: "5px",
                  borderWidth: "2px",
                }}
                role="checkbox"
                tabIndex={-1}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <TableCell
                    {...cell.getCellProps()}
                    sx={{
                      textAlign: "left",
                      fontSize: "15px",
                      color: "var(--textColor)",
                      borderColor: "var(--table-border)",
                      paddingBottom: "8px",
                      paddingTop: "8px",
                    }}
                  >
                    {cell.render("Cell")}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
        {hasFooter && (
          <TableFooter>
            {footerGroups.map((footerGroup) => (
              <TableRow
                hover
                sx={{
                  borderBottom: "5px",
                  borderWidth: "2px",
                }}
                role="checkbox"
                tabIndex={-1}
                {...footerGroup.getFooterGroupProps()}
              >
                {footerGroup.headers.map((column) => (
                  <TableCell
                    {...column.getFooterProps()}
                    sx={{
                      textAlign: "left",
                      fontSize: "15px",
                      color: "var(--textColor)",
                      borderColor: "var(--table-border)",
                      paddingBottom: "8px",
                      paddingTop: "8px",
                    }}
                  >
                    {column.render("Footer")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableFooter>
        )}
      </TableBase>
      {showPagination &&
        pagination !== undefined &&
        handleChangePagination !== undefined && (
          <div className="flex justify-end mt-4">
            <TablePagination
              page={pagination?.page || 0}
              pageCount={pagination?.pageCount || 0}
              onChange={handleChangePagination}
            />
          </div>
        )}
    </TableContainer>
  );
}

export default BaseTable;
