import React, { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../app/store';
import classes from './SportFormats.module.css';
import { Column } from 'react-table';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Pagination, SportFormat } from '../../types/types';
import {
  getGenderFormat,
  getOfficiatedStatus,
  getSports,
  getSportsFormat,
  handleSportsFormatFilter,
} from '../../app/venueMasterSlice';
import BaseTable from '../UI/Table/Table';
import Button from '../UI/Button/Button';
import Headline1Variable from '../UI/Text/Headline/Headline1Variable';
import { FormFieldSelect } from '../UI/FormField/FormFieldDropdown/FormFieldSelectV2';
import TablePagination from '../UI/Pagination/Pagination';

const SportFormats: React.FC = () => {
  //Pagination Page Size
  const pageSize = 25;

  const {
    sportsFormatFilter,
    sportsFormat,
    selectedRegions,
    sports,
    genderFormat,
    officiatedStatuses,
    sportsFormatCount,
    isLoading,
  }: any = useSelector((state: RootState) => state.venueMaster);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const rootPath =
    process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : '';

  const [pagination, setPagination] = React.useState<null | Pagination>(null);

  const redirectCreateSportFormat = () => {
    navigate(`${rootPath}/ops/sports-format/`);
  };

  const redirectEditSportFormat = (SportFormatId: number) => {
    navigate(`${rootPath}/ops/sport-format/${SportFormatId}`, {
      state: { SportFormatId },
    });
  };

  const handleChange = (name: string, value: number) => {
    dispatch(
      handleSportsFormatFilter({
        ...sportsFormatFilter,
        [name]: value === 0 ? null : value,
      })
    );
  };

  const handleChangeRegion = (value: any) => {
    const regions = value !== 0 ? [value] : null;
    dispatch(
      handleSportsFormatFilter({ ...sportsFormatFilter, regions: regions })
    );
  };

  useEffect(() => {
    dispatch(getGenderFormat(''));
    dispatch(getSports(''));
    dispatch(getOfficiatedStatus(''));
    dispatch(
      getSportsFormat({
        filters: sportsFormatFilter,
        page: pagination?.page,
        pageSize: pagination?.pageSize,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      getSportsFormat({
        filters: sportsFormatFilter,
        page: pagination?.page,
        pageSize: pagination?.pageSize,
      })
    );
  }, [selectedRegions]);

  useEffect(() => {
    if (pagination) {
      dispatch(
        getSportsFormat({
          filters: sportsFormatFilter,
          page: pagination?.page,
          pageSize: pagination?.pageSize,
        })
      );
    }
  }, [pagination]);

  useEffect(() => {
    const page = 0;
    const pageCount = Math.ceil(sportsFormatCount / pageSize);
    setPagination({
      page,
      pageSize,
      pageCount,
    });
  }, [sportsFormatCount]);

  const onSubmit = () => {
    dispatch(
      getSportsFormat({
        filters: sportsFormatFilter,
        page: pagination?.page,
        pageSize: pagination?.pageSize,
      })
    );
  };

  const handleChangePagination = (value: number) => {
    setPagination({
      pageCount: pagination?.pageCount,
      pageSize: pagination?.pageSize,
      page: value - 1,
    });
  };

  const COLUMNS: Column<SportFormat>[] = [
    {
      Header: 'SportFormatId#',
      accessor: 'id',
    },
    {
      Header: 'Region',
      id: 'region',
      accessor: (d: any) => {
        return <div>{[d.region.name]}</div>;
      },
    },
    {
      Header: 'Sport',
      id: 'sport',
      accessor: (d: any) => {
        return <div>{[d.sport.name]}</div>;
      },
    },
    {
      Header: 'Name',
      id: 'name',
      accessor: (d: any) => {
        return <div>{d['name']}</div>;
      },
    },
    {
      Header: 'Gender Format',
      id: 'genderFormat',
      accessor: (d: any) => {
        return <div>{[d.genderFormat.name]}</div>;
      },
    },
    {
      Header: 'Officiated Status',
      id: 'officiatedStatus',
      accessor: (d: any) => {
        return <div>{[d.officiatedStatus.name]}</div>;
      },
    },
    {
      Header: 'People on Field',
      id: 'peopleOnField',
      accessor: (d: any) => {
        return <div>{d['peopleOnField']}</div>;
      },
    },
    {
      Header: 'Minimum Men',
      id: 'minMen',
      accessor: (d: any) => {
        return <div>{d['minMen']}</div>;
      },
    },
    {
      Header: 'Minimum Women',
      id: 'minWomen',
      accessor: (d: any) => {
        return <div>{d['minWomen']}</div>;
      },
    },
    {
      Header: 'Max Roster Size Team',
      id: 'maxRosterSizeTeam',
      accessor: (d: any) => {
        return <div>{d['maxRosterSizeTeam']}</div>;
      },
    },
    {
      Header: 'Options',
      accessor: (d: any) => {
        return (
          <div className={classes.format__options}>
            <div className={classes.format__option}>
              <Button
                variant='primary'
                onClick={() => redirectEditSportFormat(d.id)}>
                {<EditIcon />}{' '}
              </Button>
            </div>
          </div>
        );
      },
    },
  ];

  const columns: any = useMemo(() => COLUMNS, []);
  const data = useMemo(() => sportsFormat, [sportsFormat]);

  return (
    <main>
      <Headline1Variable>Sport Format</Headline1Variable>
      <div className={classes.container}>
        <Fragment>
          <div className='flex gap-5 my-5 w-2/3'>
            <FormFieldSelect
              name='regionId'
              value={sportsFormatFilter?.regionId}
              inputChange={(value) => {
                handleChangeRegion(+value);
              }}
              label='Region'
              placeholder='Select Region'>
              {[...selectedRegions, ...[{ id: '0', name: 'All' }]]}
            </FormFieldSelect>
            <FormFieldSelect
              name='genderFormatId'
              value={sportsFormatFilter.genderFormatId?.toString() || '0'}
              inputChange={(value) => {
                handleChange('genderFormatId', +value);
              }}
              label='Gender Format'
              placeholder='Select Gender Format'>
              {[...genderFormat, ...[{ id: '0', name: 'All' }]]}
            </FormFieldSelect>
            <FormFieldSelect
              name='sportId'
              value={sportsFormatFilter.sportId?.toString() || '0'}
              inputChange={(value) => {
                handleChange('sportId', +value);
              }}
              label='Sport'
              placeholder='Select Sport'>
              {[...sports, ...[{ id: '0', name: 'All' }]]}
            </FormFieldSelect>
            <FormFieldSelect
              name='officiatedStatusId'
              value={sportsFormatFilter.officiatedStatusId?.toString() || '0'}
              inputChange={(value) => {
                handleChange('officiatedStatusId', +value);
              }}
              label='Officiated Status'
              placeholder='Select Officiated Status'>
              {[...officiatedStatuses, ...[{ id: '0', name: 'All' }]]}
            </FormFieldSelect>
            <Button
              className='w-1/2 h-1/2 m-auto'
              variant='primary'
              onClick={onSubmit}>
              Apply filters
            </Button>
          </div>
        </Fragment>
        <div className='text-right mb-4'>
          <Button variant='primary' onClick={redirectCreateSportFormat}>
            <AddIcon />
            Add New Sport Format
          </Button>
        </div>
        {sportsFormat.length > 0 && <BaseTable columns={columns} data={data} />}
        {pagination && (
          <div className='flex justify-end mt-4'>
            <TablePagination
              page={pagination?.page || 0}
              pageCount={pagination?.pageCount || 0}
              onChange={handleChangePagination}
            />
          </div>
        )}
        {!(sportsFormat.length > 0) && (
          <div className={classes.table__contracts}>No Data Found</div>
        )}
      </div>
    </main>
  );
};

export default SportFormats;
