import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "../Button/Button";
import Headline2Variable from "../Text/Headline/Headline2Variable";
import ScheduleBackup from "../ScheduleBackup";

interface ScheduleHistoryDialogProps {
  schedule: any[];
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onRestoreSchedule: () => void;
}

/**
 * A dialog that allows the user to swap two teams
 * @param schedule Array of schedule rows
 * @param open Controls whether the dialog is open or not
 * @param onOpenChange Function to change the open state
 * @param onRestoreSchedule Function to restore the schedule
 * @returns
 */
const ScheduleHistoryDialog = ({
  schedule,
  open,
  onOpenChange,
  onRestoreSchedule,
}: ScheduleHistoryDialogProps) => {
  return (
    <Dialog
      open={open}
      maxWidth="xl"
      aria-labelledby="responsive-dialog-title"
      sx={{
        zIndex: 30,
      }}
    >
      <div className="flex flex-row md:justify-between">
        <DialogTitle
          sx={{
            paddingBottom: "0px",
          }}
        >
          <Headline2Variable>Schedule Backup</Headline2Variable>
        </DialogTitle>
      </div>
      <DialogContent>
        <div className="flex gap-5 mb-3">
          <ScheduleBackup rows={schedule} />
        </div>
        <DialogActions>
          <Button
            variant="secondary"
            onClick={() => onOpenChange(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => onRestoreSchedule()}
          >
            Restore Schedule
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ScheduleHistoryDialog;
