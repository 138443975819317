import React, { useEffect, useState } from "react";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import Button from "../../UI/Button/Button";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_SHIFTS_BEFORE_CUTOFF_DATE,
  GET_SHIFT_PAYMENT_STATUSES,
  SHIFTS_MARKED_AS_PAID,
  SHIFTS_READY_FOR_PAYMENT,
  SHIFTS_SUMMARY,
} from "../../../graphql/queries/shifts";
import { PayrollBatch, Shift, ShiftPaymentStatus } from "@/src/types/types";
import { DataTable } from "../../UI/Table/DataTable";
import { ColumnDef, RowSelectionState } from "@tanstack/react-table";
import { Checkbox } from "../../UI/shadcn/checkbox";
import dayjs from "dayjs";
import FormFieldDropdown from "../../UI/FormField/FormFieldDropdown/FormFieldDropdown";
import { GET_UNPAID_PAYROLL_BATCHES } from "../../../graphql/queries/payroll";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import { Box, Modal } from "@mui/material";
import Headline2Variable from "../../UI/Text/Headline/Headline2Variable";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import {
  displayAlertError,
  displayAlertSuccess,
} from "../../../app/globalSlice";
import { numberToMoney } from "../../../utils/financialHelpers";

interface SelectedShiftsSummary {
  numOfShifts: number;
  totalAmount: number;
  totalHours: number;
}

const ShiftsForPayroll = () => {
  const { isLoading } = useSelector((state: RootState) => state.shift);
  const dispatch = useDispatch<AppDispatch>();
  const [shiftsBeforeCutoffDate, setShiftsBeforeCutoffDate] =
    useState<Shift[]>();
  const [selectedShifts, setSelectedShifts] = useState<number[]>([]);
  const [shiftFilters, setShiftFilters] = useState({
    payrollBatchId: "0",
    shiftCutoffDate: "",
    shiftPaymentStatusId: "3",
    page: 0,
  });
  const [payrollBatches, setPayrollBatches] = useState<PayrollBatch[]>([]);
  const [confirmPayrollShiftsModal, setConfirmPayrollShiftsModal] =
    useState<boolean>(false);
  const [selectedShiftsSummary, setSelectedShiftsSummary] =
    useState<SelectedShiftsSummary>({
      numOfShifts: 0,
      totalAmount: 0,
      totalHours: 0,
    });

  const pageSize = 50;

  const [getShiftsBeforeCutoffDate, { data: shiftsData, loading, error }] =
    useLazyQuery(GET_SHIFTS_BEFORE_CUTOFF_DATE, {
      variables: {
        date: shiftFilters?.shiftCutoffDate || "",
        shiftPaymentStatusId: "3",
        regionIds: [],
        page: shiftFilters.page,
        pageSize: pageSize,
      },
      fetchPolicy: "network-only",
    });
  const [
    getUnpaidPayrollBatches,
    { data: payrollBatchData, loading: loadingPayroll, error: errorPayroll },
  ] = useLazyQuery(GET_UNPAID_PAYROLL_BATCHES, {
    fetchPolicy: "network-only",
  });
  const [
    getShiftPaymentStatuses,
    {
      data: shiftPaymentStatusData,
      loading: loadingShiftPaymentStatus,
      error: errorShiftPaymentStatus,
    },
  ] = useLazyQuery(GET_SHIFT_PAYMENT_STATUSES);

  const [shiftsMarkedAsPaid, { loading: shiftsMarkedAsPaidLoading }] =
    useMutation(SHIFTS_MARKED_AS_PAID, {
      onCompleted: (data) => {
        if (data) {
          getShiftsBeforeCutoffDate().then((data) => {
            setShiftsBeforeCutoffDate(data.data.shiftsBeforeCutoffDate.shifts);
          });
        }
      },
    });

  const [
    getShiftsSummary,
    {
      data: shiftsSummaryData,
      loading: shiftsSummaryLoading,
      error: shiftsSummaryError,
    },
  ] = useLazyQuery(SHIFTS_SUMMARY, {
    variables: {
      shiftsId: selectedShifts,
    },
    fetchPolicy: "network-only",
  });

  async function handleShiftsAsPaid() {
    console.log(selectedShifts);
    const res = await shiftsMarkedAsPaid({
      variables: {
        shiftsId: selectedShifts,
        payrollBatchId: shiftFilters.payrollBatchId,
      },
    });
    if (res) {
      console.log(res);
      if (res.data.shiftsMarkedAsPaid.success) {
        dispatch(displayAlertSuccess("All shifts are marked as paid"));
      } else if (res.data.shiftsMarkedAsPaid.message) {
        dispatch(displayAlertSuccess(res.data.shiftsMarkedAsPaid.message));
      } else {
        dispatch(displayAlertError("Something went wrong"));
      }
    } else {
      dispatch(displayAlertError("Something went wrong"));
    }
  }

  const handleRunPayrollAction = () => {
    getShiftsSummary().then((data) => {
      console.log(data);
      if (data.data.shiftsSummary.success) {
        setSelectedShiftsSummary(data.data.shiftsSummary.shiftSummary);
        setConfirmPayrollShiftsModal(true);
      } else {
        dispatch(displayAlertError("Something went wrong"));
      }
    });
  };

  useEffect(() => {
    getUnpaidPayrollBatches().then((data) => {
      setPayrollBatches(data.data.payrollBatchesUnpaid);
    });
  }, []);

  useEffect(() => {
    if (shiftFilters.payrollBatchId === "0") {
      return;
    }
    getShiftsBeforeCutoffDate().then((data) => {
      if (shiftFilters.page === 0)
        setShiftsBeforeCutoffDate(data.data.shiftsBeforeCutoffDate.shifts);
      else
        setShiftsBeforeCutoffDate((prevState) => [
          ...(prevState || []),
          ...data.data.shiftsBeforeCutoffDate.shifts,
        ]);
    });
    return () => {
      setSelectedShifts([]);
    };
  }, [shiftFilters]);

  const handlePageChange = () => {
    setShiftFilters((prevState) => ({
      ...prevState,
      page: (shiftFilters.page || 0) + 1,
    }));
  };

  const paginationButton = () => {
    if (!shiftsData) return false;
    else if (
      shiftsData.shiftsBeforeCutoffDate.count >= pageSize &&
      ((shiftFilters.page || 0) + 1) * pageSize <
        shiftsData.shiftsBeforeCutoffDate.count
    ) {
      return true;
    }
  };

  const columnShifts: ColumnDef<Shift>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            table.getIsSomePageRowsSelected()
          }
          onCheckedChange={(value) => {
            setSelectedShifts(
              value ? shiftsBeforeCutoffDate?.map((row) => +row.id) ?? [] : []
            );

            table.setRowSelection((current) => {
              const newSelection: RowSelectionState = {};
              for (let i = 0; i < table.getRowCount(); i++) {
                const row = table.getRow(i.toString());
                newSelection[i] = !!value;
              }
              return newSelection;
            });
          }}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => {
        return (
          <>
            <Checkbox
              checked={row.getIsSelected()}
              onCheckedChange={(value) => {
                if (value) {
                  setSelectedShifts((shifts) => [...shifts, +row.original.id]);
                } else {
                  setSelectedShifts((prevSelectedShifts) =>
                    prevSelectedShifts?.filter((id) => +id !== +row.original.id)
                  );
                }
                row.toggleSelected(!!value);
              }}
              aria-label="Select row"
            />
          </>
        );
      },
    },
    {
      accessorKey: "id",
      header: "ID",
    },
    {
      accessorFn: (row) => {
        return `${dayjs.utc(row.startLocal).format("YYYY-MM-DD")}`;
      },
      header: "Date",
    },
    // day
    {
      header: "Day",
      accessorFn: (row) => {
        return `${dayjs.utc(row.startLocal).format("dddd")}`;
      },
    },
    {
      accessorFn: (row) => {
        return `${dayjs.utc(row.startLocal).format("h:mma")} - ${dayjs
          .utc(row.endLocal)
          .format("h:mma")}`;
      },
      header: "Time",
    },
    // Region
    {
      header: "Region",
      accessorFn: (row) => {
        return `${row.region?.name}`;
      },
    },
    {
      header: "Location",
      accessorFn: (row) => {
        return `${row.venue?.name}`;
      },
    },
    // {f and l name}
    {
      header: "Staff Name",
      accessorFn: (row) => {
        return `${row.user?.firstName} ${row.user?.lastName}`;
      },
    },
    // staff type
    {
      header: "Staff Type",
      accessorFn: (row) => {
        return `${row.staffType?.name}`;
      },
    },
    // shiftStatus
    {
      header: "Shift Status",
      accessorFn: (row) => {
        return `${row.shiftStatus?.value}`;
      },
    },
    // shiftReportStatus
    {
      header: "Shift Report Status",
      accessorFn: (row) => {
        return `${
          row.shiftReport?.shiftReportStatusId == 2
            ? "Reported"
            : "Not Reported"
        }`;
      },
    },
    // Pay rate
    {
      header: "Rate",
      accessorFn: (row) => {
        return `${row.rate ? numberToMoney(row.rate) : "Missing Rate"}`;
      },
    },
    // hours
    {
      header: "Hours",
      accessorFn: (row) => {
        return `${row.hours?.toFixed(2)}`;
      },
    },
    // amount
    {
      header: "Amount",
      accessorFn: (row) => {
        return `${row.amount ? numberToMoney(row.amount) : "Missing Amount"}`;
      },
    },
    // Payroll Status
    {
      header: "Payroll Status",
      accessorFn: (row) => {
        return `${
          row.payrollBatch?.payrollBatchStatus?.name
            ? row.payrollBatch?.payrollBatchStatus?.name
            : "Not Paid"
        }`;
      },
    },
  ];

  if (
    loading ||
    isLoading ||
    loadingPayroll ||
    loadingShiftPaymentStatus ||
    shiftsMarkedAsPaidLoading
  )
    return (
      <LoadingDialog
        open={
          loading ||
          isLoading ||
          loadingPayroll ||
          loadingShiftPaymentStatus ||
          shiftsMarkedAsPaidLoading
        }
      />
    );
  if (error) return <div>Something went wrong</div>;

  return (
    <main>
      <Modal
        open={confirmPayrollShiftsModal}
        onClose={() => setConfirmPayrollShiftsModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="flex flex-col gap-6">
            <Headline2Variable>Confirm Payroll Batch Details</Headline2Variable>
            <p>
              Please double check the details below. Once you submit this
              payroll batch you will not be able to add more shifts later.
            </p>
            <p>
              <b>Total Shifts: {selectedShiftsSummary.numOfShifts} </b>
              <br />
              <b>Total Hours: {selectedShiftsSummary.totalHours.toFixed(2)} </b>
              <br />
              <b>
                Total Payroll Amount:{" "}
                {selectedShiftsSummary.totalAmount.toFixed(2)}{" "}
              </b>
              <br />
            </p>
            <p>
              Once you run this payroll batch the system will: <br />
              1. Set the selected shifts as paid <br />
              2. Set the payroll batch record as paid <br />
              3. Link the shifts to the payroll batch record <br />
              4. Allow you to export the shifts to .csv <br />
            </p>
            <div className="flex flex-row gap-4">
              <Button
                variant="primary"
                onClick={() => {
                  handleShiftsAsPaid();
                  setConfirmPayrollShiftsModal(false);
                }}
              >
                Confirm and Run Payroll
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setConfirmPayrollShiftsModal(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Headline1Variable>Payroll Finance</Headline1Variable>
      <div className="flex flex-row flex-wrap items-end gap-4 mb-4">
        <div className="w-64">
          <FormFieldSelect
            name="payrollBatch"
            label="Payroll Batch"
            placeholder="Payroll Batch"
            value={shiftFilters.payrollBatchId}
            inputChange={(value: string) => {
              const selectedPayrollBatch = payrollBatches.find(
                (payrollBatch) => {
                  return payrollBatch.id == +value;
                }
              );
              const cutoffDate = selectedPayrollBatch?.shiftCutoffDate;

              setShiftFilters((prevState) => ({
                ...prevState,
                payrollBatchId: value,
                shiftCutoffDate: cutoffDate?.toString() ?? "",
                page: 0,
              }));
            }}
          >
            {[{ id: "0", name: "Select Payroll Batch" }, ...payrollBatches]}
          </FormFieldSelect>
        </div>
      </div>
      <div className="flex flex-row items-end w-full gap-4 mb-2">
        <Button
          className="w-[280px] h-fit"
          // variant="primary"
          variant={
            !selectedShifts.length ||
            shiftFilters.payrollBatchId === "" ||
            shiftFilters.payrollBatchId === "0"
              ? "disabled"
              : "primary"
          }
          disabled={
            !selectedShifts.length ||
            shiftFilters.payrollBatchId === "" ||
            shiftFilters.payrollBatchId === "0"
          }
          onClick={handleRunPayrollAction}
        >
          Run Payroll
        </Button>
      </div>
      <div>
        {shiftsBeforeCutoffDate && (
          <DataTable
            data={shiftsBeforeCutoffDate}
            columns={columnShifts}
          />
        )}
      </div>
      {paginationButton() && (
        <div className="pb-10 mt-3">
          <Button
            variant="primary"
            onClick={() => handlePageChange()}
          >
            Load 50 More
          </Button>
        </div>
      )}
    </main>
  );
};

export default ShiftsForPayroll;
