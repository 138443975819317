import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const PlayerSearchHome = () => {
  return (
    <main>
      <h3>This is the PLAYERS PlayerSearch home page</h3>
      <div style={{ display: "flex", gap: "1rem" }}>
        <Link to={"/player/account-search"}>
          <Button
            sx={{
              color: "var(--black)",
              bgcolor: "var(--primary-green-400)",
              "&:hover": { bgcolor: "var(--primary-green-500)" },
              borderRadius: "0.5rem",
            }}
          >
            Account Search
          </Button>
        </Link>
        <Link to={"/player/user-summary"}>
          <Button
            sx={{
              color: "var(--black)",
              bgcolor: "var(--primary-green-400)",
              "&:hover": { bgcolor: "var(--primary-green-500)" },
              borderRadius: "0.5rem",
            }}
          >
            User Summary
          </Button>
        </Link>
        <Link to={"/player/user-lists"}>
          <Button
            sx={{
              color: "var(--black)",
              bgcolor: "var(--primary-green-400)",
              "&:hover": { bgcolor: "var(--primary-green-500)" },
              borderRadius: "0.5rem",
            }}
          >
            User Lists
          </Button>
        </Link>
      </div>
    </main>
  );
};

export default PlayerSearchHome;
