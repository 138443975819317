'use client';

import { Link } from 'react-router-dom';
import React, { FC, HTMLProps, ReactNode } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { url } from 'inspector';

interface BreadCrumbsProps extends HTMLProps<HTMLButtonElement> {
  /**
   * The label of the button
   */
  paths: Array<{
    name: string;
    url: string;
  }>;
  /**
   * Go back Url
   */
  goBackTo?: string;
}

const BreadCrumbs: FC<BreadCrumbsProps> = ({
  paths,
  goBackTo = 'program-discovery',
  ...props
}: BreadCrumbsProps) => {
  const goBackPath = paths[paths.length - 2]?.url || goBackTo;
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        <li>
          <Link to={goBackPath}>
            <ArrowBackIcon />
          </Link>
        </li>
        {paths.map((path, index) => {
          const combinedStyle = `inline-flex items-center ${
            index + 1 === paths.length ? 'text-disabled' : 'text-black'
          }`;
          return (
            <div
              key={`breadcrumb-${path.name}-${index}`}
              className="flex gap-3"
            >
              <li>{index !== paths.length && index !== 0 ? '/' : ''}</li>
              <li className="inline-flex items-center">
                {path.url != '' ? (
                  <Link to={path.url} className={combinedStyle}>
                    {path.name}
                  </Link>
                ) : (
                  <div className={combinedStyle}>{path.name}</div>
                )}
              </li>
            </div>
          );
        })}
      </ol>
    </nav>
  );
};

export default BreadCrumbs;
