import { Box, Modal, Button as MuiButton } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { FormRow, FormRowSelect, LoadingMaterialUI } from "../UI";
import classes from "./CreateDivision.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import {
  createDivision,
  getSessionsForDivision,
  updateDivision,
  getGenderFormat,
  getRegistrationBatch,
  getSports,
} from "../../app/venueMasterSlice";
import { displayAlertError } from "../../app/globalSlice";
import moment from "moment";
import MultipleDatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import BreadCrumbs from "../UI/Breadcrumbs/Breadcrumbs";
import Card from "../UI/Card/Card";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import FormField from "../UI/FormField/FormField";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import Button from "../UI/Button/Button";
import FormFieldDropdown from "../UI/FormField/FormFieldDropdown/FormFieldDropdown";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import FormFieldDate from "../UI/FormField/FormFieldDate/FormFieldDate";
import FormFieldDateSelect from "../UI/FormField/FormFieldDate/FormFieldDateSelect";
import FilterListIcon from "@mui/icons-material/FilterList";
import CheckBox from "../UI/Checkbox/Checkbox";
import { useDivisionByIdLazyQuery } from "../../generated/graphql";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import Body1 from "../UI/Text/Body/Body1";
import Disclaimer from "../UI/Alerts/Disclaimer";

const CreateDivision = () => {
  const initialDivision = {
    name: "",
    vmName: "",
    vmColor: "#FFFFFF",
    sessionId: 0,
    teams: [],
  };

  const initialSession = {
    //// session info
    id: 0,
    startDate: "",
    endDate: "",
    exceptionDates: [],
    numberOfWeeks: 0,
    weeksOfPlayoffs: 0,
    teams: [],
    divisions: [],
  };

  type InitialSessionFilter = {
    regions: number | null;
    sportId: number | null;
    dayOfWeek: number | null;
    registrationBatchId: number | null;
    genderFormatId: number | null;
  };

  const initialSessionFilter: InitialSessionFilter = {
    regions: null,
    sportId: null,
    dayOfWeek: 7,
    registrationBatchId: null,
    genderFormatId: null,
  };

  const params = useParams();
  const isEditDivision = params.id;
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [division, setDivision]: any = useState({});
  const [isSchedulePublished, setIsSchedulePublished] = useState(false);
  const [session, setSession]: any = useState(initialSession);
  const [sessionFilter, setSessionFilter]: any = useState(() => {
    const urlParamRegBatchId = searchParams.get("regBatchId");
    return {
      regions: null,
      sportId: null,
      dayOfWeek: 7,
      registrationBatchId: urlParamRegBatchId ? +urlParamRegBatchId : null,
      genderFormatId: null,
    };
  });
  // const [isEditDivision, setIsEditDivision]: any = useState(id);
  const [selectSessionModal, setSelectSessionModal] = useState(0);

  const {
    isLoading,
    sessionsForDivision,
    divisionToEdit,
    selectedRegions,
    genderFormat,
    sports,
    registrationBatches,
  }: any = useSelector((state: RootState) => state.venueMaster);
  const weekdaysArray: any = [
    { id: 0, name: "Sunday" },
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
    { id: 7, name: "All" },
  ];

  const [
    divisionByScheduleId,
    { data: divisionById, error: divisionError, loading: divisionLoading },
  ] = useDivisionByIdLazyQuery({});

  useEffect(() => {
    if (isEditDivision) {
      divisionByScheduleId({
        variables: { divisionId: +isEditDivision },
        onCompleted: (data) => {
          if (!(Object.keys(data).length === 0) && isEditDivision) {
            const teams = data.division.teams?.map((team) => team.id);
            setIsSchedulePublished(
              data.division.latestSchedule?.scheduleStatus.id === 2
            );
            setDivision({
              id: data.division.id,
              name: data.division.name,
              vmName: data.division.vmName,
              vmColor: data.division.vmColor,
              sessionId: data.division.session.id,
              teams: teams,
            });
            setSession({
              id: data.division.session.id,
              startDate: moment(data.division.startDate).format("YYYY-MM-DD"),
              endDate: moment(data.division.endDate).format("YYYY-MM-DD"),
              exceptionDates: data.division.exceptionDates,
              numberOfWeeks: data.division.numberOfWeeks,
              weeksOfPlayoffs: data.division.weeksOfPlayoffs,
              teams: data.division.session.teams,
              divisions: data.division.session.divisions,
            });
          }
          return data.division;
        },
      });
    } else {
      setSelectSessionModal(0);
      dispatch(getGenderFormat(""));
      dispatch(getSports(""));
      dispatch(getRegistrationBatch(""));
    }
  }, []);

  useEffect(() => {}, [divisionToEdit, isEditDivision]);

  const generateRandomColor = () => {
    // Generate a random hex color
    const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
    setDivision({ ...division, vmColor: randomColor });
  };

  const handleSelectSessionModal = () => {
    // dispatch(deleteDivision(deleteModal)).then(() => {
    //   dispatch(getDivisions(''));
    //   setSelectSessionModal(0);
    // });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDivision({
      ...division,
      [e.target.name]: +e.target.value || e.target.value,
    });
  };

  useEffect(() => {
    dispatch(getSessionsForDivision(sessionFilter));
  }, [sessionFilter]);
  useEffect(() => {
    const urlParamSessionId = searchParams.get("sessionId");
    if (
      urlParamSessionId !== null &&
      session.id == 0 &&
      sessionsForDivision.length > 0
    ) {
      handleChangeSession(urlParamSessionId);
    }
  }, [sessionsForDivision]);

  const handleChangeSession = (selectedValue: string) => {
    if (+selectedValue === 0) {
      return;
    }
    const sessionId = +selectedValue;
    const session = sessionsForDivision.find((session: any) => {
      return +session.id === sessionId;
    });
    setDivision({
      ...division,
      sessionId,
    });

    setSession({
      ...session,
      startDate: moment(session.startDate).format("YYYY-MM-DD"),
      endDate: moment(session.endDate).format("YYYY-MM-DD"),
    });
  };

  const handleChangeSessionFilter = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSessionFilter({
      ...sessionFilter,
      [e.target.name]: +e.target.value,
    });
  };

  const handleChangeSessionDetails = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSession({
      ...session,
      [e.target.name]: +e.target.value || e.target.value,
    });
  };

  const handleTeamChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    let updatedList: any = [];
    if (division.teams) {
      updatedList = [...division.teams];
    }
    // if (e.target.checked) {
    //   updatedList = [...updatedList, +e.target.value];
    // } else {
    //   updatedList.splice(division.teams.indexOf(+e.target.value), 1);
    // }
    const teamId = +e.target.value;
    if (updatedList.includes(+e.target.value)) {
      updatedList = updatedList.filter((item: any) => item !== teamId);
    } else {
      updatedList = [...updatedList, teamId];
    }
    setDivision({ ...division, teams: updatedList });
  };

  const handleSubmitForm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (
      !division?.sessionId ||
      !division?.name ||
      !division?.vmName ||
      !division?.vmColor
    ) {
      dispatch(displayAlertError("Please enter all the fields"));
      return;
    }

    const divisionArgs = {
      name: division.name,
      vmName: division.vmName,
      vmColor: division.vmColor,
      teams: division.teams,
      sessionId: +session.id,
      startDate: session.startDate,
      endDate: session.endDate,
      exceptionDates: session.exceptionDates,
      numberOfWeeks: session.numberOfWeeks,
      weeksOfPlayoffs: session.weeksOfPlayoffs,
    };
    if (!isEditDivision) {
      dispatch(createDivision(divisionArgs)).then((res) => {
        if (res.payload.divisionCreate.success) {
          navigate(`/ops/divisions/`);
        }
      });
    } else {
      dispatch(
        updateDivision({
          id: +isEditDivision,
          divisionInput: divisionArgs,
        })
      ).then((res) => {
        if (res.payload.divisionUpdate.success) {
          navigate(`/ops/divisions/`);
        }
      });
    }
  };

  const unpooledTeams = (): React.ReactNode => {
    const teams = session.teams;
    let pooledTeams: any = [];
    session.divisions.forEach((division: any) => {
      pooledTeams = [...pooledTeams, ...division.teams];
    });

    if (teams.length === 0) {
      return (
        <div className="w-1/4 h-full p-4 m-2 text-black bg-neutral-50">
          <p>Teams are yet to be created</p>{" "}
        </div>
      );
    }

    if (teams.length === pooledTeams.length) {
      return (
        <div className="w-1/4 h-full p-4 m-2 text-black bg-neutral-50">
          <p>All teams are pooled</p>{" "}
        </div>
      );
    }

    const unpooledTeams = teams.filter(
      (team: any) =>
        !pooledTeams.some((pooledTeam: any) => pooledTeam.id === team.id)
    );

    if (unpooledTeams.length > 0) {
      return (
        <div className="w-1/4 h-full p-4 m-2 text-black bg-neutral-50 ">
          <p>Unpooled Teams</p>
          {unpooledTeams.map((team: any, index: number) => {
            return (
              <div
                key={team.id}
                className="flex flex-row items-center gap-2"
              >
                {!isSchedulePublished && (
                  <input
                    type="checkbox"
                    value={team.id}
                    onChange={handleTeamChecked}
                  />
                )}
                <span>{team.name}</span>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <p>All teams are pooled</p>;
    }
  };

  const pathsBreadcrumbs = [{ name: "Divisions", url: "/ops/divisions" }];
  if (divisionLoading) {
    return <LoadingDialog open={true} />;
  }

  return (
    <main>
      <Modal
        open={selectSessionModal ? true : false}
        onClose={() => {
          setSelectSessionModal(0);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          zIndex: 10,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div>
            <Headline1Variable>Select Session</Headline1Variable>
            <div className="mt-5">
              <Subtitle1>Session Filters</Subtitle1>
              <div className="flex flex-col">
                <FormFieldDropdown
                  initialValue={
                    sessionFilter.regions
                      ? sessionFilter.regions.toString()
                      : "0"
                  }
                  name="regions"
                  placeholder="All"
                  inputChange={(value: string) => {
                    setSessionFilter((prevState: any) => ({
                      ...prevState,
                      regions: value === "0" ? null : parseInt(value),
                    }));
                  }}
                  label="Region"
                >
                  {[...selectedRegions, ...[{ id: 0, name: "All" }]]}
                </FormFieldDropdown>
                <FormFieldDropdown
                  initialValue={
                    sessionFilter.sportId
                      ? sessionFilter.sportId.toString()
                      : "0"
                  }
                  name="sportId"
                  inputChange={(value: string) => {
                    setSessionFilter((prevState: any) => ({
                      ...prevState,
                      sportId: parseInt(value),
                    }));
                  }}
                  label="Sport"
                  placeholder="All"
                >
                  {[...sports, ...[{ id: 0, name: "All" }]]}
                </FormFieldDropdown>
                <FormFieldDropdown
                  name="dayOfWeek"
                  initialValue={
                    sessionFilter.dayOfWeek
                      ? sessionFilter.dayOfWeek.toString()
                      : "0"
                  }
                  inputChange={(value: string) => {
                    setSessionFilter((prevState: any) => ({
                      ...prevState,
                      dayOfWeek: parseInt(value),
                    }));
                  }}
                  label="Day"
                  placeholder="All"
                >
                  {[...weekdaysArray]}
                </FormFieldDropdown>
                <FormFieldDropdown
                  name="registrationBatchId"
                  initialValue={
                    sessionFilter.registrationBatchId
                      ? sessionFilter.registrationBatchId.toString()
                      : "0"
                  }
                  inputChange={(value: string) => {
                    setSessionFilter((prevState: any) => ({
                      ...prevState,
                      registrationBatchId: parseInt(value),
                    }));
                  }}
                  label="Registration Batch"
                  placeholder="All"
                >
                  {[...registrationBatches, ...[{ id: 0, name: "All" }]]}
                </FormFieldDropdown>
                <FormFieldDropdown
                  name="genderFormatId"
                  initialValue={
                    sessionFilter.genderFormatId
                      ? sessionFilter.genderFormatId.toString()
                      : "0"
                  }
                  inputChange={(value: string) => {
                    setSessionFilter((prevState: any) => ({
                      ...prevState,
                      genderFormatId: parseInt(value),
                    }));
                  }}
                  label="Gender Format"
                  placeholder="All"
                >
                  {[...genderFormat, ...[{ id: 0, name: "All" }]]}
                </FormFieldDropdown>
              </div>
            </div>
            {isLoading ? (
              <LoadingMaterialUI />
            ) : (
              <div className="mt-5">
                <Subtitle1>Select Session</Subtitle1>
                <FormFieldDropdown
                  name="sessionId"
                  initialValue={
                    division.sessionId ? division.sessionId.toString() : "0"
                  }
                  label="Session"
                  placeholder="Session"
                  width="400px"
                  inputChange={(value: string) => {
                    handleChangeSession(value);
                    setSelectSessionModal(0);
                  }}
                  disabled={isEditDivision ? true : false}
                >
                  {[
                    { id: "0", name: "Select Session" },
                    ...sessionsForDivision,
                  ]}
                </FormFieldDropdown>
              </div>
            )}
          </div>
        </Box>
      </Modal>
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/ops/divisions"
      ></BreadCrumbs>
      <div className="flex flex-row items-center justify-between w-full mt-6">
        <Headline1Variable>
          {isEditDivision ? "Edit Division" : "Create New Division"}
        </Headline1Variable>

        <div className="flex flex-row gap-2">
          <Button
            variant="primary"
            className="h-fit"
            onClick={(e) => handleSubmitForm(e)}
          >
            {isEditDivision ? (
              <span> Edit Division</span>
            ) : (
              <span> Create New Division</span>
            )}
          </Button>
          <Button
            variant="secondary"
            className="h-fit"
            onClick={() => navigate(`schedule`)}
          >
            Schedule
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {divisionById?.division?.latestSchedule?.regularGames.some(
          (game) => game.homeTeam === null || game.awayTeam === null
        ) && (
          <Disclaimer
            variant="error"
            title={`This division has games with missing teams. Please go to the schedule to fix this.`}
            size="large"
            persist={true}
          />
        )}
      </div>
      <form autoComplete="off">
        <Card className="mt-6">
          <Subtitle1>Division Details</Subtitle1>
          <div className="flex flex-col">
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <FormField
                type="name"
                name="name"
                value={division.name}
                label="Name"
                inputChange={handleChange}
                initialValue={division.name ? division.name : ""}
              />
              <FormField
                type="vmName"
                name="vmName"
                value={division.vmName}
                label="Venue Master Name"
                initialValue={division.vmName ? division.vmName : ""}
                inputChange={handleChange}
              />
              <div className={classes.color_preview}>
                <FormField
                  type="vmColor"
                  name="vmColor"
                  value={division.vmColor}
                  label="Venue Master Color"
                  inputChange={handleChange}
                  color={division.vmColor}
                  initialValue={division.vmColor ? division.vmColor : ""}
                />
                <div className="mt-3">
                  <Button
                    variant="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      generateRandomColor();
                    }}
                  >
                    Generate
                  </Button>
                </div>
                <div
                  style={{
                    backgroundColor: division.vmColor,
                    height: "35px",
                    width: "35px",
                    marginTop: "10px",
                    padding: "10px",
                    borderRadius: "20px",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </Card>
        <Card className="mt-6">
          <Subtitle1>
            {isEditDivision ? `Division Info` : `Session Info`}
          </Subtitle1>
          <div className="flex flex-col gap-2">
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <div className="flex flex-row gap-2 md:col-span-2">
                {/* className={classes.color_preview} */}
                <FormFieldDropdown
                  name="sessionId"
                  initialValue={
                    division.sessionId ? division.sessionId.toString() : "0"
                  }
                  label="Session"
                  placeholder="Session"
                  // width="400px"
                  inputChange={(value: string) => {
                    handleChangeSession(value);
                  }}
                  disabled={true}
                >
                  {[{ id: 0, name: "Select Session" }, ...sessionsForDivision]}
                </FormFieldDropdown>
                {!isEditDivision && (
                  <div>
                    <Button
                      className="w-40 mt-4"
                      variant="secondary"
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectSessionModal(1);
                      }}
                    >
                      <FilterListIcon
                        fontSize="small"
                        sx={{
                          width: "18px",
                          height: "18px",
                        }}
                      />
                      <span> Filters</span>
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <FormFieldDateSelect
                initialValue={
                  session.startDate
                    ? moment(session.startDate).format("YYYY-MM-DD")
                    : null
                }
                inputChange={(e) => {
                  setSession({
                    ...session,
                    startDate: moment(e.target.value).format("YYYY-MM-DD"),
                  });
                }}
                name="startDate"
                label="Start Date"
              />
              <FormFieldDateSelect
                initialValue={
                  session.endDate
                    ? moment(session.endDate).format("YYYY-MM-DD")
                    : null
                }
                inputChange={(e) => {
                  setSession({
                    ...session,
                    endDate: moment(e.target.value).format("YYYY-MM-DD"),
                  });
                }}
                name="endDate"
                label="End Date"
              />
            </div>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <div className="md:col-span-2">
                <div className={`flex flex-col w-full text-black`}>
                  <div className={`px-3 text-xs font-medium`}>
                    Exclusion Dates
                  </div>
                  <MultipleDatePicker
                    multiple
                    plugins={[<DatePanel />]}
                    inputClass={classes["multi-DatePicker-input"]}
                    containerClassName={classes["multi-DatePicker-container"]}
                    value={session.exceptionDates}
                    name="exclusionDates"
                    //onChange throws Date as DateObject only
                    onChange={(selectedDates: DateObject[]) => {
                      if (Array.isArray(selectedDates)) {
                        const dates = selectedDates?.map((date) => {
                          return new Date(date.format()).toISOString();
                        });
                        setSession({ ...session, exceptionDates: dates });
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <FormField
                name="numberOfWeeks"
                label="Total Weeks of Play"
                initialValue={session.numberOfWeeks ? session.numberOfWeeks : 0}
                inputChange={(e) => {
                  setSession({
                    ...session,
                    numberOfWeeks: +e.target.value,
                  });
                }}
              />
              <FormField
                name="weeksOfPlayoffs"
                label="Weeks of PlayOffs"
                initialValue={
                  session.weeksOfPlayoffs ? session.weeksOfPlayoffs : 0
                }
                inputChange={(e) => {
                  setSession({
                    ...session,
                    weeksOfPlayoffs: +e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </Card>
        <Card className="mt-6">
          <Button
            variant="secondary"
            onClick={() => {
              navigate(`/ops/division/reseed/${divisionById?.division.id}`);
            }}
          >
            <Body1>Re-seed Divisions</Body1>
          </Button>
          <Subtitle1>Teams and Divisions </Subtitle1>
          <label htmlFor="teams"></label>
          <div className="flex">
            {/* Unpooled Teams */}
            {unpooledTeams()}
            {/* Pooled Teams */}
            <div className="flex flex-wrap w-full">
              {session.divisions.length > 0 &&
                session.divisions.map((sessionDivision: any, index: number) => {
                  return (
                    <div
                      key={sessionDivision.id}
                      className="w-1/4 p-4 m-2 bg-neutral-70"
                    >
                      <div className="flex flex-row items-center gap-2">
                        <div
                          style={{
                            backgroundColor: sessionDivision.vmColor,
                            height: "16px",
                            width: "16px",
                            borderRadius: "10px",
                          }}
                        ></div>
                        <p>{sessionDivision.name}</p>
                      </div>
                      <div>
                        {sessionDivision.teams.map(
                          (team: any, index: number) => {
                            return (
                              <div
                                key={team.id}
                                className="flex flex-row items-center"
                              >
                                {!isSchedulePublished &&
                                  division?.id === sessionDivision.id && (
                                    <input
                                      className="mr-2"
                                      type="checkbox"
                                      value={team.id}
                                      defaultChecked={
                                        !sessionDivision.teams.includes(
                                          +team.id
                                        )
                                      }
                                      // checked={!division.teams.includes(+team.id)}
                                      onChange={handleTeamChecked}
                                    />
                                  )}
                                <span>{team.name}</span>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </Card>
        <div className="flex flex-row-reverse gap-3 mt-5">
          <Button
            variant="primary"
            onClick={(e) => handleSubmitForm(e)}
          >
            {isEditDivision ? (
              <span> Edit Division</span>
            ) : (
              <span> Create New Division</span>
            )}
          </Button>
          <Button
            variant="secondary"
            onClick={() => navigate("/ops/divisions")}
          >
            Cancel
          </Button>
        </div>
      </form>
    </main>
  );
};

export default CreateDivision;
