import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMyShifts,
  getShiftStatus,
  updateShiftStatus,
  createShiftReport,
  getShiftReportQuestions,
} from "../../../app/shiftSlice";
import classes from "./MyShifts.module.css";
import moment from "moment";
import { FormRow, LabTabs, LoadingMaterialUI } from "../../UI";
import { useTable, useSortBy } from "react-table";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import useWindowSize from "../../../utils/hooks/useWindowSize";
import { AppDispatch, RootState } from "../../../app/store";
import { ShiftNote, ShiftReport } from "../../../types/types";

const MyShifts = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isMobile = useWindowSize();

  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [modalContentNotes, setModalContentNotes] = useState<Array<ShiftNote>>(
    []
  );

  const [shiftId, setShiftId] = useState(0);
  const [shiftStatusId, setShiftStatusId] = useState(0);
  const [openAttendanceModal, setAttendanceModal] = useState(false);

  const [shiftInfoInShiftReport, setShiftInfoInShiftReport] = useState({});
  const [openShiftReportModal, setOpenShiftReportModal] = useState(false);
  const [shiftReport, setShiftReport] = useState({}) as any;

  const {
    myShiftsHistory,
    myShiftsUpcoming,
    shiftStatusOptions,
    isLoading,
    shiftReportQuestions,
  } = useSelector((state: RootState) => state.shift);

  const { user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(getMyShifts()).then(() => {
      if (!shiftStatusOptions.length) {
        dispatch(getShiftStatus());
      }

      if (!shiftReportQuestions.length) {
        dispatch(getShiftReportQuestions());
      }
    });
  }, [dispatch]);

  const handleViewNotes = (shiftNotes: Array<ShiftNote>) => {
    setModalContentNotes(shiftNotes);
    setOpenNotesModal(true);
  };

  const handleAttendanceModal = (shiftId: number) => {
    setShiftId(shiftId);
    setAttendanceModal(true);
  };

  const handleChangeShiftStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShiftStatusId(+e.target.value);
    setAttendanceModal(false);

    dispatch(
      updateShiftStatus({ shiftId: shiftId, shiftStatusId: e.target.value })
    ).then(() => {
      dispatch(getMyShifts());
      setShiftStatusId(0);
    });
  };

  const handleShiftReportModal = (reportArgs: ShiftReport) => {
    setShiftInfoInShiftReport(reportArgs);
    setOpenShiftReportModal(true);
  };

  const handleChangeShiftReport = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShiftReport({ ...shiftReport, [e.target.name]: e.target.value });
  };

  const onSubmitShiftReport = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    dispatch(
      createShiftReport({
        contents: shiftReport,
        ...shiftInfoInShiftReport,
      })
    )
      .then(() => {
        setShiftReport({});
        setOpenShiftReportModal(!openShiftReportModal);
      })
      .then(() => {
        dispatch(getMyShifts());
      });

    // get all the questions
    // shiftInfoInShiftReport
    // userID (will get in slice)
    // set shiftReportStatusId (will do in slice)
  };

  const COLUMNS_HISTORY = [
    {
      Header: "ShiftID#",
      accessor: "historyData.id",
    },
    {
      Header: "Date",
      id: "historyData.startLocal",
      accessor: (d: any) => {
        return (
          <div className={classes.date__attributes}>
            <div className={classes.date__day}>
              {moment(d.historyData.startLocal).local().format("dddd")}
            </div>
            <div className={classes.date__date}>
              {moment(d.historyData.startLocal).local().format("MMM,DD")}
            </div>
          </div>
        );
      },
    },
    {
      Header: "Shift Type",
      accessor: "historyData.staffType.name",
    },
    {
      Header: "Time & Location",
      // id: 'location.name',
      id: "historyData.locationId",
      accessor: (d: any) => {
        return (
          <div className={classes.timeAndLocation__attribute}>
            <div className={classes.timeAndLocation__time}>
              {moment(d.historyData.startLocal).local().format("LT")} {" - "}
              {moment(d.historyData.endLocal).local().format("LT")}
            </div>

            <div className={classes.timeAndLocation__location}>
              {d.location.Name}
            </div>
          </div>
        );
      },
    },
    // {
    //   Header: 'League',
    //   accessor: 'league.Name',
    // },
    {
      Header: "Action",
      id: "action",
      accessor: (value: any) => {
        return (
          <div>
            <Button
              type="button"
              className={classes.delete__btn}
              onClick={() => {
                handleViewNotes(value.historyData.shiftNotes);
              }}
            >
              View Notes
            </Button>
          </div>
        );
      },
    },
    {
      Header: "Shift Report",
      id: "shift_report",
      accessor: (value: any) => {
        return (
          <div>
            <Button
              type="button"
              className={classes.delete__btn}
              disabled={value.historyData.shift_report?.id > 0}
              onClick={() => {
                handleShiftReportModal({
                  shiftId: value.historyData.id,
                  arrival: value.historyData.startLocal,
                  departure: value.historyData.endLocal,
                });
              }}
            >
              <AddIcon />
            </Button>
            {/* )} */}
          </div>
        );
      },
    },
  ];

  const COLUMNS_UPCOMING = [
    {
      Header: "ShiftID#",
      accessor: "upcomingData.id",
    },
    {
      Header: "Date",
      id: "upcomingData.startLocal",
      accessor: (d: any) => {
        return (
          <div className={classes.date__attributes}>
            <div className={classes.date__day}>
              {moment(d.upcomingData.startLocal).local().format("dddd")}
            </div>
            <div className={classes.date__date}>
              {moment(d.upcomingData.startLocal).local().format("MMM,DD")}
            </div>
          </div>
        );
      },
    },
    {
      Header: "Shift Type",
      accessor: "upcomingData.staffType.name",
    },
    {
      Header: "Time & Location",
      // id: 'location.name',
      id: "upcomingData.locationId",
      accessor: (d: any) => {
        return (
          <div className={classes.timeAndLocation__attribute}>
            <div className={classes.timeAndLocation__time}>
              {moment(d.upcomingData.startLocal).local().format("LT")} {" - "}
              {moment(d.upcomingData.endLocal).local().format("LT")}
            </div>

            <div className={classes.timeAndLocation__location}>
              {d.location.name}
            </div>
          </div>
        );
      },
    },
    {
      Header: "Shift Status",
      id: "shift_action",
      accessor: (value: any) => {
        return (
          <div>
            <Button
              type="button"
              className={classes.delete__btn}
              onClick={() => {
                handleAttendanceModal(value.upcomingData?.id);
              }}
            >
              {value.upcomingData.shiftStatus?.value}
            </Button>
          </div>
        );
      },
    },
    {
      Header: "Action",
      id: "action",
      accessor: (value: any) => {
        return (
          <div>
            <Button
              type="button"
              className={classes.delete__btn}
              onClick={() => {
                handleViewNotes(value.upcomingData.shiftNotes);
              }}
            >
              View Notes
            </Button>
          </div>
        );
      },
    },
    // {
    //   Header: 'Shift Report',
    //   id: 'shift_report',
    //   accessor: (value) => {
    //     return (
    //       <div>
    //         <Button
    //           type="button"
    //           className={classes.delete__btn}
    //           disabled={value.upcomingData.shift_report?.id > 0}
    //           onClick={() => {
    //             handleShiftReportModal({
    //               shiftId: value.upcomingData.id,
    //               arrival: value.upcomingData.startLocal,
    //               departure: value.upcomingData.endLocal,
    //             });
    //           }}
    //         >
    //           <AddIcon />
    //         </Button>
    //         {/* )} */}
    //       </div>
    //     );
    //   },
    // },
  ];

  const columnsHistory = useMemo(() => COLUMNS_HISTORY, []);
  const columnsUpcoming = useMemo(() => COLUMNS_UPCOMING, []);

  const historyData = useMemo(() => {
    return myShiftsHistory;
  }, [isLoading]);
  const upcomingData = useMemo(() => myShiftsUpcoming, [isLoading]);

  const historyTable = useTable(
    {
      columns: columnsHistory,
      data: historyData,
      initialState: {
        hiddenColumns: ["historyData.id"],
      },
    },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    historyTable;

  const displayedHistoryShift = (
    <TableContainer
      className={classes.shift__container}
      sx={{ maxHeight: "80vh", width: "90vw", maxWidth: "90%" }}
    >
      <Table
        stickyHeader
        aria-label="sticky table"
        {...getTableProps()}
        className={classes.shift__table}
      >
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <TableCell
                  sx={{
                    bgcolor: "var(--primary-blue-500)",
                    color: "var(--grey-50)",
                    fontSize: "16px",
                    textAlign: "center",
                    fontWeight: 550,
                  }}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <div className={classes.headAlign}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDownwardIcon />
                        ) : (
                          <ArrowUpwardIcon />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      sx={{ textAlign: "center" }}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const upcomingTable = useTable(
    {
      columns: columnsUpcoming,
      data: upcomingData,
      initialState: {
        hiddenColumns: ["upcomingData.id"],
      },
    },
    useSortBy
  );

  const displayedUpcomingShift = (
    <TableContainer
      className={classes.shift__container}
      sx={{ maxHeight: "80vh", width: "90vw", maxWidth: "90%" }}
    >
      <Table
        stickyHeader
        aria-label="sticky table"
        {...upcomingTable.getTableProps()}
        className={classes.shift__table}
      >
        <TableHead>
          {upcomingTable.headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <TableCell
                  sx={{
                    bgcolor: "var(--primary-blue-500)",
                    color: "var(--grey-50)",
                    fontSize: "16px",
                    textAlign: "center",
                    fontWeight: 550,
                  }}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <div className={classes.headAlign}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDownwardIcon />
                        ) : (
                          <ArrowUpwardIcon />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...upcomingTable.getTableBodyProps()}>
          {upcomingTable.rows.map((row) => {
            upcomingTable.prepareRow(row);
            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      sx={{ textAlign: "center" }}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const displayedHistoryShiftsCard = (
    <div className={classes.card__container}>
      {myShiftsHistory.map((shift: any) => (
        <Card
          key={shift.historyData.id}
          sx={{ minWidth: "90vw", marginBottom: "10px" }}
          className={classes.shiftCard}
        >
          <CardContent className={classes.cardContent}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {moment(shift.historyData.startLocal)
                .local()
                .format("dddd MMM,DD")}
            </Typography>
            <Typography variant="body2">
              {moment(shift.historyData.startLocal).local().format("LT")}{" "}
              {" - "}
              {moment(shift.historyData.endLocal).local().format("LT")}
            </Typography>
            <Typography variant="body2">
              {shift.historyData.staffType.name}
            </Typography>
            <Typography
              variant="h6"
              component="div"
            >
              {shift.location.name}
            </Typography>
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Button
              type="button"
              className={classes.delete__btn}
              disabled={shift.historyData.shift_report?.id > 0}
              onClick={() => {
                handleShiftReportModal({
                  shiftId: shift.historyData.id,
                  arrival: shift.historyData.startLocal,
                  departure: shift.historyData.endLocal,
                });
              }}
            >
              <AddIcon />
            </Button>
            <Button
              type="button"
              className={classes.delete__btn}
              onClick={() => {
                handleViewNotes(shift.upcomingData.shiftNotes);
              }}
            >
              View Notes
            </Button>
          </CardActions>
        </Card>
      ))}
    </div>
  );

  const displayedUpcomingShiftsCard = (
    <div className={classes.card__container}>
      {myShiftsUpcoming.map((shift: any) => (
        <Card
          key={shift.upcomingData.id}
          sx={{ minWidth: "90vw", marginBottom: "10px" }}
          className={classes.shiftCard}
        >
          <CardContent className={classes.cardContent}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {moment(shift.upcomingData.startLocal)
                .local()
                .format("dddd MMM,DD")}
            </Typography>
            <Typography variant="body2">
              {moment(shift.upcomingData.startLocal).local().format("LT")}{" "}
              {" - "}
              {moment(shift.upcomingData.endLocal).local().format("LT")}
            </Typography>
            <Typography variant="body2">
              {shift.upcomingData.staffType.name}
            </Typography>
            <Typography
              variant="h6"
              component="div"
            >
              {shift.location.name}
            </Typography>
          </CardContent>
          <CardActions className={classes.cardActions}>
            {/* <Button
              type="button"
              className={classes.delete__btn}
              disabled={shift.upcomingData.shift_report?.id > 0}
              onClick={() => {
                handleShiftReportModal({
                  shiftId: shift.upcomingData.id,
                  arrival: shift.upcomingData.startLocal,
                  departure: shift.upcomingData.endLocal,
                });
              }}
            >
              <AddIcon />
            </Button> */}
            <Button
              type="button"
              className={classes.delete__btn}
              onClick={() => {
                handleAttendanceModal(shift.upcomingData?.id);
              }}
            >
              {shift.upcomingData.shiftStatus?.value}
            </Button>
            <Button
              type="button"
              className={classes.delete__btn}
              onClick={() => {
                handleViewNotes(shift.upcomingData.shiftNotes);
              }}
            >
              View Notes
            </Button>
          </CardActions>
        </Card>
      ))}
    </div>
  );

  // const displayedShiftsReactTable = (
  //   <table
  //     {...getTableProps()}
  //     className={classes.shift__table}
  //     stickyHeader
  //     aria-label="sticky table"
  //   >
  //     <thead>
  //       {headerGroups.map((headerGroup) => (
  //         <tr {...headerGroup.getHeaderGroupProps()}>
  //           {headerGroup.headers.map((column) => (
  //             <th {...column.getHeaderProps(column.getSortByToggleProps())}>
  //               {column.render('Header')}
  //               <span>
  //                 {column.isSorted ? (
  //                   column.isSortedDesc ? (
  //                     <ArrowDownwardIcon />
  //                   ) : (
  //                     <ArrowUpwardIcon />
  //                   )
  //                 ) : (
  //                   ''
  //                 )}
  //               </span>
  //             </th>
  //           ))}
  //         </tr>
  //       ))}
  //     </thead>
  //     <tbody {...getTableBodyProps()}>
  //       {rows.map((row) => {
  //         prepareRow(row);
  //         return (
  //           <tr {...row.getRowProps()}>
  //             {row.cells.map((cell) => {
  //               return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
  //             })}
  //           </tr>
  //         );
  //       })}
  //     </tbody>
  //   </table>
  // );

  // const displayedShiftsMaterialUI = (
  //   <TableContainer
  //     className={classes.shift__container}
  //     sx={{ maxHeight: '80vh', width: '90vw', maxWidth: '900px' }}
  //   >
  //     <Table
  //       className={classes.shift__table}
  //       stickyHeader
  //       aria-label="sticky table"
  //     >
  //       <TableHead>
  //         <TableRow>
  //           {COLUMNS.map((column) => (
  //             <TableCell
  //               key={column.id}
  //               align={column.align}
  //               style={{ minWidth: column.minWidth }}
  //             >
  //               {column.label}
  //             </TableCell>
  //           ))}
  //           {/* <TableCell>ShiftId#</TableCell>
  //           <TableCell>Start</TableCell>
  //           <TableCell>End</TableCell>
  //           <TableCell>Location/Site</TableCell> */}
  //         </TableRow>
  //       </TableHead>
  //       <TableBody>
  //         {myShifts.map((shift) => {
  //           return (
  //             <TableRow hover role="checkbox" tabIndex={-1} key={shift.id}>
  //               <TableCell>{shift.id}</TableCell>
  //               <TableCell>
  //                 {moment(shift.startLocal).format('ddd MMM,DD YYYY HH:mm')}
  //               </TableCell>
  //               <TableCell>
  //                 {moment(shift.endLocal).format('ddd MMM,DD YYYY HH:mm')}
  //               </TableCell>
  //               <TableCell>{shift.location.name}</TableCell>
  //               <TableCell>{shift.location.id}</TableCell>
  //               <TableCell>{shift.user.name}</TableCell>
  //               <TableCell>{shift.user.id}</TableCell>
  //             </TableRow>
  //           );
  //         })}
  //         {/* {myShifts.map((shift) => (
  //           <TableRow
  //             key={shift.id}
  //             sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
  //           >
  //             <TableCell>{shift.id}</TableCell>
  //             <TableCell>
  //               {moment(shift.startLocal).format('ddd MMM,DD YYYY HH:mm')}
  //             </TableCell>
  //             <TableCell>
  //               {moment(shift.endLocal).format('ddd MMM,DD YYYY HH:mm')}
  //             </TableCell>
  //             <TableCell>{shift.location.name}</TableCell>
  //           </TableRow>
  //         ))} */}
  //       </TableBody>
  //     </Table>
  //   </TableContainer>
  // );

  // const displayedShifts = (
  //   <div className={classes.shift__container}>
  //     <table className={classes.shift__table}>
  //       <thead>
  //         <tr>
  //           <th>ShiftId#</th>

  //           <th>Start</th>
  //           <th>End</th>
  //           <th>Location/Site</th>
  //           {/* <th>Actions</th> */}
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {myShifts.map((shift) => (
  //           <tr key={shift.id}>
  //             <td>{shift.id}</td>
  //             <td>{moment(shift.startLocal).format('ddd MMM,DD YYYY HH:mm')}</td>
  //             <td>{moment(shift.endLocal).format('ddd MMM,DD YYYY HH:mm')}</td>
  //             <td>{shift.location.name}</td>
  //             {/* <td>
  //               <button
  //                 type="button"
  //                 className={classes.delete__btn}
  //                 onClick={() => {}}
  //               >
  //                 Confirm
  //               </button>
  //               <button
  //                 type="button"
  //                 className={classes.delete__btn}
  //                 onClick={() => {}}
  //               >
  //                 Not Attending
  //               </button>
  //               <button
  //                 type="button"
  //                 className={classes.delete__btn}
  //                 onClick={() => {}}
  //               >
  //                 Add Report
  //               </button>
  //             </td> */}
  //           </tr>
  //         ))}
  //       </tbody>
  //     </table>
  //   </div>
  // );

  if (isLoading) {
    return <LoadingMaterialUI />;
  }

  return (
    <main>
      <h3>My Shifts</h3>
      <LabTabs
        tab1Content={
          myShiftsUpcoming?.length
            ? !isMobile
              ? displayedUpcomingShift
              : displayedUpcomingShiftsCard
            : `No such records`
        }
        tab2Content={
          myShiftsHistory?.length
            ? !isMobile
              ? displayedHistoryShift
              : displayedHistoryShiftsCard
            : `No such records`
        }
      />
      {/* MODAL FOR DISPLAY OF NOTES */}
      <Modal
        open={openNotesModal}
        onClose={() => setOpenNotesModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          {modalContentNotes.length === 0 ? (
            <div>
              <Typography>No Notes</Typography>
            </div>
          ) : (
            <div>
              {modalContentNotes?.map((note: ShiftNote, index: number) => (
                <div key={note.id}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {`Note: ${index + 1}`}
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mb: 1 }}
                  >
                    {note.note}
                  </Typography>
                </div>
              ))}
            </div>
          )}
        </Box>
      </Modal>
      {/* MODAL FOR DISPLAY OF ATTENDANCE OPTIONS */}
      <Modal
        open={openAttendanceModal}
        onClose={() => setAttendanceModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          {shiftStatusOptions.length === 0 ? (
            <div>
              <Typography>Can't change attendance status</Typography>
            </div>
          ) : (
            <div>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Attendance Status
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={shiftStatusId}
                  onChange={handleChangeShiftStatus}
                >
                  {shiftStatusOptions?.map((status, _) => {
                    if (status.id > 1) {
                      return (
                        <div key={status.id}>
                          <FormControlLabel
                            value={status.id}
                            control={<Radio />}
                            label={status.value}
                          />
                        </div>
                      );
                    }
                  })}
                </RadioGroup>
              </FormControl>
            </div>
          )}
        </Box>
      </Modal>

      <Modal
        open={openShiftReportModal}
        onClose={() => {
          // setShiftReport(initialValuesShiftReport);
          setOpenShiftReportModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            height: "70%",
            overflow: "scroll",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div>
            <form className={classes.form}>
              <div className={classes.form__center}>
                <div>
                  <h4>Shift Report</h4>
                  {shiftReportQuestions.length === 0 ? (
                    <div>
                      <Typography>No Report Questions</Typography>
                    </div>
                  ) : (
                    <div>
                      {shiftReportQuestions?.map((question) => (
                        <div key={question.questionKey}>
                          <div>
                            <label
                              htmlFor={question.questionKey}
                              className={classes.notes__label}
                            >
                              {question.questionName}
                            </label>
                          </div>
                          <TextField
                            name={question.questionKey}
                            // label="q1"
                            onChange={handleChangeShiftReport}
                            value={shiftReport.questionKey}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            multiline
                            rows={3}
                            sx={{
                              width: "550px",
                              paddingBottom: "20px",
                              paddingRight: "30px",
                            }}
                          ></TextField>
                        </div>
                      ))}
                    </div>
                  )}
                  {/*<div>
                    <label htmlFor="q1" className={classes.notes__label}>
                      Time changes
                    </label>
                  </div>
                  <TextField
                    name="q1"
                    // label="q1"
                    onChange={handleChangeShiftReport}
                    value={shiftReport.q1}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    multiline
                    rows={3}
                    sx={{
                      width: '550px',
                      paddingBottom: '20px',
                      paddingRight: '30px',
                    }}
                  ></TextField>
                  <div>
                    <label htmlFor="q2" className={classes.notes__label}>
                      Incidents
                    </label>
                  </div>
                  <TextField
                    name="q2"
                    // label="q2"
                    onChange={handleChangeShiftReport}
                    value={shiftReport.q2}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    multiline
                    rows={3}
                    sx={{
                      width: '550px',
                      paddingBottom: '20px',
                      paddingRight: '30px',
                    }}
                  ></TextField>
                  <div>
                    <label htmlFor="q3" className={classes.notes__label}>
                      Equipments
                    </label>
                  </div>
                  <TextField
                    name="q3"
                    // label="q3"
                    onChange={handleChangeShiftReport}
                    value={shiftReport.q3}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    multiline
                    rows={3}
                    sx={{
                      width: '550px',
                      paddingBottom: '20px',
                      paddingRight: '30px',
                    }}
                  ></TextField>
                  <div>
                    <label htmlFor="q4" className={classes.notes__label}>
                      Score
                    </label>
                  </div>
                  <TextField
                    name="q4"
                    // label="q4"
                    onChange={handleChangeShiftReport}
                    value={shiftReport.q4}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    multiline
                    rows={3}
                    sx={{
                      width: '550px',
                      paddingBottom: '20px',
                      paddingRight: '30px',
                    }}
                  ></TextField>*/}
                </div>
                <div className={classes.btn__container}>
                  <Button
                    variant="contained"
                    type="submit"
                    className={classes.btn__submit}
                    disabled={isLoading}
                    sx={{
                      color: "var(--primary-blue-500)",
                      bgcolor: "var(--primary-red-500)",
                      "&:hover": { bgcolor: "var(--primary-red-600)" },
                      marginRight: "10px",
                    }}
                    onClick={onSubmitShiftReport}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    type="button"
                    className={classes.btn__cancel}
                    sx={{
                      color: "var(--primary-blue-500)",
                      bgcolor: "var(--primary-red-500)",
                      "&:hover": { bgcolor: "var(--primary-red-600)" },
                      marginRight: "10px",
                    }}
                    onClick={() => {
                      setShiftReport({});
                      setOpenShiftReportModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Box>
      </Modal>

      {/* {myShifts?.length ? displayedShifts : `No such records`} */}
      {/* {myShifts?.length ? displayedShiftsReactTable : `No such records`} */}
      {/* {myShifts?.length
        ? displayedShiftReactTableMaterialUI
        : `No such records`} */}
      {/* {myShifts?.length ? displayedShiftsMaterialUI : `No such records`} */}
    </main>
  );
};

export default MyShifts;
