import { Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const MarketingContentManagementHome = () => {
  return (
    <main>
      <h3>This is the MARKETING Content Management home page</h3>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <Link to={'/marketing/content-management/content'}>
          <Button
            sx={{
              color: 'var(--black)',
              bgcolor: 'var(--primary-green-400)',
              '&:hover': { bgcolor: 'var(--primary-green-500)' },
              borderRadius: '0.5rem',
            }}
          >
            {`CMS (Content)`}
          </Button>
        </Link>
        <Link to={'/marketing/content-management/email-tool'}>
          <Button
            sx={{
              color: 'var(--black)',
              bgcolor: 'var(--primary-green-400)',
              '&:hover': { bgcolor: 'var(--primary-green-500)' },
              borderRadius: '0.5rem',
            }}
          >
            {`Email Tool`}
          </Button>
        </Link>
      </div>
    </main>
  );
};

export default MarketingContentManagementHome;
