import {
  TextField,
  createTheme,
  ThemeProvider,
  InputAdornment,
} from '@mui/material';
import { FormRowProps } from './types';

const theme = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& label.Mui-focused': {
            color: 'var(--primary-blue-500)',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: 'var(--primary-blue-500)',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'var(--primary-blue-300)',
            },
            '&:hover fieldset': {
              borderColor: 'var(--primary-blue-400)',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'var(--primary-blue-500)',
            },
          },
        },
      },
    },
  },
});

const FormRow: React.FC<FormRowProps> = ({
  type,
  name,
  value,
  defaultValue,
  handleChange,
  labelText,
  helperText,
  adornment,
  width,
  paddingBottom,
  paddingRight,
  onBlur,
  error,
  autocomplete,
  placeholder,
  id,
}) => {
  const w = width ? width : '300px';
  const pb = paddingBottom ? paddingBottom : '20px';
  const pr = paddingRight ? paddingRight : '30px';

  return (
    <ThemeProvider theme={theme}>
      <TextField
        name={name}
        onChange={handleChange}
        onBlur={onBlur}
        defaultValue={defaultValue}
        type={type}
        value={value}
        label={labelText}
        helperText={helperText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">{adornment}</InputAdornment>
          ),
        }}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          minWidth: 50,
          width: w,
          paddingBottom: pb,
          paddingRight: pr,
        }}
        error={error}
        autoComplete={autocomplete}
        placeholder={placeholder}
        id={id}
      ></TextField>
    </ThemeProvider>
  );
};

export default FormRow;
