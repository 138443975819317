import { AppDispatch } from "../../app/store";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import BreadCrumbs from "../UI/Breadcrumbs/Breadcrumbs";
import Button from "../UI/Button/Button";
import { z } from "zod";
import { generateRandomColour } from "../../utils/generateVmColour";
import SimpleDragDropBoard from "../UI/DragAndDrop/DivisionTeamBoard";
import {
  ListAllOperations,
  useDivisionByIdLazyQuery,
  useReseedDivisionsMutation,
} from "../../generated/graphql";
import { useNavigate, useParams } from "react-router-dom";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import Disclaimer from "../UI/Alerts/Disclaimer";
import { client } from "../../graphql";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";

const TeamSchema = z.object({
  id: z.number(),
  name: z.string(),
});

const DivisionReseedSchema = z.object({
  id: z.number(),
  name: z.string(),
  vmColor: z.string(),
  teams: z.array(TeamSchema),
});
export type Team = z.infer<typeof TeamSchema>;
export type DivisionReseed = z.infer<typeof DivisionReseedSchema>;

const ReseedDivisions = () => {
  // Get id from url
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [divisionsToReseed, setDivisionsToReseed] = useState<DivisionReseed[]>(
    []
  );

  const [reseedDivision, { loading: loadingReseedDivision }] =
    useReseedDivisionsMutation();

  const [
    divisionByScheduleId,
    { data: divisionById, error: divisionError, loading: loadingDivision },
  ] = useDivisionByIdLazyQuery({
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (id) {
      divisionByScheduleId({
        variables: {
          divisionId: +id,
        },
        onCompleted: (data) => {
          setDivisionsToReseed([
            {
              id: 0,
              name: "Unpooled Teams",
              vmColor: generateRandomColour(),
              teams: data.division.session.teams
                .filter(
                  (team) =>
                    !data.division.session.divisions
                      .map((division) => division.teams)
                      .flat()
                      .map((team) => team.id)
                      .includes(team.id)
                )
                .map((team) => {
                  return {
                    id: team.id,
                    name: team.name,
                  };
                }),
            },
            ...data.division.session.divisions.map((division) => {
              return {
                id: division.id,
                name: division.name,
                vmColor: division.vmColor,
                teams: division.teams.map((team) => {
                  return {
                    id: team.id,
                    name: team.name,
                  };
                }),
              };
            }),
          ]);
        },
      });
    }
  }, []);

  useEffect(() => {
    console.log(divisionsToReseed);
  }, [divisionsToReseed]);

  async function handleSubmitReseedDivisions() {
    const result = await reseedDivision({
      variables: {
        reseedDivisionInput: {
          reseedDivisionData: divisionsToReseed
            .filter((division) => division.id !== 0)
            .map((division) => {
              return {
                id: division.id,
                teams: division.teams.map((team) => {
                  return team.id;
                }),
              };
            }),
        },
      },
      onCompleted: async (data) => {
        if (data.reseedDivisions.success) {
          dispatch(displayAlertSuccess("Divisions reseeded successfully"));
          await client.refetchQueries({
            include: [
              ListAllOperations.Query.Divisions,
              ListAllOperations.Query.DivisionById,
            ],
          });
        } else {
          dispatch(displayAlertError("Error reseeding divisions"));
        }
        navigate(`/ops/division/${id}`);
      },
      onError: (error) => {
        console.log(error);
        dispatch(displayAlertError("Error reseeding divisions"));
      },
    });
  }

  // function handleNewDivision() {
  //   setDivisionsToReseed((prevState) => [
  //     ...prevState,
  //     {
  //       id: undefined,
  //       name: `${divisions?.name} ${prevState.length + 1}`,
  //       vmColor: generateRandomColour(),
  //       teams: [],
  //     },
  //   ]);
  // }

  if (!id) {
    return (
      <div>
        <Disclaimer
          persist={true}
          size="large"
          variant="info"
          title="No Division Selected"
          content="Please select a division to reseed"
        />
      </div>
    );
  }

  const pathsBreadcrumbs = [{ name: "Divisions", url: `/ops/division/${id}` }];

  return (
    <main className="flex flex-col gap-4">
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo={`/ops/division/${id}`}
      ></BreadCrumbs>
      <Headline1Variable>Reseed Divisions</Headline1Variable>
      <Button
        variant="primary"
        className="w-fit"
        onClick={handleSubmitReseedDivisions}
      >
        Confirm
      </Button>

      <div className="pb-10">
        <SimpleDragDropBoard
          divisions={divisionsToReseed}
          setDivisions={setDivisionsToReseed}
        />
      </div>
      <LoadingDialog open={loadingReseedDivision || loadingDivision} />
    </main>
  );
};

export default ReseedDivisions;
