import { gql } from '@apollo/client';

const GET_SHIFT_REPORT_QUESTIONS = gql`
  query ShiftReportQuestions {
    shiftReportQuestions {
      id
      questionKey
      questionName
    }
  }
`;

export { GET_SHIFT_REPORT_QUESTIONS };
