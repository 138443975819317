import { gql } from "@apollo/client";

const GET_VENUE_SIZE = gql`
  query VenueSizes {
    venueSizes {
      id
      name
    }
  }
`;

export { GET_VENUE_SIZE };
