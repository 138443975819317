import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import { FormRow, FormRowSelect, LoadingMaterialUI } from '../UI';
import classes from './CreateGameslot.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../app/store';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  getCapacityGroupOverview,
  getCapacityGroups,
  getGameslotById,
  getGameslots,
  getVenuesHierachy,
} from '../../app/venueMasterSlice';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {
  CREATE_GAMESLOT,
  UPDATE_GAMESLOT,
} from '../../graphql/queries/gameslot';
import { CapacityGroup, CgOverviewFilters, Venue } from '../../types/types';

interface CreateGameslotProps {
  onClose?: any;
  id?: number | null;
  selectedCapacityGroupId?: number;
  cgOverviewFilters?: CgOverviewFilters;
}

const CreateGameslot: React.FC<CreateGameslotProps> = ({
  onClose,
  id,
  selectedCapacityGroupId,
  cgOverviewFilters,
}) => {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const isEditGameslot = id;

  const {
    isLoading,
    capacityGroups,
    venuesHierarchy,
    gameslot: gameslotById,
  }: any = useSelector((state: RootState) => state.venueMaster);

  const initialGameslot = {
    date: '',
    startTime: null,
    duration: 0,
    venueId: 0,
    capacityGroupId: selectedCapacityGroupId,
  };

  useEffect(() => {
    dispatch(getCapacityGroups(''));
    if (isEditGameslot) dispatch(getGameslotById(isEditGameslot));
  }, [dispatch]);

  useEffect(() => {
    if (gameslotById && isEditGameslot) {
      setGameslot({
        ...gameslotById,
        date: moment(gameslotById.date),
        startTime: new Date(moment(gameslot.date).toISOString()),
      });
      if (gameslotById.venueId)
        dispatch(getVenuesHierachy([gameslotById.venueId]));
    } else {
      const capacityGroup = capacityGroups.find(
        (capacity: CapacityGroup) => +capacity.id === selectedCapacityGroupId
      );
      if (capacityGroup && capacityGroup.sessions) {
        let venuesIds: String[] = [];
        capacityGroup.sessions.map((session: any) => {
          if (session.venues) {
            const ids = session.venues.map((venue: any) => venue.id);
            venuesIds = ids;
          }
        });
        dispatch(getVenuesHierachy(venuesIds));
      }
    }
  }, [gameslotById]);

  // useEffect(() => {
  //   dispatch(getVenuesHierachy([gameslotById.venueId]));
  // }, [selectedCapacityGroupId])

  const [gameslot, setGameslot]: any = React.useState(initialGameslot);
  const [errors, setErrors]: any = React.useState({});

  const [CreateGameslot, { data, loading, error }]: any =
    useMutation(CREATE_GAMESLOT);
  const [UpdateGameslot, { dataUpdate, loadingUpdate, errorUpdate }]: any =
    useMutation(UPDATE_GAMESLOT);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const gameslotUpdate = {
      ...gameslot,
      [e.target.name]: +e.target.value || e.target.value,
    };
    setGameslot(gameslotUpdate);
    if (e.target.value === '') setErrors({ ...errors, [e.target.name]: true });
    else if (+e.target.value < 0)
      setErrors({ ...errors, [e.target.name]: true });
    else setErrors({ ...errors, [e.target.name]: false });
  };

  const handleVenues = (capacityGroupId: string) => {
    const capacityGroup = capacityGroups.find(
      (capacity: CapacityGroup) => capacity.id === capacityGroupId
    );
    if (capacityGroup && capacityGroup.sessions) {
      let venuesIds: String[] = [];
      capacityGroup.sessions.map((session: any) => {
        if (session.venues) {
          const ids = session.venues.map((venue: any) => venue.id);
          venuesIds = ids;
        }
      });
      dispatch(getVenuesHierachy(venuesIds));
    }
  };

  const checkFormErrors = () => {
    const errorValues: any = {};
    Object.keys(gameslot).forEach(function (key) {
      if (!gameslot[key]) errorValues[key] = true;
      else if (gameslot[key] === '0') errorValues[key] = true;
      else if (gameslot[key] === '') errorValues[key] = true;
      else if (gameslot[key] < 0) errorValues[key] = true;
      else errorValues[key] = false;
    });
    setErrors(errorValues);
    return Object.keys(errorValues).every((k) => !errorValues[k]);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const checkErrors = checkFormErrors();
    if (checkErrors) {
      const gameslotArgs = {
        date: moment(gameslot.date),
        startTime: gameslot.startTime,
        duration: gameslot.duration,
        venueId: +gameslot.venueId,
        capacityGroupId: +gameslot.capacityGroupId,
      };
      if (!isEditGameslot) {
        CreateGameslot({
          variables: { gameslotArgs: { gameslotInput: gameslotArgs } },
        }).then(() => {
          // navigate(`${rootPath}/gameslots`);
          dispatch(getGameslots({ capacityGroupId: selectedCapacityGroupId }));
          dispatch(getCapacityGroupOverview(cgOverviewFilters));
          onClose();
        });
      } else {
        UpdateGameslot({
          variables: {
            gameslotArgs: { id: +isEditGameslot, gameslotInput: gameslotArgs },
          },
        }).then(() => {
          dispatch(getGameslots({ capacityGroupId: selectedCapacityGroupId }));
          dispatch(getCapacityGroupOverview(cgOverviewFilters));
          //navigate(`${rootPath}/gameslots`);
          onClose();
        });
      }
    }
    // else console.log('form error!');
  };

  if (loading || loadingUpdate || isLoading) return <LoadingMaterialUI />;
  if (error || errorUpdate) {
    process.env.NODE_ENV === 'development' && console.log(error.message);
  }

  return (
    <main>
      {isEditGameslot ? <h1>Edit Gameslot</h1> : <h1>Create Gameslot</h1>}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <h4>Gameslot Details</h4>
        <div className={classes.container_details}>
          <div className={classes.header_details}>Date</div>
          <div className={classes.header_details}>Start Time</div>
          <div className={classes.header_details}>Duration</div>
          <div className={classes.form_item}>
            <FormRow
              type="date"
              name="date"
              width="100%"
              value={moment(gameslot.date).format('YYYY-MM-DD')}
              handleChange={handleChange}
              error={errors.date}
            />
          </div>
          <div className={classes.form_item}>
            <div className={classes.timepickers}>
              <DatePicker
                wrapperClassName={classes.datepicker_date}
                name="startTime"
                selected={gameslot.startTime}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={10}
                timeCaption="Time"
                dateFormat="h:mm aa"
                onChange={(date) => {
                  setGameslot({ ...gameslot, startTime: date });
                }}
              />
            </div>
          </div>
          <div className={classes.form_details}>
            <FormRow
              name="duration"
              type="number"
              value={gameslot.duration}
              error={errors.duration}
              handleChange={handleChange}
              width="100%"
            />
          </div>
        </div>
        <hr className={classes.line}></hr>
        <h4>Capacity Group Details</h4>
        <div className={classes.container_game_details}>
          <div className={classes.header_details}>Capacity Group</div>
          <div className={classes.header_details}>Venue</div>
          <div className={classes.form_details}>
            <FormRowSelect
              isDisabled={selectedCapacityGroupId ? true : false}
              name="capacityGroupId"
              value={selectedCapacityGroupId}
              error={errors.capacityGroupId}
              list={[
                ...capacityGroups,
                ...[{ id: '0', name: 'Select Capacity Group' }],
              ]}
              width="100%"
              handleChange={(event) => {
                handleChange(event);
                handleVenues(event.target.value);
              }}
            />
          </div>
          <div className={classes.form_details}>
            <FormRowSelect
              name="venueId"
              value={gameslot.venueId}
              error={errors.venueId}
              list={[
                ...venuesHierarchy,
                ...[{ id: '0', name: 'Select Venue' }],
              ]}
              width="100%"
              handleChange={(event) => {
                handleChange(event);
              }}
            />
          </div>
        </div>
        <div className={classes.btn__container}>
          <Button
            variant="contained"
            type="submit"
            className={classes.btn__submit}
            sx={{
              color: 'var(--white)',
              bgcolor: 'var(--primary-blue-500)',
              '&:hover': { bgcolor: 'var(--primary-blue-700)' },
            }}
          >
            {isEditGameslot ? 'Update Gameslot' : 'Create Gameslot'}
          </Button>
          <Button
            variant="contained"
            className={classes.btn__submit}
            sx={{
              color: 'var(--primary-blue-400)',
              bgcolor: 'var(--white)',
              '&:hover': { bgcolor: 'var(--gray)' },
              marginLeft: '20px',
            }}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </div>
      </form>
    </main>
  );
};

export default CreateGameslot;
