'use client';

import React, { FC, HTMLProps, useEffect, useState } from 'react';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { cn } from '../../../../lib/utils';
import moment from 'moment-timezone';

interface FormFieldProps extends HTMLProps<HTMLInputElement> {
  /**
   * The placeholder for the formfield
   */
  placeholder?: string;
  /**
   * The label for the formfield (text above the field)
   */
  label?: string;
  /**
   * The assitive text for the field (text under the field)
   */
  assistiveText?: string;
  /**
   * Set to true if the input should be disabled.
   */
  disabled?: boolean;
  /**
   * Set to true if the input should be showing an error state
   */
  error?: boolean;
  /**
   * The function that is called whenever the input changes
   */
  inputChange: (data: any) => void;
  /**
   * An intitial value for the form to have
   */
  initialValue?: string | null;
  /**
   * Custom classname attribtues
   */
  className?: string;

  visibility?: string;
  type?: string;
}

// Function to convert Date to YYYY-MM-DD format
const formatDateToYYYYMMDD = (date: Date) => {
  return moment(date).clone().tz('UTC', true).format('YYYY-MM-DD');
};

const FormFieldDateSelect: FC<FormFieldProps> = ({
  placeholder,
  label,
  assistiveText,
  disabled,
  error,
  inputChange,
  initialValue,
  className,
  visibility,
  ...props
}: FormFieldProps) => {
  const [inputVal, setInputVal] = useState<string | null>(
    initialValue ? moment(initialValue).format('YYYY-MM-DD') : null
  );

  useEffect(() => {
    if (initialValue) {
      setInputVal(moment(initialValue).format('YYYY-MM-DD'));
    }
  }, [initialValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    inputChange(e);
    setInputVal(e.target.value);
  };

  const mode = disabled
    ? 'border-2 border-neutral-60 bg-neutral-90 focus:outline-none'
    : error
    ? 'border border-error-30 hover:border-error-30 focus:outline-2 focus:outline-error-30'
    : 'border border-secondary-80 hover:border-secondary-50 focus:outline-2 focus:outline-primary-80';

  return (
    <div
      className={`flex flex-col w-full text-black ${
        visibility === 'hidden' ? 'invisible' : 'visible'
      }`}
    >
      <label
        className={`px-3 text-xs font-medium ${
          error && !disabled && 'text-error-10'
        }`}
      >
        {label}
      </label>
      <div className="relative flex flex-row items-center gap-2">
        <input
          type="date"
          disabled={disabled}
          placeholder={placeholder}
          className={cn(
            'w-full font-normal rounded-xl px-3 py-2',
            disabled
              ? 'border-2 border-neutral-60 bg-neutral-90 focus:outline-none'
              : error
              ? 'border border-error-30 hover:border-error-30 focus:outline-2 focus:outline-error-30'
              : 'border border-secondary-80 hover:border-secondary-50 focus:outline-2 focus:outline-primary-80',
            className
          )}
          {...props}
          value={inputVal ? inputVal : ''}
          onChange={handleChange}
        />
        {/* The error icon will appear to the right of the input if it's in an error state */}
        {error && (
          <ErrorOutlineOutlinedIcon className="absolute right-2 text-error-30" />
        )}
      </div>
      <div
        className={`px-3 text-xxs font-normal ${
          error && !disabled ? 'text-error-10' : 'text-light'
        }`}
      >
        {assistiveText}
      </div>
    </div>
  );
};

export default FormFieldDateSelect;
