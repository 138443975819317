import { gql } from '@apollo/client';

const GET_LEGAL_DOCUMENT_BY_REGION_AND_TYPE = gql`
  query GetLegalDocumentsByRegionAndType(
    $regionId: Int!
    $legalDocumentTypeId: Int!
  ) {
    getLegalDocumentsByRegionAndType(
      regionId: $regionId
      legalDocumentTypeId: $legalDocumentTypeId
    ) {
      id
      body {
        body
        id
      }
      authorId
      legalDocumentBodyId
      legalDocumentTypeId
      name
      regionId
      createdAt
      authorName
    }
  }
`;

const CREATE_LEGAL_DOCUMENT = gql`
  mutation CreateLegalDocument($legalDocumentArgs: CreateLegalDocumentInput!) {
    createLegalDocument(legalDocumentArgs: $legalDocumentArgs) {
      code
      message
      success
    }
  }
`;

export { GET_LEGAL_DOCUMENT_BY_REGION_AND_TYPE, CREATE_LEGAL_DOCUMENT };
