import { gql } from "@apollo/client";

const GET_APPLIED_SHIFTS = gql`
  query AppliedShifts($regions: [Int]) {
    appliedShifts(regions: $regions)
  }
`;

const GET_REQUESTED_SHIFTS = gql`
  query RequestedShifts($requestedShiftArgs: RequestedShiftsFilter) {
    requestedShifts(requestedShiftArgs: $requestedShiftArgs)
  }
`;

const CREATE_REQUESTED_SHIFT = gql`
  mutation RequestedShiftCreate($shiftId: ID!) {
    requestedShiftCreate(shiftId: $shiftId) {
      code
      message
      requestedShift {
        id
      }
    }
  }
`;

const DELETE_REQUESTED_SHIFT = gql`
  mutation RequestedShiftDelete($shiftId: ID!) {
    requestedShiftDelete(shiftId: $shiftId) {
      code
      message
      requestedShift {
        id
      }
      success
    }
  }
`;

export {
  GET_APPLIED_SHIFTS,
  GET_REQUESTED_SHIFTS,
  CREATE_REQUESTED_SHIFT,
  DELETE_REQUESTED_SHIFT,
};
