import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  HttpLink,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import axios from 'axios';

const baseExpressURL = `${
  process.env.REACT_APP_ENV === 'production'
    ? 'https://admin.jamsports.com'
    : process.env.REACT_APP_ENV === 'staging'
    ? 'https://admin.jamitall.com'
    : 'http://localhost:3001'
}/api/v1`;

const httpLink = new HttpLink({
  uri:
    process.env.REACT_APP_ENV === 'production'
      ? 'https://admin.jamsports.com/api/graphql'
      : process.env.REACT_APP_ENV === 'staging'
      ? 'https://admin.jamitall.com/api/graphql'
      : 'http://localhost:3001/graphql',
  credentials: 'same-origin',
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    process.env.NODE_ENV === 'development' &&
      console.log(`[GraphQL Error]: ${graphQLErrors}`);

    graphQLErrors.forEach(async (error) => {
      if (error.extensions?.code === 'UNAUTHENTICATED') {
        localStorage.removeItem('jwtToken');
        window.location.href = '/register';

        const url = `${baseExpressURL}/auth/logout`;

        // the cookies are being emptied from here, not required request
        await axios.post(url, {
          headers: {
            'Content-type': 'application/json',
          },
          withCredentials: true,
        });
        return;
      } else {
        process.env.NODE_ENV === 'development' &&
          console.log('GraphQL Error:', error);
      }
    });
  }

  if (networkError) {
    process.env.NODE_ENV === 'development' &&
      console.log(`[Network Error]: ${networkError}`);
    return;
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = JSON.parse(localStorage.getItem('jwtToken') || '');

  if (!token) {
    window.location.href = '/register';
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'Apollo-Require-Preflight': 'true',
      'Access-Control-Allow-Origin': '*',
      'X-Apollo-Operation-Name': 'IntrospectionQuery',
    },
  };
});

export const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
});
