"use client";

import * as React from "react";
import { Clock } from "lucide-react";
import { Label } from "../label";
import { TimePickerInput } from "./time-picker-input";

interface TimePickerDemoProps {
  date: Date | undefined;
  setDate: (date: Date | undefined) => void;
  minuteStep?: number;
  excludeSecond?: boolean;
  includeClock?: boolean;
  disabled?: boolean;
  id?: string;
}

export function TimePickerDemo({
  date,
  setDate,
  minuteStep = 1,
  excludeSecond = false,
  includeClock = true,
  disabled = false,
  id = "timePicker",
}: TimePickerDemoProps) {
  const minuteRef = React.useRef<HTMLInputElement>(null);
  const hourRef = React.useRef<HTMLInputElement>(null);
  const secondRef = React.useRef<HTMLInputElement>(null);
  return (
    <div className="flex items-end gap-2">
      <div className="grid gap-1 text-center">
        <Label
          htmlFor={id ? id + "-hour" : "hours"}
          className="text-xs"
        >
          Hour
        </Label>
        <TimePickerInput
          id={id + "-hour"}
          disabled={disabled}
          picker="hours"
          date={date}
          setDate={setDate}
          ref={hourRef}
          onRightFocus={() => minuteRef.current?.focus()}
        />
      </div>
      <div className="grid gap-1 text-center">
        <Label
          htmlFor={id ? id + "-minute" : "minutes"}
          className="text-xs"
        >
          Minute
        </Label>
        <TimePickerInput
          disabled={disabled}
          id={id + "-minute"}
          picker="minutes"
          date={date}
          setDate={setDate}
          ref={minuteRef}
          step={minuteStep}
          onLeftFocus={() => hourRef.current?.focus()}
          onRightFocus={() => secondRef.current?.focus()}
        />
      </div>
      {!excludeSecond && (
        <div className="grid gap-1 text-center">
          <Label
            htmlFor={id ? id + "-second" : "seconds"}
            className="text-xs"
          >
            Second
          </Label>
          <TimePickerInput
            disabled={disabled}
            id={id + "-second"}
            picker="seconds"
            date={date}
            setDate={setDate}
            ref={secondRef}
            onLeftFocus={() => minuteRef.current?.focus()}
          />
        </div>
      )}
      {includeClock && (
        <div className="flex items-center h-10">
          <Clock className="w-4 h-4 ml-2" />
        </div>
      )}
    </div>
  );
}
