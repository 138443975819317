import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const StaffingStaffHome = () => {
  return (
    <main>
      <h3>This is the STAFFING Staff home page</h3>
      <div style={{ display: "flex", gap: "1rem" }}>
        <Link to={"/staffing/staff-type-management"}>
          <Button
            sx={{
              color: "var(--black)",
              bgcolor: "var(--primary-green-400)",
              "&:hover": { bgcolor: "var(--primary-green-500)" },
              borderRadius: "0.5rem",
            }}
          >
            Staff Type Management
          </Button>
        </Link>
        <Link to={"/staffing/third-party-contractor-management"}>
          <Button
            sx={{
              color: "var(--black)",
              bgcolor: "var(--primary-green-400)",
              "&:hover": { bgcolor: "var(--primary-green-500)" },
              borderRadius: "0.5rem",
            }}
          >
            Third Party Contractor Management
          </Button>
        </Link>
      </div>
    </main>
  );
};

export default StaffingStaffHome;
