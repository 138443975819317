import {
  Button,
  Table as TableBase,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";
import React, { Fragment, useMemo } from "react";
import classes from "../../UI/GameSchedule.module.css";
import { Column, useTable } from "react-table";
import dayjs from "dayjs";
import { Checkbox } from "../../UI/shadcn/checkbox";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import ActionsCell from "../../UI/ActionCell";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { ordinal } from "./ScheduleNew";
import Tooltip from "../../UI/Tooltip/Tooltip";
import Body1 from "../../UI/Text/Body/Body1";
import { GameStatus } from "@/src/types/types";
import TooltipMUI from "@mui/material/Tooltip";

interface collapseVenueProps {
  schedule: [];
  teams: any[];
  venues: any[];
  divisionTeams: any[];
  handleChange: (
    scheduleTitle: string,
    name: string,
    value: string,
    index: number
  ) => void;
  handleGameScoreStanding: (
    scheduleTitle: string,
    game: any,
    index: number
  ) => void;
  week: number;
  handleGameRescheduleProps: (
    scheduleTitle: string,
    name: string,
    value: string,
    index: number,
    game: any,
    optionType: string,
    gameStatusId: string
  ) => void;
  isEdit: boolean;
  gameStatuses: GameStatus[];
  isPlayoff: boolean;
  playoffGameNumberOptions?: { id: number; name: string }[];
}

export function matchupValueToId(
  value: number | null,
  type: number | null
): string | null {
  if (value == null) {
    return null;
  }
  if (type == 1) {
    return `${value} Place`;
  } else if (type == 2) {
    return `${value < 0 ? "Loser" : "Winner"} Game ${
      value < 0 ? value * -1 + (value * -1 - 1) : value + value - 2
    }`;
  } else if (type == 3) {
    return value.toString();
  }
  return null;
}

export function matchupValueToName(
  value: number | null,
  type: number | null
): string | null {
  if (value == null) {
    return null;
  }
  if (type == 1) {
    return `${ordinal(value + 1)} Place Place`;
  } else if (type == 2) {
    return `${value < 0 ? "Loser" : "Winner"} Game ${Math.abs(value)}`;
  } else if (type == 3) {
    return value.toString();
  }
  return null;
}

const hours = Array.from({ length: 12 }, (_, i) => i + 1);
const minutes = Array.from({ length: 60 / 5 }, (_, i) => i * 5);
const timeofday = ["AM", "PM"];

const GameSchedule: React.FC<collapseVenueProps> = (props) => {
  const {
    schedule,
    teams,
    venues,
    week,
    handleGameRescheduleProps,
    isEdit,
    divisionTeams,
    handleChange,
    gameStatuses,
    isPlayoff,
    playoffGameNumberOptions,
    handleGameScoreStanding,
  } = props;

  const options: any = [
    { id: 0, text: "Reschedule" },
    { id: 1, text: "Change Game Status" },
    { id: 2, text: "Adjust Score Standings" },
  ];

  const handleSelectedOption = (
    row: any,
    indexRow: number,
    optionId: number
  ) => {
    if (optionId === 0) {
      handleGameRescheduleProps(
        scheduleTitle,
        "startDateTimeLocal",
        row.startDateTimeLocal,
        indexRow,
        row,
        "reschedule",
        row.gameStatusId
      );
    } else if (optionId === 1) {
      handleGameRescheduleProps(
        scheduleTitle,
        "startDateTimeLocal",
        row.startDateTimeLocal,
        indexRow,
        row,
        "game status",
        row.gameStatusId
      );
    } else if (optionId === 2) {
      handleGameScoreStanding(scheduleTitle, row, indexRow);
    }
  };
  const scheduleTitle: any = Object.keys(schedule)[0];
  const COLUMNS: Column<any>[] = [
    {
      Header: "GameId",
      id: "id",
      accessor: (d: any) => {
        const status: string =
          gameStatuses.find((status) => status.id == d.gameStatusId)?.name ||
          "";
        return (
          <TooltipMUI
            title={status}
            placement="top"
          >
            <div
              className="flex gap-2"
              style={{ width: "30px" }}
            >
              <p>{d.id}</p>
              {d.gameStatusId == 2 && <HistoryToggleOffIcon />}
              {d.gameStatusId == 3 && <EventBusyIcon />}
              {d.gameStatusId == 4 && <WarningAmberIcon />}
            </div>
          </TooltipMUI>
        );
      },
    },
    {
      Header: "Date",
      id: "date",
      accessor: (d: any) => {
        return (
          <div style={{ width: "100px" }}>
            {dayjs(d.startDateTimeLocal).format("YYYY-MM-DD")}
          </div>
        );
      },
    },
    {
      Header: "Start Time",
      id: "startDateTimeLocal",
      accessor: (d: any, index: number) => {
        const date = new Date(d.startDateTimeLocal);
        const dateValue = dayjs(date);
        const hour = dateValue.hour() % 12 || 12;
        const timeOfDayValue = dateValue.hour() >= 12 ? "PM" : "AM";
        return (
          <div className="flex gap-1">
            <FormFieldSelect
              defaultValue="0"
              inputChange={(value) => {
                let newHour = value;
                if (timeOfDayValue === "PM") {
                  newHour = +value + 12;
                }
                handleChange(
                  scheduleTitle,
                  "startDateTimeLocal",
                  dayjs(dateValue.hour(newHour)).format("YYYY-MM-DD HH:mm:ss"),
                  index
                );
              }}
              name="hour"
              label="Hour"
              placeholder="hour"
              value={hour.toString()}
            >
              {hours.map((h) => ({ id: h.toString(), name: h }))}
            </FormFieldSelect>
            <div className="pt-5">:</div>
            <FormFieldSelect
              defaultValue="0"
              inputChange={(value) => {
                let newMinute = value;
                handleChange(
                  scheduleTitle,
                  "startDateTimeLocal",
                  dayjs(dateValue.minute(newMinute)).format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                  index
                );
              }}
              name="minutes"
              label="Minutes"
              placeholder="minutes"
              value={dateValue.minute().toString()}
            >
              {minutes.map((h) => ({ id: h.toString(), name: h }))}
            </FormFieldSelect>
            <FormFieldSelect
              defaultValue="AM"
              inputChange={(value) => {
                let newHour = hour;
                if (value === "PM") {
                  newHour = +hour + 12;
                  if (hour === 12) newHour = 12;
                } else if (hour === 12) newHour = 0;
                handleChange(
                  scheduleTitle,
                  "startDateTimeLocal",
                  dayjs(dateValue.hour(newHour)).format("YYYY-MM-DD HH:mm:ss"),
                  index
                );
              }}
              name="timeofday"
              label="AM/PM"
              placeholder="timeofday"
              value={timeOfDayValue}
            >
              {timeofday.map((h) => ({ id: h.toString(), name: h }))}
            </FormFieldSelect>
          </div>
        );
      },
    },
    {
      Header: "Venue",
      id: "venue",
      accessor: (d: any, index: number) => {
        return (
          <div className="w-[400px] max-w-[400px]">
            <FormFieldSelect
              inputChange={(value) => {
                handleChange(scheduleTitle, "venueId", value, index);
              }}
              placeholder="Venue"
              value={d.venueId}
            >
              {venues.map((venue) => {
                return {
                  id: venue.id,
                  name: venue.name,
                };
              })}
            </FormFieldSelect>
          </div>
        );
      },
    },
    {
      Header: "Matchup",
      id: "matchup",
      accessor: (d, index: number) => {
        return (
          <div className="flex flex-row items-center gap-2">
            <div>
              <FormFieldSelect
                inputChange={(value) => {
                  handleChange(scheduleTitle, "homeTeamId", value, index);
                }}
                placeholder="Team"
                value={
                  +d.gameTypeId == 2
                    ? matchupValueToId(d.homeTeamValue, d.homeTeamType)
                    : d.homeTeamId
                }
              >
                {teams.map((team) => {
                  return {
                    id: team.id,
                    name: team.name,
                  };
                })}
              </FormFieldSelect>
            </div>
            {d.homeTeamId && d.awayTeamId && d.id ? (
              <Tooltip
                trigger="VS"
                hoverText={`${
                  divisionTeams.find((team) => team.id == d.homeTeamId)?.name
                } VS ${
                  divisionTeams.find((team) => team.id == d.awayTeamId)?.name
                } `}
              />
            ) : (
              <Body1>VS</Body1>
            )}
            <div>
              <FormFieldSelect
                inputChange={(value) => {
                  handleChange(scheduleTitle, "awayTeamId", value, index);
                }}
                placeholder="Team"
                value={
                  +d.gameTypeId == 2
                    ? matchupValueToId(d.awayTeamValue, d.awayTeamType)
                    : d.awayTeamId
                }
              >
                {teams.map((team) => {
                  return {
                    id: team.id,
                    name: team.name,
                  };
                })}
              </FormFieldSelect>
            </div>
          </div>
        );
      },
    },
    {
      Header: "Player Visibility",
      id: "isVisible",
      accessor: (d: any, index: number) => {
        return (
          <div className="flex flex-row items-center justify-center">
            <Checkbox
              checked={
                d.isVisible == 1 || d.isVisible === undefined ? true : false
              }
              className={
                "hover:bg-secondary-opacity-08 flex h-[20px] w-[20px] min-w-[20px] min-h-[20px] border-2 border-neutral-20 appearance-none items-center justify-center rounded-sm bg-white outline-none focus:outline-none data-[state=checked]:bg-primary-50 data-[state=checked]:border-none"
              }
              onCheckedChange={(event) => {
                handleChange(
                  scheduleTitle,
                  "isVisible",
                  event ? "1" : "0",
                  index
                );
              }}
            />
          </div>
        );
      },
    },
  ];

  if (isPlayoff && playoffGameNumberOptions) {
    COLUMNS.splice(1, 0, {
      Header: "Playoff Game #",
      id: "playoffGameNumber",
      accessor: (d: any, index: number) => {
        return (
          <div className="flex flex-row items-center justify-center">
            <FormFieldSelect
              inputChange={(value) => {
                handleChange(scheduleTitle, "playoffGameNumber", value, index);
              }}
              placeholder="PG#"
              name="playoffGameNumber"
              value={d.playoffGameNumber?.toString()}
            >
              {playoffGameNumberOptions}
            </FormFieldSelect>
          </div>
        );
      },
    });
  }

  if (isEdit) {
    COLUMNS.push({
      Header: "Actions",
      Cell: (d: any, index: number) => {
        return (
          <>
            {options && (
              <ActionsCell
                row={d.row}
                options={options}
                handleSelectedOption={(option, optionId) =>
                  handleSelectedOption(d.row.original, d.row.index, optionId)
                }
              />
            )}
          </>
        );
      },
    });
  }

  const columns = useMemo(
    () => COLUMNS,
    [JSON.stringify(schedule), JSON.stringify(teams), venues]
  );
  const data = useMemo(
    () => schedule[scheduleTitle],
    [JSON.stringify(schedule), JSON.stringify(teams), venues]
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: columns,
      data: data,
    });

  return (
    <Fragment>
      <h5 className={classes.scheduleTitle}>
        {`Week:${week} ${scheduleTitle}`}
      </h5>
      <TableContainer
        sx={{
          width: "95vw",
          maxWidth: "100%",
          bgcolor: "white",
          borderRadius: "12px",
          maxHeight: "100vh",
        }}
      >
        <TableBase
          stickyHeader
          aria-label="sticky table"
          sx={{
            maxHeight: "70vh",
          }}
          {...getTableProps()}
        >
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow
                {...headerGroup.getHeaderGroupProps()}
                sx={{ zIndex: 1 }}
              >
                {headerGroup.headers.map((column) => (
                  <TableCell
                    sx={{
                      bgcolor: "white",
                      color: "var(--textColor)",
                      fontSize: "16px",
                      textAlign: "left",
                      fontWeight: 550,
                      borderColor: "var(--table-border)",
                      zIndex: "inherit",
                    }}
                    {...column.getHeaderProps()}
                  >
                    <div>{column.render("Header")}</div>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow
                  hover
                  sx={{
                    borderBottom: "5px",
                    borderWidth: "2px",
                  }}
                  role="checkbox"
                  tabIndex={-1}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        sx={{
                          textAlign: "left",
                          fontSize: "15px",
                          color: "var(--textColor)",
                          // borderWidth: '2px',
                          borderColor: "var(--table-border)",
                          paddingBottom: "8px",
                          paddingTop: "8px",
                        }}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </TableBase>
      </TableContainer>
    </Fragment>
  );
};

export default GameSchedule;
