import {
  Division,
  DivisionByScheduleIdQuery,
  Game,
} from "../../../generated/graphql";
import { z } from "zod";
import { ordinal } from "./ScheduleNew";
import {
  DetailedTeamScoreRecord,
  GameForCalculatingStandings,
  PlacementToTeam,
  PlayoffGame,
  TeamPlayoffMatchupCategory,
  TeamPointsTotal,
  TeamScoreRecord,
} from "./ScheduleTypes";
import dayjs from "dayjs";

export function createPlacementToTeam(
  division: DivisionByScheduleIdQuery["division"]
): PlacementToTeam[] {
  return division.teams.map((team, index: number) => {
    return {
      placement: index,
      name: `${ordinal(index + 1)} Place`,
      teamId:
        Number(division.teams.find((t) => t.regSeasonStanding === index)?.id) ??
        null,
    };
  });
}

export function createPlayoffMatchupSelectOptions(
  division: DivisionByScheduleIdQuery["division"]
): TeamPlayoffMatchupCategory[] {
  const teamsArrayPlayoffs1: TeamPlayoffMatchupCategory[] = division.teams.map(
    (team, index: number) => {
      return {
        id: `${index} Place`,
        value: index,
        type: 1,
        name: `${ordinal(index + 1)} Place`,
      };
    }
  );

  const teamsArrayPlayoffs2: TeamPlayoffMatchupCategory[] = division.teams?.map(
    (team, index: number) => {
      const gameNumber = Math.floor(index / 2) + 1;
      const status = index % 2 === 0 ? "Winner" : "Loser";
      return {
        id: `${status} Game ${index}`,
        value: index % 2 === 0 ? gameNumber : gameNumber * -1,
        type: 2,
        name: `${status} Game ${gameNumber}`,
      };
    }
  );
  if (division.teams.length % 2 !== 0) {
    teamsArrayPlayoffs2.push({
      id: `Loser Game ${Math.floor(division.teams.length / 2) + 2}`,
      value: Math.floor(division.teams.length / 2) + 1,
      type: 2,
      name: `Loser Game ${Math.floor(division.teams.length / 2) + 3}`,
    });
  }

  const teamsArrayPlayoffs3: TeamPlayoffMatchupCategory[] = division.teams?.map(
    (team) => {
      return {
        id: team.id.toString(),
        value: team.id,
        type: 3,
        name: `${team.name}`,
      };
    }
  );
  return [
    ...teamsArrayPlayoffs1,
    ...teamsArrayPlayoffs2,
    ...teamsArrayPlayoffs3,
  ];
}

function getTeamPointsRecord(
  teamId: number,
  games: GameForCalculatingStandings[]
): { teamPointsTotal: TeamPointsTotal; gamesPlayed: number } {
  let teamPoints: TeamPointsTotal = {
    pointsTotal: 0,
    pointsFor: 0,
    pointsAgainst: 0,
    pointsDifference: 0,
  };
  let gamesPlayed = 0;
  games.map((game) => {
    let teamStatus =
      (game.homeTeamId == teamId && game.countHomeScore) ||
      (game.awayTeamId == teamId && game.countAwayScore)
        ? true
        : false;
    if (!teamStatus) {
      return;
    }
    if (
      game.homeScore != null &&
      game.awayScore != null &&
      (teamId == game.homeTeamId || teamId == game.awayTeamId)
    ) {
      if (!game.isWinByDefault) {
        teamPoints.pointsFor +=
          teamId == game.homeTeamId ? game.homeScore : game.awayScore;
        teamPoints.pointsAgainst +=
          teamId == game.homeTeamId ? game.awayScore : game.homeScore;
        teamPoints.pointsDifference =
          teamPoints.pointsFor - teamPoints.pointsAgainst;
        if (game.homeScore > game.awayScore) {
          teamPoints.pointsTotal += teamId == game.homeTeamId ? 2 : 0;
        } else if (game.homeScore < game.awayScore) {
          teamPoints.pointsTotal += teamId == game.awayTeamId ? 2 : 0;
        } else {
          teamPoints.pointsTotal += 1;
        }
      } else {
        if (game.homeScore > game.awayScore) {
          teamPoints.pointsTotal += teamId == game.homeTeamId ? 2 : -1;
        } else {
          teamPoints.pointsTotal += teamId == game.awayTeamId ? 2 : -1;
        }
      }
      gamesPlayed += 1;
    }
  });
  return { teamPointsTotal: teamPoints, gamesPlayed };
}

function getTeamScoreRecord(
  teamId: number,
  games: GameForCalculatingStandings[]
): TeamScoreRecord {
  let record: TeamScoreRecord = {
    wins: 0,
    losses: 0,
    ties: 0,
    defaultLosses: 0,
  };

  games.map((game) => {
    // For each game check what the result was and update the record obj
    if (
      game.homeScore == null ||
      game.awayScore == null ||
      (teamId != game.homeTeamId && teamId != game.awayTeamId)
    ) {
      return;
    }
    let teamStatus =
      (game.homeTeamId == teamId && game.countHomeScore) ||
      (game.awayTeamId == teamId && game.countAwayScore)
        ? true
        : false;
    if (!teamStatus) {
      return;
    }
    let losingTeamId =
      game.homeScore > game.awayScore ? game.awayTeamId : game.homeTeamId;
    // First check if it's a tie
    if (game.homeScore == game.awayScore) {
      record.ties += 1;
    }
    // Then check if it's a win by defualt
    else if (game.isWinByDefault === true) {
      if (losingTeamId == teamId) {
        record.defaultLosses += 1;
      } else {
        record.wins += 1;
      }
    } else {
      if (losingTeamId == teamId) {
        record.losses += 1;
      } else {
        record.wins += 1;
      }
    }
  });
  return record;
}

export function calculateCurrentStandings({
  teams,
  games,
}: {
  teams: { id: number; name: string }[];
  games: GameForCalculatingStandings[];
}): DetailedTeamScoreRecord[] {
  return teams.map((team) => {
    const scoreData = getTeamScoreRecord(team.id, games);
    const { teamPointsTotal, gamesPlayed } = getTeamPointsRecord(
      team.id,
      games
    );
    return {
      scoreRecord: scoreData,
      teamPointsRecord: teamPointsTotal,
      gamesPlayed: gamesPlayed,
      teamId: team.id,
      teamName: team.name,
    };
  });
}

export function generatePlayoffGames(
  teams: DivisionByScheduleIdQuery["division"]["teams"],
  startDate: string,
  numberOfWeeks: number,
  regularWeeks: number,
  exceptionDates: (string | null)[] | null | undefined // Add this parameter
): PlayoffGame[] {
  const playoffGames: PlayoffGame[] = [];

  let numberOfTeams = teams.length;
  let weekNumber = 0;
  let iWeek = 0;
  let gamesInWeek = Math.floor(numberOfTeams / 2);
  let playoffGameCounter = 1;
  while (weekNumber < numberOfWeeks) {
    const week = dayjs(startDate).add(iWeek * 7, "days");
    const weekFormat = week.format("dddd YYYY-MM-DD");

    // Check if week is an exclusion date
    const isExclusionDate = exceptionDates?.find(
      (date) => dayjs(date).format("dddd YYYY-MM-DD") === weekFormat
    );

    if (isExclusionDate) {
      iWeek++;
      continue;
    }

    weekNumber++;
    const isFirstWeek = weekNumber === 1;
    const gameType = isFirstWeek ? 1 : 2;
    for (let game = 1; game <= gamesInWeek; game++) {
      if (isFirstWeek) {
        // First week: Highest vs Lowest seed
        const homeTeamValue = game - 1;
        const awayTeamValue = numberOfTeams - game;

        playoffGames.push({
          id: undefined,
          startDateTimeLocal: week.hour(18).format("YYYY-MM-DD HH:mm:ss"),
          venueId: 0,
          homeTeamId: undefined,
          awayTeamId: undefined,
          gameTypeId: 2, // Playoff Schedule
          isVisible: 0, //Not visible
          week: weekNumber + regularWeeks,
          gameStatusId: 1,
          countHomeScore: true,
          countAwayScore: true,
          homeTeamType: gameType,
          homeTeamValue,
          awayTeamType: gameType,
          awayTeamValue,
          playoffGameNumber: playoffGameCounter,
        });
      } else {
        // Second week onwards: Winners vs Winners, Losers vs Losers
        const isWinnersGame = game <= Math.ceil(gamesInWeek / 2);
        const gameIndex = isWinnersGame
          ? game
          : game - Math.ceil(gamesInWeek / 2);

        playoffGames.push({
          id: undefined,
          startDateTimeLocal: week.hour(18).format("YYYY-MM-DD HH:mm:ss"),
          venueId: 0,
          homeTeamId: undefined,
          awayTeamId: undefined,
          gameTypeId: 2, // Playoff Schedule
          isVisible: 0, //Not visible
          week: weekNumber + regularWeeks,
          gameStatusId: 1,
          countHomeScore: true,
          countAwayScore: true,
          homeTeamType: gameType,
          homeTeamValue: isWinnersGame ? gameIndex : -gameIndex,
          awayTeamType: gameType,
          awayTeamValue: isWinnersGame ? gameIndex + 1 : -(gameIndex + 1),
          playoffGameNumber: playoffGameCounter,
        });
      }
      playoffGameCounter++;
    }

    // Handle odd number of teams
    if (numberOfTeams % 2 !== 0) {
      if (isFirstWeek) {
        playoffGames.push({
          id: undefined,
          startDateTimeLocal: week.hour(18).format("YYYY-MM-DD HH:mm:ss"),
          venueId: 0,
          homeTeamId: undefined,
          awayTeamId: undefined,
          gameTypeId: 2, // Playoff Schedule
          isVisible: 0,
          week: weekNumber + regularWeeks,
          gameStatusId: 1,
          countHomeScore: true,
          countAwayScore: true,
          homeTeamType: 3,
          homeTeamValue: +teams[teams.length - 1].id,
          awayTeamType: undefined,
          awayTeamValue: undefined,
          playoffGameNumber: playoffGameCounter,
          isDeleted: false,
        });
      } else {
        playoffGames.push({
          id: undefined,
          startDateTimeLocal: week.hour(18).format("YYYY-MM-DD HH:mm:ss"),
          venueId: 0,
          homeTeamId: undefined,
          awayTeamId: undefined,
          gameTypeId: 2, // Playoff Schedule
          isVisible: 0,
          week: weekNumber + regularWeeks,
          gameStatusId: 1,
          countHomeScore: true,
          countAwayScore: true,
          homeTeamType: 3,
          homeTeamValue: +teams[teams.length - 1].id,
          awayTeamType: undefined,
          awayTeamValue: undefined,
          playoffGameNumber: playoffGameCounter,
          isDeleted: false,
        });
      }
      playoffGameCounter++;
    }

    // Reduce the number of teams for the next week
    iWeek++;
  }

  return playoffGames;
}

// Creates the date for the week header based on the division start date, week number, and any exclusion dates
export function dateForWeek(
  weekNumber: number,
  division?: DivisionByScheduleIdQuery["division"]
) {
  if (!division) {
    return "";
  }
  let totalWeeksPast = 0;
  for (let i = 0; i < weekNumber; i++) {
    const week = dayjs(division.startDate).add(i * 7, "days");
    const weekFormat = week.format("dddd YYYY-MM-DD");

    // Check if week is an exclusion date
    const isExclusionDate = division.exceptionDates?.find(
      (date) => dayjs(date).format("dddd YYYY-MM-DD") === weekFormat
    );

    if (isExclusionDate) {
      weekNumber++;
    }
    totalWeeksPast = i;
  }

  let week = dayjs(division.startDate).add(totalWeeksPast * 7, "days");
  let weekFormat = week.format("dddd YYYY-MM-DD");

  return weekFormat;
}
