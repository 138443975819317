import { cn } from '../../../lib/utils';
import React, { ReactNode, FC } from 'react';

interface CardProps {
  /**
   * The content that will go inside the form
   */
  children: ReactNode;
  /**
   * Allows optional extra styling
   */
  className?: string;
  onClick?: () => void;
}

const Card: FC<CardProps> = ({ children, className, ...props }: CardProps) => {
  return (
    <div
      className={cn('p-4 bg-white shadow-lg rounded-xl w-full', className)}
      onClick={props.onClick}
      {...props}
    >
      {children}
    </div>
  );
};

export default Card;
