import React from 'react';

const PermissionDenied = () => {
  return (
    <>
      <h3>Permission Denied</h3>
    </>
  );
};

export default PermissionDenied;
