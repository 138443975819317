import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../app/store';
import classes from './ContractManagement.module.css';
import { useTable, useSortBy, Column } from 'react-table';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Region, League, CgOverviewFilters } from '../../types/types';
import { getGameslots } from '../../app/venueMasterSlice';
import CreateGameslot from './CreateGameslot';

interface GameslotProps {
  selectedCapacityGroupId?: number;
  cgOverviewFilters?: CgOverviewFilters;
}

const Gameslots: React.FC<GameslotProps> = ({
  selectedCapacityGroupId,
  cgOverviewFilters,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const { gameslots, gameslotFilter, isLoading }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [idEdit, setIdEdit] = React.useState<number | null>(null);

  const rootPath =
    process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : '';
  const redirectCreateGameslot = () => {
    //navigate(`${rootPath}/league/`);
    setIdEdit(null);
    handleOpen();
  };

  const redirectEditGameslot = (gameslotId: number) => {
    //   navigate(`${rootPath}/league/${LeaguesId}`, {
    //     state: { LeaguesId },
    //   });
    setIdEdit(gameslotId);
    handleOpen();
  };

  useEffect(() => {
    dispatch(getGameslots({ capacityGroupId: selectedCapacityGroupId }));
  }, [dispatch, selectedCapacityGroupId]);

  const COLUMNS: Column<League>[] = [
    {
      Header: 'Date',
      id: 'date',
      accessor: (d: any) => {
        return <div>{moment(d.date).local().format('YYYY-MM-DD')}</div>;
      },
    },
    {
      Header: 'startTime',
      id: 'startTime',
      accessor: (d: any) => {
        return <div>{new Date(d.date).toTimeString().split(' ')[0]}</div>;
      },
    },
    {
      Header: 'Duration',
      id: 'duration',
      accessor: (d: any) => {
        return <div>{d['duration']}</div>;
      },
    },
    {
      Header: 'Capacity Group',
      id: 'capacityGroup',
      accessor: (d: any) => {
        return <div>{d.capacityGroup.name}</div>;
      },
    },
    {
      Header: 'Venue',
      id: 'venue',
      accessor: (d: any) => {
        return <div>{d.venue.name}</div>;
      },
    },
    {
      Header: 'Division',
      id: 'division',
      accessor: (d: any) => {
        return (
          <div>
            <div
              style={{
                display: 'inline-block',
                backgroundColor: d.division?.vmColor,
                height: '16px',
                width: '16px',
                marginRight: '5px',
              }}
            ></div>
            {d.division ? d.division.vmName : 'Open Slot'}
          </div>
        );
      },
    },
    {
      Header: 'Options',
      accessor: (d: any) => {
        return (
          <div className={classes.format__options}>
            <div className={classes.format__option}>
              <Button
                sx={{ color: 'black' }}
                onClick={() => redirectEditGameslot(d.id)}
              >
                {<EditIcon />}{' '}
              </Button>
            </div>
          </div>
        );
      },
    },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => gameslots, [isLoading, gameslots]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columns,
        data: data,
        initialState: {
          hiddenColumns: ['id'],
        },
      },
      useSortBy
    );
  const sportFormatsTable = (
    <TableContainer
      className={classes.shift__container}
      sx={{ maxHeight: '80vh', width: '95vw', maxWidth: '95%' }}
    >
      <Table
        stickyHeader
        aria-label="sticky table"
        {...getTableProps()}
        className={classes.shift__table}
      >
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  sx={{
                    bgcolor: 'var(--primary-blue-400)',
                    color: 'white',
                    fontSize: '18px',
                    textAlign: 'left',
                    // fontWeight: 550,
                  }}
                  {...column.getHeaderProps()}
                >
                  <div className={classes.headAlign}>
                    {column.render('Header')}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow
                hover
                sx={{ borderBottom: '5px' }}
                role="checkbox"
                tabIndex={-1}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      sx={{
                        textAlign: 'left',
                        fontSize: '16px',
                        borderWidth: '2px',
                        borderColor: 'var(--primary-green-500)',
                        paddingBottom: '10px',
                        paddingTop: '10px',
                      }}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
  return (
    <main>
      <h4>Gameslots</h4>
      <div className={classes.container}>
        <div className={classes.filters}></div>
        <div className={classes.button__create}>
          <Button sx={{ color: 'black' }} onClick={redirectCreateGameslot}>
            <AddIcon />
            Add a gameslot
          </Button>
        </div>
        {gameslots.length > 0 && selectedCapacityGroupId !== 0 && (
          <div className={classes.table__venues}>{sportFormatsTable}</div>
        )}
        {!(gameslots.length > 0) && (
          <div className={classes.table__contracts}>No Data Found</div>
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CreateGameslot
            onClose={handleClose}
            id={idEdit}
            selectedCapacityGroupId={selectedCapacityGroupId}
            cgOverviewFilters={cgOverviewFilters}
          />
        </Box>
      </Modal>
    </main>
  );
};

export default Gameslots;
