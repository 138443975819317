import { cn } from '../../../../lib/utils';
import React, { ReactNode } from 'react';

interface Subtitle2Props {
  // The text inside the Headline
  children: ReactNode;
  // Extra css customization
  className?: string;
}

const Subtitle2 = ({ children, className, ...props }: Subtitle2Props) => {
  return (
    <p
      className={cn(
        'font-[600] text-[15px] tracking-[0.067em] leading-[1.5]',
        className
      )}
    >
      {children}
    </p>
  );
};

export default Subtitle2;
