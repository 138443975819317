import React from 'react';
import Button from '../../UI/Button/Button';
import classes from './DeleteShift.module.css';
import { Fragment } from 'react';

interface deleteShiftProps {
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onOkay: React.MouseEventHandler<HTMLButtonElement>;
}

const DeleteShift: React.FC<deleteShiftProps> = (props) => {
  return (
    <Fragment>
      <p> Do you want to delete the shift ?</p>
      <div className='mt-4 flex gap-3'>
        <Button
          variant='primary'
          className={classes.button__alt}
          onClick={props.onOkay}>
          Ok
        </Button>
        <Button
          variant='secondary'
          className={classes.button__alt}
          onClick={props.onClose}>
          Cancel
        </Button>
      </div>
    </Fragment>
  );
};

export default DeleteShift;
