import React, { useEffect, useState } from "react";
import { FormRow, FormRowSelect } from "../UI";
import classes from "./content.module.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Box, Button, Modal } from "@mui/material";
import {
  CREATE_CONTENT,
  UPDATE_CONTENT,
} from "../../graphql/queries/contentManagement";
import { useMutation } from "@apollo/client";
import { getContent, getContentCategories } from "../../app/marketingSlice";
import { getRegions } from "../../app/venueMasterSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../app/globalSlice";

declare module "react-quill" {
  interface QuillStatic {
    register: (module: any, suppressWarning?: boolean) => void;
  }
}

type ContentData = {
  id: number | null;
  category: number;
  categoryEdit: number;
  region: number | null;
  title: string;
  content: string;
  isDefault: boolean;
};

const initialContentData = {
  id: null,
  category: 0,
  categoryEdit: 0,
  region: null,
  title: "",
  content: "",
  isDefault: false,
};

const Content = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { contentCategories, contentEdit }: any = useSelector(
    (state: RootState) => state.marketing
  );
  const { selectedRegions }: any = useSelector(
    (state: RootState) => state.venueMaster
  );
  const [contentData, setContentData] =
    useState<ContentData>(initialContentData);
  const [regions, setRegions] = useState<any>(selectedRegions);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);

  // retrieve region and category from database
  const [CreateContent, { data, loading, error }] = useMutation(CREATE_CONTENT);
  const [UpdateContent] = useMutation(UPDATE_CONTENT);

  useEffect(() => {
    if (!contentCategories.length) {
      dispatch(getContentCategories());
    }
    if (!selectedRegions.length) {
      dispatch(getRegions(""));
    }
  }, [dispatch]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "categoryEdit") {
      if (+e.target.value === 0) {
        setIsEdit(false);
        setContentData((prevState) => ({
          ...prevState,
          id: null,
          category: 0,
          categoryEdit: 0,
          region: null,
          title: "",
          content: "",
          isDefault: false,
        }));
      } else {
        setIsEdit(true);
        setContentData((prevState) => ({
          ...prevState,
          category: 0,
        }));

        const regions = selectedRegions.map((region: any) => {
          return +region.id;
        });

        dispatch(
          getContent({
            category: +e.target.value,
            selectedRegions: regions,
          })
        ).then((res) => {
          if (!res.payload.content && res.payload.message === "No Content") {
            dispatch(displayAlertWarning(res.payload.message));
            setContentData((prevState) => ({
              ...initialContentData,
            }));
            setIsEdit(false);
            return;
          } else {
            setContentData((prevState) => ({
              ...prevState,
              id: res.payload.content.id,
              categoryEdit: res.payload.content.categoryId,
              region: res.payload.content.regionId || 0,
              title: res.payload.content.title,
              content: res.payload.content.content,
              isDefault: res.payload.content.isDefault,
            }));
          }
        });
      }
    }

    setContentData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeText = (value: string) => {
    setContentData((prevState) => ({
      ...prevState,
      content: value,
    }));
  };

  const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContentData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleChangeRegion = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContentData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    // while editing or creating the content
    if (isEdit) {
      if (contentData.region !== 0 || contentData.region !== null) {
        dispatch(
          getContent({
            category: contentData.categoryEdit,
            selectedRegions: e.target.value
              ? [+e.target.value]
              : selectedRegions.map((region: any) => {
                  return +region.id;
                }),
          })
        ).then((res) => {
          if (!res.payload.content && res.payload.message === "No Content") {
            // call a outlay to create a new content
            setOpenCreateModal(true);
          } else {
            setContentData((prevState) => ({
              ...prevState,
              id: res.payload.content.id,
              categoryEdit: res.payload.content.categoryId,
              region: res.payload.content.regionId || 0,
              title: res.payload.content.title,
              content: res.payload.content.content,
              isDefault: res.payload.content.isDefault,
            }));
          }
        });
        return;
      } else {
        process.env.NODE_ENV === "development" &&
          console.log("no region selected");
      }
    }
  };

  const handleCreateNewContentModal = () => {
    // create new content
    setOpenCreateModal(false);
    setIsEdit(false);
    setContentData((prevState) => ({
      ...prevState,
      id: null,
      category: prevState.categoryEdit,
      categoryEdit: 0,
      region: prevState.region,
      title: "",
      content: "",
      isDefault: false,
    }));
  };

  const handleSubmit = () => {
    // submit content to database
    if (isEdit) {
      UpdateContent({
        variables: {
          contentArgs: {
            contentInput: {
              content: contentData.content,
              contentCategoryId: +contentData.categoryEdit,
              regionId: contentData.region ? +contentData.region : null,
              title: contentData.title,
            },
            id: contentData.id,
          },
        },
      }).then((res) => {
        if (res.data.contentUpdate.success) {
          setContentData(initialContentData);
          dispatch(displayAlertSuccess(res.data.contentUpdate.message));
        } else {
          dispatch(displayAlertError(res.data.contentUpdate.message));
        }
      });
    } else {
      CreateContent({
        variables: {
          contentArgs: {
            contentInput: {
              content: contentData.content,
              contentCategoryId: +contentData.category,
              regionId: contentData.region ? +contentData.region : null,
              title: contentData.title,
            },
          },
        },
      }).then((res) => {
        if (res.data.contentCreate.success) {
          setContentData(initialContentData);
          dispatch(displayAlertSuccess(res.data.contentCreate.message));
        } else {
          dispatch(displayAlertError(res.data.contentCreate.message));
        }
      });
    }
    return;
  };

  return (
    <div className={classes.form}>
      <h2>Content Management</h2>
      <FormRowSelect
        name="categoryEdit"
        value={contentData.categoryEdit}
        labelText="Select a category to edit"
        list={[{ id: 0, name: "<Select Category>" }, ...contentCategories]}
        handleChange={handleChange}
      />
      <FormRowSelect
        name="category"
        value={contentData.category}
        labelText="Category"
        list={[{ id: 0, name: "<Select Category>" }, ...contentCategories]}
        handleChange={handleChange}
        isDisabled={isEdit}
      />
      <FormRow
        name="title"
        value={contentData.title}
        labelText="Title"
        handleChange={handleChange}
      />
      <div>
        <FormRowSelect
          name="region"
          value={contentData.region || 0}
          labelText="Region"
          list={[{ id: 0, name: `<Select Region>` }, ...selectedRegions]}
          handleChange={handleChangeRegion}
        />
        <input
          checked={contentData.isDefault}
          type="checkbox"
          id="isDefault"
          name="isDefault"
          onChange={handleChangeCheckbox}
          disabled={true}
        />
      </div>
      <div className={classes.text__editor}>
        <ReactQuill
          style={{ height: "300px", width: "700px" }}
          theme="snow" // you can use 'bubble' theme as well
          value={contentData.content}
          onChange={(text: string) => handleChangeText(text)}
        />
      </div>
      <div className={classes.btn__container}>
        <Button
          variant="contained"
          type="submit"
          // className={classes.btn__submit}
          onClick={handleSubmit}
          // disabled={isLoading}
          sx={{
            color: "var(--primary-blue-500)",
            bgcolor: "var(--primary-red-500)",
            "&:hover": { bgcolor: "var(--primary-red-600)" },
          }}
        >
          {isEdit ? "Edit Content" : "Create Content"}
        </Button>
      </div>
      <Modal
        open={openCreateModal}
        onClose={(e, reason) => {
          // if (reason !== 'backdropClick') setOpenCreateModal(false);
          return;
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div>
            <h5>The content for the selected region does not exist.</h5>
          </div>
          <div className={classes.btn__container__modal}>
            <Button
              variant="contained"
              type="button"
              className={classes.btn__submit}
              sx={{
                color: "var(--primary-blue-500)",
                bgcolor: "var(--primary-red-500)",
                "&:hover": { bgcolor: "var(--primary-red-600)" },
                margin: "10px",
              }}
              onClick={handleCreateNewContentModal}
            >
              Create new Content
            </Button>
            <Button
              variant="contained"
              type="button"
              className={classes.btn__cancel}
              sx={{
                color: "var(--primary-blue-500)",
                bgcolor: "var(--primary-red-500)",
                "&:hover": { bgcolor: "var(--primary-red-600)" },
                margin: "10px",
              }}
              onClick={() => {
                setContentData((prevState) => {
                  return {
                    ...prevState,
                    id: null,
                    title: "",
                    category: prevState.categoryEdit,
                    categoryEdit: 0,
                    region: prevState.region,
                  };
                });
                setOpenCreateModal(false);
                setIsEdit(false);
              }}
            >
              Copy Content to selected region
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Content;
