import { gql } from '@apollo/client';

const GET_GAME_STATUSES = gql`
  query GameStatuses {
    gameStatuses {
      id
      name
    }
  }
`;

export { GET_GAME_STATUSES };
