import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "../Button/Button";
import Headline2Variable from "../Text/Headline/Headline2Variable";
import React, { useEffect } from "react";
import {
  Table as ShadcnTable,
  TableBody as ShadcnTableBody,
  TableCell as ShadcnTableCell,
  TableHead as ShadcnTableHead,
  TableHeader as ShadcnTableHeader,
  TableRow as ShadcnTableRow,
} from "../../UI/shadcn/table";
import dayjs from "dayjs";
import { VenuesTreeQuery } from "@/src/generated/graphql";
import { TeamList } from "../../Admin/Schedule/ScheduleNew";
import { RegularGame } from "../../Admin/Schedule/ScheduleTypes";
import Subtitle1 from "../Text/Subtitle/Subtitle1";
import { Separator } from "../shadcn/separator";
import Tooltip from "../../UI/Tooltip/Tooltip";

interface TimeDistributionDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  divisionTeams: TeamList[];
  gamesByWeek: RegularGame[];
  venuesList: VenuesTreeQuery["venuesTree"];
}

/**
 * A dialog that allows the user to swap two teams
 * @param schedule Array of schedule rows
 * @param open Controls whether the dialog is open or not
 * @param onOpenChange Function to change the open state
 * @param onRestoreSchedule Function to restore the schedule
 * @returns
 */
const TimeDistributionDialog = ({
  open,
  onOpenChange,
  divisionTeams,
  gamesByWeek,
  venuesList,
}: TimeDistributionDialogProps) => {
  const [timeDistribution, setTimeDistribution] = React.useState<{
    [timeSlot: string]: {
      [teamName: string]: { count: number; games: RegularGame[] };
    };
  }>({});
  const [venueDistribution, setVenueDistribution] = React.useState<{
    [venueId: string]: {
      [teamName: string]: { count: number; games: RegularGame[] };
    };
  }>({});
  const [gamesDistribution, setGamesDistribution] = React.useState<{
    [homeTeamName: string]: {
      [awayTeamName: string]: { count: number; games: RegularGame[] };
    };
  }>({});

  useEffect(() => {
    handleTeamsDistribution();
  }, [gamesByWeek]);

  const handleTeamsDistribution = () => {
    // unique teams in division
    const teamIds: number[] = divisionTeams.map((team) => team.id);

    // extract unique venueIds from gamesByWeek
    const venueIds: number[] = [];
    if (gamesByWeek.length === 0) return;

    gamesByWeek.map((game) => {
      if (!venueIds.includes(game.venueId)) {
        venueIds.push(game.venueId);
      }
    });

    // Initialize venueDistribution 2-D array
    const venueDistribution: {
      [venueId: string]: {
        [teamName: string]: { count: number; games: RegularGame[] };
      };
    } = {};
    venueIds.forEach((venueId) => {
      venueDistribution[venueId] = {};
      teamIds.forEach((teamId) => {
        venueDistribution[venueId][teamId] = {
          count: 0,
          games: [],
        };
      });
    });

    // 2-D array of teamIds and venueIds from gamesByWeek

    gamesByWeek.forEach((game) => {
      if (game.homeTeamId) {
        if (!venueDistribution[game.venueId][game.homeTeamId]) {
          venueDistribution[game.venueId][game.homeTeamId] = {
            count: 1,
            games: [game],
          };
        } else {
          venueDistribution[game.venueId][game.homeTeamId] = {
            count: venueDistribution[game.venueId][game.homeTeamId].count + 1,
            games: [
              ...venueDistribution[game.venueId][game.homeTeamId].games,
              game,
            ],
          };
        }
      }

      if (game.awayTeamId) {
        if (!venueDistribution[game.venueId][game.awayTeamId]) {
          venueDistribution[game.venueId][game.awayTeamId] = {
            count: 1,
            games: [game],
          };
        } else {
          venueDistribution[game.venueId][game.awayTeamId] = {
            count: venueDistribution[game.venueId][game.awayTeamId].count + 1,
            games: [
              ...venueDistribution[game.venueId][game.awayTeamId].games,
              game,
            ],
          };
        }
      }
    });

    // extract unique timeSlots from gamesByWeek
    const timeSlots: string[] = [];

    gamesByWeek.forEach((game) => {
      const timeSlot = dayjs(game.startDateTimeLocal).format("HH:mm");
      if (!timeSlots.includes(timeSlot)) {
        timeSlots.push(timeSlot);
      }
    });
    timeSlots.sort();

    // Initialize timeDistribution 2-D array
    const timeDistribution: {
      [timeSlot: string]: {
        [teamName: string]: { count: number; games: RegularGame[] };
      };
    } = {};
    timeSlots.forEach((timeSlot) => {
      timeDistribution[timeSlot] = {};
      teamIds.forEach((teamId) => {
        timeDistribution[timeSlot][teamId] = {
          count: 0,
          games: [],
        };
      });
    });

    // 2-D array of teamIds and timeSlots from gamesByWeek

    gamesByWeek.forEach((game) => {
      const timeSlot = dayjs(game.startDateTimeLocal).format("HH:mm");

      if (game.homeTeamId) {
        if (!timeDistribution[timeSlot][game.homeTeamId]) {
          timeDistribution[timeSlot][game.homeTeamId] = {
            count: 0,
            games: [],
          };
        }
        timeDistribution[timeSlot][game.homeTeamId] = {
          count: timeDistribution[timeSlot][game.homeTeamId].count + 1,
          games: [...timeDistribution[timeSlot][game.homeTeamId].games, game],
        };
      }
      if (game.awayTeamId) {
        if (!timeDistribution[timeSlot][game.awayTeamId]) {
          timeDistribution[timeSlot][game.awayTeamId] = {
            count: 0,
            games: [],
          };
        }

        timeDistribution[timeSlot][game.awayTeamId] = {
          count: timeDistribution[timeSlot][game.awayTeamId].count + 1,
          games: [...timeDistribution[timeSlot][game.awayTeamId].games, game],
        };
      }
    });

    // teamIds to names in timeDistribution and venueDistribution
    const teamIdToName: { [id: string]: string } = {};
    divisionTeams.forEach((team) => {
      teamIdToName[team.id] = team.name;
    });

    const newTimeDistribution: {
      [timeSlot: string]: {
        [teamName: string]: { count: number; games: RegularGame[] };
      };
    } = {};
    Object.keys(timeDistribution).forEach((timeSlot) => {
      newTimeDistribution[timeSlot] = {};
      Object.keys(timeDistribution[timeSlot]).forEach((teamId) => {
        newTimeDistribution[timeSlot][teamIdToName[teamId]] =
          timeDistribution[timeSlot][teamId];
      });
    });
    const newVenueDistribution: {
      [venueId: string]: {
        [teamName: string]: { count: number; games: RegularGame[] };
      };
    } = {};
    // venueIds to names in venueDistribution

    Object.keys(venueDistribution).forEach((venueId) => {
      newVenueDistribution[venueId] = {};
      Object.keys(venueDistribution[venueId]).forEach((teamId) => {
        newVenueDistribution[venueId][teamIdToName[teamId]] =
          venueDistribution[venueId][teamId];
      });
    });

    //Games Distribution by matchups
    const newGamesDistribution: {
      [homeTeamName: string]: {
        [awayTeamName: string]: { count: number; games: RegularGame[] };
      };
    } = {};

    divisionTeams.forEach((team) => {
      newGamesDistribution[team.name] = {};
      divisionTeams.forEach((team2) => {
        newGamesDistribution[team.name][team2.name] = {
          count: 0,
          games: [],
        };
      });
    });

    gamesByWeek.forEach((game) => {
      if (game.homeTeamId !== null || game.awayTeamId !== null) {
        const homeTeamName = teamIdToName[game.homeTeamId || 0];
        const awayTeamName = teamIdToName[game.awayTeamId || 0];
        if (!homeTeamName || !awayTeamName) return;
        if (!newGamesDistribution[homeTeamName][awayTeamName]) {
          newGamesDistribution[homeTeamName][awayTeamName] = {
            count: 0,
            games: [],
          };
        }
        if (!newGamesDistribution[awayTeamName][homeTeamName]) {
          newGamesDistribution[awayTeamName][homeTeamName] = {
            count: 0,
            games: [],
          };
        }

        newGamesDistribution[homeTeamName][awayTeamName] = {
          count: newGamesDistribution[homeTeamName][awayTeamName].count + 1,
          games: [
            ...newGamesDistribution[homeTeamName][awayTeamName].games,
            game,
          ],
        };
        newGamesDistribution[awayTeamName][homeTeamName] = {
          count: newGamesDistribution[awayTeamName][homeTeamName].count + 1,
          games: [
            ...newGamesDistribution[awayTeamName][homeTeamName].games,
            game,
          ],
        };
      }
    });

    setTimeDistribution(newTimeDistribution);
    setVenueDistribution(newVenueDistribution);
    setGamesDistribution(newGamesDistribution);
  };

  const createTimeDistributionRows = () => {
    const rows: any[] = [];
    Object.keys(timeDistribution).forEach((timeSlot) => {
      const row: any = [];
      row.push(<ShadcnTableCell>{timeSlot}</ShadcnTableCell>);
      Object.keys(timeDistribution[timeSlot]).forEach((teamName) => {
        const times: any = timeDistribution[timeSlot][teamName].games.map(
          (game) => {
            return <p>{dayjs.utc(game.startDateTimeLocal).format("MMM D")}</p>;
          }
        );
        row.push(
          <ShadcnTableCell>
            <Tooltip
              hoverText={times}
              trigger={timeDistribution[timeSlot][teamName].count || 0}
            />
          </ShadcnTableCell>
        );
      });
      rows.push(<ShadcnTableRow>{row}</ShadcnTableRow>);
    });
    return rows;
  };

  const createVenueDistributionRows = () => {
    const venues = venuesList.filter((venue) => {
      return Object.keys(venueDistribution).includes(venue.id);
    });

    const rows: any[] = [];
    Object.keys(venueDistribution).forEach((venueId) => {
      const row: any = [];
      const venue = venues.find((venue) => venue.id === venueId);
      row.push(<ShadcnTableCell>{venue?.venueName}</ShadcnTableCell>);
      Object.keys(venueDistribution[venueId]).forEach((teamName) => {
        row.push(
          <ShadcnTableCell>
            {venueDistribution[venueId][teamName].count || 0}
          </ShadcnTableCell>
        );
      });
      rows.push(<ShadcnTableRow>{row}</ShadcnTableRow>);
    });
    return rows;
  };

  const createGamesDistributionRows = () => {
    const rows: any[] = [];
    Object.keys(gamesDistribution).forEach((homeTeamName) => {
      const row: any = [];
      row.push(<ShadcnTableCell>{homeTeamName}</ShadcnTableCell>);
      Object.keys(gamesDistribution[homeTeamName]).forEach((awayTeamName) => {
        const times: any = gamesDistribution[homeTeamName][
          awayTeamName
        ].games.map((game) => {
          return (
            <p>{dayjs.utc(game.startDateTimeLocal).format("MMM D, h:mm A")}</p>
          );
        });
        row.push(
          <ShadcnTableCell>
            <Tooltip
              hoverText={times}
              trigger={
                homeTeamName === awayTeamName
                  ? "-"
                  : gamesDistribution[homeTeamName][awayTeamName].count || 0
              }
            />
          </ShadcnTableCell>
        );
      });
      rows.push(<ShadcnTableRow>{row}</ShadcnTableRow>);
    });
    return rows;
  };

  return (
    <Dialog
      open={open}
      maxWidth="xl"
      aria-labelledby="responsive-dialog-title"
      sx={{
        zIndex: 30,
        marginTop: "8vh",
      }}
    >
      <DialogContent>
        <div className="max-h-[70vh]">
          <div className="flex flex-col gap-2">
            <Subtitle1>Time Slot Distribution</Subtitle1>
            <Separator />
            <ShadcnTable>
              <ShadcnTableHeader>
                <ShadcnTableRow>
                  {/* TEAM NAMES */}
                  <ShadcnTableHead className="pl-0">TimeSlots</ShadcnTableHead>
                  {divisionTeams.map((team) => {
                    return (
                      <ShadcnTableHead className="pl-0">
                        {team.name}
                      </ShadcnTableHead>
                    );
                  })}
                </ShadcnTableRow>
              </ShadcnTableHeader>
              <ShadcnTableBody className="text-center">
                {createTimeDistributionRows()}
              </ShadcnTableBody>
            </ShadcnTable>
          </div>
          <div className="flex flex-col gap-2">
            <Subtitle1>Venue Distribution</Subtitle1>
            <Separator />
            <ShadcnTable>
              <ShadcnTableHeader>
                <ShadcnTableRow>
                  {/* TEAM NAMES */}
                  <ShadcnTableHead className="pl-0">Venues</ShadcnTableHead>
                  {divisionTeams.map((team) => {
                    return (
                      <ShadcnTableHead className="pl-0">
                        {team.name}
                      </ShadcnTableHead>
                    );
                  })}
                </ShadcnTableRow>
              </ShadcnTableHeader>
              <ShadcnTableBody className="text-center">
                {createVenueDistributionRows()}
              </ShadcnTableBody>
            </ShadcnTable>
          </div>
          <div className="flex flex-col gap-2">
            <Subtitle1>Games Distribution</Subtitle1>
            <Separator />
            <ShadcnTable>
              <ShadcnTableHeader>
                <ShadcnTableRow>
                  <ShadcnTableHead className="pl-0"></ShadcnTableHead>
                  {/* TEAM NAMES */}
                  {divisionTeams.map((team) => {
                    return (
                      <ShadcnTableHead className="pl-0">
                        {team.name}
                      </ShadcnTableHead>
                    );
                  })}
                </ShadcnTableRow>
              </ShadcnTableHeader>
              <ShadcnTableBody className="text-center">
                {createGamesDistributionRows()}
              </ShadcnTableBody>
            </ShadcnTable>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="secondary"
          onClick={() => onOpenChange(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimeDistributionDialog;
