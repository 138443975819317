import {
  TextField,
  MenuItem,
  createTheme,
  ThemeProvider,
  InputAdornment,
} from '@mui/material';
import { FormRowSelectProps } from './types';

const theme = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& label.Mui-focused': {
            color: 'var(--primary-blue-500)',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: 'var(--primary-blue-500)',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'var(--primary-blue-300)',
            },
            '&:hover fieldset': {
              borderColor: 'var(--primary-blue-400)',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'var(--primary-blue-500)',
            },
          },
        },
      },
    },
  },
});

const FormRowSelect: React.FC<FormRowSelectProps> = ({
  type,
  name,
  value,
  defaultValue,
  handleChange,
  labelText,
  helperText,
  adornment,
  list,
  width,
  paddingBottom,
  paddingRight,
  error,
  isDisabled,
}) => {
  const w = width ? width : '300px';
  const pb = paddingBottom ? paddingBottom : '20px';
  const pr = paddingRight ? paddingRight : '30px';

  return (
    <ThemeProvider theme={theme}>
      <TextField
        select
        disabled={isDisabled}
        name={name}
        onChange={handleChange}
        defaultValue={defaultValue}
        type={type}
        value={value}
        label={labelText}
        helperText={helperText}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>{adornment}</InputAdornment>
          ),
        }}
        sx={{
          minWidth: 50,
          width: w,
          paddingBottom: pb,
          paddingRight: pr,
        }}
        error={error}
      >
        {list?.map((itemValue, index) => (
          <MenuItem
            key={index}
            value={itemValue.id}
            sx={{
              paddingLeft: '20px',
            }}
            className='text-red-500'
          >
            {itemValue.name}
          </MenuItem>
        ))}
      </TextField>
    </ThemeProvider>
  );
};

export default FormRowSelect;
