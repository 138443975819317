import React, { ReactNode, FC, HTMLProps } from "react";
import { cn } from "../../../lib/utils";
import Body2 from "../Text/Body/Body2";
import { CircleX, X } from "lucide-react";

interface ChipProps extends HTMLProps<HTMLDivElement> {
  /**
   * The string for the chip
   */
  text: string;
  /**
   * True if the chip should have an outline
   */
  outline?: boolean;
  /**
   * The class name of the text inside the chip
   */
  textClassName?: string;
  /**
   * The class name of the chip
   */
  className?: string;
  /**
   * onClick for the delete button
   */
  onDelete: () => void;
}

const FilterChip: FC<ChipProps> = ({
  outline = false,
  text,
  textClassName,
  className,
  onDelete,
  ...props
}: ChipProps) => {
  return (
    <div
      className={cn(
        "rounded-full bg-secondary-95 py-1 pl-2 pr-1 justify-center items-center text-center inline-block",
        outline && "border border-secondary-60",
        className
      )}
      {...props}
    >
      <div className="flex flex-row items-center justify-between gap-1">
        <Body2 className={cn(textClassName)}>{text}</Body2>
        <X
          onClick={onDelete}
          className="w-5 h-5 rounded-full text-error-30 hover:bg-error-95 hover:cursor-pointer"
        />
      </div>
    </div>
  );
};

export default FilterChip;
