import classes from "./RequestedShift.module.css";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRequestedShifts,
  updateRequestedShift,
  getAllLocations,
  handleRequestedShiftFilters,
} from "../../../app/shiftSlice";
import moment from "moment";
import { FormRow, FormRowSelect, LoadingMaterialUI } from "../../UI";
import { Button } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useTable, useSortBy } from "react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { AppDispatch, RootState } from "../../../app/store";

const RequestedShift = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    requestedShifts,
    isLoading,
    locationsOptions,
    requestedShiftFilters,
  } = useSelector((state: RootState) => state.shift);

  useEffect(() => {
    if (!locationsOptions.length) {
      dispatch(getAllLocations());
    }
  }, [dispatch, locationsOptions]);

  useEffect(() => {
    dispatch(getAllRequestedShifts(requestedShiftFilters));
  }, [dispatch, requestedShiftFilters]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      handleRequestedShiftFilters({
        ...requestedShiftFilters,
        [e.target.name]: e.target.value,
      })
    );
  };

  const handleAssignShift = (shift: any) => {
    dispatch(updateRequestedShift(shift)).then(() => {
      // the value of requestedShiftFilters doesnt get updated here, after we enter the link for first time
      dispatch(getAllRequestedShifts(requestedShiftFilters));
    });
  };

  const COLUMNS = [
    {
      Header: "ShiftID#",
      accessor: "requestedShift.shiftId",
    },
    {
      Header: "LA",
      // accessor: 'user.FirstName',
      accessor: "user.firstName",
    },
    {
      Header: "LA Email Id",
      //accessor: 'user.Email',
      accessor: "user.email",
    },
    {
      Header: "Start",
      accessor: "requestedShift.shift.startLocal",
      Cell: ({ value }: any) => {
        return moment(value).local().format("ddd MMM,DD YYYY HH:mm");
      },
    },
    {
      Header: "End",
      accessor: "requestedShift.shift.endLocal",
      Cell: ({ value }: any) => {
        return moment(value).local().format("ddd MMM,DD YYYY HH:mm");
      },
    },
    {
      Header: "Hours",
      accessor: (value: any) => {
        return value.requestedShift.shift.hours;
      },
    },
    {
      Header: "Location",
      accessor: "location.name",
    },
    // {
    //   Header: 'League',
    //   accessor: 'league.Name',
    // },
    {
      Header: "Action",
      id: "action",
      accessor: (value: any) => {
        return (
          <Button
            type="button"
            className={classes.delete__btn}
            onClick={() => {
              handleAssignShift(value.requestedShift);
            }}
          >
            Assign
          </Button>
        );
      },
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => requestedShifts, [isLoading]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columns as any,
        data: data,
        // initialState: {
        //   hiddenColumns: ['requestedShift.shiftId'],
        // },
      },
      useSortBy
    );

  const displayedShiftReactTableMaterialUI = (
    <TableContainer
      className={classes.shift__container}
      sx={{ maxHeight: "80vh", width: "90vw", maxWidth: "90%" }}
    >
      <Table
        stickyHeader
        aria-label="sticky table"
        {...getTableProps()}
        className={classes.shift__table}
      >
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <TableCell
                  sx={{
                    bgcolor: "var(--primary-blue-500)",
                    color: "var(--grey-50)",
                    fontSize: "16px",
                    textAlign: "center",
                    fontWeight: 550,
                  }}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <div className={classes.headAlign}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDownwardIcon />
                        ) : (
                          <ArrowUpwardIcon />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      sx={{ textAlign: "center" }}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  // const displayedShifts = (
  //   <div className={classes.shift__container}>
  //     <table className={classes.shift__table}>
  //       <thead>
  //         <tr>
  //           <th>ShiftId#</th>
  //           <th>LA</th>
  //           <th>LA email Id</th>
  //           <th>Start</th>
  //           <th>End</th>
  //           <th>Location/Site</th>
  //           <th>Actions</th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {requestedShifts?.map((shift) => (
  //           <tr key={shift.id}>
  //             <td>{shift.shiftId}</td>
  //             <td>{shift.user.name}</td>
  //             <td>{shift.user.email}</td>
  //             <td>
  //               {moment(shift.shift.startLocal)
  //                 .local()
  //                 .format('ddd MMM,DD YYYY, HH:mm')}
  //             </td>
  //             <td>
  //               {moment(shift.shift.endLocal)
  //                 .local()
  //                 .format('ddd MMM,DD YYYY, HH:mm')}
  //             </td>
  //             <td>{shift.shift.location.name}</td>
  //             <td>
  //               <Button
  //                 type="button"
  //                 className={classes.delete__btn}
  //                 onClick={() => {
  //                   handleAssignShift(shift);
  //                 }}
  //               >
  //                 Assign
  //               </Button>
  //             </td>
  //           </tr>
  //         ))}
  //       </tbody>
  //     </table>
  //   </div>
  // );

  if (isLoading) {
    return (
      <div>
        <LoadingMaterialUI />
      </div>
    );
  }

  return (
    <main>
      <h3>Shifts Requested</h3>
      <div className={classes.shifts__filters}>
        <div>
          <FormRow
            type="date"
            name="fromDate"
            // defaultValue={requestedShiftFilters.fromDate}
            value={requestedShiftFilters.fromDate}
            labelText="Start Date"
            handleChange={handleChange}
          />
          <p className={classes.para}>to</p>
          <FormRow
            type="date"
            name="toDate"
            // defaultValue={requestedShiftFilters.toDate}
            value={requestedShiftFilters.toDate}
            labelText="End Date"
            handleChange={handleChange}
          />
        </div>
        <div>
          <FormRowSelect
            name="locationId"
            value={requestedShiftFilters.locationId}
            labelText="Location"
            list={[{ id: 0, name: "All Location" }, ...locationsOptions]}
            handleChange={handleChange}
          />
        </div>
      </div>
      {/* {requestedShifts?.length ? displayedShifts : `No such records`} */}
      {requestedShifts?.length
        ? displayedShiftReactTableMaterialUI
        : `No such records`}
    </main>
  );
};

export default RequestedShift;
